import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import LandingpageFirstHeader from '../common/LandingpageFirstHeader';
import CatalogueSecondaryHeader from '../dataCatalogue/landingPage/components/CatalogueSecondaryHeader';
import LoadingComponent from '../common/loadingComponent';
import { useStore } from 'react-redux';
import { getLineageData } from '../../../store/modules/lineage/lineageAction';
import { Literals } from '../common/literals';
import NoResultsFound from '../flowsNew/createFlows/NoResultsFound';

function ChartIndex() {

    let { chartId } = useParams();
    let store = useStore()
    let navigate = useNavigate()
    const [showError, setshowError] = useState(false)

    useEffect(() => {
        if (chartId) {
            store.dispatch(getLineageData(chartId, 'chart', 'PARENT')).then((resp) => {
                let chartPodId = resp?.data?.pod_id;
                let chartName =resp?.data?.name;

                if (chartPodId) {
                    setshowError(false)
                    let redirecturl = `${Literals.links.DATA_CATALOG_POD_DETAILS}${chartPodId}?chartId=${chartId}&chartName=${encodeURIComponent(chartName)}#charts`;
                    navigate(redirecturl)
                }
            }).catch(err => navigate(Literals?.links?.PAGE_404))
        }
    }, [chartId])

    return (
        <>
            <LandingpageFirstHeader title="Navigator" />
            <CatalogueSecondaryHeader
                showPage={'Charts'}
                currentMenu={'Charts'}
                setCurrentMenu={() => { }}
                pageName={'Charts'}
                isEditPermission={true}
                podData={{}}
                getDictionaryTableData={() => { }}
                isUploadInProgress={false}
            />
            {/* {showError ? <NoResultsFound height='100vh'></NoResultsFound> : null} */}
        </>

    )
}

export default ChartIndex