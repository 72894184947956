import React, { useEffect, useRef, useState } from 'react';
import { Handle } from 'reactflow';

import { Dropdown, Tooltip } from 'antd';
import { connect, useDispatch, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteTransformer, showFlowsTransformdetails } from '../../../../../store/modules/flows/NewFlowsRedux/flowsActions';
import { CHECKMARKCIRCLE, DELETETRANSFORMER, DISMISSCIRCLE, ERRORCIRCLE, FILTERBLUE, FILTERFLOW, LOADINGCIRCLE, LOADINGCIRCLEQUEUE, STOPOCTAGON } from '../../../../Common/iconSource';
import { ExecuteTransformer, checkIsEditorFlow, getTransformerName, isTransformerNamePresent, transformerMenuItem } from '../../transformers/commonFunctions/FlowsHelperFunction';
import { addSelectedTransformerClass } from '../../../common/helperFunctions';
import ConfigureTransformerModal from '../../transformers/commonFunctions/ConfigureTransformerModal';

const Filters = (props) => {
    const store = useStore()
    const dispatch = useDispatch()
    const { flowId } = useParams();
    const [openConfigureModal, setopenConfigureModal] = useState(false)
    const [newActiveKey, setnewActiveKey] = useState('')
    const [openDropdownMenu, setopenDropdownMenu] = useState(false)

    let clickCount = useRef(0);
    let { currentTransformer, executeEntireFlow, transformerProgress, id, flowsElements } = props
    const getStatus = () => {

        if (transformerProgress && transformerProgress.length) {
            let currentStatus = ''
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;

            if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'starting' || currentStatus?.toLowerCase() === 'running')) {
                return <span className='bx-spin d-inline-flex'><LOADINGCIRCLE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'success' || currentStatus?.toLowerCase() === 'done' || currentStatus?.toLowerCase() === 'finished')) {
                return <CHECKMARKCIRCLE />
            }
            else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error')) {
                return <DISMISSCIRCLE />
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'queued')) {
                return <span className='bx-spin d-inline-flex'><LOADINGCIRCLEQUEUE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'aborted')) {
                return <span className='d-flex'> <STOPOCTAGON /></span>
            }
            else {
                return <ERRORCIRCLE />
            }

        }
        else if (executeEntireFlow && !transformerProgress.length) {
            return <ERRORCIRCLE />//<span className='bx-spin d-inline-flex'><LOADINGCIRCLE /></span>

        } else if (!transformerProgress.length && !executeEntireFlow) {
            return <ERRORCIRCLE />
        }

        // }


    }
    // const getTransformerName = (valueFor = '') => {

    //     // if (currentTransformer?.id === id) {
    //     let transformerName = flowsElements.find((ele) => ele.id === id)?.transformerName;
    //     // if (transformerName && transformerName?.length > 10 && valueFor === '') {
    //     //     return `${transformerName?.slice(0, 6)}...`
    //     // } else {
    //         return transformerName ? transformerName : ''
    //     // }

    //     // } else {
    //     //     return ''
    //     // }
    // }
    const getInitialStatus = () => {

        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        return currentStatus ? currentStatus?.toLowerCase() : 'initial'
    }


    const getErrorMsg = () => {
        let error_msg = transformerProgress.find(element => {
            return element.crnt_transformer === id
        })?.error_msg;
        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        return (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error' || currentStatus?.toLowerCase() === 'aborted') ? error_msg : null

    }

    // useEffect(() => {
    //     if (currentTransformer?.id) {

    //         addSelectedTransformerClass(currentTransformer?.id, currentTransformer?.type)
    //     }
    // }, [flowsElements, currentTransformer])

    const onDeleteClick = () => {
        store.dispatch(deleteTransformer(id, flowId))
    }
    const onRunClick = () => {
        store.dispatch(ExecuteTransformer('menuClick'))
    }

    useEffect(() => {

        if (!openConfigureModal) {
            setnewActiveKey('')

        } else {
            dispatch(showFlowsTransformdetails(true));

        }
    }, [openConfigureModal])
    return (
        <>
            {openConfigureModal ? <ConfigureTransformerModal setOpenModal={setopenConfigureModal} newActiveKey={newActiveKey} openModal={openConfigureModal} /> : null}


            <Dropdown
                destroyPopupOnHide={true}
                menu={{
                    items: dispatch(transformerMenuItem(onRunClick, setopenConfigureModal, setnewActiveKey, onDeleteClick, store.dispatch(checkIsEditorFlow()))),
                    onClick: (e) => e.domEvent.stopPropagation()
                }}
                open={openDropdownMenu}
                // trigger={["click"]}
                onOpenChange={() => setopenDropdownMenu(false)}
                placement="bottomRight"
                overlayStyle={{ border: 'solid 1px #e4e4e4' }}
            >
                <div className='transformer-transperent-div'
                >
                    <div className={`transformer-box merge-box ${currentTransformer.id === id ? `transformer-active` : ``}`}>
                        <Handle type="source" position="right" />
                        <div>
                            {/* <img src={filter} alt={`filter`} /> */}
                            <div className='transformer-icon'
                                // onClick={(e) => {

                                //     clickCount.current++;
                                //     setTimeout(function () {
                                //         if (clickCount.current === 1) {
                                //             // Single click action
                                //             setopenDropdownMenu(true)
                                //         } else if (clickCount.current === 2) {
                                //             // Double click action

                                //             setopenDropdownMenu(false)
                                //             setopenConfigureModal(true);
                                //         }
                                //         clickCount.current = 0;
                                //     }, 300);
                                // }}
                                onClick={() => {
                                    setopenDropdownMenu(false)
                                    setopenConfigureModal(true);
                                }
                                }
                                // onDoubleClickCapture={() => {
                                //     setopenDropdownMenu(false)
                                //     setopenConfigureModal(true);
                                // }
                                // }
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    setopenDropdownMenu(true);
                                }}
                            >

                                <FILTERBLUE height="34" width="34" color='#692AA4' />
                            </div>
                            {/* {!window.location.href.includes('viewFlows') && currentTransformer && currentTransformer.id === id ? <span className="transformer-bottom-img-delete cursor-pointer" onClick={(e) => { e.stopPropagation(); store.dispatch(deleteTransformer(id, flowId)) }} >
                                <DELETETRANSFORMER />
                            </span> : null} */}
                            {/* <div className="transformer-bottom-img" >
                        {flowsElements.find(item => item.id === id)?.status === 'success' ? <CHECKMARKCIRCLE /> : flowsElements.find(item => item.id === id)?.status === 'error' ? <DISMISSCIRCLE /> : transformerLoading ? <LOADINGCIRCLE /> : <ERRORCIRCLE />}
                    </div> */}
                            {getErrorMsg() ? <div className="transformer-bottom-img" >
                                <Tooltip placement='bottomLeft' title={getErrorMsg()}> {getStatus()}</Tooltip>
                            </div> : getInitialStatus() === 'initial' ? <Tooltip title={'Transformer not executed yet'}><div className="transformer-bottom-img" >
                                {getStatus()}
                            </div></Tooltip> : <div className="transformer-bottom-img" >
                                {getStatus()}
                            </div>}
                            <Tooltip title={dispatch(getTransformerName(id))}>
                                <div style={{color:'#692AA4'}} className={dispatch(isTransformerNamePresent(id)) ? "transformer-bottom-name" : "error-transformer-bottom-name"} >

                                    {dispatch(getTransformerName(id))}

                                </div>
                            </Tooltip>
                        </div>
                        <Handle type="target" position="left" />
                    </div>
                </div>
            </Dropdown>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        flowsElements: state.Lab.flowsElements,
        currentTransformer: state.Lab.currentTransformer,

        executeEntireFlow: state.Lab.executeEntireFlowStart,
        transformerProgress: state.Lab.transformerProgress,

    };
};

export default connect(mapStateToProps)(Filters)