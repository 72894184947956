import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DOWNARROW } from '../../Common/iconSource';
import { Badge, notification, Popover, Tooltip } from 'antd';
import { Literals } from '../common/literals';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../../services/SocketContextProvider';
import { getObjectLength, stringToColor } from '../common/helperFunctions';
import _ from 'lodash';
import { emitToastNotification } from '../../../helpers/toast_helper';
import { setclusterStatusNotification } from '../../../store/modules/common/Notifications/NotificationsReducer';

const ClusterStatusNotification = React.forwardRef(({ fromRoute }, ref) => {

    const history = useNavigate()
    const dispatch = useDispatch();
    const [clusterStatus, setclusterStatus] = useState(null)
    const [userDetails, setuserDetails] = useState([])
    const [showProcessingDot, setshowProcessingDot] = useState(false)
    const CurrentUserDetails = useSelector(state => state?.UserReducer?.user?.userDetails)
    const SocketContext = useSocket(null);

    const clusterStatusNotifications = useSelector(state => state?.CommonComponent?.Notifications?.clusterStatusNotification)

    let localStorageUsers = localStorage.getItem('user');
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (message, placement) => {
        setshowProcessingDot(true);
        api.open({
            message: message,
            duration: 2,
            placement,
            closeIcon: false,

            className: 'user-status-notification',
        });
        setTimeout(() => {
            setshowProcessingDot(false)
        }, 5000);

    };



    function findNewEmails(previousList, currentList) {
        // Use a Set to store previous emails for quick lookup
        const previousEmails = new Set(previousList);

        // Filter current list to find new emails
        const newEmails = currentList.filter(email => !previousEmails.has(email));

        return newEmails;
    }
    function findRemovedEmails(previousList, currentList) {
        // Use a Set to store current emails for quick lookup
        const currentEmails = new Set(currentList);

        // Filter previous list to find removed emails
        const removedEmails = previousList.filter(email => !currentEmails.has(email));

        return removedEmails;
    }

    const createStatus = (clusterStatusNotifications) => {
        if (clusterStatusNotifications) {
            setclusterStatus(clusterStatusNotifications?.state)
            if (SocketContext?.userDetails?.length) {
                let currentEmailList = [];
                if (clusterStatusNotifications?.users_detail?.length) {
                    currentEmailList = clusterStatusNotifications?.users_detail.map((item) => item.email);
                }
                let previousEmailList = userDetails.map((item) => item.email);
                let userList = SocketContext?.userList;

                const userData = _.sortBy(SocketContext?.userDetails, [
                    (o) => o?.id !== CurrentUserDetails?.id,
                    (o) => o?.firstName?.toLocaleLowerCase()
                ])



                if (previousEmailList?.length && currentEmailList?.length && (currentEmailList?.length > previousEmailList?.length)) {

                    let email = findNewEmails(previousEmailList, currentEmailList);


                    if (email?.length && userList?.length) {
                        let onlineUser = userList.find(item => email.includes(item.email));

                        if (getObjectLength(onlineUser)) {
                            let msg = <div className='d-flex align-items-center'>
                                <div className="login-user-initial-tile fw-800"
                                    style={{ background: stringToColor(`${onlineUser?.first_name} ${onlineUser?.last_name}`), color: '#FFF' }}
                                >
                                    {
                                        onlineUser?.first_name ? `${onlineUser?.first_name?.charAt(0)?.toUpperCase()}${onlineUser?.last_name?.charAt(0)?.toUpperCase()}` : null
                                    }
                                </div>

                                <div className='paragraph ml-2'><span className='text-capitalize text-elipse fw-800'>{`${onlineUser?.first_name} ${onlineUser?.last_name}`}</span> is now <span style={{ color: "#28A745", fontWeight: "600" }}>online</span></div>
                            </div >
                            openNotification(msg, 'topRight')
                        }
                    }
                } else if (previousEmailList?.length && currentEmailList?.length && currentEmailList?.length < previousEmailList?.length) {


                    let email = findRemovedEmails(previousEmailList, currentEmailList);


                    if (email?.length && userDetails?.length) {
                        let offlineUser = userDetails.find(item => email.includes(item.email));
                        if (getObjectLength(offlineUser)) {
                            let msg = <div className='d-flex align-items-center'>
                                <div className="login-user-initial-tile fw-800"
                                    style={{ background: stringToColor(`${offlineUser?.first_name} ${offlineUser?.last_name}`), color: '#FFF' }}
                                >
                                    {
                                        offlineUser?.first_name ? `${offlineUser?.first_name?.charAt(0)?.toUpperCase()}${offlineUser?.last_name?.charAt(0)?.toUpperCase()}` : null
                                    }
                                </div>
                                <div className='paragraph ml-2 '><span className='text-capitalize text-elipse fw-800'>{`${offlineUser?.first_name} ${offlineUser?.last_name} `}</span> is now <span style={{ color: "#CB1010", fontWeight: "600" }}>offline</span></div>

                            </div>
                            openNotification(msg, 'topRight')
                        }
                    }
                }
                setuserDetails(userData);


            }
        }
    }

    useEffect(() => {


        if (SocketContext?.socketResponse && SocketContext?.socketResponse?.type === 'heartbeat') {
            dispatch(setclusterStatusNotification(SocketContext?.socketResponse))
            // createStatus(SocketContext?.socketResponse)
        }



        setTimeout(() => {
            SocketContext.checkConnectionState();
        }, 5000)



    }, [SocketContext]);

    useEffect(() => {
        if (clusterStatusNotifications) {
            
            createStatus(clusterStatusNotifications)
        }
    }, [clusterStatusNotifications]);
    // React.useImperativeHandle(ref, () => ({
    //     refreshNotification: () => {

    //     }
    // }), [])

    

    return (


        <div className='mr-3 d-flex align-items-center'>
            {contextHolder}
            {userDetails?.length ? <Popover id={'online-popover'} placement="bottom" trigger={"click"} className=""
                content={() => <div className='users-list pt-2 pb-2'>{userDetails?.map((userItem) => <div className='d-flex pb-1 pt-2 justify-content-between align-items-center'>
                    <div className='col-3'>

                        <Tooltip placement='left' title={userItem?.email}> <div className="login-user-initial-tile position-relative fw-800"
                            style={{ background: stringToColor(`${userItem?.first_name} ${userItem?.last_name}`), color: '#FFF' }}
                        >
                            {
                                userItem?.first_name ? `${userItem?.first_name?.charAt(0)?.toUpperCase()}${userItem?.last_name?.charAt(0)?.toUpperCase()}` : null

                            }
                        </div></Tooltip>
                        {userItem.id === CurrentUserDetails?.id ? <span style={{ fontSize: "0.5rem", padding: "1px" }} className="me-icon bg-warning text-dark px-1 rounded text-uppercase fontInterSemiBold ml-auto">me</span> : null}

                    </div>
                    <div className='col-9'>
                        <div style={{ fontSize: "0.75rem" }} className='paragraph text-capitalize cursor-arrow text-elipse fw-600'>{
                            userItem?.first_name ?
                                <Tooltip placement='bottomLeft' title={userItem?.first_name}>  {`${userItem?.first_name}`} </Tooltip> : null

                        }</div>
                        {/* <p style={{ color: "#333" }} className='label'>{userItem?.email}</p> */}
                        <p style={{ fontSize: "0.625rem", color: "#ADB5BD" }} className='label text-elipse text-capitalize mb-0'>{userItem?.designation ?? 'Designation'}
                        </p>

                    </div>
                </div>
                )}
                </div>}
            >
                <div className='d-flex cursor-pointer align-items-center justify-content-between mr-3 login-user-initial-capsule'>
                    {showProcessingDot ? <Badge className={`mr-2 ${fromRoute === 'flows' || fromRoute === 'pulse' ? "online-user-dot-normal" : "online-user-dot"}`} color="#28A745" status="processing" text='' />
                        : <Badge className='mr-2 online-user-dot-normal' color="#28A745" status="success" text='' />}
                    <span style={{ fontSize: "0.75rem", color: `${fromRoute === 'flows' || fromRoute === 'pulse' ? "#333" : "#FFF"}` }} className='paragraph fw-800'> {userDetails?.length} online <DOWNARROW color={`${fromRoute === 'flows' || fromRoute === 'pulse' ? "#333" : "#FFF"}`} ></DOWNARROW></span>
                </div>

            </Popover>
                : null
            }


            {
                clusterStatus ?
                    <div onClick={
                        () => {
                            if (!localStorageUsers?.userDetails?.is_superuser && !CurrentUserDetails?.is_superuser) {
                                emitToastNotification('error', 'You do not have permission to perform this action.')
                            } else {
                                history(Literals.links.CLUSTERS)
                            }

                        }} className={`cursor-pointer cluster-square-pill`} style={{ background: `${fromRoute === 'flows' || fromRoute === 'pulse' ? (clusterStatus?.toLowerCase() === 'running' ? '#E5F4E8' : clusterStatus?.toLowerCase() === 'stopped' ? '#F8E2E2' : (clusterStatus?.toLowerCase() === 'pending' || clusterStatus?.toLowerCase() === 'initializing') ? '#FDF6E4' : '#F8E2E2') : '#FFF'}` }} >
                        <div style={{ background: `${clusterStatus?.toLowerCase() === 'running' ? '#28A745' : clusterStatus?.toLowerCase() === 'stopped' ? '#CB1010' : (clusterStatus?.toLowerCase() === 'pending' || clusterStatus?.toLowerCase() === 'initializing') ? '#EEB524' : '#CB1010'}` }} className={`cluster-sphere`}></div>
                        <div style={{ color: ` ${clusterStatus?.toLowerCase() === 'running' ? '#28A745' : clusterStatus?.toLowerCase() === 'stopped' ? '#CB1010' : clusterStatus?.toLowerCase() === 'pending' || clusterStatus?.toLowerCase() === 'initializing' ? '#EEB524' : '#CB1010'}` }} className={`cursor-pointer cluster-status-text`}>
                            <Tooltip title={clusterStatus}>  Cluster</Tooltip>
                        </div>
                    </div >

                    : null}

        </div >
    )
})

export default ClusterStatusNotification