import { Checkbox, Input, List, Popover, Select, Switch, Tabs, Tooltip } from 'antd'
import React from 'react'
import { Literals } from '../../modules/common/literals';
import Buttons from '../button/Buttons'
import { CHECK, CLOSE, FILTER, RESETFILTER } from '../iconSource';
import SearchInput from '../search/Search';
import './dictionaryLandingPageFilter.scss'
import { ICON_REFRESH } from '../newIconSource';
import { isArray } from 'lodash';

const DictionaryLandingPageFilter = ({
    filterList,
    setFilterList   = () => {},
    showApplyButton = true,
    onApply         = () => {},
    onReset         = () => {},
    reload          = {}
}) => {
    const [showFilterPop, setFilterPop]         = React.useState(false);
    const [searchData, setSearchData]           = React.useState('');
    const [localFilterList, setLocalFilterList] = React.useState({
        category: [], 
        classifiers: [],
        status: [
            // {id: 0, name: 'PENDING', selected: false},
            {id: 1, name: 'ACTIVE', selected: false},
            {id: 2, name: 'ARCHIVED', selected: false}
        ], 
        addfields: [], 
        pii: false
    });

    React.useEffect(() => {
        setLocalFilterList(
            {
                category    : filterList.category, 
                classifiers : filterList.classifiers,
                addfields   : filterList.addfields,
                status      : filterList.status,
                pii         : filterList.pii
            })
    }, [filterList])

    const filterTabItems = React.useMemo(() => {

        const categories  = showApplyButton ? [...localFilterList.category]    : [...filterList.category]
        const classifiers = showApplyButton ? [...localFilterList.classifiers] : [...filterList.classifiers]
        const addfields   = showApplyButton ? [...localFilterList.addfields]   : [...filterList.addfields]
        const status      = showApplyButton ? [...localFilterList.status]      : [...filterList.status]
        const pii         = showApplyButton ? localFilterList.pii              : filterList.pii
    
        const selectedCats      = filterList?.category?.filter(v => v.selected)
        const selectedClass     = filterList?.classifiers?.filter(v => v.selected)
        const selectedAddFields = filterList?.addfields?.filter(v => v.selected)
        const selectedStatus    = filterList?.status?.filter(v => v.selected)
        const selectedPii       = pii

        const searchTerm = searchData?.toLowerCase()

        const LIST = [
            // {key: 'category',    name: 'Category',   list: categories,  selectedList: selectedCats},
            {key: 'classifiers', name: 'Classifier', list: classifiers, selectedList: selectedClass},
            // {key: 'addfields',   name: 'Attributes', list: addfields,   selectedList: selectedAddFields},
            {key: 'status',      name: 'Status',     list: status,      selectedList: selectedStatus},
        ]

        let tabPanelItems =  LIST?.map(({key, name, list, selectedList}, i) => {
            return {
                label: <span className="align-items-center d-flex small fontInterSemiBold">
                {name} 
                {
                    selectedList?.length
                    ?
                    <span style={{background: '#2c83c4'}} className="ml-2 badge badge-pill text-white">{selectedList?.length}</span>
                    : ''
                }
                </span>, 
            
            key: `item-${i}`, 
            children:   <div className="category-flt-li">
                        {
                            list?.length === 0
                            ?
                            <>
                                <p className='mb-0 text-black-50 text-center p-3'>No {name}</p>
                                {
                                    key === 'classifiers' || key === 'category'
                                    ?
                                    <>
                                        <div className='d-flex align-items-center justify-content-center'>
                                          <Buttons
                                            props={{
                                                tooltip: "",
                                                buttonText: "Reload",
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-text border",
                                                buttonEvent: () => {
                                                    reload[key]()
                                                },
                                                ImgSrc: () => <ICON_REFRESH />,
                                                isDisable: false,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                            }}
                                            />
                                        </div>
                                    </>
                                    :   ''
                                }
                            </>
                            :
                            <List
                                size="small"
                                dataSource={list?.filter(v => v?.name?.toLowerCase()?.includes(searchTerm))}
                                renderItem={(item, i) => <List.Item className='fontInterSemiBold text-dark'>
                                    <Checkbox 
                                        id={`${item?.name}${i}_${name}_chk`}
                                        className="mr-2 dict-lpg-chk" 
                                        checked={item.selected} 
                                        onChange={(e) => handleFilterChange(key, item.name, e.target.checked)} /> 
                                        <Tooltip title={item?.name?.toUpperCase()} placement='topLeft'>
                                            <label htmlFor={`${item?.name}${i}_${name}_chk`}  
                                                className='d-block mb-0 cursor-pointer text-with-ellipsis'>
                                                {item.name}
                                            </label>
                                        </Tooltip>
                                    </List.Item>
                                }
                                />
                        }
                    </div> 
            }
        })

        const piiOpt = {
            key: 'item-pii',
            label: <span className="align-items-center d-flex small fontInterSemiBold">
                PII 
                {
                    selectedPii
                    ?
                    <span style={{background: '#2c83c4'}} className='sphere ml-2'></span>
                    : ''
                }
                </span>,
                children:   <div className="category-flt-li px-2 py-1">
                    <Switch
                        size='small'
                        checked={selectedPii}
                        onChange={(bool) => handleFilterChange('pii', null, bool)}
                        className='dict-lpg-chk'
                    />
                    <span className='ml-2 fontSizeLabel text-dark fontInterSemiBold'>
                        Contains PII
                    </span>
            </div> 
        }

        let addFieldArr = addfields?.map((a, i) => (
            {
                key: 'addfield-item-'+i,
                label: <span 
                        className={`d-block text-left text-with-ellipsis small fontInterSemiBold text-uppercase ${filterList?.addfields?.length - 1 === i ? 'pb-2' : ''}`}
                        title={a?.name?.toUpperCase()}
                        style={{width: 88}}
                        >
                        {
                            <Checkbox
                                className="mr-1 dict-lpg-chk"
                                checked={a?.selected}
                                onChange={e => {
                                    handleAddFieldFilterChange(a?.id, e?.target?.checked)
                                }}
                            />


                            // a?.selected
                            // ?
                            // <span className='mr-1'>
                            //     <i className='bx bxs-circle color-primary'></i>
                            // </span>
                            // :   ''
                        }
                        {a?.name}

                    </span>,
                children:   <div className="category-flt-li px-2 py-1">
                        {
                            (() => {
                                switch (a?.type?.toLowerCase()) {
                                    case "dropdown":
                                        return <>
                                            <div className='d-flex'>
                                                {/* <div>
                                                    <Checkbox
                                                        className="mr-2 dict-lpg-chk"
                                                        checked={a?.selected}
                                                        onChange={e => {
                                                            handleAddFieldFilterChange(a?.id, e?.target?.checked)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className='w-100'>
                                                    <div>
                                                        <label className='label text-uppercase mb-1'>{a?.name}</label>
                                                    </div>

                                                    {
                                                        a?.selectedValues?.map((o, i) => (
                                                            <div className='d-flex align-items-center py-1 px-0'>
                                                                <Checkbox 
                                                                    id={`${o?.value}${i}__chk`}
                                                                    className="mr-2 dict-lpg-chk" 
                                                                    checked={o?.selected} 
                                                                    onChange={(e) => {
                                                                        let val = e?.target?.checked
                                                                        handleAddFieldValue(a?.id, val, true, o?.value)
                                                                    }} 
                                                                /> 
                                                                <Tooltip title={o?.value} placement='topLeft'>
                                                                    <label htmlFor={`${o?.value}${i}_${o?.value}_chk`}  
                                                                        className='d-block mb-0 cursor-pointer text-with-ellipsis'>
                                                                        {o?.value}
                                                                    </label>
                                                                </Tooltip>
                                                            </div>
                                                        ))
                                                    }

                                                    {/* <Select
                                                        value={a?.value}
                                                        defaultValue={a?.default_value}
                                                        // style={{
                                                        //   width: 120,
                                                        // }}
                                                        onChange={(val) => {
                                                            console.log({val})
                                                            handleAddFieldValue(a?.id, val)
                                                        }}
                                                        options={
                                                            a?.options?.map(o => (
                                                                {
                                                                    value: o,
                                                                    label: o
                                                                }
                                                            ))
                                                        }
                                                    /> */}
                                                </div>
                                            </div>
                                        </>
                                    case 'toggle':
                                        return <>
                                            <div className='d-flex'>
                                                {/* <div>
                                                    <Checkbox
                                                        className="mr-2 dict-lpg-chk"
                                                        checked={a?.selected}
                                                        onChange={e => {
                                                            handleAddFieldFilterChange(a?.id, e?.target?.checked)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className='w-100'>
                                                    <div>
                                                        <label className='label text-uppercase mb-1'>{a?.name}</label>
                                                    </div>
                                                    <Switch
                                                        size='small'
                                                        defaultChecked={a?.default_value}
                                                        checked={a?.value}
                                                        onChange={(bool) => {
                                                            handleAddFieldValue(a?.id, bool)
                                                        }}
                                                        className='dict-lpg-chk'
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    case 'date':
                                        return <>
                                            <div className='d-flex'>
                                                {/* <div>
                                                    <Checkbox
                                                        className="mr-2 dict-lpg-chk"
                                                        checked={a?.selected}
                                                        onChange={e => {
                                                            handleAddFieldFilterChange(a?.id, e?.target?.checked)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className='w-100'>
                                                    <div>
                                                        <label className='label text-uppercase mb-1'>{a?.name}</label>
                                                    </div>
                                                    <Input
                                                        defaultValue={a?.default_value}
                                                        value={a?.value}
                                                        type='date'
                                                        onChange={e => {
                                                            let val = e?.target?.value
                                                            handleAddFieldValue(a?.id, val)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    case 'text':
                                        return <>
                                            <div className='d-flex'>
                                                {/* <div>
                                                    <Checkbox
                                                        className="mr-2 dict-lpg-chk"
                                                        checked={a?.selected}
                                                        onChange={e => {
                                                            handleAddFieldFilterChange(a?.id, e?.target?.checked)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className='w-100'>
                                                    <div>
                                                        <label className='label text-uppercase mb-1'>{a?.name}</label>
                                                    </div>
                                                    <Input
                                                        defaultValue={a?.default_value}
                                                        value={a?.value}
                                                        onChange={e => {
                                                            let val = e?.target?.value
                                                            handleAddFieldValue(a?.id, val)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    case 'numeric':
                                        return <>
                                            <div className='d-flex'>
                                                {/* <div>
                                                    <Checkbox
                                                        className="mr-2 dict-lpg-chk"
                                                        checked={a?.selected}
                                                        onChange={e => {
                                                            handleAddFieldFilterChange(a?.id, e?.target?.checked)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className='w-100'>
                                                    <div>
                                                        <label className='label text-uppercase mb-1'>{a?.name}</label>
                                                    </div>
                                                    <Input
                                                        type='number'
                                                        defaultValue={a?.default_value}
                                                        value={a?.value}
                                                        onChange={e => {
                                                            let val = e?.target?.value
                                                            handleAddFieldValue(a?.id, val)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    default:
                                        return <></>
                                }
                            })()
                        }
                </div> 
            }
        ))

        tabPanelItems = [
            tabPanelItems[0],
            tabPanelItems[1],
            piiOpt,
            tabPanelItems[2],
            ...addFieldArr
        ]

        return tabPanelItems

    }, [filterList, localFilterList, searchData]) 
    
    const handleFilterChange = (key, name, checked) => {
        let prevFunc = () => {}

        if(key === 'pii') {
            prevFunc = prev => (
                {
                    ...prev,
                    [key]: checked
                }
            )
        }
        else {
            prevFunc = prev => (
                {
                    ...prev,
                    [key]: prev[key].map(v => ({...v, selected: name === v.name ? checked : v.selected }))
                }
            )
        }
        
        if(showApplyButton) {
            setLocalFilterList(prevFunc)
            return
        }
        setFilterList(prevFunc)
    }
    
    const handleAddFieldFilterChange = (a_id, checked) => {
        let prevFunc = () => {}
        
        prevFunc = prev => (
                {
                    ...prev,
                    addfields: prev?.addfields?.map(a => ({...a, selected: a_id === a?.id ? checked : a?.selected}))
                }
            )
        
        if(showApplyButton) {
            setLocalFilterList(prevFunc)
            return
        }
        setFilterList(prevFunc)
    }
    
    const handleAddFieldValue = (a_id, val, isDropdown, valName) => {
        let prevFunc = () => {}

        if(isDropdown) {
            prevFunc = prev => (
                {
                    ...prev,
                    addfields: prev?.addfields?.map(a => ({
                        ...a, 
                        selectedValues: a_id === a?.id ? a?.selectedValues?.map(s => ({...s, selected: s?.value === valName ? val : s?.selected})) 
                        : a?.selectedValues}))
                }
            )
        }
        else {
            prevFunc = prev => (
                {
                    ...prev,
                    addfields: prev?.addfields?.map(a => ({...a, value: a_id === a?.id ? val : a?.value}))
                }
            )
        }
        
        if(showApplyButton) {
            setLocalFilterList(prevFunc)
            return
        }
        setFilterList(prevFunc)
    }

    const handleApply = () => {
        setFilterList({...localFilterList})

        onApply(localFilterList)
    }
    
    const filterPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                    setFilterList(prev => (
                                        {
                                            category: prev['category'].map(v => ({...v, selected: false })),
                                            classifiers: prev['classifiers'].map(v => ({...v, selected: false })),
                                            addfields: prev['addfields'].map(v => ({...v, selected: false, selectedValues: v?.selectedValues?.map(f => ({...f, selected: false}))})),
                                            status: prev['status'].map(v => ({...v, selected: false })),
                                            pii: false
                                        }
                                    ))
                                    setSearchData('')
                                    onReset()
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setFilterPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className='' style={{ width: '18.875rem' }}>
                    <Tabs 
                        tabPosition='left' 
                        // type="card" 
                        className="dict-landing-filter-tabs" 
                        items={filterTabItems} 
                        style={{height: '15.625rem'}}
                        />
                    {
                        showApplyButton
                        ?   <div className='p-2 d-flex align-items-center justify-content-end border-top' style={{gap: 10}}>
                                {/* <div>
                                    <SearchInput searchData={searchData} setSearchData={setSearchData} />
                                </div> */}
                                <Buttons
                                    props={{
                                    buttonText: "Apply",
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                    buttonEvent: handleApply,
                                    ImgSrc: () => <CHECK />,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    toggleBtnOption: null,
                                    }}
                                />
                            </div>
                        :   ''
                    }
                </div>
            </>
        }
    }

    const isFilterActive = () => {
        return (
               filterList.category?.filter(v => v.selected)?.length > 0 
            || filterList.classifiers?.filter(v => v.selected)?.length > 0
            || filterList.addfields?.filter(v => v.selected)?.length > 0
            || filterList.status?.filter(v => v.selected)?.length > 0
            || filterList.pii === true
        )
    }

    const handleFilterVisibleChange = (visible) => {
        setFilterPop(visible)
    }

    return (
        <>
            <Popover placement="bottomLeft"
                title={filterPopover.header}
                content={filterPopover.body}
                trigger="click"
                autoAdjustOverflow={true}
                open={showFilterPop}
                onOpenChange={handleFilterVisibleChange}
            >
                <Tooltip placement='bottom' title='Filter'>
                    <button className={`${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"
                        } custom-btn back-transparent btn-with-icon border-0 ml-2 mr-1 ${isFilterActive() ? 'rdg-filter-active' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setFilterPop(true)
                        }
                        }
                    >
                        <FILTER />
                    </button>
                </Tooltip>
            </Popover>
        </>
    )
}

export default DictionaryLandingPageFilter