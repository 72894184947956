import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from '../../common/loadingActions';
import {
  CREATE_FOLDER,
  DATA_DOMAIN_LIST,
  DOMAIN_GROUP_DATA, IS_TAG_LIST, OPEN_MODEL_LANDING, SET_LANDINGPAGE_DATA, SET_LANDINGPAGE_FILTER,
  SET_LANDINGPAGE_TAG, SET_LANDING_PAGE_DEFAULT, SET_SELECTED_POD_LIST, SET_TAG_LIST
} from "./landingPageActionTypes";
import { setUserActiveDomain } from "../../../actions";

const LANDING_PAGE_API_URL = window._env_.REACT_APP_API_BASE_URL;

export const setLandingPageData = (data) => {
  return {
    type: SET_LANDINGPAGE_DATA,
    payload: data,
  };
};

export const createFolderCatalog = (data) => {
  return {
    type: CREATE_FOLDER,
    payload: data
  }
}

export const isTagListStatus = (data) => {
  return {
    type: IS_TAG_LIST,
    payload: data
  }
}

export const setLandingPageFilter = (data) => {
  return {
    type: SET_LANDINGPAGE_FILTER,
    payload: data,
  };
};

export const setLandingPageTag = (data) => {
  return {
    type: SET_LANDINGPAGE_TAG,
    payload: data,
  };
};

export const openModel = (data) => {
  return {
    type: OPEN_MODEL_LANDING,
    payload: data,
  };
};

export const setTagList = (data) => {
  return {
    type: SET_TAG_LIST,
    payload: data,
  };
};

export const setSelectedRulePODList = (data) => {
  return {
    type: SET_SELECTED_POD_LIST,
    payload: data,
  };
};
export const setLandingPageDomainList = (data) => {
  return {
    type: DATA_DOMAIN_LIST,
    payload: data,
  };
};

export const setLandingPageGroupData = (data) => {

  return {
    type: DOMAIN_GROUP_DATA,
    payload: data,
  };
};

export const setLandingPageDefault = () => {
  return {
    type: SET_LANDING_PAGE_DEFAULT,
  };
};

export const getDomainTags = (domainId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('GET', `${LANDING_PAGE_API_URL}v2/tag/?data_domain_id=${domainId}`)
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const createDomainTags = (payload, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('POST', `${LANDING_PAGE_API_URL}v2/tag/`, payload)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};

export const updateDomainTags = (id, payload, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('PUT', `${LANDING_PAGE_API_URL}v2/tag/${id}/`, payload)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};


export const deleteDomainTags = (id, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('DELETE', `${LANDING_PAGE_API_URL}v2/tag/${id}/`)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};

export const assignDomainTag = (payload, tableId, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('POST', `${LANDING_PAGE_API_URL}catalog_home/v2/${tableId}/tag/`, payload)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};

export const unassignDomainTag = (id, tableId, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('DELETE', `${LANDING_PAGE_API_URL}catalog_home/v2/${tableId}/tag/?tag=${id}`)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};

export const getTagList = () => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}tag/usertag/`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
          } else {
            dispatch(setTagList(resp.data));
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

        });
    });
  };
};

export const saveTag = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}tag/usertag/`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === 'error') {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {

            resolve(resp);
            emitToastNotification('success', 'Tag saved Successfully.')
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err.message);
          emitToastNotification('error', err.message)
        });
    });
  };
};

export const updateTag = (id, payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("PUT", `${LANDING_PAGE_API_URL}tag/usertag/${id}/`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {

            resolve(resp);
            emitToastNotification('success', 'Tag updated Successfully.')
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          // 'Failed to update tag.'
          emitToastNotification('error', err.message)
        });
    });
  };
};

export const deleteSavedTag = (id) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("DELETE", `${LANDING_PAGE_API_URL}tag/usertag/${id}/`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
            emitToastNotification('error', resp.message)
          } else {

            resolve(resp);
            emitToastNotification('success', 'Tag removed Successfully.')
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

        });
    });
  };
};


export const getLandingPageData = (showLoader = true, showToast = true, isEditor = false, domainId = undefined, minimal = false) => {

  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}catalog_home/v2/?${isEditor ? 'editor=true' : ''}${minimal ? '&mode=minimal' : ''}${domainId ? '&data_domain_id=' + domainId : ''}`)
      )
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp.message)
            }
          } else {
            dispatch(setLandingPageData(resp.data));
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err.message)
          }
          reject(err);
        });
    });
  };
};
export const getLandingPageDataOldApi = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}catalog_home/`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            dispatch(setLandingPageData(resp.data));
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};

export const getCatalogListing = (activeDomain, mode = 'full', published, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}catalog_home/v2/?data_domain_id=${activeDomain}&mode=${mode}${published ? '&published=' + published : ''}`)
      )
        .then((resp) => {
          if (resp.status === false) {
            reject(resp.message);

            if (showToast)
              emitToastNotification('error', resp.message)

          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showToast)
            emitToastNotification('error', err.message)

          reject(err);
        });
    });
  };
};

export const addRemoveBookmarkToPOD = (table_id, remove_bookmark) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(remove_bookmark ? 'DELETE' : "POST", `${LANDING_PAGE_API_URL}catalog_home/v2/${table_id}/bookmark/`))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {

            resolve(resp);
            emitToastNotification('success', resp.message)
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
}

export const getTableDataV2 = (id) => {
  const searchParams = new URLSearchParams(document.location.search)
  const history_id = searchParams.get("history_id");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const action = decodeURI(searchParams.get("action") ?? "");

  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}pod_readme/v2/${id}${history_id ? `?history_id=${history_id}&start_date=${start_date}&end_date=${end_date}` : ""}`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {

            dispatch(setLandingPageData(resp.data));
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

        });
    });
  };
}

export const getLandingPageDataV2 = () => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}catalog_home/v2/`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {

            dispatch(setLandingPageData(resp.data));
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err.message)
          reject(err)
        });
    });
  };
}

export const removePOD = (tableId) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${LANDING_PAGE_API_URL}pod_crud/v2/${tableId}/`))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {

            resolve(resp);
            emitToastNotification('success', 'Successfully removed POD.')
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err);
          emitToastNotification('error', err.message)
        });
    });
  };
}

export const assignTagToPOD = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}tag/tabletag/`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {

            resolve(resp);
            emitToastNotification('success', resp.message)
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err);
          emitToastNotification('error', err.message)
        });
    });
  };
}

export const unassignTagToPOD = (tag_id) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${LANDING_PAGE_API_URL}tag/tabletag/${tag_id}/`))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
            emitToastNotification('success', resp.message)
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err);
          emitToastNotification('error', err.message)
        });
    });
  };
}

export const CreateDuplicatePOD = ({ payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}pod_crud`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === "success") {
            emitToastNotification('success', resp.message);
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const CategoryList = ({ payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}catalog_home`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === "success") {
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};



export const createFolderApiV2 = (payload, showLoader = true, showToast = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file_upload/v2/create_folder/`, payload))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp.message);
            }
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {

          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          reject(err);
          if (showToast) {
            emitToastNotification('error', err.message)
          }
        });
    });
  };
};

export const createFolderApi = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file_upload`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err.message);
          emitToastNotification('error', err.message)
        });
    });
  };
};


export const deleteFolderApiV2 = (folder_name) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${LANDING_PAGE_API_URL}file_upload/v2/delete_folder/?folder=${folder_name}`))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err.message);
          emitToastNotification('error', err.message)
        });
    });
  };
}

export const deleteFolderTargetTransformer = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file_upload/v2/delete_folder/`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err.message);
          emitToastNotification('error', err.message)
        });
    });
  };
}

export const deleteFolderApi = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file_upload`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err.message);
          emitToastNotification('error', err.message)
        });
    });
  };
}

export const deleteFileApiV2 = (payload, showLoader = true, showToast = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file_upload/v2/delete_file/`, payload))
        // dispatch(fetchData("DELETE", `${LANDING_PAGE_API_URL}file_upload/v2/delete_file/?folder=${folderName}&file=${fileName}`))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp.message);
            }
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          reject(err);
          if (showToast) {
            emitToastNotification('error', err.message)
          }
        });
    });
  };
}
export const deleteFileApi = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file_upload`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err.message);
          emitToastNotification('error', err.message)
        });
    });
  };
}

export const downloadFileApiV2 = (prefix, fileName, showLoader = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${LANDING_PAGE_API_URL}file_upload/v2/file_download/?prefix=${prefix}&name=${fileName}`))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          reject(err.message);
          emitToastNotification('error', err.message)
        });
    });
  };
}

export const downloadFileApi = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file_upload`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          reject(err.message);
          emitToastNotification('error', err.message)
        });
    });
  };
}

export const getUserSelectedDomain = () => {
  return (dispatch, state) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}data_domain/user_domain_detail/`)
      )
        .then((resp) => {

          if(!(!!state()?.UserManagement?.DataDomain?.userSelectedDomain)) {
            dispatch(setUserActiveDomain(resp?.data?.domain))
          }

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)
            reject(resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {

          emitToastNotification('error', err?.message);
          reject(err);
        })
    });
  };
}

export const setUserSelectedDomain = (payload, showToast = true) => {
  return (dispatch, state) => {

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}data_domain/user_domain_detail/`, payload)
      )
        .then((resp) => {
          let dmList = state()?.UserManagement?.DataDomain?.datadomainList ?? []

          dispatch(setUserActiveDomain(
            !!payload?.data_domain_id 
            ? {id: payload?.data_domain_id, name: payload?.data_domain_name ?? dmList?.find(d => d?.data_domain_id === payload?.data_domain_id)?.data_domain_name ?? null} 
            : null
          ))

          if (resp.status !== 'success') {
            if (showToast) {
              emitToastNotification('error', resp.message)
            }
            reject(resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        })
    });
  };
}