import React from 'react'
import LandingSearchInput from './LandingSearchInput'
import './legend.scss'
import navigatorGif from '../../../../assets/images/landingPage/navigator-gif.gif'
import pulseGif from '../../../../assets/images/landingPage/pulse-gif.gif'
import labGif from '../../../../assets/images/landingPage/lab-gif.gif'
import navigatorBg from '../../../../assets/images/landingPage/navigator_bg.png'
import pulseBg from '../../../../assets/images/landingPage/pulse_bg.png'
import labBg from '../../../../assets/images/landingPage/lab_bg.png'
import { useStore } from 'react-redux'

const bgGifMap = {
    navigator: { bg: navigatorBg, gif: navigatorGif },
    pulse: { bg: pulseBg, gif: pulseGif },
    lab: { bg: labBg, gif: labGif }
}

const Legend = ({
    page = 'navigator',
    paragraph = "Find what you're looking for quickly and accurately with our advanced search.",
    searchTerm = '',
    setSearchTerm = () => { },
    searchPlaceholder = '',
    activeTabKey = ''
}) => {

    const store = useStore()
    let userinfo = store.getState().UserReducer?.user?.userDetails;
    // const { first_name, last_name } = 

    const displayUserName = () => {
        let name = userinfo?.first_name
        if(userinfo?.last_name)
        name += " " + userinfo?.last_name
        return name ?? 'Unknown User'
    }

    return (
        <div className={`clsta-land-legend p-3 ${activeTabKey === 'business' ? 'leg-shrink-dict' : ''}`} 
            style={{backgroundImage: `linear-gradient(240deg, white, transparent), url(${bgGifMap[page]?.bg??navigatorBg})`}}>

            <div className='leg-content-wrap'>
                <div className='row position-relative'>
                    <div className='col-8'>
                        <div className='leg-title-cont'>
                            <h2 className={`mb-3 text-capitalize ${page}`}>Clarista {page}</h2>
                            <h4>WELCOME! <span className='text-capitalize'>{`${displayUserName()}`}</span></h4>
                            <p className='mb-2'>{paragraph}</p>
                        </div>
                        <div className={`pt-3 pb-3`}>
                            <LandingSearchInput
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                searchPlaceholder={searchPlaceholder}
                            />
                        </div>
                    </div>
                    <div className='col-4 text-right'>
                        <img className='lpg-gif img-fluid' src={bgGifMap[page]?.gif ?? navigatorGif} alt={`${page} gif`} />
                        {/* <img src='https://via.placeholder.com/200' /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Legend