import React from "react";
import SelectedUserGroupsTable from "../../../../Common/landingPageTable/LandingPageTable";
import { Tooltip, Switch } from "antd";
import { Literals } from "../../../common/literals";
import Buttons from "../../../../Common/button/Buttons";
import { MINUS } from "../../../../Common/iconSource";
import { ICON_DELETE } from "../../../../Common/newIconSource";
import DeleteModal from "../../../../Common/deleteModal/DeleteModal";

const UserGroupsTable = ({
  selectedUserGroups,
  setSelectedUserGroups,
  onChangePermission,
  groupPermissions,
  viewMode,
  isEditorPermission
}) => {

  const [openDelete, setOpenDelete] = React.useState(false)

  const currentGroup = React.useRef(null)

  const handleRemoveGroup = groupId => {
    currentGroup.current = groupId
    setOpenDelete(true)
  };

  const removeGroupFromSelectedGroups = () => {
    let groupId = currentGroup.current

    if(!groupId) return

    setSelectedUserGroups((groups) => {
      const newGroups = [...groups].filter((group) => group.id !== groupId);

      return newGroups;
    });

    setOpenDelete(false)
  }

  const userGroupHeadings = [
    {
      title: Literals.USERGROUPNAME,
      dataIndex: "name",
      key: "name",
      width: "20%",
      sortable: true,
      sorter: {
        compare: (a, b) => {
          return a?.data?.name?.localeCompare(b?.data?.name) ?? null
        }
      },
    },
    {
      title: Literals.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      width: "80%",
      sortable: false,

    },
    // {
    //   title: Literals.VIEW,
    //   dataIndex: "view",
    //   key: "view",
    //   resizable:false,
    //   sortable:false,

    //   width: "10%",
    // },
    // {
    //   title: Literals.EDIT,
    //   sortable:false,
    //   resizable:false,

    //   dataIndex: "edit",
    //   key: "edit",
    //   width: "10%",
    // },
    // {
    //   title: Literals.DELETE,
    //   dataIndex: "delete",
    //   resizable:false,

    //   sortable:false,

    //   key: "delete",
    //   width: "10%",
    // },
    {
      title: "",
      resizable: false,

      dataIndex: "action",
      sortable: false,

      key: "action",
      width: "10%",
    },
  ];

  let userGroupsData = [];

  if (groupPermissions.length) {
    userGroupsData = selectedUserGroups.map((group, index) => {
      return {
        data: group,
        name: (
          <Tooltip placement="topLeft" title={group?.name}>
            <p className='text-with-ellipsis mb-0'>  {group?.name}</p>
          </Tooltip>
        ),
        description: (
          <Tooltip placement="topLeft" title={group?.description}>
            <p className='text-with-ellipsis mb-0'> {group?.description}</p>
          </Tooltip>
        ),
        // view: (
        //   <Switch
        //     size="small"
        //     checked={groupPermissions[index]?.view_pod}
        //   disabled={ !isEditorPermission || viewMode?.includes("view")}
        //     onChange={(checked) =>
        //       onChangePermission(index, "view_pod", checked, group.id)
        //     }
        //   />
        // ),
        // edit: (
        //   <Switch
        //     size="small"
        //     checked={groupPermissions[index]?.edit_pod}
        //   disabled={ !isEditorPermission || viewMode.includes("view")}
        //     onChange={(checked) =>
        //       onChangePermission(index, "edit_pod", checked, group.id)
        //     }
        //   />
        // ),
        // delete: (
        //   <Switch
        //     size="small"
        //     checked={groupPermissions[index]?.delete_pod}
        //   disabled={ !isEditorPermission || viewMode.includes("view")}
        //     onChange={(checked) =>
        //       onChangePermission(index, "delete_pod", checked, group.id)
        //     }
        //   />
        // ),
        action: (
          <Buttons
            props={{
              tooltipPlacement: "left",
              tooltip: "Remove",
              buttonText: "",
              buttonClassName:
                "custom-btn-outline error-red custom-btn btn-with-icon border-0 bg-transparent",
              buttonEvent: (e) => {
                handleRemoveGroup(group?.id);
              },
              ImgSrc: () => <ICON_DELETE />,
              isDisable: !isEditorPermission || viewMode.includes("view"),
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
            }}
          />
        ),
      };
    });
  }



  return (
    <>
      <SelectedUserGroupsTable
        props={{
          pagination: false,
          size: "small",
          columns: userGroupHeadings,
          dataSource: userGroupsData,
          tableHeight: "254px",
          showSorterTooltip: false,
        }}
      />

      <DeleteModal
        open={openDelete}
        setOpen={setOpenDelete}
        onConfirm={() => removeGroupFromSelectedGroups()}
      />

    </>
  );
};

export default UserGroupsTable;
