import { useDrag, useDrop } from "react-dnd";
import React from "react";
import { useCombinedRefs } from "../../../customHooks/useCombinedRefs";
import { Row } from "react-data-grid";
import './DraggableRowRenderer.scss'

export function DraggableRowRenderer({
  rowIdx,
  isRowSelected,
  onRowReorder,
  onlyFirstColumnDraggable = false,
  rowReorder = true,
  ...props
},) {

  const [{ isDragging }, drag] = useDrag({
    type: "ROW_DRAG",
    item: { index: rowIdx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "ROW_DRAG",
    drop({ index }) {
      onRowReorder(index, rowIdx);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleDragStart = (e) => {
    if (e?.target?.querySelector('input.custom-biz-input.hovered')) {
      e.preventDefault();
    }
  };

  return (
    <Row
      // ref={useCombinedRefs(drag, drop)}
      onDragStart={handleDragStart}
      ref={(ref) => {
        if(!rowReorder) return
        if (ref && onlyFirstColumnDraggable) {
          drag(ref.firstElementChild);
        } else {
          drag(ref)
        }
        drop(ref);
      }}
      rowIdx={rowIdx}
      isRowSelected={isRowSelected}
      // cursor 
      className={` ${isOver ? 'on-vlist-over' : ''} ${isDragging ? 'on-vlist-dragging' : ''}`}
      {...props}
    />
  );
}
