import { Checkbox, List, Popover, Tabs, Tooltip } from 'antd'
import React from 'react'
import { Literals } from '../../../modules/common/literals';
import Buttons from '../../button/Buttons'
import { CHECK, CLOSE, FILTER, RESETFILTER } from '../../iconSource';
import SearchInput from '../../search/Search';
import { ICON_CONNECTION, ICON_FILE, ICON_TAG } from '../../newIconSource';

const CatalogFilter = ({
    id = 'catalog-flt',
    filterList,
    setFilterList = () => {},
    showApplyButton = true,
    onApply = () => {},
    onReset = () => {}
}) => {
    const [showFilterPop, setFilterPop] = React.useState(false);
    const [localFilterList, setLocalFilterList] = React.useState({connections: [], tags: [], types: []});
    const [searchData, setSearchData] = React.useState('');
    const [activeTabKey, setActiveTabKey] = React.useState('item-1');

    React.useEffect(() => {
        setLocalFilterList({connections: filterList.connections, tags: filterList.tags, types: filterList.types})
    }, [filterList])

    const filterTabItems = React.useMemo(() => {
        const conns = showApplyButton ? [...localFilterList.connections] : [...filterList.connections]
        const tags = showApplyButton ? [...localFilterList.tags] : [...filterList.tags]
        const types = showApplyButton ? [...localFilterList.types] : [...filterList.types]

        const selectedConns = filterList?.connections?.filter(v => v.selected)
        const selectedTags = filterList?.tags?.filter(v => v.selected)
        const selectedTypes = filterList?.types?.filter(v => v.selected)

        return [
            {
                label: <span className="align-items-center d-flex small">
                    <span className='mr-1 filter-tab-ic'><ICON_CONNECTION color={'currentColor'} height='12' width='12' /></span>Connections
                    {
                        selectedConns?.length
                            ?
                            <span className="ml-2 badge badge-pill bg-primary text-white">{selectedConns?.length}</span>
                            : ''
                    }
                </span>,

                key: 'item-1',
                children: <div className="comm-flt-li">
                    {
                        conns?.length === 0
                            ?
                            <p className='mb-0 text-black-50 text-center p-3'>No Connections</p>
                            :
                            <List
                                size="small"
                                dataSource={conns?.filter(v => v?.name?.includes(searchData))}
                                renderItem={(item) => <List.Item><Checkbox className="mr-2" checked={item.selected}
                                    onChange={(e) => handleFilterChange('connections', item.name, e.target.checked)} /> {item.name}</List.Item>}
                            />
                    }
                </div>
            },
            {
                label: <span className="align-items-center d-flex small">
                    <span className='mr-1 filter-tab-ic'><ICON_TAG color={'currentColor'} height='12' width='12' /></span>Tags
                    {
                        selectedTags?.length
                            ?
                            <span className="ml-2 badge badge-pill bg-primary text-white">{selectedTags?.length}</span>
                            : ''
                    }
                </span>,
                key: 'item-2',
                children: <div className="comm-flt-li">
                    {
                        tags?.length === 0
                            ?
                            <p className='mb-0 text-black-50 text-center p-3'>No Tags</p>
                            :
                            <List
                                size="small"
                                dataSource={tags?.filter(v => v?.name?.includes(searchData))}
                                renderItem={(item) => <List.Item><Checkbox className="mr-2" checked={item.selected}
                                    onChange={(e) => handleFilterChange('tags', item.name, e.target.checked)} /> {item.name}</List.Item>}
                            />
                    }
                </div>
            },
            {
                label: <span className="align-items-center d-flex small">
                    <span className='mr-1 filter-tab-ic'><ICON_FILE color={'currentColor'} height='12' width='12' /></span>Types
                    {
                        selectedTypes?.length
                            ?
                            <span className="ml-2 badge badge-pill bg-primary text-white">{selectedTypes?.length}</span>
                            : ''
                    }
                </span>,
                key: 'item-3',
                children: <div className="comm-flt-li">
                    {
                        types?.length === 0
                            ?
                            <p className='mb-0 text-black-50 text-center p-3'>No Types</p>
                            :
                            <List
                                size="small"
                                dataSource={types?.filter(v => v?.name?.includes(searchData))}
                                renderItem={(item) => <List.Item><Checkbox className="mr-2" checked={item.selected}
                                    onChange={(e) => handleFilterChange('types', item.name, e.target.checked)} /> {item.name}</List.Item>}
                            />
                    }
                </div>
            }
        ]
    }, [filterList, localFilterList, searchData, activeTabKey]) 
    
    const handleFilterChange = (key, name, checked) => {
        const prevFunc = prev => (
            {
              ...prev,
              [key]: prev[key].map(v => ({...v, selected: name === v.name ? checked : v.selected }))
            }
        )
        
        if(showApplyButton) {
            setLocalFilterList(prevFunc)
            return
        }
        setFilterList(prevFunc)
    }

    const handleApply = () => {
        setFilterList({...localFilterList})

        onApply(localFilterList)
    }
    
    const filterPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                  setFilterList(prev => (
                                    {
                                      connections: prev['connections'].map(v => ({...v, selected: false })),
                                      tags: prev['tags'].map(v => ({...v, selected: false })),
                                      types: prev['types'].map(v => ({...v, selected: false }))
                                    }
                                  ))
                                  setSearchData('')
                                  onReset()
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setFilterPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className='' style={{ width: '18.875rem' }}>
                    <Tabs type="card" className="dict-filter-tabs" items={filterTabItems} onChange={onTabChange} />
                    {
                        showApplyButton
                        ?   <div className='p-2 d-flex align-items-center justify-content-between border-top' style={{gap: 10}}>
                                <div>
                                    <SearchInput searchData={searchData} setSearchData={setSearchData} />
                                </div>
                                <Buttons
                                    props={{
                                    buttonText: "Apply",
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                    tooltipPlacement: 'top',
                                    buttonEvent: handleApply,
                                    ImgSrc: () => <CHECK />,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    toggleBtnOption: null,
                                    }}
                                />
                            </div>
                        :   ''
                    }
                </div>
            </>
        }
    }

    const isFilterActive = React.useMemo(() => {
        return (
            filterList.connections?.filter(v => v.selected)?.length > 0 
            || filterList.tags?.filter(v => v.selected)?.length > 0
            || filterList.types?.filter(v => v.selected)?.length > 0
        )
    }, [filterList])

    const handleFilterVisibleChange = (visible) => {
        setFilterPop(visible)
    }

    const onTabChange = (key) => {
        setActiveTabKey(key)
    }

    return (
        <>
            <Popover placement="bottomLeft"
                title={filterPopover.header}
                content={filterPopover.body}
                trigger="click"
                autoAdjustOverflow={true}
                open={showFilterPop}
                onOpenChange={handleFilterVisibleChange}
            >
                <Tooltip placement='bottom' title='Filter'>
                    <button 
                        id={id}
                        className={`${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"
                        } custom-btn back-transparent btn-with-icon border-0 ml-2 ${isFilterActive ? 'rdg-filter-active' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setFilterPop(true)
                        }
                        }
                    >
                        <FILTER />
                    </button>
                </Tooltip>
            </Popover>
        </>
    )
}

export default CatalogFilter