import React, { forwardRef } from 'react'
import Buttons from '../../../../Common/button/Buttons'
import { ICON_CHECK, ICON_CLEAR, ICON_ENGINE, ICON_GUIDE, ICON_LOADING_CIRCLE, ICON_MINUS, ICON_PARAMETER, ICON_PLAY, ICON_PLUS } from '../../../../Common/newIconSource'
import { Literals } from '../../../common/literals'
import SqlEditor from '../../preview/components/SqlEditor'
import { useDispatch, useSelector } from 'react-redux'
import { getConfirmationModal, preventSpecialCharsRegex } from '../../../common/helperFunctions'
import { v4 as uuidv4 } from 'uuid'
import { getCreatePodPreviewTable } from '../../../../../store/actions'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import _ from 'lodash'
import { Drawer, Dropdown, Input } from 'antd'
import { getSchemaLevelStringPattern } from '../PodCreation'
import { setQueryKillFlag, setRowCountByUUID, setTableUUID } from '../../../../../store/modules/common/DataGridTable/dataGridTableRedux'
import StoreCacheOption from './StoreCacheOption'
import { getPreviewTableRowCount } from '../../../../../store/modules/dataCatalogue/preview/previewActions'

const engines = {
    clarista: 'clarista_engine',
    db_engine: 'db_engine',
}

const QueryEditorContainer = forwardRef(({
    setPreviewLoading = () => {},
    previewLoading = false,
    setPreviewError = () => {},
    setPreviewUUID = () => {},
    isEditMode = false,
    previewUUID,
    isEditing = false,
    query = '',
    setQuery = () => {},
    isReadOnly = false,
    sqlTableDetails = [],
    setActiveTabKey = () => {},
    isPodOfPod = false,
    isAnyTableSelected = false,
    setShowTableSelectInfo = () => {},
    showTableSelectInfo = false,
    setPodPreviewRunning = () => {},
    setStoredStringPattern = () => {},
    onBlurSqlEvent = () => {},
    queryToDisplay = '',
    podCreationTutorialComponentIds = {},
    setWalkThrough = () => {},
    walkthroughStepIndex = 0,
    setIsCustomQuery = () => {},
    isCustomQuery = false,
    setEditorQuery = () => {},
    setStaticQuery = () => {},
    setAutoClassifyObj = () => {},
    autoClassifyObj = {},
    setCurrentActiveTabKey = () => {},
    sqlReady = false,
    setSqlReady = () => {},
    setLoadingTablesKeys = () => {}
}, ref) => {
    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)
    const selectedConn = useSelector((state) => {
        return state.UserManagement.ConnectionReducer.selectedConn
    })
    const dispatch = useDispatch()
    
    const [openParamaterDrawer, setParamaterDrawer] = React.useState(false)
    const [parameters, setParameters] = React.useState(reduxData?.parameters ?? [])
    const [openEngineMenu, setEngineMenu] = React.useState(false)
    const [activeEngine, setActiveEngine] = React.useState(reduxData?.clarista_engine === false ? engines.db_engine : engines.clarista)
    const [showParamGuide, setShowParamGuide] = React.useState(false)
    const [openClearModal, setOpenClearModal] = React.useState(false)

    const counter = React.useRef(0)
    const sqlEditorRef = React.useRef({})

    React.useEffect(() => {
        if(isEditMode && counter.current === 0 && reduxData?.parameters?.length > 0) {
            setParameters([...reduxData?.parameters])
            counter.current++
        }
    }, [reduxData])

    React.useImperativeHandle(ref, () => ({
        executeQuery() {
            onRun()
        },
    }))

    const onRun = () => {
        let editorQuery = query
        let queryToExecute = query
        const editor = sqlEditorRef.current

        if(editor) {
          const selectedText = editor?.getModel()?.getValueInRange(editor?.getSelection()) ?? ''
          
          /* console.log({
              selectedText,
              val: editor.getValue()
          }) */
  
          queryToExecute = selectedText?.trim() || editor?.getValue()
          editorQuery = editor?.getValue()
        }

        if(_.isEmpty(queryToExecute)) return
            
        setActiveTabKey('result')
        setCurrentActiveTabKey('result')
        setLoadingTablesKeys(prev => [...prev, 'result'])
        setPreviewError(undefined)
        setPreviewLoading(true)
        setPodPreviewRunning(true)
        reduxData.schemaQueryMap['result'] = editorQuery
        setReduxData(reduxData)
        
        if(!isCustomQuery) setIsCustomQuery(true) // Flag For Custom Query

        let uuid = uuidv4()
        setPreviewUUID(uuid)

        /**To Handle Cancel Preview**/
        dispatch(setQueryKillFlag(false))
        dispatch(setTableUUID(uuid))

        let clarista_engine = activeEngine === engines.clarista
        ,   params = parameters.length === 0 ? undefined
                    : _.uniqBy(parameters, 'name')?.reduce((acc, curr) => ({...acc, [curr?.name]: curr?.value}), {})
        ,   connection_name = isPodOfPod ? undefined : selectedConn?.connection_name
        ,   selected_tables = Object.keys(reduxData?.tableData)?.filter(t => t !== 'result')?.map(v => isPodOfPod ? v : `${connection_name}.${v}`)

        const payload = { query: queryToExecute, uuid, clarista_engine, params, connection_name, selected_tables }
        dispatch(getCreatePodPreviewTable(payload, false, false))
        .then(res => {
            const { result, output_regex } = res?.data

            if(result?.data_count === 200) {
                dispatch(setRowCountByUUID({ uuid: res?.data?.uuid, rowCount: [] }));
                dispatch(getPreviewTableRowCount(payload)).catch((e) => { });
            }
            else {
                dispatch(setRowCountByUUID({ uuid: res?.data?.uuid, rowCount: [result?.data_count] }));
            }

            setAutoClassifyObj(prev => {
                prev = {...prev, result: reduxData?.tableData?.['result']?.autoClassify ?? false}
                return prev
            })

            setStaticQuery(reduxData.schemaQueryMap['result'])

            reduxData['output_regex'] = output_regex??[]
            reduxData.tableData['result'] = {...result, uuid: res?.data?.uuid, autoClassify: autoClassifyObj['result'] ?? false}
            reduxData.schemaQueryMap['result'] = editorQuery
            reduxData.executeSqlPayload = payload
            setReduxData(reduxData)
            setPreviewLoading(false)
            setPodPreviewRunning(false)
            setLoadingTablesKeys(prev => prev?.filter(t => t !== 'result'))
            let stringPattern = getSchemaLevelStringPattern(parameters, editorQuery, clarista_engine)
            setStoredStringPattern(stringPattern)
            setTimeout(() => {
                setWalkThrough(prev => ({...prev, stepIndex: walkthroughStepIndex + 1}))
            }, 500)
        })
        .catch(err => {
            setPreviewError(err?.message)
            setPreviewLoading(false)
            setPodPreviewRunning(false)
            setLoadingTablesKeys(prev => prev?.filter(t => t !== 'result'))
            setTimeout(() => {
                setWalkThrough(prev => ({...prev, stepIndex: walkthroughStepIndex + 1}))
            }, 500)
        })
    }

    const setReduxData = (data) => {
        dispatch(setPodCreationData(data))
    }

    const runButtonVisibility = React.useMemo(() => {
        // if(!isAnyTableSelected) return true
        return (query === undefined || _.isEmpty(query?.trim())) || previewLoading || !isEditing || !sqlReady
    }, [
        query,
        previewLoading,
        // isAnyTableSelected,
        isEditing
    ])
    
    const items = React.useMemo(() => {
        let list = [
                {
                    key: engines.clarista,
                    label: <>
                    <div>
                        <label className='mb-0 fontSizeHeading'>Clarista Query Engine</label>
                        <p className='mb-0 small fontSizeExtraSmall'>
                            An in-house Clarista engine leveraging parallel SQL technology and our custom tools, it executes queries using ANSI SQL syntax.
                        </p>
                    </div>
                </>,
                },
                {
                    key: engines.db_engine,
                    label: <div>
                            <label className='mb-0 fontSizeHeading'>Source Database Query Engine</label>
                            <p className='mb-0 small fontSizeExtraSmall'>
                                A direct bridge to the end database, this engine allows for the execution of queries using the specific syntax supported by the respective database.
                            </p>
                        </div>,
                    disabled: isPodOfPod
                }
            ]
        if( isPodOfPod
            // || selectedConn?.source_type?.toLowerCase() === 'snowflake'
        ) {
            list = [list[0]]
        }
        return list
    }, [isPodOfPod])

    const onDrawerClose = () => setParamaterDrawer(false)

    const handleAddParams = (e, lastId = 0) => {
        e?.stopPropagation()
        setParameters(prev => {
            const newArr = [...prev, {id: lastId + 1, name: '', value: ''}]
            reduxData.parameters = [...newArr]
            setReduxData(reduxData)

            return newArr
        })
    }

    const handleRemoveParam = (id) => {
        setParameters(prev => {
            prev = prev.filter(p => p?.id !== id)
            reduxData.parameters = [...prev]
            setReduxData(reduxData)

            return prev
        })
    }

    const onParamInputChange = ({key, value, id}) => {
        if(key === 'name') {
            value = value?.trim()
            if(!preventSpecialCharsRegex.test(value) && value !== '') {
                return
            }
        }
        
        setParameters(prev => {
            prev = prev?.map(p => (
                {   ...p, 
                    [key]: p?.id === id ? value : p?.[key]
                }
            ))
            reduxData.parameters = [...prev]
            setReduxData(reduxData)

            return prev
        })
    }

    const isDuplicateParams = React.useMemo(() => {
        const paramArr = parameters.map(p => p.name ).filter(v => v !== '');
        const isDuplicate = paramArr.some((item, idx) => { 
            return paramArr.indexOf(item) !== idx 
        });
        return isDuplicate
    }, [parameters])

    const isParamAlreadyExist = (param) => {
        if(param.name === '') return false
        return parameters.filter(v => v.name === param.name)?.length > 1
    }
    
    return (
        <div className='h-100 w-100'>
            <div className='file-opts-head d-flex align-items-center justify-content-between py-2'>
                <label className='mb-0 fontSizeHeading fontInterSemiBold'>
                  {/* Report Editor

                    <Tooltip placement={'right'} title={'Switch to "Result" tab to write your own query'}>
                        <span className='ml-1 mb-1'><ICON_INFO_FILLED width='11' height='11' /></span>
                    </Tooltip> */}
                </label>
                <div className='d-flex align-items-center justify-content-end'>

                    <StoreCacheOption
                        isEditMode={isEditMode}
                        isEditing={isEditing}
                        disabled={previewLoading}
                    />

                    <div className='vertical-separator' style={{height: '1.25rem'}} ></div>

                    <Buttons
                        props={{
                            tooltip: "Clear Editor",
                            tooltipPlacement: 'top',
                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2`,
                            buttonEvent: () => {
                                setOpenClearModal(true)
                            },
                            ImgSrc: () => <ICON_CLEAR/>,
                            isDisable: runButtonVisibility,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />

                    <Buttons
                        props={{
                            tooltip: openParamaterDrawer ? "Close Drawer" : "Show Parameters",
                            tooltipPlacement: 'top',
                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2 
                                ${openParamaterDrawer ? 'active' : ''}
                                ${parameters?.length > 0 ? 'rdg-filter-active' : ''}
                                `,
                            buttonEvent: () => {
                                setParamaterDrawer(!openParamaterDrawer)
                            },
                            ImgSrc: () => <ICON_PARAMETER/>,
                            isDisable: runButtonVisibility,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />

                    <Dropdown
                        menu={{
                            items,
                            selectable: true,
                            defaultSelectedKeys: [activeEngine],
                            className: 'eng-dpd',
                            onClick: ({key}) => {
                                setActiveEngine(key)
                                reduxData['clarista_engine'] = key === engines.clarista
                                setReduxData(reduxData)
                            },
                            disabled: runButtonVisibility
                        }}
                        trigger={'click'}
                        open={openEngineMenu}
                        onOpenChange={(open) => setEngineMenu(open)}
                    >
                        <Buttons
                            props={{
                                tooltip: activeEngine === engines.clarista ? "Clarista Engine" : "DB Query Engine",
                                tooltipPlacement: 'top',
                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2 
                                    ${activeEngine === engines.clarista ? 'eng-btn-clarista-active' : 'eng-btn-db-active'}
                                    ${openEngineMenu ? 'draw-up' : ''}`,
                                buttonEvent: () => {
                                    setEngineMenu(!openEngineMenu)
                                },
                                ImgSrc: () => <ICON_ENGINE/>,
                                isDisable: runButtonVisibility,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </Dropdown>
                    
                    <Buttons
                        props={{
                            buttonId: podCreationTutorialComponentIds?.sqlRunButtonId,
                            buttonText: previewLoading ? "Executing..." : "Run",
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: () => {
                                onRun()
                            },
                            ImgSrc: () => previewLoading ? <ICON_LOADING_CIRCLE/> : <ICON_PLAY/>,
                            isDisable: runButtonVisibility,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </div>
            </div>
            <div className={`position-relative fopts-cus-cont pod-crt-sql-cont ${isReadOnly ? 'read-only' : ''} ${openParamaterDrawer ? 'draw-op' : ''}`}>
                
                {/* {
                    !isAnyTableSelected ? <div className='position-absolute w-100 h-100 d-block' 
                        style={{ top: 0, left: 0, zIndex: 1, background: '#d6d6d690' }}
                            onClick={() => {
                                if(showTableSelectInfo) return
                                setShowTableSelectInfo(true)
                            }}
                        ></div>
                    :   ''
                } */}

                <SqlEditor
                    id={podCreationTutorialComponentIds?.sqlEditorComponentId}
                    sqlTableDetails={sqlTableDetails}
                    isQueryViewMode={isEditMode ? !isEditing ? true : isReadOnly || previewLoading : isReadOnly}
                    readOnly={previewLoading || isReadOnly}
                    setQueryvalue={setQuery}
                    QueryValue={queryToDisplay}
                    height={'100%'}
                    // disableDebounce={true}
                    debounceTime={200}
                    handleEmptyQuery={true}
                    wrapClassName="pod-crt-sql-edit"
                    getInstantQuery={(q) => {
                        if(isCustomQuery) return
                        setEditorQuery(q)
                    }}
                    isChanged={sqlReady}
                    setChanged={setSqlReady}
                    parentEditorRef={sqlEditorRef}
                    // ExecuteQueryClick={onBlurSqlEvent}
                />

                {
                    getConfirmationModal({
                        open: openClearModal,
                        setOpen: setOpenClearModal,
                        heading: 'Clear Sql Editor',
                        body: `This will clear the Editor. Do you want to proceed?\nPress CTRL + Z to undo the changes`,
                        confirmationText: 'Yes, Clear',
                        onConfirm: () => {
                            // reduxData.tableData['result'] = reduxData.tableData[activeTab]
                            reduxData.schemaQueryMap['result'] = ''
                            dispatch(setPodCreationData(reduxData))

                            setStaticQuery('')
                        },
                        onCancel: () => {
                            setOpenClearModal(false)
                        }
                    })
                }

                <Drawer
                    title={
                        <div className='d-flex align-items-center justify-content-between'>
                            <label className='mb-0 fontSizeHeading fontInterSemibold'>Parameters</label>
                            <span className='underline-button' 
                                onClick={(e) => {handleAddParams(e, _.last(parameters)?.id);setShowParamGuide(false)}}>
                                    <ICON_PLUS/> Add
                            </span>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={onDrawerClose}
                    open={openParamaterDrawer}
                    getContainer={false}
                    rootClassName={`crt-pod-par-draw ${isEditMode ? 'src-view' : ''}`}
                    width={375}
                >
                    
                    <div className='param-cont-wrap' style={!showParamGuide ? {height: 'calc(100% - 80px)'} : {}}>
                        <div className='row'>
                            {
                                isDuplicateParams ?
                                <div className='col-12 mb-1'>
                                    <div className='text-center alert alert-danger p-1 small mb-0'>Duplicate Parameter(s) Found</div>
                                </div> : ''
                            }
                            {
                                parameters?.length > 0 && !showParamGuide
                                ?   <>
                                        <div className='col-5 pr-2'>
                                            <label className='fontSizeLabel fontInterSemiBold mb-3'>Name</label>
                                        </div>
                                        <div className='col-7 pl-2'>
                                            <label className='fontSizeLabel fontInterSemiBold mb-3'>Value</label>
                                        </div>
                                    </>
                                :   ''
                            }
                        </div>
                        {
                            showParamGuide ? '' :
                            parameters?.map(p => (
                                <div key={`par-${p.id}`} className='row mb-3'>
                                    <div className='col-5 align-items-center pr-2'>
                                        <Input 
                                            className={`custom-input-field ${isParamAlreadyExist(p) ? 'form-control is-invalid' : ''}`} 
                                            value={p?.name}
                                            onChange={(e) => onParamInputChange({key: 'name', value: e.target.value, id: p?.id})}
                                        />
                                    </div>
                                    <div className='col-7 pl-2'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <Input 
                                                className={`custom-input-field`}
                                                value={p?.value}
                                                onChange={(e) => onParamInputChange({key: 'value', value: e.target.value, id: p?.id})}
                                            />
                                            <Buttons
                                                props={{
                                                    tooltip: "",
                                                    tooltipPlacement: 'top',
                                                    buttonClassName: `danger-btn error-red custom-btn-outline custom-btn btn-with-icon ml-2`,
                                                    buttonEvent: (e) => {
                                                        e.stopPropagation()
                                                        handleRemoveParam(p?.id)
                                                    },
                                                    ImgSrc: () => <ICON_MINUS/>,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                            <div className={`bg-white `}>
                                {
                                    parameters?.length === 0
                                    ?
                                    <div className='p-2 text-center fontSizeHeading text-black-50'>
                                        <span className='underline-button' 
                                            onClick={(e) => handleAddParams(e, 0)}>
                                                <ICON_PLUS/> Add Parameter
                                        </span>
                                    </div>
                                    :   ''
                                }

                                    <div className={``} style={{height: showParamGuide && parameters?.length === 0 ? '100%' : 'calc(100% - 53px)'}}>
                                        {getParameterIntro({
                                            show: showParamGuide,
                                            setShow: setShowParamGuide,
                                            showLess: parameters?.length > 0
                                        })}
                                    </div>
                                </div>
                        
                        <div className='param-draw-done align-items-center border-top d-flex justify-content-end px-2 py-2 w-100'>
                            <Buttons
                                props={{
                                    buttonText: 'Done',
                                    buttonClassName: `custom-btn-primary custom-btn btn-with-text ml-2`,
                                    buttonEvent: (e) => {
                                        e.stopPropagation()
                                        setParamaterDrawer(false)
                                    },
                                    ImgSrc: () => <ICON_CHECK/>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                        </div>
                    </div>
                </Drawer>
            </div>
        </div>
    )
})

export default QueryEditorContainer

const getParameterIntro = ({ show = false, setShow = () => {}, showLess = false }) => {
    
    return <>
        <div className={`param-intro mt-3 w-100 ${show ? 'position-relative h-100' : 'cont-hidden'} pt-2`}>
            {
                !showLess
                ?
                <>
                    <h2 className='fontInterSemiBold h4'> What are Parameters? </h2>
                    <h2 className='fontInterSemiBold h4'> How it works? </h2>
                    <p className='mb-2'>Let's find out</p>
                </>
                :   ''
            }
            <div className='pb-2'>
                <Buttons
                    props={{
                        buttonText: show ? "Hide User Guide" : "Show User Guide",
                        tooltipPlacement: 'top',
                        buttonClassName: `custom-btn-outline custom-btn btn-with-text border`,
                        buttonEvent: (e) => {
                            e.stopPropagation()
                            setShow(!show)
                        },
                        ImgSrc: () => <ICON_GUIDE/>,
                        isDisable: false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />
            </div>
            <div className={`pt-2 ${show ? '' : 'd-none'}`} style={{height: showLess ? 'calc(100% - 35px)' : 'calc(100% - 136px)', overflowY: 'auto'}}>
                <p>Ready to Boost Your SQL Queries? Check Out Clarista Parameters!
                </p>
    

                <p className='fontInterSemiBold'>Ever wondered what parameters are in SQL queries?</p>
                <p> 
                Here's the fun fact! With Clarista, you can jazz up your SQL queries using templating. 
                Parameters are like little placeholders in your SQL template that can be filled with specific values when executing your query. Neat, right?
                </p>
                

                <p>Here's how to do it. 
                You can set your parameters as a key-value pair from our user-friendly UI (my_param: my_val). 
                Once you set them, you can pull them into your SQL like this: </p>
                {generateSqlTemplate("SELECT * FROM my_table WHERE my_column = {{my_param}}")}
                {/* <SqlEditor
                    QueryValue={`SELECT * FROM my_table WHERE my_column='{{my_param}}'`}
                    fromRoute="usage"
                    width={"100%"}
                    height={60}
                    readOnly={true}
                    className='guide-editor'
                /> */}
                <p>
                That's the magic of our templating syntax!
                </p>
                

                <p className='fontInterSemiBold'>Here's a quick peek at how parameters can make your SQL queries shine:</p>
                <p>
                Imagine you have an SQL template: 
                </p>
                {generateSqlTemplate("SELECT * FROM my_table WHERE my_numeric_col = {{my_numeric_param}}")}
                {/* <SqlEditor
                    QueryValue={`SELECT * FROM my_table WHERE my_numeric_col='{{my_numeric_param}}'`}
                    fromRoute="usage"
                    width={"100%"}
                    height={60}
                    readOnly={true}
                    className='guide-editor'
                /> */}
                <p>
                You set the parameter as my_numeric_param: 72, and voila! Your final SQL query looks like this:
                </p>

                {generateSqlTemplate("SELECT * FROM my_table WHERE my_numeric_col = 72")}

                {/* <SqlEditor
                    QueryValue={`SELECT * FROM my_table WHERE my_numeric_col= 72`}
                    fromRoute="usage"
                    width={"100%"}
                    height={60}
                    readOnly={true}
                    className='guide-editor'
                /> */}
                


                <p className='fontInterSemiBold mt-2'>You can also use parameters for string values, like this. Notice how the parameter is enclose in single quotes.
                </p>
                {generateSqlTemplate("SELECT * FROM my_table WHERE my_col = '{{my_param}}'")}
                {/* <SqlEditor
                    QueryValue={`SELECT * FROM my_table WHERE my_col= '{{my_param}}'`}
                    fromRoute="usage"
                    width={"100%"}
                    height={60}
                    readOnly={true}
                    className='guide-editor'
                /> */}
                <p>
                Set the parameter as my_param: my_val, and your final query will be
                </p>
                {generateSqlTemplate("SELECT * FROM my_table WHERE my_col = 'my_val'")}
                {/* <SqlEditor
                    QueryValue={`SELECT * FROM my_table WHERE my_col= 'my_val'`}
                    fromRoute="usage"
                    width={"100%"}
                    height={60}
                    readOnly={true}
                    className='guide-editor'
                /> */}
                

                <p className='fontInterSemiBold mt-2'>And, guess what? 
                Clarista also has universal variables for your SQL queries! 
                </p>
                <p>
                    
                These are your super handy friends: <code className='font-w-600 border px-1'>current_user</code>, 
                <code className='font-w-600 border px-1 ml-1'>current_date</code>, 
                <code className='font-w-600 border px-1 ml-1'>current_time</code>, 
                and <code className='font-w-600 border px-1 ml-1'>current_timestamp</code>. 
                So, you could have a query like
                </p>
                {generateSqlTemplate("SELECT * FROM my_table WHERE account_user = current_user")}
                {/* <SqlEditor
                    QueryValue={`SELECT * FROM my_table WHERE account_user=current_user`}
                    fromRoute="usage"
                    width={"100%"}
                    height={60}
                    readOnly={true}
                    className='guide-editor'
                /> */}
                <p>where current_user fetches the current user email. Nifty, huh?</p>

                <p className='fontInterSemiBold mt-2'>For timestamp based parameter:
                </p>
                <p>In clarista engine you will have to use:
                    <code className='font-w-600 border px-1 ml-1'>{"from_iso8601_timestamp('{{date_param}}')"}</code>
                </p>
                {generateSqlTemplate("SELECT * FROM table_name WHERE YEAR(from_iso8601_timestamp( '{{date_param}}' )) = 2017")}
                <p><code style={{fontSize: 14}} className='font-w-600 border px-1 mr-1'>from_iso8601_timestamp</code>this function will change according to engine select and what env is the query actually going to run over.</p>
                <p>Just a quick heads-up! When you compare a column to a value, make sure their data types match. For string and date data types make sure to use single quotes for parameter.
                Now, go on and ramp up your SQL game with Clarista!</p>
            </div>
        </div>
    </>
}

const generateSqlTemplate = (stringQuery) => {

    const strArr = stringQuery?.split(' ')?.filter(v => v !== '')
    const isSelectKey = (v) => v?.toLowerCase() === 'select' || v?.toLowerCase() === 'from' || v?.toLowerCase() === 'where'
    const isQuotedString = (v) => v?.split('')?.[0] === "'"

    return <code className='border d-block my-2 p-1 rounded text-dark'>
        {
            strArr?.map(v => (
                <span className={`
                ${ isSelectKey(v) ? 'color-primary font-w-600 text-uppercase' : isQuotedString(v) ? 'mtk4' : '' }
                `}>{`${v} `}</span>
            ))
        }
    </code>
}