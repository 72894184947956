import {
    SET_DATADOMAIN_LIST,
    SET_DATADOMAIN_ID,
    SET_DATADOMAIN_NAME,
    SET_DATADOMAIN_DESCRIPTION,
    SET_DATA_DOMAIN_PAGE_DEFAULT,
    SET_DISABLE_UM_BTN,
    SET_USER_SELECTED_DOMAIN
} from './dataDomainActionTypes'

const initialState = {
    datadomainList: [],
    dataDomainId: "",
    dataDomainDescription: '',
    dataDomainName: '',
    isUMHeaderBtnDisable: { isDisable: false, dataTab: '' },
    userSelectedDomain: null
};
const DataDomainReducer = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case SET_DATADOMAIN_LIST:
            return {
                ...state,
                datadomainList: [...payload]
            }
        case SET_DATA_DOMAIN_PAGE_DEFAULT:
            return initialState;

        case SET_DATADOMAIN_ID:
            return {
                ...state,
                dataDomainId: payload
            }
        case SET_DATADOMAIN_NAME:
            return {
                ...state,
                dataDomainName: payload
            }

        case SET_DISABLE_UM_BTN:
            return {
                ...state,
                isUMHeaderBtnDisable: payload
            }
        case SET_DATADOMAIN_DESCRIPTION:
            return {
                ...state,
                dataDomainDescription: payload
            }
        case SET_USER_SELECTED_DOMAIN:
            return {
                ...state,
                userSelectedDomain: payload
            }
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default DataDomainReducer;