import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../../helpers/toast_helper";
import { fetchData } from "../../../services/apiService";
import { setLoadingStatus } from "../common/loadingActions";
import { SET_SCHEDULER_PAYLOAD, SET_SELECTED_SCHEDULER, SET_TEMP_SELECTED_SCHEDULER } from "./SchedulerActionType";


var BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const SetSchedulerPayload = (payload) => {

	return {
		type: SET_SCHEDULER_PAYLOAD,
		payload: payload
	}
}

export const setSelectedScheduler = (payload) => {
	return {
		type: SET_SELECTED_SCHEDULER,
		payload: payload
	}
}
export const setSelectedTempScheduler = (payload) => {
	return {
		type: SET_TEMP_SELECTED_SCHEDULER,
		payload: payload
	}
}
export const getSchedulerLandingPage = (data_domain_id, mode = '') => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}scheduler/?data_domain_id=${data_domain_id ? data_domain_id : ''}&${mode}`, {}))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}


export const createSchedulerBookmark = (id, bookmarkStatus) => {

	return (dispatch, getState) => {
		dispatch(setLoadingStatus(true));
		return new Promise((resolve, reject) => {
			dispatch(
				fetchData(bookmarkStatus ? "GET" : "DELETE", `${BASE_URL}scheduler/${id}/bookmark`)
			).then((resp) => {
				dispatch(setLoadingStatus(false));
				if (resp.status !== 'success') {
					reject(resp.message);
					emitToastNotification('error', resp.message);
				} else {
					// toastr.success(resp.message);
					emitToastNotification('success', resp.message)
					// dispatch(setChartResponseQuery(resp.data.query))
					resolve(resp);
				}
			})
				.catch((err) => {
					dispatch(setLoadingStatus(false));
				});
		});
	};
}

export const getSchedulerAssignee = (flow_Id, dashboard_id) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}scheduler/recipients/?page=${1}${flow_Id ? "&flow=" + flow_Id : ''}${dashboard_id ? "&dashboard=" + dashboard_id : ''}`, {}))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const CreateScheduler = (Method, payload, endpoint) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData(Method, `${BASE_URL}${endpoint}`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}
export const GetSchedulerDetails = (id) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}scheduler/${id}/`, {}))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const toggleSchedulerStatus = (method, id) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData(method, `${BASE_URL}scheduler/${id}/status/`, {}))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						emitToastNotification('success', resp?.message)
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}


export const ExecuteScheduler = (id) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}scheduler/${id}/execute/`, {}))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						emitToastNotification('success', resp?.message)
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const DeleteScheduler = (id) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('Delete', `${BASE_URL}scheduler/${id}/`, {}))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						emitToastNotification('success', resp?.message)
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const GetSchedulerLastRun = (id, pageNo, limit, executionId) => {
	return (dispatch) => {
		// dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}scheduler/execution/?scheduler_id=${id}&page=${pageNo}&limit=${limit}&execution_id=${executionId ? executionId : ''}`, {}))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const assignSchedulerTag = (payload, schedulerId, showToast = true) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(
				fetchData('POST', `${BASE_URL}scheduler/${schedulerId}/tag/`, payload)
			)
				.then((resp) => {
					if (showToast) emitToastNotification("success", resp.message);

					resolve(resp);
				})
				.catch((err) => {
					if (showToast) emitToastNotification("error", err.message);

					reject(err);
				});
		});
	};
};

export const unassignSchedulerTag = (tagId, schedulerId, showToast = true) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(
				fetchData('DELETE', `${BASE_URL}scheduler/${schedulerId}/tag/?tag=${tagId}`)
			)
				.then((resp) => {
					if (showToast) emitToastNotification("success", resp.message);

					resolve(resp);
				})
				.catch((err) => {
					if (showToast) emitToastNotification("error", err.message);

					reject(err);
				});
		});
	};
};