import { Dropdown, Input } from 'antd'
import React from 'react'
import { ChevronDown, ChevronUp, Search } from 'react-bootstrap-icons'

const DataGridCriteriaDropdown = React.forwardRef(({
    column = {},
    setConList = () => { },
    activeCriteria = 'none',
    parentClassName = "",
    disable
}, ref) => {


    const [activeCriteriaKey, setActiveCriteriaKey] = React.useState(activeCriteria)
    const [ddlOpen, setDdlOpen] = React.useState(false)
    const [ddlInputValue, setDdlInputValue] = React.useState('')

    React.useImperativeHandle(ref, () => {
        // Future Use
        return {

        }
    }, [])

    React.useEffect(() => {

        setActiveCriteriaKey(activeCriteria)
    }, [activeCriteria])

    /**
     * char, varchar, boolean, date, time, timestamp, datetime, int, bigint, smallint
     */

    const criteriaOptions = React.useMemo(() => {
        let arr = [], dataType = column?.dtype
        


        let __text = [
            {
                label: 'Text contains',
                value: 'Text contains',
                key: 'like'
            },
            {
                label: 'Text does not contain',
                value: 'Text does not contain',
                key: 'not like'
            },
            {
                label: 'Text starts with',
                value: 'Text starts with',
                key: 's%'
            },
            {
                label: 'Text ends with',
                value: 'Text ends with',
                key: '%s'
            },
            {
                label: 'Text is exactly',
                value: 'Text is exactly',
                key: '=='
            },
            
            {
                label: 'Regex Like',
                value: 'Regex Like',
                key: 'regexp_like'
            },
            {
                label: 'Include',
                value: 'in',
                key: 'in'
            },
            {
                label: 'Exclude',
                value: 'not in',
                key: 'not in'
            },
        ]

        let __date = [
            {
                label: dataType?.split('(')?.[0] === "time" ? 'Time is' : 'Date is',
                value: dataType?.split('(')?.[0] === "time" ? 'Time is' : 'Date is',
                key: 'date='
            },
            {
                label: dataType?.split('(')?.[0] === "time" ? 'Time is before' : 'Date is before',
                value: dataType?.split('(')?.[0] === "time" ? 'Time is before' : 'Date is before',
                key: 'date<'
            },
            {
                label: dataType?.split('(')?.[0] === "time" ? 'Time is after' : 'Date is after',
                value: dataType?.split('(')?.[0] === "time" ? 'Time is after' : 'Date is after',
                key: 'date>'
            },
           
        ]

        let __numeric = [
            {
                label: 'Greater than',
                value: '>',
                key: '>'
            },
            {
                label: 'Greater than or equal to',
                value: '>=',
                key: '>='
            },
            {
                label: 'Less than',
                value: '<',
                key: '<'
            },
            {
                label: 'Less than or equal to',
                value: '<=',
                key: '<='
            },
            {
                label: 'Is equal to',
                value: '=',
                key: '='
            },
            {
                label: 'Is not equal to',
                value: '<>',
                key: '<>'
            },
            {
                label: 'Is between',
                value: 'between',
                key: 'between'
            },
            {
                label: 'Is not between',
                value: 'not between',
                key: 'not between'
            },
            {
                label: 'Include',
                value: 'in',
                key: 'in'
            },
            {
                label: 'Exclude',
                value: 'not in',
                key: 'not in'
            },
        ]

        let __common = [
            {
                label: 'None',
                value: 'none',
                key: 'none'
            },
            {
                type: 'divider'
            },
            {
                label: 'Is empty',
                value: 'Is empty',
                key: 'is'
            },
            {
                label: 'Is not empty',
                value: 'Is not empty',
                key: 'is not'
            }
        ]

        if (['char', 'varchar', 'string'].includes(dataType?.split('(')?.[0]))
            arr = [
                ...__common,
                {
                    type: 'divider'
                },
                ...__text,
            ]
        else if (['date', 'time', 'datetime', 'timestamp'].includes(dataType?.split('(')?.[0]))
            arr = [
                ...__common,
                {
                    type: 'divider'
                },
                ...__date,
                {
                    type: 'divider'
                },
                ...__numeric?.filter(c => c?.key?.includes('between') || c?.key?.includes('<>'))
            ]
        else if (['int', 'tinyint', 'bigint', 'smallint', 'integer', 'decimal', 'float', 'real', 'double'].includes(dataType?.split('(')?.[0]))
            arr = [
                ...__common,
                {
                    type: 'divider'
                },
                ...__numeric
            ]
        else if (dataType === 'boolean')
            arr = [
                ...__common,
                {
                    type: 'divider'
                },
                {
                    label: 'Is equal to',
                    value: '=',
                    key: '='
                },
                
            ]
        else
            arr = [
                ...__common,
                {
                    type: 'divider'
                },
                ...__text,
                {
                    type: 'divider'
                },
                ...__date,
                {
                    type: 'divider'
                },
                ...__numeric
            ]

        return arr
    }, [column])

    React.useEffect(() => {
        let val = criteriaOptions?.find(c => c?.key?.toLowerCase() === activeCriteriaKey?.toLowerCase())?.label ?? ''
        setDdlInputValue(val === 'None' ? '' : val)
    }, [activeCriteriaKey, criteriaOptions])

    return (
        <>
            <Dropdown
                menu={{
                    items: criteriaOptions,
                    selectable: true,
                    activeKey: activeCriteriaKey,
                    onClick: ({ key }) => {
                        setActiveCriteriaKey(key)
                        setConList(key)
                    }
                }}
                trigger={'click'}
                open={ddlOpen}
                disabled={window.location.href?.includes('viewFlows') || window.location.href?.includes('viewAlert') || disable ? true : false} //disable in view mode
                onOpenChange={(open) => setDdlOpen(open)}
                rootClassName={`criteria-flt-ddl ${parentClassName}`}
            >
                <Input
                    value={ddlInputValue}
                    placeholder='Criteria'
                    className={`criteria-prv-ddl ${!ddlOpen ? 'criteria-ddl-close' : 'criteria-ddl-open'}`}
                    style={{ height: '1.875rem', borderRadius: 0 }}
                    readOnly
                    // prefix={
                    //     ddlOpen
                    //     ? <Search color='#d9d9d9' size={10} className='mr-1' />
                    //     : <></>
                    // }
                    suffix={
                        ddlOpen
                            ? <ChevronUp color='#d9d9d9' onClick={() => setDdlOpen(false)} />
                            : <ChevronDown color='#d9d9d9' onClick={() => setDdlOpen(true)} />
                    }
                />
            </Dropdown>
        </>
    )
})

export default DataGridCriteriaDropdown