import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { Literals } from '../components/modules/common/literals'

const LoginRoutes = ({ user }) => {
   
    if (user?.userDetails?.email?.length > 0) {

        let splitParams = window.location.search?.split('?redirectFrom=')
        let url = null

        if (splitParams?.length === 2) {
            url = splitParams[1];
        }

        if (user && user?.userDetails?.openAi?.is_active && splitParams?.filter(s => s !== '')?.length === 0) {
            return <Navigate to={Literals.links.CLARISTA_HOME} replace />
        }
        else if (url && url !== '/' && !url?.includes('/login') && !url?.includes('/logout')) {
            return <Navigate to={url} replace />
        } else if (user?.userDetails?.designation === Literals.DATA_ANALYST) {

            return <Navigate to={Literals.links.CLARISTA_NAVIGATOR} replace />

        } else if (user?.userDetails?.designation === Literals.DATA_SCIENTIST) {

            return <Navigate to={Literals.links.CLARISTA_LAB} replace />

        } else if (user?.userDetails?.designation === Literals.DATA_CONSUMER) {
            return <Navigate to={Literals.links.CLARISTA_PULSE} replace />

        } else {
            return <Navigate to={Literals.links.CLARISTA_NAVIGATOR} replace />


        }

       
    }
  
    return <Outlet />
}
const mapStateToProps = (storeState) => {
    return {
        user: storeState.UserReducer.user,
    };
};
export default connect(mapStateToProps, null)(LoginRoutes);