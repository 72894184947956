import { emitToastNotification } from "../../../helpers/toast_helper";
import { fetchData } from "../../../services/apiService";
import { setLoadingStatus } from "../common/loadingActions";

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;



export const getChangeLogById=({id})=>{

	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}audit_log/audit_log/?page=1&limit=100&module_id=${id}`))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}


}


export const uploadFile=(payload)=>{
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('POST', `${BASE_URL}data_dictionary/dictionary/file_upload/`,payload))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}

}

export const deleteClassificationData=(id, force = false)=>{
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('DELETE', `${BASE_URL}data_dictionary/classification/${id}/?force=${force}`))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}


export const updateClassificationData=(id, payload)=>{
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('PUT', `${BASE_URL}data_dictionary/classification/${id}/`,payload))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const createClassificationData=(payload)=>{
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('POST', `${BASE_URL}data_dictionary/classification/`,payload))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const getClassificationData=(showLoader = true, showToast = true)=>{
	return (dispatch) => {
		if(showLoader) {
			dispatch(setLoadingStatus(true));
		}

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/classification/`))
				.then((resp) => {
					if (resp.status === "success") {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						resolve(resp)
					} else {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						if(showToast) {
							emitToastNotification('error', resp?.message);
						}
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showLoader) {
						dispatch(setLoadingStatus(false));
					}
					if(showToast) {
						emitToastNotification('error', err?.message);
					}
					reject(err);
				});
		})
	}
}



export const createNewSettingsAdditionalDetails = (payload) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('POST', `${BASE_URL}data_dictionary/additional/`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const updateSettingsAdditionalDetails = (id, payload) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('PUT', `${BASE_URL}data_dictionary/additional/${id}/`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const deleteSettingsAdditionalDetails = (id) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('DELETE', `${BASE_URL}data_dictionary/additional/${id}/`))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const getSettingsAdditionalDetails = (id) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/additional/${id}/`))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const getSettingsAdditionalDetailsList = (showLoader = true, showToast = true) => {
	return (dispatch) => {
		if(showLoader) {
			dispatch(setLoadingStatus(true));
		}

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/additional/`))
				.then((resp) => {
					if (resp.status === "success") {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						resolve(resp)
					} else {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						if(showToast) {
							emitToastNotification('error', resp?.message);
						}
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showLoader) {
						dispatch(setLoadingStatus(false));
					}
					if(showToast) {
						emitToastNotification('error', err?.message);
					}
					reject(err);
				});
		})
	}
}


export const createSettingsDataCategory = (payload) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('POST', `${BASE_URL}data_dictionary/category/`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const updateSettingsDataCategory = (id, payload) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('PUT', `${BASE_URL}data_dictionary/category/${id}/`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const deleteSettingsDataCategory = (id, force = false) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('DELETE', `${BASE_URL}data_dictionary/category/${id}/?force=${force}`))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const createBussinessTerm=(payload, showToast = true, showLoader = true)=>{
	return (dispatch) => {
		if(showLoader) {
			dispatch(setLoadingStatus(true));
		}

		return new Promise((resolve, reject) => {
			dispatch(fetchData('POST', `${BASE_URL}data_dictionary/dictionary/`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						if(showToast) {
							emitToastNotification('success', resp?.message)
						}
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						resolve(resp)
					} else {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						if(showToast) {
							emitToastNotification('error', resp?.message);
						}
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showLoader) {
						dispatch(setLoadingStatus(false));
					}
					if(showToast) {
						emitToastNotification('error', err?.message);
					}
					reject(err);
				});
		})
	}
}
export const deleteBussinessTerm = id => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('DELETE', `${BASE_URL}data_dictionary/dictionary/${id}/`))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}
export const getSettingsDataCategory = (showLoader = true, showToast = true) => {
	return (dispatch) => {
		if(showLoader) {
			dispatch(setLoadingStatus(true));
		}

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/category/`))
				.then((resp) => {
					if (resp.status === "success") {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						resolve(resp)
					} else {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						if(showToast) {
							emitToastNotification('error', resp?.message);
						}
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showLoader) {
						dispatch(setLoadingStatus(false));
					}
					if(showToast) {
						emitToastNotification('error', err?.message);
					}
					reject(err);
				});
		})
	}
}

export const uploadCategoryPolicyFile = (payload) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));
	  
	  return new Promise((resolve, reject) => {
		dispatch(fetchData("POST", `${BASE_URL}data_dictionary/policy/`, payload))
		  .then((resp) => {
			if (resp.status === "success") {
			  resolve(resp);
			  dispatch(setLoadingStatus(false));
			} else {
			  emitToastNotification('error', resp.message);
			  reject(resp)
			  dispatch(setLoadingStatus(false));
			}
		  })
		  .catch((err) => {
			dispatch(setLoadingStatus(false));
			reject(err);
			emitToastNotification('error', err?.message);
		  });
	  });
	};
};

export const updateCategoryPolicyFileName = (id, payload) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));
	  
	  return new Promise((resolve, reject) => {
		dispatch(fetchData("PUT", `${BASE_URL}data_dictionary/policy/${id}/`, payload))
		  .then((resp) => {
			if (resp.status === "success") {
			  resolve(resp);
			  dispatch(setLoadingStatus(false));
			} else {
			  emitToastNotification('error', resp.message);
			  reject(resp)
			  dispatch(setLoadingStatus(false));
			}
		  })
		  .catch((err) => {
			dispatch(setLoadingStatus(false));
			reject(err);
			emitToastNotification('error', err?.message);
		  });
	  });
	};
};

export const getCategoryPolicyFiles = (id) => {
	return (dispatch) => {
	  
	  return new Promise((resolve, reject) => {
		dispatch(fetchData("GET", `${BASE_URL}data_dictionary/policy/retrieve_category_policy/?category_id=${id}`))
		  .then((resp) => {
			if (resp.status === "success") {
			  resolve(resp);
			} else {
			  emitToastNotification('error', resp.message);
			  reject(resp)
			}
		  })
		  .catch((err) => {
			emitToastNotification('error', err?.message);
			reject(err);
		  });
	  });
	};
};

export const getCategoryPolicyFileContent = (id) => {
	return (dispatch) => {
	  
	  return new Promise((resolve, reject) => {
		dispatch(fetchData("GET", `${BASE_URL}data_dictionary/policy/${id}/`))
		  .then((resp) => {
			if (resp.status === "success") {
			  resolve(resp);
			} else {
			  emitToastNotification('error', resp.message);
			  reject(resp)
			}
		  })
		  .catch((err) => {
			emitToastNotification('error', err?.message);
			reject(err);
		  });
	  });
	};
};

export const deleteCategoryPolicyFile = (id) => {
	return (dispatch) => {
	  
	  return new Promise((resolve, reject) => {
		dispatch(fetchData("DELETE", `${BASE_URL}data_dictionary/policy/${id}/`))
		  .then((resp) => {
			if (resp.status === "success") {
			  resolve(resp);
			} else {
			  emitToastNotification('error', resp.message);
			  reject(resp)
			}
		  })
		  .catch((err) => {
			emitToastNotification('error', err?.message);
			reject(err);
		  });
	  });
	};
};

export const getDictionaryList = (pageNo, pageLimit, filters, showLoader = true) => {
	return (dispatch) => {
		if(showLoader) {
			dispatch(setLoadingStatus(true));
		}

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/dictionary/?page=${pageNo}&limit=${pageLimit}${filters}`))
				.then((resp) => {
					if (resp.status === "success") {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						resolve(resp)
					} else {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showLoader) {
						dispatch(setLoadingStatus(false));
					}
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const getBusinessTerms = () => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/dictionary/`))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const getBusinessTermsOfPod = (table_id,showLoader=true) => {
	return (dispatch) => {
		if(showLoader)
			dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/dictionary/?table_id=${table_id}`))
				.then((resp) => {
					if (resp.status === "success") {
						if(showLoader)
							dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						if(showLoader)
							dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showLoader)
						dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const getBusinessTermDetails = (id,module_type,isSearch) =>  {
	const searchParams = new URLSearchParams(document.location.search)
    const start_date=searchParams.get("start_date");
    const end_date=searchParams.get("end_date");

	return (dispatch) => {
		
		// dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/dictionary/${id}/${module_type?`?history_id=${module_type}&start_date=${start_date}&end_date=${end_date}`:""}`))
				.then((resp) => {
					if (resp.status === "success") {
						// dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						// dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					if(!isSearch)
						// dispatch(setLoadingStatus(false));;
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}


export const downloadTemplate=()=>{
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/dictionary/download_template/`,{download:true}))
				.then((resp) => {
					dispatch(setLoadingStatus(false));

					resolve(resp);
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}

}

export const updateBusinessTermDetails = (id, payload) => {
	return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('PUT', `${BASE_URL}data_dictionary/dictionary/${id}/`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						dispatch(setLoadingStatus(false));
						resolve(resp)
					} else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const updateTechnicalTerm=({id,payload})=>{

	return (dispatch) => {
		

		return new Promise((resolve, reject) => {
			dispatch(fetchData('PUT', `${BASE_URL}data_dictionary/tech_biz_term/${id}/`,payload))
				.then((resp) => {
					if (resp.status === "success") {
					
						resolve(resp)
					} else {
						
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}
export const deleteTechnicalTerm=({id})=>{
	return (dispatch) => {
		

		return new Promise((resolve, reject) => {
			dispatch(fetchData('DELETE', `${BASE_URL}data_dictionary/tech_biz_term/${id}/`))
				.then((resp) => {
					if (resp.status === "success") {
					
						resolve(resp)
					} else {
						
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}
export const getSearchedBusinessTerms = (payload, showLoader = true)=>{
	return (dispatch) => {
		if(showLoader) {
			dispatch(setLoadingStatus(true));
		}

		return new Promise((resolve, reject) => {
			dispatch(fetchData('POST', `${BASE_URL}data_dictionary/tech_biz_term/search_biz_term/`, payload))
			// dispatch(fetchData('POST', `${BASE_URL}data_dictionary/tech_biz_term/search_biz_term/`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						resolve(resp)
					} else {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showLoader) {
						dispatch(setLoadingStatus(false));
					}
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}


export const registerTechnicalWithBusinessTerm = (payload, showToast = true, showLoader = true)=>{
	return (dispatch) => {
		if(showLoader) {
			dispatch(setLoadingStatus(true));
		}

		return new Promise((resolve, reject) => {
			dispatch(fetchData('POST', `${BASE_URL}data_dictionary/tech_biz_term/`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						resolve(resp)
					} else {
						if(showLoader) {
							dispatch(setLoadingStatus(false));
						}
						if(showToast) {
							emitToastNotification('error', resp?.message);
						}
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showLoader) {
						dispatch(setLoadingStatus(false));
					}
					if(showToast) {
						emitToastNotification('error', err?.message);
					}
					reject(err);
				});
		})
	}
}


export const updateBusinessTermStatus = (id, payload) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchData('PUT', `${BASE_URL}data_dictionary/dictionary/${id}/status/`, payload))
				.then((resp) => {
					if (resp.status === "success") {
						resolve(resp)
					} else {
						emitToastNotification('error', resp?.message, '', { timeOut: 10000 });
						reject(resp?.message);
					}
				}).catch((err) => {
					emitToastNotification('error', err?.message, '', { timeOut: 10000 });
					reject(err);
				});
		})
	}
}

export const getBusinessTermStats = (id) => {
	return (dispatch) => {

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/dictionary/${id}/health/?current=true`))
				.then((resp) => {
					if (resp.status === "success") {
						resolve(resp)
					} else {
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const getTableLogsStats = (id, table_id) => {
	return (dispatch) => {

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/dictionary/${id}/health/?table_id=${table_id}`))
				.then((resp) => {
					if (resp.status === "success") {
						resolve(resp)
					} else {
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}



export const getPodRetrieveData = (table_id, showToast = true) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}catalog_home/v2/${table_id}/`))
				.then((resp) => {
					if (resp.status === "success") {
						resolve(resp)
					} else {
						if(showToast) {
							emitToastNotification('error', resp?.message);
						}
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showToast) {
						emitToastNotification('error', err?.message);
					}
					reject(err);
				});
		})
	}
}

export const createRecognizer = (payload, showToast = true) => {
	return (dispatch) => {

		return new Promise((resolve, reject) => {
			dispatch(fetchData('POST', `${BASE_URL}data_dictionary/recognizer/`,payload))
				.then((resp) => {
					if (resp.status === "success") {
						resolve(resp)
					} else {
						if(showToast) {
							emitToastNotification('error', resp?.message);
						}
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showToast) {
						emitToastNotification('error', err?.message);
					}
					reject(err);
				});
		})
	}
}

export const updateRecognizer = (id, payload, showToast = true) => {
	return (dispatch) => {

		return new Promise((resolve, reject) => {
			dispatch(fetchData('PUT', `${BASE_URL}data_dictionary/recognizer/${id}/`,payload))
				.then((resp) => {
					if (resp.status === "success") {
						resolve(resp)
					} else {
						if(showToast) {
							emitToastNotification('error', resp?.message);
						}
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showToast) {
						emitToastNotification('error', err?.message);
					}
					reject(err);
				});
		})
	}
}

export const fetchRecognizer = (showToast = true) => {
	return (dispatch) => {

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}data_dictionary/recognizer/`))
				.then((resp) => {
					if (resp.status === "success") {
						resolve(resp)
					} else {
						if(showToast) {
							emitToastNotification('error', resp?.message);
						}
						reject(resp?.message);
					}
				}).catch((err) => {
					if(showToast) {
						emitToastNotification('error', err?.message);
					}
					reject(err);
				});
		})
	}
}