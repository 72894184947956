import React from 'react'
import { connect } from 'react-redux'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom'
import { Literals } from '../components/modules/common/literals'
import { getObjectLength } from '../components/modules/common/helperFunctions'

const ProtectedRoutes = ({ user }) => {

    const history = useNavigate();
    const { flowId } = useParams();
  

    let localStorageUsers = localStorage.getItem('user');



    const s = window.screen.availWidth < 768 // screen size less than 768px down towards the mobile screensizes

    // Check user agent
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);

    function isDesktopView() {

        // Check screen dimensions
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        // Assuming desktop view if screen width is greater than 1024px
        const isDesktopScreen = screenWidth > 1024;

        return !isMobile && isDesktopScreen;
    }

    if (isDesktopView()) {
        // console.log("Desktop View is enabled.");
    } else if (isMobile) {
        // console.log("Mobile View is enabled.");
    }

    if (
        user?.userDetails?.email?.length
        && !window.location?.href?.includes('login')
        && !window.location?.href?.includes('logout')
        && !window.location?.href?.includes('clarista-pulse/talk')
        && !window.location?.href?.includes('home')
        && isMobile
    ) {
        if (isDesktopView())
            return <Navigate to={`${Literals.links.LOGOUT}?redirectFrom=${window.location.pathname} ${window?.location?.search}`} replace state={{ redirectFrom: window.location.pathname }} />
        return history(Literals.links.CLARISTA_HOME)
    }
    else if (!user?.userDetails?.email?.length && !window.location?.href?.includes('login') && !window.location?.href?.includes('forgot-password') && !window.location?.href?.includes('verify-otp') && !window.location?.href?.includes('signup') && !window.location?.href?.includes('logout')) {

        return <Navigate to={`${Literals.links.LOGOUT}?redirectFrom=${window.location.pathname} ${window?.location?.search}`} replace state={{ redirectFrom: window.location.pathname }} />

        // return <Navigate to={`${ Literals.links.LOGIN }?redirectFrom = ${ window.location.pathname }${ window?.location?.search } `} replace state={{ redirectFrom: window.location.pathname }} />

    }
    else if (!localStorageUsers?.userDetails?.is_superuser && !user?.userDetails?.is_superuser && window.location?.href?.includes('clusters')) {
        return <Navigate to={`page404`} />

    } else if (user?.userDetails?.email?.length && window.location?.href?.includes('/flow/') && flowId) {

        return <Navigate to={`${Literals.links.FLOWS} /${flowId}`} />
    } else if (!getObjectLength(user) && window.location?.href?.includes('logout?redirectFrom=/clarista-navigator')) {

        return <Navigate to={`${Literals.links.LOGOUT}?redirectFrom=/clarista-navigator#pod`}></Navigate>
    }
    return <Outlet />;



}
const mapStateToProps = (storeState) => {
    return {
        user: storeState.UserReducer.user,
    };
};
export default connect(mapStateToProps, null)(ProtectedRoutes);