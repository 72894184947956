import * as htmlToImage from "html-to-image";
import moment from "moment";
import React from "react";

import "toastr/build/toastr.min.css";
import { Tooltip, Typography, Skeleton, Carousel } from "antd";
import {
  BOOLEANTYPE,
  DATEDATATYPE,
  EQUALTOTYPE,
  FLOATDATATYPE,
  INTEGERDATATYPE,
  STRINGDATATYPE,
  CLOSE,
  OUTBOUND,
  ALERT,
  SQLBLUE,
  FILTERBLUE,
  LOGICALICON,
  FLOWS,
  FLOWICON,
  TIMETYPE,
} from "../../Common/iconSource";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Buttons from "../../Common/button/Buttons";
import * as msal from "@azure/msal-browser";

import Cookies from "js-cookie";
import AZURE from "../../../../src/assets/images/connectorsIcon/azure.svg";
import CASSANDRA from "../../../../src/assets/images/connectorsIcon/cassandra.svg";
import CLARISTA_DEMO from "../../../../src/assets/images/connectorsIcon/clarista_demo.svg";
import CSV from "../../../../src/assets/images/connectorsIcon/csv.svg";
import DELTA_LAKE from "../../../../src/assets/images/connectorsIcon/delta_lake.svg";
import ELASTICSEARCH from "../../../../src/assets/images/connectorsIcon/elastic_search.svg";
import FILE_UPLOAD from "../../../../src/assets/images/connectorsIcon/file_upload.svg";
import GOOGLE_SHEETS from "../../../../src/assets/images/connectorsIcon/google_sheets.svg";
import KAFKA from "../../../../src/assets/images/connectorsIcon/kafka.svg";
import MARIADB from "../../../../src/assets/images/connectorsIcon/mariadb.svg";
import MONGODB from "../../../../src/assets/images/connectorsIcon/mongoDB.svg";
import MYSQL from "../../../../src/assets/images/connectorsIcon/Mysql.svg";
import ORACLE from "../../../../src/assets/images/connectorsIcon/Oracle.svg";
import PARQUET from "../../../../src/assets/images/connectorsIcon/Parquet.svg";
import POSTGRESQL from "../../../../src/assets/images/connectorsIcon/Posgress.svg";
import REDIS from "../../../../src/assets/images/connectorsIcon/redis.svg";
import REDSHIFT from "../../../../src/assets/images/connectorsIcon/Redshift.svg";
import S3 from "../../../../src/assets/images/connectorsIcon/S3.svg";
import SALESFORCE from "../../../../src/assets/images/connectorsIcon/Salesforce.svg";
import SFTP from "../../../../src/assets/images/connectorsIcon/SFTP.svg";
import SNOWFLAKE from "../../../../src/assets/images/connectorsIcon/Snowflake.svg";
import SQLSERVER from "../../../../src/assets/images/connectorsIcon/sql_server.svg";
import JSONTYPE from "../../../../src/assets/images/json-type.png";
import ARRAYTYPE from "../../../../src/assets/images/array-type.png";
import MAPTYPE from "../../../../src/assets/images/map-type.png";
import ROBOT from "../../../../src/assets/images/Robot.png";
import DatabricksLogo from "../../../assets/images/connectorsIcon/databricks_icon.svg";
import TXT from "../../../../src/assets/images/connectorsIcon/Txt.svg";
import OPENAI from "../../../../src/assets/images/connectorsIcon/OpenAI.svg";
import SAP_HANA from "../../../../src/assets/images/connectorsIcon/Sap_hana.svg";
import { emitToastNotification } from "../../../helpers/toast_helper";
import _, { set } from "lodash";
import "./comman.scss";
import { Literals } from "./literals";
import { Input } from "antd";
import { Buffer } from "buffer";
import { flushSync } from "react-dom";
import Highlighter from "react-highlight-words";
import { CustomSkeleton } from "../../Common/skeleton/CustomSkeleton";
import { ICON_CALENDAR, ICON_CONFIRM, ICON_DEAD, ICON_ERROR, ICON_INFO, ICON_INFO_FILLED, ICON_REDIRECT, ICON_REFRESH, ICON_TXT_FILE } from "../../Common/newIconSource";
import CommonModal from "../../Common/CommonModal/CommonModal";
import unicodes from './unicodeListArray.json'
import helpfile from '../../../../src/assets/datatypes.rst'

window.Buffer = window.Buffer || require("buffer").Buffer;
var parser = require("cron-parser");

export function getQueryParams(query) {
  let params = query.split("&");
  let queryParamObj = {};
  params.forEach((element) => {
    let param = element.split("=");
    param = [param[0]?.replaceAll('?', ''), param[1]]
    queryParamObj[param[0]] = param[1];
  });
  return queryParamObj;
}

export function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    let summaryHeight = document.querySelector("#timeline-summary").offsetHeight;
    let scrollElement = document.querySelector(".preview-timeline");
    scrollElement.addEventListener("scroll", (e) => {
      console.log(e, e.target.scrollTop);
      if (e.target.scrollTop > summaryHeight && isIntersecting == false) {
        setIntersecting(true)
      }
      else if (e.target.scrollTop < summaryHeight && isIntersecting == true) {
        setIntersecting(false);
      }
    })
  }, [])

  // const observer = React.useMemo(
  //   () =>
  //     new IntersectionObserver(([entry]) =>
  //       setIntersecting(entry.isIntersecting)
  //     ),
  //   [ref]
  // );

  // React.useEffect(() => {
  //   if (ref?.current) observer.observe(ref?.current);
  //   return () => observer.disconnect();
  // }, []);

  return isIntersecting;
}

export const NUMERIC_DATATYPE = [{ Name: "integer", value: "integer", Type: "integer" },
{ Name: "float", value: "float", Type: "float" },
{ Name: "decimal", value: "decimal", Type: "decimal" }, { Name: "tinyint", value: "tinyint", Type: "tinyint" },
{ Name: "bigint", value: "bigint", Type: "bigint" },
{ Name: "smallint", value: "smallint", Type: "smallint" },
{ Name: "real", value: "real", Type: "real" },
{ Name: "double", value: "double", Type: "double" }]

export const STRING_DATATYPE = [{ Name: "char", value: "char", Type: "char" },
{ Name: "varchar", value: "varchar", Type: "varchar" }]

export const DATE_DATATYPE = [{ Name: "date", value: "date", Type: "date" },
{ Name: "time", value: "time", Type: "time" },
{ Name: "timestamp", value: "timestamp", Type: "timestamp" },
{ Name: "datetime", value: "datetime", Type: "datetime" },
]

export const BOOLEAN_DATATYPE = [{ Name: "boolean", value: "boolean", Type: "boolean" }]

export const DATATYPEGROUPS = {
  NUMERIC_DATATYPE: NUMERIC_DATATYPE,
  STRING_DATATYPE: STRING_DATATYPE,
  DATE_DATATYPE: DATE_DATATYPE,
  BOOLEAN_DATATYPE: BOOLEAN_DATATYPE
}


export const dataTypeList = [
  { Name: "char", value: "char", Type: "char" },
  { Name: "varchar", value: "varchar", Type: "varchar" },
  { Name: "boolean", value: "boolean", Type: "boolean" },
  { Name: "integer", value: "integer", Type: "integer" },
  // { Name: "float", value: "float", Type: "float" },
  { Name: "decimal", value: "decimal", Type: "decimal" },
  { Name: "date", value: "date", Type: "date" },
  { Name: "time", value: "time", Type: "time" },
  { Name: "timestamp", value: "timestamp", Type: "timestamp" },
  { Name: "tinyint", value: "tinyint", Type: "tinyint" },
  { Name: "bigint", value: "bigint", Type: "bigint" },
  { Name: "smallint", value: "smallint", Type: "smallint" },
  { Name: "real", value: "real", Type: "real" },
  { Name: "double", value: "double", Type: "double" },
  { Name: "json", value: "json", Type: "json" },
  { Name: "array", value: "array", Type: "array" },
  { Name: "map", value: "map", Type: "map" },
];

export const isUrlIncludePublicRoute = () => {
  if (!window.location?.href?.includes('login')
    && !window.location?.href?.includes('forgot-password')
    && !window.location?.href?.includes('verify-otp') &&
    !window.location?.href?.includes('signup') &&
    !window.location?.href?.includes('logout')) {
    return false;
  } else {
    return true
  }
}
export const getDateFromEPOC = (epoc) => {
  let d = new Date(Number(epoc));
  let dd = d.getDate();
  let mm = d.getMonth() + 1;
  let yyyy = d.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  const today = yyyy + "-" + mm + "-" + dd;

  return today;
};

export const getJourneyDate = (date) => {
  let d = new Date(date);
  let splitDate = d.toDateString().toString().split(" ");

  return (
    splitDate[0] +
    ", " +
    splitDate[1] +
    " " +
    splitDate[2] +
    ", " +
    splitDate[3]
  );
};

export const getJourneyTime2 = (date) => {
  let d = new Date(date);
  let time = d.toTimeString().slice(0, 5);
  return time;
};

export const getJourneyTime = (d) => {
  let date = new Date(d);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export function getDateToEPOC(epoc) {
  var today = new Date(epoc);

  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

export function titleCase(str) {
  if (_.isEmpty(str.trim())) return "";
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function dateformater(date, type) {
  if (type !== undefined && type.toLowerCase() === "datetime") {
    return moment(date).format("DD-MM-YYYY HH:mm:ss");  //.utc    remove utc for now showing local time

  } else if (type !== undefined && type.toLowerCase() === "daydatetime") {
    return moment(date).format('ddd MMM DD YYYY HH:mm:ss');  //.utc    remove utc for now showing local time

  } else if (type !== undefined && type.toLowerCase() === "datetimenosecond") {
    return moment(date).format("DD-MM-YYYY HH:mm");
  } else {
    return moment(date).format("DD-MM-YYYY");
  }

}

export function getTime(date) {
  return moment(date).format("HH:mm");  //remove utc for now showing local time
}

export function TextFile(data) {
  const element = document.createElement("a");
  const file = new Blob([data], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = "Query.txt";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

function fallbackCopyTextToClipboard(text, message) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    if (msg === "successful") {
      emitToastNotification("success", message);
      // emitToastNotification('success', message)
    } else {
      emitToastNotification("error", Literals.COPYING_WAS_UNSUCCESSFULL);
      // emitToastNotification('error', Literals.COPYING_WAS_UNSUCCESSFULL)
    }
  } catch (err) {
    console.error(Literals.COPYING_WAS_UNSUCCESSFULL);
  }

  document.body.removeChild(textArea);
}
export function copyToClipboard(text, message) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, message);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      emitToastNotification("success", message);
      // emitToastNotification('success', message)
    },
    function (err) {
      emitToastNotification("error", Literals.COPYING_WAS_UNSUCCESSFULL);
      // emitToastNotification('error', Literals.COPYING_WAS_UNSUCCESSFULL)
    }
  );
}

export function getInsightDefaultStartDate(initialdate) {
  let startDate = moment(initialdate).format("YYYY-MM-DD");
  const oneDay = 24 * 60 * 60 * 1000;
  const podcreate = new Date(initialdate);
  const now = new Date();
  const diffDays = Math.round(Math.abs((now - podcreate) / oneDay));
  if (diffDays > 180) {
    var d = new Date();
    d.setDate(d.getDate() - 180);
    startDate = moment(d).format("YYYY-MM-DD");
  }
  return startDate;
}

export function cronToDate(cron) {
  if (cron && cron !== "null") {
    try {
      var options = {
        utc: true,
      };
      return getRelativeTime(
        parser
          .parseExpression(cron.replace(/['"]+/g, ""), options)
          .next()
          .toString(),
        "datetime"
      );
    } catch (e) { }
  }
  return "";
}

export function createGraphFrames(graphData, isHorizontal) {
  let frames = [];
  let frameData = [];
  graphData.forEach((ele, i) => {
    frameData.push({ x: [], y: [] });
  });
  let tempFrame = [];
  graphData.forEach((trace, traceIndex) => {
    let temp = [];
    let traceDataLen = trace?.x?.length;
    for (var i = 0; i < traceDataLen; i++) {
      let data = { x: [], y: [] };
      let xAxis = trace.x.slice(0, i + 1);
      let yAxis = trace.y.slice(0, i + 1);
      if (xAxis && yAxis) {
        var filteredX = xAxis.filter(function (el) {
          return el != "";
        });

        data.x = isHorizontal ? yAxis : filteredX;
        data.y = isHorizontal ? filteredX : yAxis;
        temp.push(data);
      }
    }
    tempFrame.push(temp);
  });
  let arrayLength = tempFrame.map((ele) => ele.length);

  let largest = arrayLength.sort((a, b) => a - b).reverse()[0];

  for (let index = 0; index < largest; index++) {
    let dataObj = [];
    tempFrame.forEach((element) => {
      if (element.length < largest && index > element.length - 1) {
        dataObj.push(element[element.length - 1]);
      } else {
        dataObj.push(element[index]);
      }
    });
    frames.push({ data: dataObj });
  }

  return frames;
}

export function toTitleCase(str) {
  if (str === null || str === undefined) return ""
  if (_.isEmpty(str.trim())) return "";
  return (
    str?.length &&
    str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
  );
}

export function capitalizeFirstChar(str) {
  if (_.isEmpty(str.trim())) return "";
  str = str.charAt(0).toUpperCase() + str.substr(1);
  return str.replaceAll(" ", "_");
}

export function preventSpaceEvent(e) {
  if (e.keyCode === 32) {
    e.preventDefault();
  }
}

export function checkSpcialChar(event) {
  if (
    !(
      (event.keyCode >= 65 && event.keyCode <= 90) ||
      (event.keyCode >= 97 && event.keyCode <= 122) ||
      (event.keyCode >= 48 && event.keyCode <= 57)
    )
  ) {
    event.returnValue = false;
    return;
  }
  event.returnValue = true;
}

export const getISODateElapseTime = (start, end) => {
  const milliseconds = Math.abs(end - start).toString();
  const seconds = parseInt(milliseconds / 1000);
  const minutes = parseInt(seconds / 60);
  const hours = parseInt(minutes / 60);
  const days = parseInt(hours / 24);
  const time =
    days + ":" + (hours % 24) + ":" + (minutes % 60) + ":" + (seconds % 60);

  return time;
};
export const getChartType = (chart_type) => {
  if (toTitleCase(chart_type) === "Waterfall_bar") {
    return "Bar";
  } else if (chart_type === "KPI" || chart_type === "kpis") {
    return "KPI";
  } else {
    return toTitleCase(chart_type);
  }
};

export const stateReducer = (state, newState) => {
  return {
    ...state,
    ...newState,
  };
};

export const getVirtualTableRecords = (data = [], columns) => {
  let records = [];

  let obj = {};
  if (data && data.length) {
    data.map((ele, i) => {
      if (columns.length > 0) {
        obj[columns[i]] = ele;
        records.push(Object.values(ele));
      }
    });
  }

  return records;
};

export const getVirtualTableColumn = (columns = []) => {
  let obj = {};

  let tableColumns = columns.map((ele) => {
    obj = {
      title: ele.columnName,
      dataIndex: ele.dataIndex,
      key: ele.dataIndex,
      width: ele.width ? ele.width : "",
      className: "single-line-ellipsis",
      sorter: ele?.isSort
        ? {
          compare: (a, b) =>
            a[ele?.dataIndex] !== undefined
              ? a[ele?.dataIndex].localeCompare(b[ele?.dataIndex])
              : null,
        }
        : false,
    };
    return obj;
  });

  return tableColumns;
};

export const getColumnDataType = (dataType) => {
  if (
    dataType?.toLowerCase()?.includes("integer") ||
    dataType?.toLowerCase()?.includes("smallint") ||
    dataType?.toLowerCase()?.includes("int") ||
    dataType?.toLowerCase()?.includes("bigint") ||
    dataType?.toLowerCase()?.includes("tinyint")
  ) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <INTEGERDATATYPE />
      </Tooltip>
    );
  } else if (
    dataType?.toLowerCase()?.includes("varchar") ||
    dataType?.toLowerCase()?.includes("string") ||
    dataType?.toLowerCase()?.includes("char")
  ) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <STRINGDATATYPE />
      </Tooltip>
    );
  } else if (
    dataType?.toLowerCase()?.includes("float") ||
    dataType?.toLowerCase()?.includes("double") ||
    dataType?.toLowerCase()?.includes("decimal") ||
    dataType?.toLowerCase()?.includes("real")
  ) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <FLOATDATATYPE />
      </Tooltip>
    );
  } else if (
    dataType?.toLowerCase()?.includes("date") ||
    dataType?.toLowerCase()?.includes("datetime") ||
    dataType?.toLowerCase().includes("timestamp") ||
    dataType?.toLowerCase().includes("time")
  ) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <DATEDATATYPE />
      </Tooltip>
    );
  } else if (

    dataType?.toLowerCase().includes("timestamp") ||
    dataType?.toLowerCase().includes("time")
  ) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <DATEDATATYPE />

      </Tooltip>
    );
  } else if (dataType?.toLowerCase()?.includes("equalto")) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <EQUALTOTYPE />
      </Tooltip>
    );
  } else if (dataType?.toLowerCase()?.includes("boolean")) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <BOOLEANTYPE />
      </Tooltip>
    );
  } else if (dataType?.toLowerCase()?.includes("json")) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <img className="mx-1" height={15} width={15} src={JSONTYPE}></img>

      </Tooltip>
    );
  } else if (dataType?.toLowerCase()?.includes("array")) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <img className="mx-1" height={15} width={15} src={ARRAYTYPE}></img>
      </Tooltip>
    );
  } else if (dataType?.toLowerCase()?.includes("map")) {
    return (
      <Tooltip title={dataType}>
        {" "}
        <img className="mx-1" src={MAPTYPE}></img>
      </Tooltip>
    );
  } else {
    return "";
  }
};

function saveScreenshot(canvas) {
  let fileName = "image";
  const link = document.createElement("a");
  link.download = fileName + ".png";
  canvas.toBlob(function (blob) {
    link.href = URL.createObjectURL(blob);
    link.click();
  });
}

export const convertDOMElementToImage = (selector, calculateDimension) => {
  const node = document.querySelector(selector);
  let dataURL;
  let dimensions;
  let newResizeImage;
  return new Promise(function (resolve, reject) {
    switch (calculateDimension) {
      case "exact":
        dimensions = calculateWidthHeight(node);

        htmlToImage.toPng(node, { ...dimensions }).then((dataURL) => {
          const base64string = "";
          const pageImage = new Image();
          pageImage.src = dataURL + base64string;
          pageImage.onload = function () {
            let maxWidth = 500;
            let mime_type = "image/png";
            let quality = 90;
            let natW = pageImage.naturalWidth;
            let natH = pageImage.naturalHeight;
            let ratio = natH / natW;
            if (natW > maxWidth) {
              natW = maxWidth;
              natH = ratio * maxWidth;
            }

            let cvs = document.createElement("canvas");
            cvs.width = natW;
            cvs.height = natH;

            let ctx = cvs.getContext("2d").drawImage(pageImage, 0, 0, natW, natH);
            let newImageData = cvs.toDataURL(mime_type, quality / 100);

            resolve(newImageData);
          }
        })



        break;

      case "visible":
        dimensions = extractVisibleWidthHeight(node);
        // dataURL =  
        htmlToImage.toPng(node, { ...dimensions }).then((dataURL) => {
          resolve(dataURL)
        })
        break;

      default:
        // dataURL =  

        htmlToImage.toPng(node).then((dataURL) => {
          resolve(dataURL)
        })
    }
  });
};

export const extractVisibleWidthHeight = (node) => {
  const bounding = node.getBoundingClientRect();
  const dimensions = {
    width: bounding.width - 60,
    height: window.screen.availHeight - bounding.top - 60,
  };
  return dimensions;
};

export const calculateWidthHeight = (node) => {
  const bounding = node.getBoundingClientRect();
  const dimensions = { width: bounding.width - 60, height: bounding.height };
  return dimensions;
};

export const checkIfNodeVisibleInViewPort = (node) => {
  const bounding = node.getBoundingClientRect();

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= window.screen.availHeight &&
    bounding.right <= window.screen.availWidth
  );
};

export function deleteCookies() {
  // let allCookies = document.cookie.split(';');

  // // The "expire" attribute of every cookie is
  // // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  // for (let i = 0; i < allCookies.length; i++)
  //   document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie =
      name +
      "=null;" +
      "expires=Thu, 01-Jan-1970 00:00:01 GMT;" +
      "path=" +
      "/;";
  }
  const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

  let splitDomain = BASE_URL?.split(".");
  let lastDomain = splitDomain[splitDomain?.length - 1];
  if (splitDomain[splitDomain?.length - 1].includes("/")) {
    lastDomain = lastDomain.replace("/", "");
  }

  let domainName = `${splitDomain[splitDomain?.length - 2]}.${lastDomain}`;

  Cookies.remove("jupyterhub-hub-login");
  Cookies.remove("jupyterhub-session-id");
  Cookies.remove("jupyterhub-hub-login", { domain: domainName, path: "" });
  Cookies.remove("_xsrf");
  Cookies.remove("_xsrf", { domain: domainName, path: "" });
  Cookies.remove("jupyterhub-session-id", { domain: domainName, path: "" });
}

const sourceTransformerGroup = ['datasource', 'filter', 'merge', 'schema', 'aggregate']
const scriptTransformerGroup = ['script', 'databricks', 'sql']
const outputTransformerGroup = ['target', 'alert']

// export function addSelectedTransformerClass(currentTransformerId, transformerType) {
//   let reactNodeDiv = document.getElementsByClassName("react-flow__node");

//   if (reactNodeDiv && reactNodeDiv.length) {
//     for (let index = 0; index < reactNodeDiv.length; index++) {
//       if (reactNodeDiv[index]?.dataset?.id === currentTransformerId) {
//         if (reactNodeDiv[index]?.classList?.length && sourceTransformerGroup?.includes(transformerType)) {
//           reactNodeDiv[index].classList.add("source-transformer-selected");
//         } else if (reactNodeDiv[index]?.classList?.length && scriptTransformerGroup?.includes(transformerType)) {
//           reactNodeDiv[index].classList.add("script-transformer-selected");
//         } else if (reactNodeDiv[index]?.classList?.length && outputTransformerGroup?.includes(transformerType)) {
//           reactNodeDiv[index].classList.add("output-transformer-selected");
//         }
//       } else {
//         if (reactNodeDiv[index]?.classList.contains("source-transformer-selected")) {
//           reactNodeDiv[index].classList.remove("source-transformer-selected");
//         } else if (reactNodeDiv[index]?.classList.contains("source-transformer-selected")) {
//           reactNodeDiv[index].classList.remove("script-transformer-selected");
//         } else if (reactNodeDiv[index]?.classList.contains("source-transformer-selected")) {
//           reactNodeDiv[index].classList.remove("output-transformer-selected");
//         }
//       }
//     }
//   }
// }

export function addSelectedTransformerClass(currentTransformerId, transformerType) {
  let reactNodeDiv = document.getElementsByClassName("react-flow__node");

  if (reactNodeDiv && reactNodeDiv.length) {
    for (let index = 0; index < reactNodeDiv.length; index++) {
      if (reactNodeDiv[index]?.dataset?.id === currentTransformerId) {
        if (reactNodeDiv[index]?.classList?.length) {
          reactNodeDiv[index].classList.add("selected");
        }
      } else {
        if (reactNodeDiv[index]?.classList.contains("selected")) {
          reactNodeDiv[index].classList.remove("selected");
        }
      }
    }
  }
}


export const preventEnterEvent = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
  }
};

export const filterOptions = [
  { Name: ">", icon: () => <EQUALTOTYPE /> },
  { Name: "<", icon: () => <EQUALTOTYPE /> },
  { Name: ">=", icon: () => <EQUALTOTYPE /> },
  { Name: "<=", icon: () => <EQUALTOTYPE /> },
  { Name: "=", icon: () => <EQUALTOTYPE /> },
  { Name: "<>", icon: () => <EQUALTOTYPE /> },
  { Name: "BETWEEN", icon: () => <EQUALTOTYPE /> },
  { Name: "NOT BETWEEN", icon: () => <EQUALTOTYPE /> },
  { Name: "IS", icon: () => <EQUALTOTYPE /> },
  { Name: "IS NOT", icon: () => <EQUALTOTYPE /> },
  { Name: "IN", icon: () => <EQUALTOTYPE /> },
  { Name: "NOT IN", icon: () => <EQUALTOTYPE /> },
];
export const numericFilterOptions = [
  { Name: ">", icon: () => <EQUALTOTYPE /> },
  { Name: "<", icon: () => <EQUALTOTYPE /> },
  { Name: ">=", icon: () => <EQUALTOTYPE /> },
  { Name: "<=", icon: () => <EQUALTOTYPE /> },
  { Name: "=", icon: () => <EQUALTOTYPE /> },
  { Name: "<>", icon: () => <EQUALTOTYPE /> },
  { Name: "BETWEEN", icon: () => <EQUALTOTYPE /> },
  { Name: "NOT BETWEEN", icon: () => <EQUALTOTYPE /> },
  { Name: "IS", icon: () => <EQUALTOTYPE /> },
  { Name: "IS NOT", icon: () => <EQUALTOTYPE /> },
  { Name: "IN", icon: () => <EQUALTOTYPE /> },
  { Name: "NOT IN", icon: () => <EQUALTOTYPE /> },
];
export const dateOptions = [
  { Name: ">", icon: () => <EQUALTOTYPE /> },
  { Name: "<", icon: () => <EQUALTOTYPE /> },
  { Name: ">=", icon: () => <EQUALTOTYPE /> },
  { Name: "<=", icon: () => <EQUALTOTYPE /> },
  { Name: "=", icon: () => <EQUALTOTYPE /> },
  { Name: "<>", icon: () => <EQUALTOTYPE /> },
  { Name: "BETWEEN", icon: () => <EQUALTOTYPE /> },
  { Name: "NOT BETWEEN", icon: () => <EQUALTOTYPE /> },
  { Name: "IS", icon: () => <EQUALTOTYPE /> },
  { Name: "IS NOT", icon: () => <EQUALTOTYPE /> },

];
export const stringFilterOptions = [
  { Name: "LIKE", icon: () => <EQUALTOTYPE /> },
  { Name: "NOT LIKE", icon: () => <EQUALTOTYPE /> },
  { Name: "=", icon: () => <EQUALTOTYPE /> },
  { Name: "<>", icon: () => <EQUALTOTYPE /> },
  { Name: "BETWEEN", icon: () => <EQUALTOTYPE /> },
  { Name: "NOT BETWEEN", icon: () => <EQUALTOTYPE /> },
  { Name: "IS", icon: () => <EQUALTOTYPE /> },
  { Name: "IS NOT", icon: () => <EQUALTOTYPE /> },
  { Name: "IN", icon: () => <EQUALTOTYPE /> },
  { Name: "NOT IN", icon: () => <EQUALTOTYPE /> },
];
export const booleanFilterOptions = [
  // { Name: "TRUE", icon: () => <EQUALTOTYPE /> },

  // { Name: "FALSE", icon: () => <EQUALTOTYPE /> },
  { Name: "=", icon: () => <EQUALTOTYPE /> },
  { Name: "IS", icon: () => <EQUALTOTYPE /> },
  { Name: "IS NOT", icon: () => <EQUALTOTYPE /> },
];

export const showSimpleLoading = (message = "Loading...") => {
  // {message}
  return (
    <div className="align-items-center d-flex justify-content-center p-3 text-center">
      <i className="bx bx-loader-alt bx-spin mr-1"></i>{" "}
      <label className="mb-0 label">{message}</label>
    </div>
  );
  // return <div className='py-3 small loader'><Spin tip="Loading...">
  // </Spin></div>
};

export const mergeArrayByProperty = (target, source, prop, reverseArrayOrder = false) => {
  source.forEach(sourceElement => {
    let targetElement = target.find(targetElement => {
      return sourceElement[prop] === targetElement[prop];
    })
    if (!reverseArrayOrder) {

      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    } else {
      targetElement ? Object.assign(targetElement, sourceElement) : target.unshift(sourceElement);

    }
  })
  return target;
}

export const mergeArrayByPropertyJoinType = (target, source, prop, reverseArrayOrder = false) => {
  source.forEach(sourceElement => {
    let targetElement = target.find(targetElement => {
      return (sourceElement[prop] === targetElement[prop] && sourceElement['frame'] === targetElement['frame']);
    })

    if (!reverseArrayOrder) {

      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    } else {
      targetElement ? Object.assign(targetElement, sourceElement) : target.unshift(sourceElement);

    }
  })
  if (target?.length) {
    let sortedtarget = target.sort((a, b) => {
      if (a.dataSource < b.dataSource) return -1;
      if (a.dataSource > b.dataSource) return 1;
      return 0;
    });
    return sortedtarget;
  }
}

export const getAlertCount = (number) => {
  if (number === 0) {
    return number;
  }
  if (number) {
    let decimalNumber = Math.ceil(Math.abs(number));

    let noOfDigit = decimalNumber.toString().length;
    switch (noOfDigit) {
      case 1:
      case 2:
      case 3:
        return decimalNumber;

      case 4:
        return `${decimalNumber.toString().slice(0, 1)}K+`;

      case 5:
        return `${decimalNumber.toString().slice(0, 2)}K+`;

      case 6:
        return `${decimalNumber.toString().slice(0, 3)}K+`;

      case 7:
        return `${decimalNumber.toString().slice(0, 1)}M+`;

      default:
        break;
    }
  }
};

export const getMonthName = {
  full: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  short: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
};
export const getReadableExecutionTime = (time = "00:00:00") => {
  let etm = time.split(":");
  let hrs = parseInt(etm[0]);
  let min = parseInt(etm[1]);
  let sec = parseFloat(etm[2]);
  if (hrs > 0) {
    return hrs + " hrs";
  } else if (min > 0) {
    return min + " min";
  } else if (sec > 0) {
    return sec + " sec";
  } else {
    return "00:00:00";
  }
};

export const convertBase64ToString = (data) => {
  return Buffer.from(data, "base64").toString();
};

export const queryStatus = {
  success: "SUCCESS",
  running: "RUNNING",
  failed: "FAILED",
  error: "ERROR",
  finished: "FINISHED",
  queued: "QUEUED",
  done: "DONE",
};
export const successStatus = ["success", "finished", "done"];
export const failedStatus = ["failed", "error"];
export const connectorsList = {
  sources: [
    "s3",
    "oracle",
    "postgresql",
    "sqlserver",
    "mariadb",
    "azure_adls",
    "cassandra",
    // "clarista_demo",
    // "csv",
    // "delta_lake",
    // "kafka",
    "elasticsearch",
    // "google_sheets",
    "mongodb",
    // "parquet",
    "redis",
    "redshift",
    // "sales_force",
    "sap_hana",
    // "sftp",
    "snowflake",
    "mysql",
    "databricks",
    // "openai",
    // "upload",
    // "txt",
  ],
  icons: {
    azure_adls: AZURE,
    cassandra: CASSANDRA,
    clarista_demo: CLARISTA_DEMO,
    "pod of pods": CLARISTA_DEMO,
    csv: CSV,
    upload: FILE_UPLOAD,
    delta_lake: DELTA_LAKE,
    kafka: KAFKA,
    elasticsearch: ELASTICSEARCH,
    google_sheets: GOOGLE_SHEETS,
    mongodb: MONGODB,
    oracle: ORACLE,
    parquet: PARQUET,
    redis: REDIS,
    redshift: REDSHIFT,
    sales_force: SALESFORCE,
    sftp: SFTP,
    snowflake: SNOWFLAKE,
    sqlserver: SQLSERVER,
    txt: TXT,
    postgresql: POSTGRESQL,
    postgres: POSTGRESQL,
    mysql: MYSQL,
    s3: S3,
    mariadb: MARIADB,
    databricks: DatabricksLogo,
    sap_hana: SAP_HANA
    // openai: OPENAI
  },
  title: {
    azure_adls: "Azure ADLS",
    cassandra: "Apache Cassandra",
    clarista_demo: "POD of PODs",
    csv: "CSV",
    upload: "File Upload",
    delta_lake: "DELTA LAKE",
    kafka: "Apache Kafka",
    elasticsearch: "Elasticsearch",
    google_sheets: "Google Sheets",
    mongodb: "MongoDB",
    oracle: "Oracle",
    parquet: "Apache Parquet",
    redis: "Redis",
    redshift: "Amazon Redshift",
    sales_force: "Salesforce",
    sftp: "SFTP",
    snowflake: "Snowflake",
    sqlserver: "SQL Server",
    txt: "Text File",
    postgresql: "PostgreSQL",
    postgres: "PostgreSQL",

    mysql: "MySQL",
    s3: "Amazon S3",
    mariadb: "MariaDB",
    databricks: "Databricks",
    sap_hana: "SAP Hana"
    // openai: "OpenAI"
  },
};

export const getObjectLength = (obj) => {
  if (obj) {
    return Object.keys(obj).length
  }
  else {
    return 0
  }
};

export const convertSecondToHHMMSS = (totalSeconds) => {

  let duration = moment.duration(totalSeconds, 'seconds');
  let timeStr =  moment.utc(duration.asMilliseconds()).format('HH:mm:ss');

  return timeStr

};

export const removeDuplicates = (arr) => {
  let result = [];
  arr.forEach((item, index) => {
    if (arr.indexOf(item) === index) result.push(item);
  });
  return result;
};

export const columnNameRegexExp = /[^\w\*]/g;
export const specialCharacterRegexExp =
  /[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/g;
export const technicaTermRegexExp = /^[_a-z][a-z0-9_]*$/g;

export const integerRegexExp = /[^0-9-]/g;
export const floatRegexExp = /[^0-9.-]/g;
// export const podNameRegexExp = /^[a-zA-Z][a-zA-Z0-9_]*$/
export const columnNameRegex = new RegExp("^[a-zA-Z][a-zA-Z0-9_]*$");
export const businessTermRegex = new RegExp("^[a-zA-Z][a-zA-Z0-9_]*$");
export const podNameRegex = new RegExp("^[a-zA-Z][a-zA-Z0-9_]*$");

export const allowOnlyNumberRegex = new RegExp("^[0-9]*$");
export const preventSpecialCharsRegex = new RegExp("^[a-zA-Z][a-zA-Z0-9_ ]*$");

export const podDeleteNote = (podId, type) => {
  return (
    <>
      <p className="font-w-600">Are you sure you want to delete this Pod?</p>
      <p className="alert alert-warning mb-0">
        {type == "upload"
          ? "You should delete the data through Source details before deleting the POD . Once the POD is deleted , you will no longer be able to access the underlying data through Clarista application."
          : "Deleting this Pod might affect Alert, Flows, Scheduler."}
        <div className="mt-2">
          <a
            className="text-underline"
            href={`/viewLineage/pod/${podId}`}
            target="_blank"
          >
            <span className="mr-1">
              <ICON_REDIRECT height="12" width="12" />
            </span>
            Click here
          </a>{" "}
          to check its Lineage.
        </div>
      </p>
    </>
  );
};

export const DeleteTechnicalTerm = () => {
  return (
    <>
      <p className="font-w-600 pl-2">
        This action will delete Technical Name permanently.
      </p>
      <p className="alert alert-warning mb-0">
        Are you sure, you want to delete ?
      </p>
    </>
  );
};
export const DeleteRoleContent = () => {
  return (
    <>
      <p className="font-w-600 pl-2">
        This action will delete Role permanently.
      </p>
      <p className="alert alert-warning mb-0">
        Deleting Roles will delete the usergroup in Data Domain. Are you sure to
        delete?
      </p>
    </>
  );
};
export const DeleteNoteContent = (
  id,
  type,
  msg,
  showWarning = true,
  showLineage = true
) => {
  return (
    <>
      <p className="font-w-600">Are you sure you want to delete this {type}?</p>
      {showWarning ? (
        <p className="alert alert-warning mb-0">
          {msg}
          {type !== "alert" && showLineage ? (
            <div className="mt-2">
              <a
                className="text-underline"
                href={`/viewLineage/${type}/${id}`}
                target="_blank"
              >
                <span className="mr-1">
                  <ICON_REDIRECT height="12" width="12" />
                </span>
                Click here
              </a>{" "}
              to check its Lineage.
            </div>
          ) : null}
        </p>
      ) : (
        ""
      )}
    </>
  );
};

export const CreateRoleNoteContent = (updateValue) => {
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <label className="fontSizeHeading">Name</label>
        <Input
          className="custom-input-field"
          onChange={(e) => updateValue("name", e.target.value)}
        />
      </div>
      <div>
        <label className="fontSizeHeading">Description</label>
        <textarea
          className="custom-textarea-field"
          onChange={(e) => updateValue("description", e.target.value)}
        />
      </div>
    </>
  );
};

export const logoutContent = (msg) => {
  return (
    <>
      <p className="alert alert-warning mb-0">{msg}</p>
    </>
  );
};

export const RolePrivilegeModal = ({ openModal, setOpenModal }) => {
  const ROLE_OPTIONS = ["SUPERUSER", "GROUP LEADER", "DOMAIN OWNER"];
  const [role, setRole] = React.useState("SUPERUSER");

  const { Title, Paragraph, Text, Link } = Typography;
  const onClose = () => {
    setOpenModal(false);
  };
  return (
    <Modal
      size="xl"
      className="connection-guide-modal role-guide"
      isOpen={openModal}
      centered={true}
    >
      <ModalHeader>
        <div className="d-flex align-items-center ">
          <OUTBOUND></OUTBOUND>
          <div>
            <h2 className="section-heading ml-2"> Roles Privileges</h2>
          </div>
        </div>

        <div className="">
          <Buttons
            props={{
              buttonText: "",
              buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
              buttonEvent: () => {
                onClose(false);
              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_SECONDARY,
            }}
          />
        </div>
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="merge-join-content">
          <div
            style={{ width: "15%" }}
            className="join-list custom-border-right"
          >
            {ROLE_OPTIONS.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    onClick={() => {
                      setRole(item);
                    }}
                    className={`join-list-item ${item === role ? `selected-list-item` : ``
                      }`}
                  >
                    {/* <Img /> */}
                    <p className="label mb-0">{item}</p>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div
            id="conn-guide-mod"
            style={{ width: "85%", overflow: "auto" }}
            className="infographic"
          >
            {role == "SUPERUSER" ? (
              <React.Fragment>
                <h4 className="fontSizeLargeHeading fontInterSemiBold mb-3">Superuser Privileges</h4>
                <h5 className="fontSizeLabel fontInterSemiBold"> Connection</h5>
                <ul className="mb-2">
                  <li className="fontSizeHeading">Create(Configure, Test, Save)</li>
                  <li className="fontSizeHeading">Update</li>
                  <li className="fontSizeHeading">Delete</li>
                </ul>
                <h5 className="fontSizeLabel fontInterSemiBold">Data Domain</h5>
                <ul className="mb-2">
                  <li className="fontSizeHeading">Create</li>
                  <li className="fontSizeHeading">Delete</li>
                  <li className="fontSizeHeading">
                    Add owner group (owner group users can add other group as
                    member/owner of the data domain)
                  </li>
                  <li className="fontSizeHeading">Remove owner group</li>
                </ul>
                <h5 className="fontSizeLabel fontInterSemiBold"> User</h5>
                <ul className="mb-2">
                  <li className="fontSizeHeading">Create user or superuser</li>
                  <li className="fontSizeHeading">Delete user</li>
                  <li className="fontSizeHeading">View other user</li>
                  <li className="fontSizeHeading">View other super user</li>
                </ul>
                <h5 className="fontSizeLabel fontInterSemiBold">Group</h5>
                <ul className="mb-2">
                  <li className="fontSizeHeading">Create</li>
                  <li className="fontSizeHeading">Delete</li>
                  <li className="fontSizeHeading">
                    Add leader user(leader user can add other user as
                    member/leader of the group)
                  </li>
                  <li className="fontSizeHeading">Remove leader user</li>
                </ul>
                <h5 className="fontSizeLabel fontInterSemiBold">Entitlement</h5>
                <ul className="mb-2">
                  <li className="fontSizeHeading">Create</li>
                  <li className="fontSizeHeading">Update</li>
                  <li className="fontSizeHeading">Delete</li>
                </ul>
              </React.Fragment>
            ) : role == "GROUP LEADER" ? (
              <React.Fragment>
                <h4 className="fontSizeLargeHeading fontInterSemiBold mb-3">Group Leader Privileges </h4>
                <h5 className="fontSizeLabel fontInterSemiBold">Group</h5>
                <ul className="mb-2">
                  <li className="fontSizeHeading">
                    {" "}
                    Add or remove other user as member/leader of the group
                  </li>
                  <li className="fontSizeHeading">Update the description of the group</li>
                  <li className="fontSizeHeading">Update the name of the group</li>
                </ul>
              </React.Fragment>
            ) : role == "DOMAIN OWNER" ? (
              <React.Fragment>
                <h4 className="fontSizeLargeHeading fontInterSemiBold mb-3">DOMAIN OWNER Privileges </h4>
                <h5 className="fontSizeLabel fontInterSemiBold">Data Domain</h5>
                <ul className="mb-2">
                  <li className="fontSizeHeading">
                    Add or remove other group as member/owner of the data domain
                  </li>
                  <li className="fontSizeHeading">Update the description of the data domain</li>
                  <li className="fontSizeHeading">Assign access policy on the pods in the data domain</li>
                </ul>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export const UpdateDomainContent = (id, type, msg) => {
  return (
    <>
      <p className="font-w-600 pl-2">
        This action will update {type} permanently.
      </p>
      <p className="alert alert-warning mb-0">
        {msg}
        {type !== "alert" ? (
          <div className="mt-2">
            <a
              className="text-underline"
              href={`/viewLineage/${type == "Data Domain" || type == "data_domain" ? "Domain" : type
                }/${id}`}
              target="_blank"
            >
              click here
            </a>{" "}
            to check dependancy.
          </div>
        ) : null}
      </p>
    </>
  );
};

const type_azure = "azure_adls";
const type_s3 = "s3";

export const SliderSkeleton = () => {
  return (
    <Carousel dots={true} arrows={true}>
      <React.Fragment>
        <div
          className="search-land-prd-row"
          style={{ gridTemplateColumns: " minmax(31%, 50%) minmax(31%, 50%)" }}
        >
          {
            // demoDataProducts.map((el, i) => (
            [0, 0, 0, 0].map((el, i) => (
              <React.Fragment key={`demoDataProducts-${i}`}>
                <div className="search-land-prd-col">
                  <Skeleton.Input
                    active={true}
                    style={{ flexGrow: 1, height: "10vh" }}
                  />
                  {i % 2 === 0 ? (
                    <div className="align-items-center d-flex justify-content-center">
                      <span className="vert-line"></span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            ))
          }
        </div>
      </React.Fragment>
    </Carousel>
  );
};

export const SearchQueryError = () => {
  return (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <span>
        <img
          style={{
            height: "15vh",
            textAlign: "center",
          }}
          src={ROBOT}
        />
      </span>
      <p
        style={{
          maxWidth: "30vw",
          margin: "auto",
          color: "#2c83c4",
          marginTop: "20px",
          textTransform: "capitalize",
          fontWeight: "bold",
        }}
      >
        ohh oww!! I am still learning ,sorry no result found for this query
        please contact the admin
      </p>
    </div>
  );
};
export const QueryKillError = ({ topPercentage }) => {
  return (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        top: topPercentage ?? "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <span>
        <img
          style={{
            height: "15vh",
            textAlign: "center",
          }}
          src={ROBOT}
        />
      </span>
      <h3 style={{
        color: "rgba(51, 51, 51, 0.88)",
        fontSize: "31px",
        fontWeight: "bold",
        marginTop: "15px",

      }}>OOPS!</h3>
      <p
        style={{
          maxWidth: "30vw",
          margin: "auto",
          color: "#333333",
          marginTop: "15px",
          fontWeight: 600,
        }}
      >
        Sorry! It seems like process was cancelled.
      </p>
    </div>
  );
};

export const NoDataComponent = ({ logo, message }) => {
  return (
    <div
      className="text-center p-5"
      style={{ background: "transparent", height: "calc(100vh - 120px)" }}
    >
      <span>{logo}</span>
      <h2 className="h5 mt-2">{message}</h2>
    </div>
  );
};
export const ErrorComponent = ({ msg }) => {
  return (
    <div
      className=""
      style={{
        minWidth: "100px",
        maxWidth: "540px",
        padding: "1% 1%",
        margin: "auto",
        marginTop: "5%",
        border: "1px solid #AFAFAF",
        borderRadius: "10px",
      }}
    >
      <div
        className="d-flex align-items-center pb-1 mb-2"
        style={{ gap: "10px", borderBottom: "solid 1px #d4d4d4" }}
      >
        <div className="col-1 p-0">
          <i
            className="bx bx-error"
            style={{ fontSize: "40px", color: "#ff8888" }}
          ></i>
        </div>
        <div
          className="col-4 p-0"
          style={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}
        >
          <div style={{ fontSize: "12px", fontWeight: "bold" }}>
            <p style={{ color: "#df0000", margin: 0 }}>ERR_FAILED</p>
            <p style={{ margin: 0, color: "#474747" }}>
              Something went wrong !
            </p>
          </div>
        </div>
      </div>
      <pre
        className="bg-light border font-w-600 mb-0 py-1 rounded"
        style={{ paddingLeft: "8px", color: "#6c757d", fontSize: "10px", whiteSpace: 'normal' }}
      >
        {msg}
      </pre>
    </div>
  );
};

export const getPrefixFromKey = (sourceType, key, bucket, rootPfx) => {
  let newPfx;
  if (sourceType === type_s3) {
    let protocol = "s3a://";
    let stringToRemove = `${protocol}${bucket}`;
    stringToRemove += _.isEmpty(rootPfx) ? `/` : `/${rootPfx}/`;
    newPfx = key.replace(stringToRemove, "");
  } else if (sourceType === type_azure) {
    let stringToRemove = `abfss://${bucket}`;
    stringToRemove += _.isEmpty(rootPfx) ? `/` : `/${rootPfx}/`;
    newPfx = key.replace(stringToRemove, "");
  }
  return newPfx;
};

export const getLastFolderNameFromKey = (sourceType, key, bucket, rootPfx) => {
  let newPfx;
  if (sourceType === type_s3) {
    let protocol = "s3a://";
    let stringToRemove = `${protocol}${bucket}`;
    stringToRemove += _.isEmpty(rootPfx) ? `/` : `/${rootPfx}/`;
    newPfx = key.replace(stringToRemove, "");
    newPfx = newPfx.split("/");
    newPfx = newPfx[newPfx.length - 1];
  } else if (sourceType === type_azure) {
    let stringToRemove = `abfss://${bucket}`;
    stringToRemove += _.isEmpty(rootPfx) ? `/` : `/${rootPfx}/`;
    newPfx = key.replace(stringToRemove, "");
    newPfx = newPfx.split("/");
    newPfx = newPfx[newPfx.length - 1];
  }
  return newPfx;
};

export const generateKeyFromFolderName = (
  sourceType,
  folderName,
  bucketName,
  rootPrefix
) => {
  let newString;
  if (sourceType === type_s3) {
    newString = `s3a://${bucketName}/${rootPrefix}/${folderName}`;
    if (_.isEmpty(rootPrefix)) {
      newString = `s3a://${bucketName}/${folderName}`;
    }
  } else if (sourceType === type_azure) {
    newString = `abfss://${bucketName}/${rootPrefix}/${folderName}`;
    if (_.isEmpty(rootPrefix)) {
      newString = `abfss://${bucketName}/${folderName}`;
    }
  }
  return newString;
};

export const getFormattedDataConnectionFilter = (
  c_data = [],
  domainLabels = [],
  appliedFilters = []
) => {
  let data = [...c_data]
    .map((con) => {
      return con.data_domain.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.data_domain_id]: [...(acc[curr.data_domain_id] || []), con],
        }),
        {}
      );
    })
    .reduce((acc, item) => {
      let entry = Object.keys(item).reduce(
        (s, v) => ({ ...s, [v]: item[v] }),
        {}
      );
      return {
        ...acc,
        ...entry,
      };
    }, {});

  let Obj = {};
  let arr = [];
  Object.keys(data).forEach((id) => {
    domainLabels.forEach((obj) => {
      if (obj.data_domain_id === parseInt(id)) {
        Obj[obj.data_domain_name + "|" + id] = data[id];
      }
    });
  });
  Object.keys(data).forEach((id) => {
    domainLabels.forEach((obj) => {
      if (obj.data_domain_id === parseInt(id)) {
        Obj[obj.data_domain_name + "|" + id] = data[id];
      }
    });
    let newArr = [...arr, ...data[id]];
    newArr.forEach((id) => {
      if (!arr.includes(id)) {
        arr.push(id);
      }
    });
  });

  let dataDomain = [];
  let sources = [];
  if (appliedFilters.length > 0) {
    appliedFilters.forEach((val) => {
      if (val["Data Domain"]) {
        dataDomain = val["Data Domain"].map((val) => val.value) || [];
      }
      if (val["Sources"]) {
        sources = val["Sources"].map((val) => val.value) || [];
        sources = [...new Set(sources)];
      }
    });
  }

  let onlySourceList = Object.keys(data).map((id) =>
    data[id]
      .filter((v) => !v.isAssignedConnection)
      .map((obj) => obj.source_type)
  );

  onlySourceList = _.uniq(_.flatten(onlySourceList));

  const obj1 = {
    header: "Sources",
    selectAll: false,
    list: onlySourceList.map((val, key) => {
      return {
        id: key,
        checked: sources.includes(val),
        name: val,
        value: val,
      };
    }),
  };
  const obj2 = {
    header: "Data Domain",
    selectAll: false,
    list: Object.keys(Obj).map((val, key) => {
      return {
        id: key,
        checked: dataDomain.includes(val.split("|")[1]),
        name: val.split("|")[0],
        value: val.split("|")[1],
      };
    }),
  };

  return [obj1, obj2];
};

export const schemaTableOptionalFieldHelpContent = () => {
  return (
    <>
      <div className="p-2">
        <ul className="small">
          <li>
            -{" "}
            <span className="custom-badge mb-1 px-1 py-1">
              <pre className="mb-0">varchar/char/decimal</pre>
            </span>{" "}
            int value
          </li>
          <li>
            -{" "}
            <span className="custom-badge mb-1 px-1 py-1">
              <pre className="mb-0">real</pre>
            </span>{" "}
            is a 32-bit inexact
          </li>
          <li>
            -{" "}
            <span className="custom-badge mb-1 px-1 py-1">
              <pre className="mb-0">double</pre>
            </span>{" "}
            is a 64-bit inexact
          </li>
          <li>
            -{" "}
            <span className="custom-badge mb-1 px-1 py-1">
              <pre className="mb-0">timestamp</pre>
            </span>{" "}
            : int or blank
          </li>
          <li>
            -{" "}
            <span className="custom-badge mb-1 px-1 py-1">
              <pre className="mb-0">date</pre>{" "}
            </span>
            : please refer{" "}
            {/* <a
              href="https://database.guide/list-of-date-format-specifiers-in-mysql/"
              target="_blank"
            > */}

            <a className='ml-2' onClick={() => window.open(helpfile, "_blank")} >
              guide
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export const removeStartingUnderscores = (str) => {
  if (_.isEmpty(str)) return str;
  else if (str.charAt(0) === "_")
    return removeStartingUnderscores(str.substring(1));
  else return str;
};

export const removeStartEndUnderscores = (str) => {
  if (_.isEmpty(str)) return str;
  return str.replace(/^_+|_+$/g, '')
};

export const getFieldTypeIcon = (type) => {
  switch (type) {
    case "DROPDOWN":
      return <i className="d-inline-flex align-items-center bx bx-list-ul"></i>;
    case "TOGGLE":
      return (
        <i className="d-inline-flex align-items-center bx bx-toggle-left"></i>
      );
    case "TEXT":
      return <ICON_TXT_FILE width="11" height="11" />;
    // return <i className="d-inline-flex align-items-center bx bx-text"></i>;
    case "DATE":
      return (
        // <ICON_CALENDAR width="12" height="12" />
        <i className="d-inline-flex align-items-center bx bx-calendar"></i>
      );
    case "NUMERIC":
      return <i className="d-inline-flex align-items-center bx bx-hash"></i>;
    default:
      return <i className="d-inline-flex align-items-center bx bx-text"></i>;
  }
};

export const correctBusinessTerm = (term) => {
  if (term && _.isEmpty(term.trim())) return "";
  term = term.replaceAll(columnNameRegexExp, "_");
  // term = term.split('_').map(txt => txt.charAt(0).toUpperCase() + txt.substr(1) ?? txt).join('_')
  return removeStartingUnderscores(term);
  // return toTitleCase(term)
  // return capitalizeFirstChar(term)
};

export const correctVarcharTerm = (term) => {
  // term=term.replaceAll(specialCharacterRegexExp,'');
  return term;
};
export const correctIntegerTerm = (term) => {
  term = term.replaceAll(integerRegexExp, "");
  return term;
};
export const correctTechincalTerm = (term) => {
  const regex = new RegExp(technicaTermRegexExp);

  return regex.test(term);
};
export const correctFloatTerm = (term) => {
  term = term.replaceAll(floatRegexExp, "");
  return term;
};

const msalConfig = {
  auth: {
    clientId: window._env_.REACT_APP_MICROSOFT_AUTH_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${window._env_.REACT_APP_MICROSOFT_AUTH_TENANT_ID}`,
    redirectUri: window._env_.REACT_APP_MICROSOFT_AUTH_REDIRECT_URI, // Replace with your backend API's redirect URL
    postLogoutRedirectUri: window._env_.REACT_APP_MICROSOFT_AUTH_REDIRECT_URI,
  },
};
export const msalInstance = new msal.PublicClientApplication(msalConfig);

export const generateHtmlTitle = (name, module) => {
  let title = "Clarista";
  if (module) {
    title = `${module} | ${title}`;
  }
  if (name) {
    title = `${name} | ${title}`;
  }
  return title;
};

export const permissions = {
  flows: {

    "editor": "editor_datadomain",
    "viewer": "viewer_datadomain",
    "add": "add_flow",
    "edit": "change_flow",
    "view": "view_flow",
    "delete": "delete_flow",
    "execute": "execute_flow",
    "jupyter_server": "jupyter_server",
  },
  dataCatalog: {
    "editor": "editor_datadomain",
    "viewer": "viewer_datadomain",
    "add": "add_poddetail",
    "edit": "change_poddetail",
    "view": "view_poddetail",
    "delete": "delete_poddetail",
    "query": "query_poddetail",
    "kill": "kill_poddetail",
    "download": "download_poddetail",
  },
  alerts: {
    "editor": "editor_datadomain",
    "viewer": "viewer_datadomain",
    "add": "add_rule",
    "edit": "change_rule",
    "view": "view_rule",
    "delete": "delete_rule",
  },
  scheduler: {
    "editor": "editor_datadomain",
    "viewer": "viewer_datadomain",
    "add": "add_scheduler",
    "edit": "change_scheduler",
    "view": "view_scheduler",
    "delete": "delete_scheduler",
  },
  dataDictionary: {
    "editor": "editor_datadomain",
    "viewer": "viewer_datadomain",
  },
  dashboard: {
    "editor": "editor_datadomain",
    "viewer": "viewer_datadomain",
  },
  domain: {
    "editor": "editor_datadomain",
    "viewer": "viewer_datadomain",
    "owner": "owner_datadomain",
  },
  policy: {
    "editor": "editor_datadomain",
    "viewer": "viewer_datadomain",
    "owner": "owner_datadomain",
  },
  usergroup: {
    "editor": "editor_datadomain",
    "leader": "leader_user"
  }
}

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

export function numberAbbr(n) {
  return new Intl.NumberFormat("en-GB", {
    notation: "compact",
    compactDisplay: "short",
  }).format(n);
}

export const progressBarFilterTypes = {
  health: "healthy",
  anomaly: "unhealthy",
  missing: "missing",
};

export const exceptionFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const getRelativeTime = (date) => {
  // Parse the date in UTC and calculate the relative time
  let text = moment.utc(date, "YYYY-MM-DDTHH:mm:ssZ").fromNow();  //remove .utc
  // console.log(moment('2024-12-17 07:29:13.554385', "YYYY-MM-DDTHH:mm:ssZ").fromNow(), moment.utc('2024-12-17 07:29:13.554385', "YYYY-MM-DDTHH:mm:ssZ").fromNow(),date)

  // Normalize specific phrases
  if (text?.toLowerCase() === "a month ago") {
    text = "1 month ago";
  }
  if (text?.toLowerCase() === "a day ago") {
    text = "1 day ago";
  }
  if (text?.toLowerCase() === "a minute ago") {
    text = "1 minute ago";
  }
  if (text?.toLowerCase() === "a second ago") {
    text = "1 second ago";
  }

  return text;
};
// export const getRelativeTime = (date) => {
//   let text = moment.utc(date, "YYYY-MM-DDTHH:mm:ssZ").fromNow();
//   if (text?.toLowerCase() === "a month ago") {
//     text = "1 month ago";
//   }
//   if (text?.toLowerCase() === "a day ago") {
//     text = "1 day ago";
//   }
//   if (text?.toLowerCase() === "a minute ago") {
//     text = "1 minute ago";
//   }
//   if (text?.toLowerCase() === "a second ago") {
//     text = "1 second ago";
//   }
//   return text;
// };

export const getAlertPriorityIcon = (type = "low", extraClass = "") => {
  return (
    <div
      className={`alert-priority-icon ${type?.toLocaleLowerCase()} ${extraClass}`}
    >
      {type?.charAt(0)?.toUpperCase()}
    </div>
  );
};

export const downloadTableDataToCSV = (csvString, tableName = "untitled") => {
  const d = new Blob([csvString], { type: "text/csv" });
  const downloadLink = window.URL.createObjectURL(d);
  const a = document.createElement("a");
  a.download = tableName;
  a.href = downloadLink;
  a.click();
  a.remove();
};

export const unicodeList = [...unicodes]

const getType = (val, from = "ListView") => {
  let value = val.toLowerCase();
  switch (value) {
    case "sql":
      return (
        <SQLBLUE
          width={from && from.toLowerCase() === "listview" ? "20" : "35"}
          height={from && from.toLowerCase() === "listview" ? "20" : "35"}
        />
      );
    case "filter":
      return (
        <FILTERBLUE
          width={from && from.toLowerCase() === "listview" ? "20" : "35"}
          height={from && from.toLowerCase() === "listview" ? "20" : "35"}
        />
      );
    case "logical":
      return (
        <LOGICALICON
          width={from && from.toLowerCase() === "listview" ? "20" : "35"}
          height={from && from.toLowerCase() === "listview" ? "20" : "35"}
        />
      );
    case "flow":
      return (
        <span className="">
          {/* <span className="primary-svg-color"> */}
          {from.toLowerCase() === "listview" ? (
            <FLOWS
              width={from && from.toLowerCase() === "listview" ? "20" : "35"}
              height={from && from.toLowerCase() === "listview" ? "20" : "35"}
              color="#2C83C4"
            />
          ) : (
            <FLOWICON width="20" color="#2C83C4" />
          )}
        </span>
      );
    default:
      return (
        <SQLBLUE
          width={from && from.toLowerCase() === "listview" ? "24" : "35"}
          height={from && from.toLowerCase() === "listview" ? "24" : "35"}
        />
      );
  }
};

export const getLandingPageDataModuleWise = (moduleName, data, searchValue) => {
  switch (moduleName) {
    case "alert":
      let list = [...data];

      let temp = list?.map((item) => {
        let domainName = _.uniq(
          item?.data_domain?.map((v) => v.data_domain_name)
        );
        return {
          key: item.id,
          data: {
            ...item,
            domain_name_for_sort:
              domainName && domainName.length ? domainName?.join(",") : "",
          },
          name: (
            <div className="d-flex align-items-center pl-2 alert-prioricon-in-list">
              <Tooltip placement="top" title={item?.purpose + " Alert"}>
                <span
                  className={`noti-alert-prps-ic ${item?.purpose.toLowerCase() === "quality"
                    ? "quality"
                    : "business"
                    }`}
                >
                  <ALERT />
                </span>
              </Tooltip>
              {/* {getType(item?.type, "listview")} */}
              <Tooltip
                className="ml-4 text-with-ellipsis font-w-600 text-uppercase"
                placement="top"
                title={item?.name}
              >
                <Highlighter
                  searchWords={[searchValue?.toLocaleLowerCase()]}
                  autoEscape={true}
                  textToHighlight={item?.name}
                >
                  {" "}
                  <span className="single-line-ellipsis">{item?.name}</span>
                </Highlighter>
              </Tooltip>
            </div>
          ),
          type: (
            <div className="d-flex align-items-center pl-2 alert-varicon-in-list h-100">
              <Tooltip placement="top" title={toTitleCase(item?.type ?? "")}>
                <span>{getType(item?.type, "listview")}</span>
              </Tooltip>
            </div>
          ),
          description: item.description ? (
            <Tooltip placement="top" title={item?.description}>
              <Highlighter
                searchWords={[searchValue?.toLocaleLowerCase()]}
                autoEscape={true}
                textToHighlight={item?.description}
              >
                {" "}
                <span className="single-line-ellipsis">
                  {item?.description}
                </span>
              </Highlighter>
            </Tooltip>
          ) : (
            <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis">
              <span style={{ marginLeft: "0px" }}>
                No description has been given yet.
              </span>
            </label>
          ),
          domain_name: (
            <Tooltip
              placement="top"
              title={domainName?.join(",")?.toUpperCase()}
              className="text-capitalize"
            >
              <Highlighter
                searchWords={[searchValue?.toLocaleLowerCase()]}
                autoEscape={true}
                textToHighlight={
                  domainName && domainName.length
                    ? domainName?.join(", ")?.toUpperCase()
                    : ""
                }
              >
                {/* {" "}
              <span className="single-line-ellipsis">
                {domainName && domainName.length ? domainName?.join(", ") : ""}
              </span> */}
              </Highlighter>
            </Tooltip>
          ),
          priority: (
            <div className="w-auto h-100 d-flex align-items-center">
              <Tooltip placement="top" title={`${item?.priority}`}>
                {getAlertPriorityIcon(item?.priority?.toLowerCase())}
              </Tooltip>
            </div>
          ),
          // priority: <div className="text-with-ellipsis">{item?.priority}</div>,
          purpose: (
            <span
              className={
                item?.purpose?.toLowerCase() === "quality"
                  ? "pl-0 quality"
                  : "pl-0 business"
              }
            >
              <Highlighter
                searchWords={[searchValue?.toLocaleLowerCase()]}
                autoEscape={true}
                textToHighlight={
                  item?.purpose ? titleCase(item?.purpose) + " Alert" : ""
                }
              >
                {" "}
              </Highlighter>{" "}
            </span>
          ),
          created_on: (
            <>
              <Tooltip
                placement="top"
                title={getRelativeTime(new Date(item?.created_on))}
              >
                {getRelativeTime(item?.created_on)}
              </Tooltip>
            </>
          ),
        };
      });
      console.log(temp);
      return temp;
      break;

    default:
      break;
  }
};

export const getLandingPageColumn = (moduleName) => {
  switch (moduleName) {
    case "alert":
      return [
        {
          name: <span style={{ paddingLeft: "48px" }}>Alert Name</span>,
          key: "name",
          sortable: true,
          resizable: true,
          // width: "20%"
        },
        {
          name: "Type",
          key: "type",
          sortable: false,
          resizable: false,
        },
        {
          name: "Description",
          key: "description",
          sortable: true,
          resizable: true,
          // width: "30%"
        },
        {
          name: "Domain Name",
          key: "domain_name",
          sortable: true,
          resizable: false,
        },
        {
          name: <div className="text-center">Priority</div>,
          key: "priority",
          sortable: true,
          resizable: false,
        },

        {
          name: "Purpose",
          key: "purpose",
          sortable: true,
          resizable: false,
        },
        {
          name: "Created On",
          key: "created_on",
          sortable: true,
          resizable: false,
        },
      ];

      break;

    default:
      break;
  }
};

export const getTableSkeleton = (rows = 15) => (
  <div>
    {[...Array(rows)].map((v, i) => (
      <div key={`col-skeleton-row-${v}${i}`} className={`row mb-2 mx-n2`}>
        {[...Array(12)].fill("1").map((col, i) => (
          <div key={`col-skeleton-${i}`} className={`col-${col} px-2`}>
            <CustomSkeleton height="25px" width="100%" isActive={false} />
          </div>
        ))}
      </div>
    ))}
  </div>
);

export const getConfirmationModal = ({
  open = false,
  setOpen = () => { },
  onConfirm = () => { },
  onCancel = () => { },
  confirmationText = "Yes",
  cancelText = "No",
  heading = "Confirm",
  hideHeading = false,
  body = "Are you sure, want to continue?",
  hideButton = false
}) => {
  return (
    <CommonModal
      open={open}
      setOpen={setOpen}
      onClose={onCancel}
      className="confirm-popup"
      header={
        <>
          {!hideHeading ? <span className="mr-2">
            {hideButton ? <ICON_INFO color="#2c83c4" height="20" width="20" /> : <ICON_CONFIRM color="#2c83c4" height="20" width="20" />}
          </span> : null}
          <span>{heading}</span>
        </>
      }
      body={<div className="fontSizeHeading" style={{ whiteSpace: 'pre-wrap' }}>{body}</div>}  //font-weight-bold4

      footer={
        hideButton ? <></> : <>
          <div className="d-block w-100 py-1">
            <Buttons
              props={{
                buttonId: "primary-focus-btn",
                buttonText: confirmationText,
                // onEnterCall: () => {
                //   onConfirm();
                //   setOpen(false);
                //   // alert('here')
                // },
                buttonClassName:
                  "w-100 custom-btn-primary custom-btn btn-with-text",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  onConfirm();
                  setOpen(false);
                },
                ImgSrc: () => <></>,
                isDisable: false,
                // actionType: "submit",
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
            <Buttons
              props={{
                buttonText: cancelText,
                buttonClassName:
                  "w-100 custom-btn-outline border custom-btn btn-with-text mt-2",
                buttonEvent: () => {
                  onCancel();
                  setOpen(false);
                },
                ImgSrc: () => <></>,
                isDisable: false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          </div>
        </>
      }
    />
  );
};

export const getDeleteMessage = ({ highlighted = undefined, title = "" }) => {
  return (
    <>
      Are you sure you want to delete{" "}
      {highlighted ? <strong>{highlighted}</strong> : "this"}
      {title !== "" ? ` ${title}?` : "?"}
    </>
  );
};


export const getReloadErrorTemplate = ({ errorMessage = 'Something went wrong', hideReloadBtn = false, onReload = () => { }, wrapClass = '' }) => {
  return <div className={`text-center d-flex flex-column align-items-center my-5 reloadble-err-comp ${wrapClass}`}>
    <div className='mb-2 text-center'>
      <ICON_DEAD height='60' width='60' color='#8e8e8e' />
    </div>
    <code className='alert alert-danger text-center mx-auto w-50 fontSizeLabel p-1' style={{ maxWidth: '31.25rem', whiteSpace: 'normal' }}>
      {errorMessage}
    </code>
    {!hideReloadBtn ? <Buttons
      props={{
        tooltip: "",
        buttonText: "Reload",
        buttonClassName: "custom-btn-outline custom-btn btn-with-text border",
        buttonEvent: () => {
          onReload()
        },
        ImgSrc: () => <ICON_REFRESH />,
        isDisable: false,
        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
      }}
    /> : null}
  </div>
}

export const ErrorModal = ({ openErrorModal, setopenErrorModal, errorDetails }) => {

  return <Modal size="lg" isOpen={openErrorModal} className="custom-modal" centered={true} onClosed={() => setopenErrorModal(false)}>
    <ModalHeader close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setopenErrorModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className='d-flex'>

          <h2 className="section-heading">Error</h2>
        </div>
        <div className='mb-n1 mr-3 small'>
          <label className='label d-block mb-0 small text-right' style={{ lineHeight: 0 }}>Status: Failed</label>
          <label className='label mb-0 small text-black-50 d-block text-right'>{JSON?.parse(localStorage.getItem('user'))?.userDetails?.email}</label>
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div className='usage-drawer'>
        <div>
          <div className='py-2'>
            <div className='row mx-n2'>


              <div className='col-12 h-100'>

                <div>
                  <p className="paragraph">
                    {errorDetails?.message}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
    </ModalFooter>
  </Modal>
}
export const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}



export const getSimpleTutorialMessageTemplate = (title = '', content = '') => {
  return <>
    <h4 className='fontSizeHeading font-w-600 color-primary'>{title}</h4>
    <p className='text-dark mb-0 paragraph'>
      {content}
    </p>
  </>
}

export const showOneLineError = (error = 'Something went wrong, try again after sometime') => {
  return <div className="one-line-error-comp">
    <ICON_ERROR width="18" height='18' color='#dc3545' />
    <span className='ml-1 paragraph text-danger'>{error}</span>
  </div>
}

export const highlightText = (msg = '') => {
  return <span className="notice-highlighted-text">{msg}</span>
}

export const businessTermStatusOptions = [
  // { Name: 'Pending', value: 'pending', color: '#f7b40a' },
  { Name: 'Active', value: 'active', color: '#18D607' },
  { Name: 'Archived', value: 'archived', color: '#828282' },
]

export const showVerticalBarLoader = () => {
  return <div class="vert-bars-loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
}

export const stringToColor = (string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360; // Full range of hues (0-360)
  const saturation = 100 + (hash % 60); // Saturation between 40% and 100%
  const lightness = 30 + (hash % 25); // Lightness between 50% and 75%

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  /**
   * For Dark Colors Use Below Code 
   * */
  // let hash = 0;
  // for (let i = 0; i < string.length; i++) {
  //     hash = string.charCodeAt(i) + ((hash << 5) - hash);
  // }

  // let color = '#';
  // for (let i = 0; i < 3; i++) {
  //     const value = (hash >> (i * 8)) & 0xFF;
  //     color += ('00' + value.toString(16)).substr(-2);
  // }
  // return color;
};

export const handleCriteria = (crit, value) => {
  if (crit === 'date=' && value?.startsWith('@')) {
    return 'is'
  }
  return criteriaMap[crit] ?? crit
}

export const criteriaMap = {
  'date=': "=",
  'date<': "<",
  'date>': ">",
  '==': "=",
  "s%": "like",
  "%s": "like"
}

export let onlyDateRegex = /\b(?:date)\b/gi;
export let onlytimeRegex = /\b(?:time)\b/gi;
export let onlyDatetimeRegex = /\b(?:timestamp|datetime)\b/gi;

export const handleCriteriaValue = (crit, val) => {
  if (val) {
    if (crit === "s%") return `${val}%`
    if (crit === "%s") return `%${val}`
    if (crit === "like" || crit === "not like") return `%${val}%`
    return val
  } else {
    return ""
  }

}

export const NonPII = () => {
  const style = {
    'background': '#eef8ff',
    'display': 'flex',
    'alignItems': 'center',
    'padding': '0.3125rem 0.625rem 0.3125rem 0.5rem',
    'borderRadius': '1.375rem',
    'fontSize': '0.75rem',
    'color': '#333333',
    'lineHeight': '1.4'
  }

  return <div style={style} className='fontInterSemiBold non-pii-icon'>
    <ICON_INFO_FILLED height='14' width='14' color='#2c83c4' />
    <span className='pl-1'></span>
    Non PII
  </div>
}

export const eChartsThemeObject = {
  color: [
    "#082B59",
    "#1B78F2",
    "#8DB9F2",
    "#5CCBD8",
    "#0C6D8B",
    "#496C94",
    "#9EDAFF",
    "#77C5D6",
    "#256fa7",
    "#2c83c4"
  ]
}

export const remToPx = rem => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export const pxToRem = px => Number(parseFloat(px / 16).toPrecision(3));