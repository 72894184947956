import React, { useEffect, useState } from 'react'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import { ICON_DASHBOARD, ICON_FLOWS } from '../../../Common/newIconSource'
import { CLOCK, FLOWS, PLUS, REDIRECTJOBS, UPDATED_BY, USER } from '../../../Common/iconSource'
import FlowSelectionModal from '../../alert/createAlert/FlowSelectionModal'
import { useSelector, useStore } from 'react-redux'
import { SetSchedulerPayload } from '../../../../store/modules/scheduler/action'
import { cloneDeep } from 'lodash'
import { Input, Radio, Space, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getObjectLength, getRelativeTime } from '../../common/helperFunctions'
import DashboardSelectionModal from '../../alert/createAlert/DashboardSelectionModal'
import { getDashboardDetails } from '../../../../store/modules/analytics/dashboard/dashboardActions'
import DefaultFlow from "../../../../assets/images/emptyFlow.png";
import { getFlowsDetails } from '../../../../store/modules/flows/landingPage/landingPageAction'


function ScheduleModelSelection({ isExecutePermission, setshowParameterBtn, setrefreshUserList }) {
    const toggleBtnOption = ['flow', 'dashboard'];

    // let toggleBtnOption = [{ label: <div className='d-flex align-items-center justify-content-center'><div className='col-1 p-0'><CLOCK /></div><div className='col-1 p-0'></div>Flow</div>, value: 'FLOW', disabled: !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view') ? true : false }, { label: <div className='d-flex align-items-center justify-content-center'><div className='col-1 p-0'><CLOCK /></div><div className='col-1 p-0'></div>Dashboard</div>, value: 'DASHBOARD', disabled: !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view') ? true : false },]
    const [toggleValue, settoggleValue] = useState('FLOW')

    const flowsAlertTransformerList = useSelector(state => state.Alert.CreateAlert?.flowsAlertTransformerList)
    const selectedScheduledDashboard = useSelector(state => state.Scheduler?.selectedScheduledDashboard)

    const [openFlowSelectionModal, setopenFlowSelectionModal] = useState(false)
    const [openDashboardSelectionModal, setopenDashboardSelectionModal] = useState(false)
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)
    const [selectedTypeName, setselectedTypeName] = useState('');

    const [flowTilesDetails, setflowTilesDetails] = useState(null);
    const [dashboardTilesDetails, setdashboardTilesDetails] = useState(null);

    const navigate = useNavigate()

    const store = useStore();



    useEffect(() => {
        const newPayload = cloneDeep(schedulerPayload);

        if (newPayload['task_type']?.toLowerCase() === 'flow') {
            if (getObjectLength(flowsAlertTransformerList)) {
                getFlowDetails(flowsAlertTransformerList?.selectedFlowDetails?.id)
                setselectedTypeName(flowsAlertTransformerList?.selectedFlowDetails?.name)
                newPayload['flow'] = flowsAlertTransformerList?.selectedFlowDetails?.id;
            } else {
                setselectedTypeName('')
                newPayload['flow'] = null;
            }
        }
        else if (newPayload['task_type']?.toLowerCase() === 'dashboard') {
            if (getObjectLength(selectedScheduledDashboard)) {
                getDashboard(selectedScheduledDashboard?.selectedDashboardDetails?.id)

                setselectedTypeName(selectedScheduledDashboard?.selectedDashboardDetails?.name)
                newPayload['dashboard'] = selectedScheduledDashboard?.selectedDashboardDetails?.id;

            } else {
                setselectedTypeName('')
                newPayload['dashboard'] = null;

            }
        }


        store.dispatch(SetSchedulerPayload(newPayload))

    }, [flowsAlertTransformerList, selectedScheduledDashboard]);

    const getFlowDetails = (flowId) => {
        store.dispatch(getFlowsDetails(flowId)).then((resp) => {
            if (resp?.data) {
                setflowTilesDetails(resp?.data)
            }
        })
    }

    const getDashboard = (dashboard_id) => {
        store.dispatch(getDashboardDetails(dashboard_id)).then((resp) => {
            if (resp?.data) {
                setdashboardTilesDetails(resp?.data)
            }
        })
    }
    useEffect(() => {
        settoggleValue(schedulerPayload?.task_type)
        if (schedulerPayload?.task_type.toLowerCase() === 'dashboard') {
            setshowParameterBtn(false)
        }
    }, [schedulerPayload])


    const onToggleChange = (value) => {
        // settoggleValue(value);
        const newPayload = cloneDeep(schedulerPayload);
        newPayload['task_type'] = value;

        store.dispatch(SetSchedulerPayload(newPayload));

        if (value.toLowerCase() === 'flow') {
            if (getObjectLength(flowsAlertTransformerList)) {
                setselectedTypeName(flowsAlertTransformerList?.selectedFlowDetails?.name)

            } else {
                setselectedTypeName('')

            }
        }
        else if (value.toLowerCase() === 'dashboard') {
            setshowParameterBtn(false)
            if (getObjectLength(selectedScheduledDashboard)) {
                setselectedTypeName(selectedScheduledDashboard?.selectedDashboardDetails?.name)  // dashboard scheduler integration done
            } else {
                setselectedTypeName('')


            }
        }
    }




    return (
        <>

            <div className="d-flex flex-column align-items-center">
                <div className='col-12 p-0 m-0 pb-2'>
                    <span className='mt-0 mr-2 label'>Schedule : </span>
                    {/* <div className="toggle-btn-with-text Scheduler-Trigger-Section w-100 m-0">
                        <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: toggleValue, buttonClassName: "", buttonEvent: (value) => { onToggleChange(value);setrefreshUserList(value) }, ImgSrc: () => <></>, isDisable: !isExecutePermission, buttonType: Literals.BTN_TOGGLE }} />
                    </div> */}

                    <Radio.Group style={{ textTransform: "capitalize" }} disabled={!isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view')} options={toggleBtnOption} onChange={(e) => { onToggleChange(e.target.value?.toUpperCase()); setrefreshUserList(e.target.value) }} value={toggleValue?.toLowerCase()} />

                </div>


                {/* <div className={'col-12 pl-1 m-0'} >

                    <label style={{ textTransform: "capitalize" }} className='mt-0 label'>Selected {toggleValue?.toLowerCase()}</label>


                    <div className="d-flex align-items-center p-0 m-0"  >

                        <div className='select-model-input col-8 m-0 '>{selectedTypeName ? <div className='d-flex align-items-center w-100 justify-content-between'>
                            <span>{selectedTypeName}</span>
                            <span className='cursor-pointer' onClick={(e) => {
                                if (selectedTypeName?.length && toggleValue?.toLowerCase() === 'flow') { window.open(`${Literals.links.FLOWS}/${flowsAlertTransformerList?.selectedFlowDetails?.id}`, '_blank') } else {
                                    window.open(`${Literals.links.DASHBOARD}/${selectedScheduledDashboard?.selectedDashboardDetails?.id}`, '_blank')
                                }
                            }}><REDIRECTJOBS /></span></div>
                            : <div onClick={(e) => {

                                !selectedTypeName?.length && toggleValue?.toLowerCase() === 'flow' ? setopenFlowSelectionModal(true) : setopenDashboardSelectionModal(true)

                            }} className='cursor-pointer'>
                                <span>{toggleValue?.toLowerCase() === 'flow' ? 'Click here to add flow' : 'Click here to add dashboard'} </span>
                            </div>
                        }
                        </div>
                        <div className={`col-4 w-100 m-0 p-0`}>
                            <Buttons
                                props={{
                                    buttonText: toggleValue?.toLowerCase() === 'flow' ? 'Select Flow' : "Select Dashboard",
                                    buttonClassName: "custom-btn-primary select-model-btn custom-btn btn-with-text w-100",
                                    buttonEvent: (e) => {
                                        toggleValue?.toLowerCase() === 'flow' ? setopenFlowSelectionModal(true) : setopenDashboardSelectionModal(true)

                                        e.stopPropagation();

                                    },
                                    ImgSrc: () => <PLUS />,
                                    isDisable: !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view'),
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        </div>
                    </div>

                </div> */}

                {!selectedTypeName && toggleValue?.toLowerCase() === 'flow' ? <div className='tile-view-default cursor-pointer mt-1' onClick={(e) => {
                    if (isExecutePermission && !window?.location?.href?.toLowerCase().includes('scheduler/view')) {

                        setopenFlowSelectionModal(true)
                    }

                }}>
                    <div>  <ICON_FLOWS color='#2c83c4' height='40' width='40'></ICON_FLOWS></div>
                    <div className='mt-2' style={{ color: '#646060', fontSize: "14px" }}> Click to select flow</div>
                    <div className='mt-2' style={{ color: '#a3a3a3', fontSize: "12px" }}>Select a flow to create scheduler.</div>
                </div> : !selectedTypeName && toggleValue?.toLowerCase() === 'dashboard' ? <div className='tile-view-default cursor-pointer mt-1' onClick={(e) => {
                    if (isExecutePermission && !window?.location?.href?.toLowerCase().includes('scheduler/view')) {

                        setopenDashboardSelectionModal(true)
                    }
                }}>
                    <div>  <ICON_DASHBOARD color='#2c83c4' height='40' width='40'></ICON_DASHBOARD></div>
                    <div className='mt-2' style={{ color: '#646060', fontSize: "14px" }}> Click to select dashboard</div>
                    <div className='mt-2' style={{ color: '#a3a3a3', fontSize: "12px" }}>Select a dashboard to create scheduler.</div>
                </div> : null}
                {selectedTypeName?.length && toggleValue?.toLowerCase() === 'flow' && flowTilesDetails ?
                    <div className='flow-tile-view cursor-pointer position-relative' onClick={(e) => {

                        selectedTypeName?.length && toggleValue?.toLowerCase() === 'flow' ? setopenFlowSelectionModal(true) : setopenDashboardSelectionModal(true)

                    }}>
                        <span className='cursor-pointer position-absolute redirect-ic' style={{ top: '5px', right: "5px" }} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (selectedTypeName?.length && toggleValue?.toLowerCase() === 'flow') { window.open(`${Literals.links.FLOWS}/${flowsAlertTransformerList?.selectedFlowDetails?.id}`, '_blank') } else {
                                window.open(`${Literals.links.DASHBOARD}/${selectedScheduledDashboard?.selectedDashboardDetails?.id}`, '_blank')
                            }
                        }}><REDIRECTJOBS /></span>
                        <div className='flow-image'>
                            {flowTilesDetails?.thumbnail ? <img
                                alt="example"
                                src={flowTilesDetails?.thumbnail}
                            /> : <div className="empty-flow pt-3">
                                <img
                                    alt="example"
                                    src={DefaultFlow}
                                    height={30}
                                    width={30}
                                />
                                <span className="text-black-50">Empty Flow</span>
                            </div>}
                        </div>
                        <div className="d-flex flex-column justify-content-left w-100 mt-2 p-2 flow-details-container">
                            <h2 className="section-heading block-custom-ellipsis text-uppercase one-line-ellipsis">

                                {flowTilesDetails?.name}

                            </h2>

                            <div className="description-data mt-2">


                                <p className="fl-desc small custom-ellipsis two-line-ellipsis">{

                                    flowTilesDetails.description !== ""
                                        ? `${flowTilesDetails?.description?.slice(0, 300)}...`
                                        : "No description added for this flow."
                                }</p>
                                {/* <div className="flow-meta-det">
                                        <ul className="landingCard-profile-list">
                                            <li className="landingCard-profile-listitem d-flex align-items-center justify-content-between">
                                                <div style={{ flexBasis: 'calc(100% - 60px)' }}>
                                                    <UPDATED_BY width="15 " height="15" />
                                                    <span className="label label-gray ml-2">{'Last Updated'} </span>
                                                    <Tooltip placement="topLeft" title={getRelativeTime(new Date(flowTilesDetails?.updated_on), undefined)}>
                                                        <span className="paragraph ml-1 ant-tooltip-open">

                                                            {getRelativeTime(flowTilesDetails?.updated_on)}


                                                        </span>
                                                    </Tooltip>
                                                </div>

                                            </li>
                                            <li className="landingCard-profile-listitem mt-1">
                                                <div className="align-items-end d-flex w-100" style={{ marginLeft: '-3px' }}>
                                                    <USER width="22" height="15" />
                                                    <span className="label label-gray" style={{ flexBasis: 80, marginLeft: "2px" }}>{'Updated By'} </span>
                                                    <Tooltip placement="topLeft" title={flowTilesDetails?.updated_by_user?.email}>
                                                        <span className="paragraph d-block text-with-ellipsis ant-tooltip-open label-color">


                                                            {flowTilesDetails?.updated_by_user?.email}

                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </li>
                                        </ul>

                                    </div> */}
                            </div>
                        </div>

                    </div> :
                    selectedTypeName?.length && toggleValue?.toLowerCase() === 'dashboard' && dashboardTilesDetails ? <div className='dashboard-tile-view cursor-pointer position-relative' onClick={(e) => { setopenDashboardSelectionModal(true) }}>
                        <span className='cursor-pointer position-absolute' style={{ top: '5px', right: "5px" }} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (selectedTypeName?.length && toggleValue?.toLowerCase() === 'flow') { window.open(`${Literals.links.FLOWS}/${flowsAlertTransformerList?.selectedFlowDetails?.id}`, '_blank') } else {
                                window.open(`${Literals.links.DASHBOARD}/${selectedScheduledDashboard?.selectedDashboardDetails?.id}`, '_blank')
                            }
                        }}><REDIRECTJOBS /></span>
                        {/* <div className='dashboard-image text-center'>

                                <div>  <ICON_DASHBOARD color='#2c83c4' height='40' width='40'></ICON_DASHBOARD></div>
                            </div> */}
                        <div className="d-flex flex-column justify-content-left w-100 mt-2 p-2 flow-details-container">
                            <h2 className="section-heading block-custom-ellipsis text-uppercase one-line-ellipsis">
                                <span className='mr-2'><ICON_DASHBOARD color='#2c83c4' height='20' width='20'></ICON_DASHBOARD></span>
                                {dashboardTilesDetails?.name}

                            </h2>

                            <div className="description-data mt-2">


                                <p className="fl-desc small custom-ellipsis two-line-ellipsis">{

                                    dashboardTilesDetails?.description && dashboardTilesDetails.description !== ""
                                        ? `${dashboardTilesDetails?.description?.slice(0, 300)}...`
                                        : "No description added for this dashboard."
                                }</p>
                                <div className="flow-meta-det scheduler-mod-meta">
                                    <ul className="landingCard-profile-list">
                                        <li className="landingCard-profile-listitem d-flex align-items-center justify-content-between">
                                            <div style={{ flexBasis: 'calc(100% - 3.75rem)' }}>
                                                <UPDATED_BY width="15 " height="15" />
                                                <span className="label label-gray ml-2">{'Last Updated'} </span>
                                                <Tooltip placement="topLeft" title={getRelativeTime(new Date(dashboardTilesDetails?.updated_on), undefined)}>
                                                    <span className="paragraph ml-1 ant-tooltip-open">

                                                        {getRelativeTime(dashboardTilesDetails?.updated_on)}


                                                    </span>
                                                </Tooltip>
                                            </div>

                                        </li>
                                        <li className="landingCard-profile-listitem mt-1">
                                            <div className="align-items-end d-flex w-100">
                                                <USER width="22" height="15" />
                                                <span className="label label-gray ml-2" style={{ flexBasis: '5rem' }}>{'Updated By'} </span>
                                                <Tooltip placement="topLeft" title={dashboardTilesDetails?.updated_by}>
                                                    <span className="paragraph d-block text-with-ellipsis ant-tooltip-open label-color">


                                                        {dashboardTilesDetails?.updated_by}

                                                    </span>
                                                </Tooltip>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div> : null}

            </div>
            <FlowSelectionModal isModalOpen={openFlowSelectionModal} onClose={() => setopenFlowSelectionModal(false)} fromRoute='scheduler' />
            <DashboardSelectionModal isModalOpen={openDashboardSelectionModal} onClose={() => setopenDashboardSelectionModal(false)} fromRoute='scheduler' />

        </>



    )
}

export default ScheduleModelSelection