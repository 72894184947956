import React from 'react'
import Buttons from '../../../../Common/button/Buttons'
import { ICON_CHECK, ICON_CLOSE, ICON_CLOUD_UPLOAD, ICON_CSV_FILE, ICON_DELETE, ICON_DOWNLOAD, ICON_FILE, ICON_FILE_DOC, ICON_FILE_JPG, ICON_FILE_PDF, ICON_FILE_PNG, ICON_FILE_PPT, ICON_INFO, ICON_LOADING_CIRCLE, ICON_PARQUET_FILE, ICON_PLUS, ICON_RESET, ICON_TXT_FILE, ICON_XLS_FILE } from '../../../../Common/newIconSource'
import { Literals } from '../../../common/literals'
import Dropzone from 'react-dropzone'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { createFolderApiV2, deleteFileApiV2, downloadAzureFile, downloadFileApiV2, fetchFileSystem, getUploadCatalog, getXLSXSheets, updateFileUploadProgress, uploadFileToS3V2 } from '../../../../../store/actions'
import { getConfirmationModal, getObjectLength, getReloadErrorTemplate, humanFileSize, pxToRem, remToPx } from '../../../common/helperFunctions'
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader'
import { v4 as uuidv4 } from 'uuid'
import './FileUploadContainer.scss'
import { Checkbox, Progress, Spin, Tooltip } from 'antd'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import _, { isString } from 'lodash'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import Axios from 'axios'
import DeleteModal from '../../../../Common/deleteModal/DeleteModal'
import { STOPOCTAGON } from '../../../../Common/iconSource'

const fileTypeIcon = (type) => {
    switch (type) {
        case 'txt': return <ICON_TXT_FILE color='#2c83c4' height='20' width='20' />
        case 'parquet': return <ICON_PARQUET_FILE color='#2c83c4' height='20' width='20' />
        case 'csv': return <ICON_CSV_FILE color='#2c83c4' height='20' width='20' />
        case 'xls':
        case 'xlsx': return <ICON_XLS_FILE color='#2c83c4' height='20' width='20' />
        case 'jpg':
        case 'jpeg': return <ICON_FILE_JPG color='#2c83c4' height='20' width='20' />
        case 'doc':
        case 'docx': return <ICON_FILE_DOC color='#2c83c4' height='20' width='20' />
        case 'pdf': return <ICON_FILE_PDF color='#2c83c4' height='20' width='20' />
        case 'ppt': 
        case 'pptx': return <ICON_FILE_PPT color='#2c83c4' height='20' width='20' />
        case 'png': return <ICON_FILE_PNG color='#2c83c4' height='20' width='20' />
        default: return <ICON_FILE color='#2c83c4' height='20' width='20' />
    }
}

const abortedFilesKeyLS = 'cancelledFiles' // LocalStorageKey

const FileUploadContainer = ({
    uniqueUUID = '',
    setUniqueUUID = () => { },
    fileUploadConnName = '',
    setFileUploadConnName = () => { },
    isEditMode = true,
    isEditing = true,
    previewHandlerRef,
    getDetails = () => { },
    podCreationTutorialComponentIds = {},
    setWalkThrough = () => { },
    isTutorialActive = false,
    setUploadInProgress = () => { },
    isUploadInProgress = false,
    isUnstructured = false,
    setFilesList = () => {},
    handleUnstructuredPreview = () => {},
    previewError,
    setPreviewError = () => {},
    setTopicsData = () => {}
}) => {
    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)
    const uploadProgressValue = useSelector(state => state?.CreatePODReducer?.fileUploadProgress)

    const { format } = reduxData?.fileOptions

    const [loading, setLoading] = React.useState(false)
    const [source, setSource] = React.useState(reduxData?.fileUploadSource ?? '')
    const [errorDetail, setErrorDetail] = React.useState(undefined)
    const [selectedFiles, setSelectedFiles] = React.useState(reduxData?.fileUploadFiles ?? [])
    const [fileStatusObj, setFileStatusObj] = React.useState(reduxData?.fileUploadFilesStatus ?? {})
    const [folderCreated, setFolderCreated] = React.useState(reduxData?.isFolderCreated ?? false)
    const [isDragEnter, setDragEnter] = React.useState(false)
    const [deleteConfOpen, setDeleteConfOpen] = React.useState(false)
    const [abortClick, setAbortClick] = React.useState(false)
    const [isFileDialogOpen, setFileDialogOpen] = React.useState(false)
    const [showNameError, setShowNameError] = React.useState(false)
    const [uploadedProgressCount, setuploadedProgressCount] = React.useState(0)

    const getCancelledFilesFromLocalStorage = () => JSON.parse(localStorage.getItem(abortedFilesKeyLS)) ?? {}
    const setCancelledFilesFromLocalStorage = (files) => localStorage.setItem(abortedFilesKeyLS, JSON.stringify({...files}))

    const [cancelledFiles, setCancelledFiles] = React.useState(
        (() => {
            let canFilesLS = getCancelledFilesFromLocalStorage()
            
            if(uniqueUUID && typeof canFilesLS === 'object' && Object.keys(canFilesLS)?.includes(uniqueUUID)) {
                return canFilesLS[uniqueUUID]
            }
            return []
        })()
    )

    const inputFileRef = React.useRef(null)
    const deleteFileRef = React.useRef(null)
    const fileWrapRef = React.useRef({})

    const dispatch = useDispatch()

    const store = useStore()

    React.useEffect(() => {
        reduxData['fileUploadFiles'] = [...selectedFiles]
        reduxData['fileUploadFilesStatus'] = { ...fileStatusObj }
        setReduxData(reduxData)
    }, [selectedFiles, fileStatusObj])

    React.useEffect(() => {
        if (!folderCreated)
            fetchUploadCache()
    }, [])

    React.useEffect(() => {
        
        setuploadedProgressCount(Math.round(uploadProgressValue))
    }, [uploadProgressValue])
    React.useEffect(() => {
        if (showNameError) {
            setTimeout(() => {
                setShowNameError(false)
            }, 10000)
        }
    }, [showNameError])

    const fetchUploadCache = () => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        let reduxPodData = {}
        if (isEditMode && podData?.source_type === 'upload') {
            reduxPodData = store.getState().DataCatalogue.PodCreationData
        }
        else {
            reduxPodData = reduxData
        }
        setLoading(true)
        setErrorDetail(undefined)
        dispatch(getUploadCatalog(false, false))
            .then(res => {
                const cacheConn = res?.data?.catalog
                const cacheSource = res?.data?.source

                setFileUploadConnName(res?.data?.catalog)
                setSource(res?.data?.source);
                if (isEditMode && podData?.source_type === 'upload') {
                    reduxPodData['isFolderCreated'] = true
                    setFolderCreated(true)
                    fetchFileList(cacheConn)
                }
                else {
                    setLoading(false)
                }

                reduxPodData['fileUploadConnection'] = cacheConn
                reduxPodData['fileUploadSource'] = cacheSource
                setReduxData(reduxPodData)

                if (isEditMode && podData?.source_type === 'upload') {
                    getDetails()
                }

                setWalkThrough(prev => {
                    prev = { ...prev, run: isTutorialActive }
                    return prev
                })
            })
            .catch(err => {
                setLoading(false)
                setErrorDetail({ key: 'cache', message: err?.message, reload: fetchUploadCache })
            })
    }

    const createFolder = async () => {
        setLoading(true)
        setErrorDetail(undefined)

        let id = uuidv4()
        setUniqueUUID(id)

        let payload = {
            "folder": id + '/'
        }
        try {
            const { status } = await dispatch(createFolderApiV2(payload, false, false))
            if (status) {

                reduxData['isFolderCreated'] = true
                setReduxData(reduxData)

                setFolderCreated(true)
                setLoading(false)

                let canFilesLS = getCancelledFilesFromLocalStorage()

                canFilesLS = {...canFilesLS, [id]: []}
                
                setCancelledFilesFromLocalStorage(canFilesLS)  

            }
            return id
        }
        catch (err) {
            setLoading(false)
            setErrorDetail({ key: 'create', message: err?.message, reload: createFolder })
            return false
        }
    }

    const fetchFileList = async (connName) => {

        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        const { pod_file_info } = podData
        const prefix = podData?.source_detail[0]
        const requestBody = {
            connection_name: connName,
            prefix: prefix,
            file_properties: {
                format: podData?.pod_file_info?.format
            }
        }
        dispatch(fetchFileSystem(requestBody, false))
            .then(res => {
                const data = res?.data
                if (data?.object && data?.object?.length) {
                    let res = {}

                    data.object.forEach((ele) => {
                        res[ele.name] = 100
                    })
                    let files = data?.object.map((v) => {
                        v['type'] = `text/${v.name.split('.')[1]}`
                        return v
                    })
                    let fileStatus = files?.reduce((acc, curr) => ({ ...acc, [curr?.name]: ({ isUploaded: true, isUploading: false }) }), {})

                    setSelectedFiles(files?.map(f => ({ ...f, path: f?.name })) ?? [])
                    setFilesList(files?.map(f => ({ ...f, path: f?.name })) ?? [])
                    setFileStatusObj({ ...fileStatus })

                    /**************Preview File When Fetched**************/
                    if(isUnstructured) {
                        handleUnstructuredPreview(true, connName)
                    }
                    else {
                        previewHandlerRef?.current?.executePreview()
                    }
                    if (pod_file_info?.format?.toLowerCase() === 'xlsx') {
                        fetchSheets(prefix, connName)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setErrorDetail({ key: 'create', message: err?.message, reload: fetchFileList(connName) })
            })

    };

    const uploadFiles = async (files) => {
        setAbortClick(false)

        /*****************Creating Folder*****************/
        let uuid = uniqueUUID
        if (!isEditMode && !folderCreated) {
            const res = await createFolder()
            uuid = res
            if (!res) return
        }
        else if(isEditMode) {
            const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
            if(podData && podData?.source_type !== 'upload') {
                const res = await createFolder()
                uuid = res
                if (!res) return
            }
        }

        /*****************Uploading Files*****************/
        setUploadInProgress(true)
        let requestBody = {}
        for (let index = 0; index < files.length; index++) {

            const canFileObj = getCancelledFilesFromLocalStorage()

            const cancelled = (() => {
                if(typeof canFileObj === 'object' && Object?.keys(canFileObj)?.includes(uuid)) {
                    return canFileObj[uuid]
                }
                return undefined
            })()

            const f = files[index]; //file
            const extension = _.last(f?.name?.split('.'))
            
            if(!cancelled || cancelled?.length === 0 || !cancelled?.includes(f.name)) {
                requestBody = {
                    "prefix": uuid + '/',
                    "name": f?.name
                }
                setuploadedProgressCount(0);
                dispatch(updateFileUploadProgress(0))
                setFileStatusObj(prev => ({ ...prev, [f?.name]: { isUploading: true, isUploaded: false } }))
    
                
                // if(fileWrapRef?.current && fileWrapRef?.current?.scrollHeight) {
                //     fileWrapRef?.current?.scrollTo(0, fileWrapRef?.current?.scrollHeight)
                // }
    
                try {
                    let res = await dispatch(uploadFileToS3V2(requestBody, f, source, false, false))
                    setAbortClick(false)
    
                    setFileStatusObj(prev => ({ ...prev, [f?.name]: { isUploading: false, isUploaded: true } }))
    
                    if (extension?.toLowerCase() === 'xlsx') {
                        try {
                            await fetchSheets(uuid)
                        }
                        catch {}
                    }
                }
                catch (err) {
                    if (typeof (err) === 'string' && err?.toLowerCase() === 'aborted') {
                        setAbortClick(false)
                        
                        setFileStatusObj(prev => ({ ...prev, [f?.name]: { isFailed: false, isAborted: true, isUploaded: false, isUploading: false } }))
                    } else {
    
                        if(err?.message?.toLowerCase()?.includes("access") && err?.message?.toLowerCase()?.includes("denied")) {
                            emitToastNotification('error', "The credentials of your connection does not have write permission")
                        }
                        else {
                            emitToastNotification('error', err?.message)
                        }
                        setFileStatusObj(prev => ({ ...prev, [f?.name]: { isFailed: true, isAborted: false, isUploaded: false, isUploading: false } }))
                    }
                }
            }
            else {
                console.log(f?.name, ' is cancelled')
            }
        }
        setUploadInProgress(false)
    }

    const fetchSheets = async (uuid, connName = fileUploadConnName, fromDelete = false) => {
        let payload = {
            connection_name: connName,
            prefix: uuid + '/',
            file_properties: { format: 'xlsx' }
        }

        const showErrorToast = fromDelete ? selectedFiles?.length > 1 : true

        let resp = await dispatch(getXLSXSheets(payload, false, showErrorToast))
        if (resp && resp?.data?.sheet_info?.length) {
            let sheet_resp = resp.data.sheet_info
            let sheets = []
            sheet_resp.forEach(s => {
                let opt = s.sheet_name.map(sn => ({ file_name: s.file_name, sheet_name: sn }))
                opt.forEach(v => sheets.push(v))
            })

            let reduxData = store.getState()?.DataCatalogue?.PodCreationData

            reduxData['sheetsList'] = [...sheets]
            setReduxData(reduxData)
        }
        else {
            reduxData['sheetsList'] = []
            setReduxData(reduxData)
        }
    }

    const handleDownload = (f) => {

        emitToastNotification('info', `Downloading "${f.name}"`)
        dispatch(downloadFileApiV2(uniqueUUID, f.name))
            .then((res) => {
                if (source === "azure_adls") {
                    let data = res.data;
                    downloadFileAzure(f, data)
                }
                else {
                    let url = res.data;
                    downloadFileS3(url, f.name)
                }
            })
            .catch((err) => {
                emitToastNotification('error', `Downloading failed`)
            })
    }

    const downloadFileAzure = async (file, data) => {
        try {
            setFileStatusObj(prev => ({ ...prev, [file?.name]: { ...prev[file?.name], isDownloading: true } }))
            await downloadAzureFile(
                data.response,
                file.name,
                data.account_name,
                data.container_name,
                data.path,
                data.pod,
                dispatch,
                false
            )
            setFileStatusObj(prev => ({ ...prev, [file?.name]: { ...prev[file?.name], isDownloading: false } }))
            emitToastNotification('success', `${file?.name} Downloaded Successfully`)
        }
        catch (err) {
            setFileStatusObj(prev => ({ ...prev, [file?.name]: { ...prev[file?.name], isDownloading: false } }))

            emitToastNotification('error', `Failed to download ${file?.name}`)
        }
    }

    const downloadFileS3 = async (url, file_name) => {
        try {
            setFileStatusObj(prev => ({ ...prev, [file_name]: { ...prev[file_name], isDownloading: true } }))
            let { data: content } = await Axios.get(url, { responseType: "arraybuffer", headers: { 'Content-Type': 'application/json' } })
            let extension = _.last(file_name.split('.'))
            setFileStatusObj(prev => ({ ...prev, [file_name]: { ...prev[file_name], isDownloading: false } }))
            const file = new File([content], `${file_name}`, { type: 'application/' + extension })
            var FileSaver = require('file-saver');
            FileSaver.saveAs(file);
            emitToastNotification('success', `${file_name} Downloaded Successfully`)
        }
        catch (err) {
            setFileStatusObj(prev => ({ ...prev, [file_name]: { ...prev[file_name], isDownloading: false } }))
            console.error(err)
            emitToastNotification('error', `Failed to download ${file_name}`)
        }
    }

    const onDelete = (file, showToast = true) => {

        let selectedArray = []
        if(isString(file) && file === 'bulk') {
            let obj = {}

            for (const key in fileStatusObj) {
                const element = fileStatusObj[key];

                obj[key] = {...element, isDeleting: element?.isSelected ?? false, isUploaded: false}
                if(element?.isSelected) {
                    selectedArray?.push({...element, fileName: key})
                }
            }
            
            setFileStatusObj(() => ({...obj}))
        }
        else {
            setFileStatusObj(prev => ({ ...prev, [file?.name]: { isUploaded: false, isUploading: false, isDeleting: true } }))
        }
        
        let payload = {
            folder: uniqueUUID,
        }

        if(isString(file) && file === 'bulk') {
            if(selectedArray?.length !== selectedFiles?.length) {
                payload.file_list = selectedArray?.map(s => s?.fileName)
            }
        }
        else {
            payload.file_list = [file?.name]
        }

        dispatch(deleteFileApiV2(payload, false, showToast))
            .then(() => {

                if(selectedFiles?.length === 0) {
                    setTopicsData([])
                }

                if(isString(file) && file === 'bulk') {


                    let obj = {}, arr = []
                    for (const key in fileStatusObj) {
                        const element = fileStatusObj[key];
                        if(!element?.isSelected) {
                            obj[key] = {...element, isDeleting: false}
                        }
                        else {
                            arr.push(key)
                        }
                    }
                    setSelectedFiles(prev => {
                        let fileArr = prev.filter(f => !arr?.includes(f?.name))
                        setFilesList([...fileArr])
                        return fileArr
                    })
                    
                    setFileStatusObj({...obj})
                }
                else {
                    setSelectedFiles(prev => {
                        let fileArr = prev.filter(f => f?.name !== file?.name)
                        setFilesList([...fileArr])
                        return fileArr
                    })
                    let fsobj = { ...fileStatusObj }
                    delete fsobj[file?.name]
                    setFileStatusObj({ ...fsobj })
                }

                setCancelledFiles(prev => {
                    if(isString(file) && file === 'bulk') {
                        let arr = prev?.filter(f => !payload?.file_list?.includes(f))

                        let canFilesLS = getCancelledFilesFromLocalStorage()
    
                        canFilesLS = {...canFilesLS, [uniqueUUID]: arr}
                        
                        setCancelledFilesFromLocalStorage(canFilesLS)
    
                        return arr
                    }
                    else {
                        let arr = prev?.filter(f => f !== file?.name)
                        
                        let canFilesLS = getCancelledFilesFromLocalStorage()
    
                        canFilesLS = {...canFilesLS, [uniqueUUID]: arr}
                        
                        setCancelledFilesFromLocalStorage(canFilesLS)
    
                        return arr
                    }
                })


                if(!isString(file) && file !== 'bulk') {
                    const extension = _.last(file?.name?.split('.'))
                    if (extension?.toLowerCase() === 'xlsx') {
                        fetchSheets(uniqueUUID, fileUploadConnName, true)
                    }
                }

                let reduxPodData = store.getState().DataCatalogue.PodCreationData
                if (getObjectLength(reduxPodData?.tableData?.result) > 0) {
                    reduxPodData.tableData.result = {}
                    setReduxData(reduxPodData)
                }
                if (previewError !== undefined) {
                    previewHandlerRef?.current?.setPreviewTableError(undefined)
                }
                if (isUnstructured && previewError !== undefined) {
                    setPreviewError(undefined)
                }

            })
            .catch(() => {
                setFileStatusObj(prev => ({ ...prev, [file?.name]: { isUploaded: true, isUploading: false, isDeleting: false } }))
            })

    }

    const onDrop = (files) => {
        let isDiffer = false, fileType = _.findLast(files?.[0]?.name?.split('.'))

        let invalidfFiles = files?.filter(f => f?.name?.charAt(0) === "_" || f?.name?.charAt(0) === ".")

        if (invalidfFiles?.length > 0) {
            setShowNameError(true)
            emitToastNotification("error", `File name should not starts with underscores("_") or dot("."), rename the file(s) ${invalidfFiles?.map(f => `"${f?.name}"`)?.join(", ")} and try again`)
        }
        files = files?.filter(f => f?.name?.charAt(0) !== "_" && f?.name?.charAt(0) !== ".")

        if (!isUnstructured && !['csv', 'xlsx', 'txt', 'parquet', '']?.includes(fileType?.toLowerCase())) {
            emitToastNotification('info', 'File type not supported')
            return
        }

        if(!isUnstructured) {
            if (selectedFiles?.length > 0) {
                fileType = _.findLast(selectedFiles?.[0]?.name?.split('.'))
                isDiffer = !files?.every(f => _.findLast(f?.name?.split('.'))?.toLowerCase() === fileType?.toLowerCase())
            }
            else {
                isDiffer = !files?.every(f => _.findLast(f?.name?.split('.'))?.toLowerCase() === fileType?.toLowerCase())
            }
        }

        /**
         * Auto Selecting File Format in File Options
         */
        if (fileType && fileType !== '')
            previewHandlerRef?.current?.autoSetFileFormat(fileType?.toLowerCase())
        else
            previewHandlerRef?.current?.autoSetFileFormat('parquet')
        /**
         * End of above comment
         */

        if (!isUnstructured && isDiffer) {
            if (selectedFiles?.length > 0) {
                emitToastNotification('info', `Different file types found. Make sure all files are of "${fileType?.toLowerCase()}" file type`)
            }
            else {
                emitToastNotification('info', 'Different file types found. Make sure all files are of same file type')
            }
            return
        }

        let filesToUpload = [...files]
        if (selectedFiles?.length > 0) {
            filesToUpload = filesToUpload.filter(f => !selectedFiles?.some(v => v?.name === f.name))
            let existingFiles = [...files].filter(f => selectedFiles?.some(v => v?.name === f.name))
                ?.map(f => f.name)?.join(', ')

            if (existingFiles && existingFiles !== "")
                emitToastNotification('info', `File(s) "${existingFiles}" already exist`)
        }

        let filesObj = {}
        filesToUpload.forEach(v => {
            filesObj[v.name] = { isUploading: false, isUploaded: false }
        })
        setFileStatusObj(prev => ({ ...prev, ...filesObj }))
        setSelectedFiles(prev => {
            const newArr = [...prev, ...filesToUpload]
            setFilesList([...newArr])
            return newArr
        })

        uploadFiles(filesToUpload)
        setDragEnter(false)
    }

    const setReduxData = (data) => {
        dispatch(setPodCreationData(data))
    }

    const onRemoveFile = (fileName) => {
        let fsobj = { ...fileStatusObj }
        delete fsobj[fileName]
        setSelectedFiles(prev => {
            const newArr = prev?.filter(f => f?.name !== fileName)
            setFilesList([...newArr])
            return newArr
        })
        setFileStatusObj({ ...fsobj })
        setCancelledFiles(prev => {
            let arr = prev?.filter(file => file !== fileName)

            let canFilesLS = getCancelledFilesFromLocalStorage()
    
            canFilesLS = {...canFilesLS, [uniqueUUID]: arr}
            
            setCancelledFilesFromLocalStorage(canFilesLS)

            return arr
        })


    }

    const onRetry = (file) => {
        let filesObj = {}
        filesObj[file?.name] = { isUploading: false, isUploaded: false }
        setFileStatusObj(prev => ({ ...prev, ...filesObj }))
        setSelectedFiles(prev => {
            prev = prev?.filter(f => f?.name !== file?.name)
            const newArr = [...prev, file]
            setFilesList([...newArr])
            return newArr
        })

        uploadFiles([file])
    }

    const isAnySelected = React.useMemo(() => {
        let arr = []
        for (const key in fileStatusObj) {
            const element = fileStatusObj[key]
            arr.push(element?.isSelected === true ? true : false)
        }
        return arr?.some(a => a === true)
    }, [fileStatusObj])

    const selectAll = React.useMemo(() => {
        let arr = []
        for (const key in fileStatusObj) {
            const element = fileStatusObj[key]
            if(element?.isSelected) {
                arr.push(true)
            }
        }
        return arr?.length === selectedFiles?.length
    }, [fileStatusObj, selectedFiles])

    const isAnyFilePending = React.useMemo(() => {
        let arr = []
        for (const key in fileStatusObj) {
            const element = fileStatusObj[key]
            arr.push(element?.isUploading === true ? true : false)
        }
        return arr?.some(a => a === true)
    }, [fileStatusObj])

    const isAnyFileDeleting = React.useMemo(() => {
        let arr = []
        for (const key in fileStatusObj) {
            const element = fileStatusObj[key]
            arr.push(element?.isDeleting === true ? true : false)
        }
        return arr?.some(a => a === true)
    }, [fileStatusObj])

    const onSelectAll = (chk) => {
        setFileStatusObj(prev => {
            let obj = {}
            for (const key in prev) {
                const element = prev[key]
                obj[key] = {...element, isSelected: chk}
            }
            return {...obj}
        })
    }

    const showFileSelect = (f) => {
        if(isEditMode) {
            return isEditing && fileStatusObj[f?.name]?.isUploaded 
        }
        return fileStatusObj[f?.name]?.isUploaded || cancelledFiles?.find(c => c === f?.name)
    }

    return (
        <div id={podCreationTutorialComponentIds?.fileUploadContainerId} className='h-100' >
            <div className='file-opts-head d-flex align-items-center justify-content-between py-2'>
                <label className='mb-0 fontSizeHeading fontInterSemiBold file-left-header'>
                    Files
                </label>
                <div className='d-flex align-items-center file-left-header-action-wrap'>
                    {
                        selectedFiles?.length > 1
                        ?
                        <Tooltip placement='top' title={selectAll ? "Unselect All" : "Select All"}>
                            <Checkbox
                                className='mr-2'
                                checked={selectAll}
                                onChange={(e) => {
                                    let chk = e?.target?.checked
                                    onSelectAll(chk)
                                }}
                                disabled={
                                    (() => {
                                        if(isEditMode) {
                                            return !isEditing || isAnyFilePending
                                        }
                                        return isAnyFilePending
                                    })()
                                }
                            />
                        </Tooltip>
                        :   ''
                    }
                    {
                        isAnySelected
                        ?
                        <Buttons
                            props={{
                                buttonText: "Delete",
                                buttonClassName: "custom-btn-outline border custom-btn btn-with-text mr-2 error-red",
                                buttonEvent: () => {
                                    deleteFileRef.current = "bulk"
                                    setDeleteConfOpen(true)
                                },
                                ImgSrc: () => <ICON_DELETE />,
                                isDisable: (isEditMode && !isEditing) 
                                        || loading 
                                        || (errorDetail && errorDetail?.message)
                                        || isAnyFileDeleting,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                        :   ''
                    }
                    <Buttons
                        props={{
                            buttonText: "Add File",
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: () => {
                                if (loading || (errorDetail && errorDetail?.message)) return
                                inputFileRef?.current?.open()
                            },
                            ImgSrc: () => <ICON_PLUS />,
                            isDisable: (isEditMode && !isEditing) || loading || (errorDetail && errorDetail?.message),
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </div>
            </div>
            <div className={`fi-up-container ${isEditMode ? 'src-view' : ''}`}>
                {
                    loading ? <ClaristaLoader /> :
                        errorDetail && errorDetail?.message
                            ? getReloadErrorTemplate({ errorMessage: errorDetail?.message, onReload: errorDetail?.reload })
                            : <Dropzone
                                accept=".csv,.xlsx,.parquet,.txt"
                                multiple={true}
                                onDrop={onDrop}
                                disabled={(isEditMode && !isEditing) || isFileDialogOpen}
                                ref={inputFileRef}
                                onDragEnter={() => setDragEnter(true)}
                                onDragLeave={() => setDragEnter(false)}
                                onFileDialogOpen={() => {
                                    setFileDialogOpen(true)
                                }}
                                onFileDialogCancel={() => {
                                    setFileDialogOpen(false)
                                }}
                                onDropAccepted={() => {
                                    setFileDialogOpen(false)
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <>
                                        {
                                            selectedFiles?.length > 0
                                                ?
                                                <div ref={fileWrapRef} className='p-2 h-100 position-relative' style={{ overflowY: 'auto', scrollBehavior: 'smooth' }}>
                                                    {
                                                        isUnstructured ? ''
                                                        :
                                                        <div class="fontInterSemiBold fup-note fontSizeLabel text-black-50" style={{ zIndex: isDragEnter ? 2 : 3 }}>
                                                            Make sure all files are of "{format?.toUpperCase() ?? 'same'}" format and contains same columns.
                                                        </div>
                                                    }
                                                    {
                                                        showNameError
                                                            ?
                                                            <div className='p-2 fi-up-fibox position-relative mb-2 border rounded border-danger d-flex align-items-center'>
                                                                <div className='text-danger mr-2'>
                                                                    <ICON_INFO color='currentColor' />
                                                                </div>
                                                                <div className='text-danger'>
                                                                    <label className='fontInterSemiBold fontSizeHeading mb-0 w-100'>
                                                                        File name should not starts with underscores("_")
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <Buttons
                                                                        props={{
                                                                            tooltipPlacement: 'right',
                                                                            tooltip: 'Close',
                                                                            buttonText: "",
                                                                            buttonClassName: "custom-btn custom-btn-outline btn-with-icon ml-2",
                                                                            buttonEvent: (e) => {
                                                                                e.preventDefault()
                                                                                setShowNameError(false)
                                                                            },
                                                                            ImgSrc: () => <ICON_CLOSE />,
                                                                            isDisable: false,
                                                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        selectedFiles?.map((f, i) => (
                                                            <div key={`${f.name}${i}`} className='p-2 fi-up-fibox position-relative mb-2'
                                                                style={{ filter: cancelledFiles?.find(c => c === f?.name) ? '' : 'none' }}
                                                            >
                                                                <span className='file-ic'>
                                                                    {fileTypeIcon(f?.name?.split('.')?.[1]?.toLowerCase())}
                                                                    {
                                                                        showFileSelect(f)
                                                                        ?
                                                                        <span className={`file-select-checkbox ${fileStatusObj[f?.name]?.isSelected ? 'active' : ''}`}
                                                                            // onClick={() => {
                                                                            //     setFileStatusObj(prev => {
                                                                            //         prev[f?.name] = {...prev[f?.name], isSelected:!(!!prev[f?.name]?.isSelected)}
                                                                            //         return {...prev}
                                                                            //     })
                                                                            // }}
                                                                        >
                                                                            <Checkbox 
                                                                                checked={fileStatusObj[f?.name]?.isSelected}
                                                                                onClick={e => {
                                                                                    setFileStatusObj(prev => {
                                                                                        prev[f?.name] = {...prev[f?.name], isSelected: e?.target?.checked}
                                                                                        return {...prev}
                                                                                    })
                                                                                }}
                                                                            />
                                                                            {/* <ICON_CHECK color='currentColor'/> */}
                                                                        </span>
                                                                        :   ''
                                                                    }
                                                                </span>
                                                                <div className='file-det'>
                                                                    <Tooltip title={f?.name} placement='topLeft'>
                                                                        <label className='d-block text-with-ellipsis'>
                                                                            {f?.name ?? 'Untitled'}
                                                                        </label>
                                                                    </Tooltip>
                                                                    <small>{humanFileSize(f?.size ?? 0)}</small>
                                                                    {/* <small>{(f?.size / 1024).toFixed(2) + 'kb'}</small> */}
                                                                    {
                                                                        cancelledFiles?.find(c => c === f?.name) && !fileStatusObj[f?.name]?.isDeleting
                                                                        ?
                                                                        <small className='ml-2 text-danger'>
                                                                            <span className='mr-1'><ICON_CLOSE color='#dc3545' height='10' width='10' /></span>
                                                                            cancelled
                                                                        </small>
                                                                        :
                                                                        fileStatusObj[f?.name]?.isFailed
                                                                            ?
                                                                            <>
                                                                                <small className='ml-2 text-danger'>
                                                                                    <span className='mr-1'><ICON_CLOSE color='#dc3545' height='10' width='10' /></span>
                                                                                    failed
                                                                                </small>
                                                                                {/* <span onClick={() => onRemoveFile(f?.name)} 
                                                                        className='btn-danger-outline cursor-pointer ml-2 px-1 rounded small'> 
                                                                    <ICON_CLOSE color='#33333370' height='10' width='10' /> remove</span> */}
                                                                            </> :

                                                                            fileStatusObj[f?.name]?.isAborted
                                                                                ?
                                                                                <>
                                                                                    <small className='ml-2 text-warning'>
                                                                                        <span className='mr-1'><STOPOCTAGON color='#EF892B' height='10' width='10' /></span>
                                                                                        Aborted
                                                                                    </small>
                                                                                    {/* <span onClick={() => onRemoveFile(f?.name)} 
                                                                    className='btn-danger-outline cursor-pointer ml-2 px-1 rounded small'> 
                                                                <ICON_CLOSE color='#33333370' height='10' width='10' /> remove</span> */}
                                                                                </>
                                                                                :
                                                                                fileStatusObj[f?.name]?.isUploaded
                                                                                    ? fileStatusObj[f?.name]?.isDownloading
                                                                                        ? <small className='ml-2 text-success'>
                                                                                            <span className='mr-1'><ICON_LOADING_CIRCLE color='#28a745' height='10' width='10' /></span>
                                                                                            downloading...
                                                                                        </small>
                                                                                        :
                                                                                        <small className='ml-2 text-success'>
                                                                                            <span className='mr-1'><ICON_CHECK color='#28a745' height='10' width='10' /></span>
                                                                                            done
                                                                                        </small>
                                                                                    :
                                                                                    fileStatusObj[f?.name]?.isDeleting
                                                                                        ? <small className='ml-2 text-danger'>
                                                                                            <span className='mr-1'><ICON_LOADING_CIRCLE color='#dc3545' height='10' width='10' /></span>
                                                                                            deleting...
                                                                                        </small>
                                                                                        :
                                                                                        fileStatusObj[f?.name]?.isUploading
                                                                                            // && !abortClick
                                                                                            ? <Progress className='ml-3' percent={uploadedProgressCount} steps={5} size={10} strokeColor={'#28a745'} />
                                                                                            // <small className='ml-2 text-success'>
                                                                                            //     <span className='mr-1'><ICON_LOADING_CIRCLE color='#28a745' height='10' width='10' /></span>
                                                                                            //     uploading...
                                                                                            // </small>
                                                                                            // : !abortClick 
                                                                                            // ? 
                                                                                            :
                                                                                            fileStatusObj[f?.name]?.isCancel
                                                                                                ?
                                                                                                <small className='ml-2 text-danger'>
                                                                                                    <span className='mr-1'><ICON_LOADING_CIRCLE color='#dc3545' height='10' width='10' /></span>
                                                                                                    cancelling...
                                                                                                </small>
                                                                                                :
                                                                                                <small className='ml-2 text-dark'>
                                                                                                    pending...
                                                                                                </small>
                                                                        // :
                                                                        //     <small className='ml-2 text-dark'>
                                                                        //         cancelling..
                                                                        //     </small>
                                                                    }
                                                                </div>
                                                                <span className='file-act'>

                                                                    {
                                                                        (fileStatusObj[f?.name]?.isUploaded || fileStatusObj[f?.name]?.isFailed)
                                                                        || cancelledFiles?.find(c => c === f?.name)
                                                                            ?
                                                                            <Buttons
                                                                                props={{
                                                                                    tooltipPlacement: 'topLeft',
                                                                                    tooltip: fileStatusObj[f?.name]?.isFailed || cancelledFiles?.find(c => c === f?.name) ? 'Retry' : 'Download',
                                                                                    buttonText: "",
                                                                                    buttonClassName: "custom-btn custom-btn-outline btn-with-icon mr-1",
                                                                                    buttonEvent: (e) => {
                                                                                        e.preventDefault()
                                                                                        if (fileStatusObj[f?.name]?.isFailed) {
                                                                                            onRetry(f)
                                                                                            return
                                                                                        }
                                                                                        else if(cancelledFiles?.find(c => c === f?.name)) {
                                                                                            setCancelledFiles(prev => {
                                                                                                let arr = prev?.filter(c => c !== f?.name)

                                                                                                let canFilesLS = getCancelledFilesFromLocalStorage()

                                                                                                canFilesLS = {...canFilesLS, [uniqueUUID]: arr}
                                                                                                
                                                                                                setCancelledFilesFromLocalStorage(canFilesLS)

                                                                                                return arr
                                                                                            })
                                                                                            onRetry(f)
                                                                                            return
                                                                                        }
                                                                                        handleDownload(f)
                                                                                    },
                                                                                    ImgSrc: () => fileStatusObj[f?.name]?.isFailed || cancelledFiles?.find(c => c === f?.name) ? <ICON_RESET /> : <ICON_DOWNLOAD />,
                                                                                    isDisable: false,
                                                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                                                }}
                                                                            />
                                                                            : ''
                                                                    }
                                                                    {
                                                                        (fileStatusObj[f?.name]?.isUploaded || fileStatusObj[f?.name]?.isFailed)
                                                                        || cancelledFiles?.find(c => c === f?.name)
                                                                            ?
                                                                            <Buttons
                                                                                props={{
                                                                                    tooltipPlacement: 'topLeft',
                                                                                    tooltip: 'Delete',
                                                                                    buttonText: "",
                                                                                    buttonClassName: "custom-btn custom-btn-outline btn-with-icon error-red",
                                                                                    buttonEvent: (e) => {
                                                                                        e.preventDefault()
                                                                                        if(cancelledFiles?.find(c => c === f?.name)) {
                                                                                            onRemoveFile(f?.name)
                                                                                            onDelete(f, false)
                                                                                        }
                                                                                        else {
                                                                                            deleteFileRef.current = f
                                                                                            setDeleteConfOpen(true)
                                                                                        }
                                                                                    },
                                                                                    ImgSrc: () => <ICON_DELETE />,
                                                                                    isDisable: (isEditMode && !isEditing),
                                                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                                                }}
                                                                            />
                                                                            : ''
                                                                    }
                                                                    {
                                                                        // fileStatusObj[f?.name]?.isUploading &&
                                                                        //  !abortClick
                                                                        //     ? 
                                                                        !cancelledFiles?.find(c => c === f?.name)
                                                                        && (!fileStatusObj[f?.name]?.isUploaded) 
                                                                        && !fileStatusObj[f?.name]?.isFailed 
                                                                        && !(!!fileStatusObj[f?.name]?.isDeleting)
                                                                            ? <Buttons
                                                                                props={{
                                                                                    tooltipPlacement: 'topLeft',
                                                                                    tooltip: 'Cancel Uploading',
                                                                                    buttonText: "",
                                                                                    buttonClassName: "custom-btn custom-btn-outline btn-with-icon mt-1 ml-1",
                                                                                    buttonEvent: (e) => {
                                                                                        e.preventDefault();

                                                                                        localStorage.setItem('isCancelBtnClick', JSON
                                                                                        .stringify({ aborted: true }))
                                                                                        setAbortClick(true)
                                                                                        setCancelledFiles(prev => {
                                                                                            let arr = [...prev, f?.name]
                                                                                            
                                                                                            let canFilesLS = getCancelledFilesFromLocalStorage()

                                                                                            canFilesLS = {...canFilesLS, [uniqueUUID]: arr}
                                                                                            
                                                                                            setCancelledFilesFromLocalStorage(canFilesLS)

                                                                                            return arr
                                                                                        })
                                                                                        // setFileStatusObj(prev => ({ ...prev, [f?.name]: { isUploaded: false, isUploading: false, isCancel: true } }))
                                                                                        // setTimeout(() => {

                                                                                        //     onRemoveFile(f?.name)
                                                                                        //     onDelete(f)
                                                                                        // }, 2000);

                                                                                    },
                                                                                    ImgSrc: () => <ICON_CLOSE width='10px' height='10px' color='#ff565e'></ICON_CLOSE>,
                                                                                    isDisable: false,
                                                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                                                }}
                                                                            />
                                                                            // :
                                                                            // abortClick ? 
                                                                            // <span className='mr-1'>
                                                                            //     <ICON_LOADING_CIRCLE color='#ff565e' height='12' width='12' /> cancelling..
                                                                            // </span> 
                                                                            :
                                                                            null
                                                                    }
                                                                </span>
                                                            </div>
                                                        ))

                                                    }
                                                    <div
                                                        style={{ 
                                                            opacity: (isEditMode && !isEditing) ? .6 : 1, 
                                                            cursor: (isEditMode && !isEditing) ? 'not-allowed' : 'pointer',
                                                            height: fileWrapRef?.current?.scrollHeight
                                                        }}
                                                        className={`text-center upload-more ${isDragEnter ? 'on-upload-drag' : 'on-upload-ideal'}`} {...getRootProps()}>
                                                        <input {...getInputProps()} />

                                                        <div 
                                                        style={{position: 'sticky', top: '50%', bottom: '44%'}}
                                                        className='text-black-50 text-center fontSizeHeading bg-white rounded p-2 upload-note'>
                                                            <ICON_PLUS color='#00000080' height='16' width='30' />
                                                            {`Drop ${isDragEnter ? 'File(s) Here' : 'More File(s)'}`}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div
                                                    style={{ opacity: (isEditMode && !isEditing) ? .6 : 1, cursor: (isEditMode && !isEditing) ? 'not-allowed' : 'pointer' }}
                                                    className={`py-5 px-2 text-center drag-upload-large ${isDragEnter ? 'on-upload-drag' : 'on-upload-ideal'}`} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <div>
                                                        <div className='drop-cloud-icon'>
                                                            <ICON_CLOUD_UPLOAD color='#828282' height='85' width='85' />
                                                        </div>
                                                        <p className='mb-0 fontSizeHeading fontInterSemiBold'>
                                                            {

                                                                isFileDialogOpen ? 'Choose files from File Dialog Box'
                                                                    :
                                                                    'Drag and drop your files here'
                                                            }
                                                        </p>
                                                            <p className='text-black-50 fontSizeHeading fontInterSemiBold'>
                                                            {
                                                                isUnstructured
                                                                ?
                                                                "(Supported file types are : .pdf, .ppt, .pptx, .doc, .docx, .rtf, .xls, .xlsx, .jpg, .jpeg, .png, .svg, .bmp, .tiff, .webp, .heic, .jfif)"
                                                                :
                                                                "(Supported file types are : .txt, .csv, .xlsx, .parquet)"
                                                            }
                                                            </p>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                )}
                            </Dropzone>
                }
            </div>

            <DeleteModal
                open={deleteConfOpen}
                setOpen={setDeleteConfOpen}
                heading={
                    (() => {
                        let f = deleteFileRef?.current
                        if(isString(f) && f === 'bulk') {
                            return "Delete File(s)"
                        }
                        return 'Delete File'
                    })()
                }
                onConfirm={() => {
                    let f = deleteFileRef?.current
                    setDeleteConfOpen(false)
                    if (fileStatusObj[f?.name]?.isFailed) {
                        onRemoveFile(f?.name)
                        return
                    }
                    else if(f === 'bulk') {
                        onDelete("bulk")
                    }
                    else {
                        onDelete(f)
                    }
                }}
            />

            {/* {
                getConfirmationModal({
                    open: deleteConfOpen,
                    setOpen: setDeleteConfOpen,
                    heading: "Delete File",
                    confirmationText: "Yes, Delete",
                    onConfirm: () => {
                        let f = deleteFileRef?.current
                        if(fileStatusObj[f?.name]?.isFailed) {
                            onRemoveFile(f?.name)
                            return
                        }
                        onDelete(f)
                    }
                })
            } */}
        </div>
    )
}

export default FileUploadContainer