import { Checkbox, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { MultipleColsDndList } from './MultipleColsDndList'

import { getColumnDataType, getObjectLength, pxToRem, remToPx, showSimpleLoading } from '../../../../common/helperFunctions'
import { ICON_ARROW_RIGHT, ICON_CLOSE } from '../../../../../Common/newIconSource'
import ColumnSelectPopover from './ColumnSelectPopover'
import { cloneDeep } from 'lodash'
import { useSelector, useStore } from 'react-redux'
import { fetchPreviewTable } from '../../../../../../store/modules/common/VirtualDataTable/action'
import { v4 as uuidv4 } from 'uuid'
import { SIXDOTS } from '../../../../../Common/iconSource'

const DndAlertList = ({ actualData, isExecutePermission = true, reOrderRender = true, setshowCloseWarning, setreOrderRender, stopDraggingRow = false, setNewColumnList = () => { }, Selectall = () => { }, setupdatedcolumnList = () => { }, originalColumnList }) => {

    const [demo, setDemo] = useState([])
    const [columns, setColumns] = useState([])

    const [showLoader, setshowLoader] = useState(false);

    const store = useStore()
    const [colsList, setColsList] = useState([
        { key: 'checked', title: '', resizable: false, isRequired: false, disabled: true, visible: true, width: 5, frozen: true },
        { key: 'Name', title: '', resizable: false, isRequired: false, disabled: false, visible: true, width: 90 },

    ])







    const getFieldTemplate = (key, index, value = '', additionalClasses = '', isDisable = false, column) => {

        switch (key) {
            case 'checked':
                return <div className='d-flex align-items-center'>
                    <Checkbox checked={value} className="mr-2 " disabled={isDisable || !isExecutePermission} onChange={(e) => onInputChangeHandler(key, e.target.checked, index)}>
                    </Checkbox>
                    <div className='cursor d-block bx-rotate-90 ml-3 six-dot-dnd-ic'> <SIXDOTS /></div>
                </div>

            case 'Name':

                return <div className='d-flex align-items-center justify-content-left'>

                    {reOrderRender ? <div className='w-100' onKeyDown={(e) => e.stopPropagation()}>


                        <div className={`list-without-drag alert-fl-inp-cols-wrap margin-left-2`}>
                            <div className={`  ${column?.showError ? 'invalid-column-present' : ''} d-flex  align-items-center  `}>
                                {getColumnDataType(column?.Type)}
                                <span className="paragraph">{column?.Name}</span>
                            </div>

                        </div>

                    </div> : null}
                </div>
        }
    }

    const generateColumns = (columns_list) => {
        let cols = [], colsListCopy = [...columns_list]
        cols = colsListCopy.filter(v => v.visible === true).map(v => {
            return v.key !== 'checked' ? {
                name: <label className='font-w-600 small mb-0'>
                    {v.title}
                    {v.isRequired ? <span><sup>*</sup></span> : ''}
                </label>,
                key: v.key,
                sortable: false,
                resizable: false,
                width: v?.width ? remToPx(pxToRem(v?.width)) : undefined,
                frozen: v.frozen ?? false
            } : {
                name: '',
                key: v.key,
                sortable: false,
                resizable: false,
                width: v?.width ? remToPx(pxToRem(v?.width)) : undefined,
                frozen: v.frozen ?? false
            }
        })

        return cols
    }




    useEffect(() => {
        let temp = [], cols = [], obj = {};
        let rows = [...actualData]
        temp = rows.map((v, i) => {

            Object.keys(v).forEach(key => obj[key] = getFieldTemplate(key, i, v[key], '', key === 'old_name' ? true : v[key]?.disabled, v))
            return { ...obj }

        })
        setDemo([...temp])
        cols = [...generateColumns(colsList)]
        setColumns([...cols])

    }, [actualData, reOrderRender])


    const onInputChangeHandler = (field, value, index) => {

        let dataCopy = [...actualData];

        dataCopy[index][field] = value

        setNewColumnList([...dataCopy]);

        if (field === 'checked') {
            let dataCopy = [...originalColumnList];

            dataCopy[index][field] = value

            setupdatedcolumnList([...dataCopy]);
        }

    }




    return (
        <>

            <div className='alert-dnd-list mt-1' style={{ overflow: 'auto', height: 'calc(100vh - 226px)' }}>
                {!showLoader ? <MultipleColsDndList
                    setreOrderRender={setreOrderRender}
                    colsList={columns}
                    removePadding={true}
                    rowsList={demo}
                    headerRowHeight={0}
                    stopDraggingRow={stopDraggingRow}
                    setData={(data) => { setNewColumnList(data); setshowCloseWarning(true); setupdatedcolumnList(data) }}
                    rawData={actualData}
                /> : showSimpleLoading('Please wait columns are loading')}
            </div>

        </>
    )
}

export default DndAlertList;
