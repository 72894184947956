import { emitToastNotification } from "../../../../helpers/toast_helper";
import { fetchData } from "../../../../services/apiService";

const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

const SET_UNREAD_NOTIFICATIONS = "SET_UNREAD_NOTIFICATIONS"
const SET_CLUSTER_STATUS = "SET_CLUSTER_STATUS"

export const setUnreadNotifications = (data) => {

    return {
        type: SET_UNREAD_NOTIFICATIONS,
        payload: data
    };
};

export const setclusterStatusNotification= (data) => {

    return {
        type: SET_CLUSTER_STATUS,
        payload: data
    };
};

export const getNotifications = (type, page, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}notification/?limit=${page.limit}&page=${page.number}${type ? '&content_type=' + type : ''}${page?.domain ? '&data_domain_id=' + page?.domain : ''}`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const markReadNotification = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}notification/mark/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const markAllReadNotification = (module, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}notification/mark_module/?module=${module}`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

const initialState = {
    unreadNotifications: {},
    clusterStatusNotification: {}
}

const NotificationsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                unreadNotifications: payload
            }
        case SET_CLUSTER_STATUS:
            return {
                ...state,
                clusterStatusNotification: payload
            }

        default:
            return state;
    }
}
export default NotificationsReducer;