import React from 'react'
import CommonModal from '../../../Common/CommonModal/CommonModal'
import { Input } from 'antd'
import Buttons from '../../../Common/button/Buttons'
import { ICON_CHECK, ICON_LOADING_CIRCLE } from '../../../Common/newIconSource'
import { Literals } from '../../common/literals'

const VerifyModal = ({
    open = false,
    setOpen = () => {},
    setComment = () => {},
    onVerify = () => {},
    comment = "",
    verifying = false
}) => {
    return <>
        <CommonModal
            open={open}
            setOpen={setOpen}
            size='lg'
            onClose={() => {
                setOpen(false)
            }}
            header={'Confirm Verify'}
            body={
                <>
                    <div>
                        <div>
                            <label className='fontSizeHeading mb-2'>Do you want to add note <em>(optional)</em></label>
                            <div className='d-flex' style={{ gap: 10 }}>
                                <Input.TextArea style={{ borderRadius: 3 }}
                                    placeholder='Start writing here...'
                                    rows={5}
                                    className='custom-input-field h-100'
                                    value={comment}
                                    onChange={(e) => {
                                        setComment(e?.target?.value)
                                    }}
                                    disabled={verifying}
                                />
                            </div>
                            <div className='d-flex justify-content-center pt-3'>
                                <Buttons
                                    props={{
                                        buttonText: verifying ? "Verifying..." : "Verify",
                                        buttonClassName: `custom-btn-primary custom-btn btn-with-text feed-verify-btn`,
                                        buttonEvent: () => {
                                            onVerify()
                                        },
                                        ImgSrc: () => verifying ? <ICON_LOADING_CIRCLE /> : <ICON_CHECK />,
                                        isDisable: verifying,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
            footer=''
        />
    </>
}

export default VerifyModal