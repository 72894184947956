import { Popover } from 'antd'
import React from 'react'
import { ICON_ARROW_RIGHT, ICON_CATALOG, ICON_CATALOG_DOMAIN, ICON_CHART, ICON_CONNECTION, ICON_DASHBOARD, ICON_DICTIONARY, ICON_DOWNLOAD, ICON_FLOWS, ICON_SCHEDULER, ICON_USER, ICON_USERS_GROUP } from '../../../Common/newIconSource'
import CustomDropdownContent from '../../../Common/CustomDropdownContent'
import { CLUSTERMENUICON } from '../../../Common/iconSource'

const ModuleDropdown = ({
    defaultActiveModule = 'pod',
    setActiveModule = () => { }
}) => {

    const [menuList, setMenuList] = React.useState([])
    const [searchDomain, setSearchDomain] = React.useState('');

    let isGodUser = JSON.parse(localStorage.getItem('user'))?.userDetails?.is_goduser ?? false//store.getState.UserReducer.user?.userDetails?.is_goduser;
    let isFirstUser = JSON.parse(localStorage.getItem('user'))?.userDetails?.is_firstuser ?? false //store.getState.UserReducer.user?.userDetails?.is_firstuser;
    let isSuperUser = JSON.parse(localStorage.getItem('user'))?.userDetails?.is_superuser ?? false //store.getState.UserReducer.user?.userDetails?.is_firstuser;


    const moduleList = [
        { icon: (props) => <ICON_CATALOG {...props} />, name: 'PODs', key: 'pod' },
        { icon: (props) => <ICON_DICTIONARY {...props} />, name: 'Data Dictionary', key: 'data_dictionary' },
        { icon: (props) => <ICON_FLOWS {...props} />, name: 'Flows', key: 'flow' },
        { icon: (props) => <ICON_SCHEDULER {...props} />, name: 'Scheduler', key: 'scheduler' },
        { icon: (props) => <ICON_DASHBOARD {...props} />, name: 'Dashboard', key: 'dashboard' },
        { icon: (props) => <ICON_CHART {...props} />, name: 'Chart', key: 'chart' },
        { icon: (props) => <ICON_CONNECTION {...props} />, name: 'Connection', key: 'connection' },
        { icon: (props) => <ICON_CATALOG_DOMAIN {...props} />, name: 'Domain', key: 'data_domain' },
        { icon: (props) => <ICON_USER {...props} />, name: 'User', key: 'user' },
        { icon: (props) => <ICON_USERS_GROUP {...props} />, name: 'Usergroup', key: 'group' },
        { icon: (props) => <ICON_USER {...props} />, name: 'Identity & OpenAI', key: 'control_centre' },
        { icon: (props) => <ICON_DOWNLOAD {...props} />, name: 'Data Downloaded', key: 'download' },
        { icon: (props) => <CLUSTERMENUICON color='#333333' {...props} />, name: 'Cluster', key: 'cluster' },
    ]

    React.useEffect(() => {
        let list = [...moduleList]


        if (isGodUser || isFirstUser || isSuperUser) {
            list = list?.map(item => (
                {
                    label: <div className='d-flex align-items-center'>
                        <span className='mr-2 d-flex'>
                            {item?.icon({ height: '14', width: '14' })}
                        </span>
                        <span>
                            {item?.name}
                        </span>
                    </div>,
                    name: item?.name,
                    tooltip: item?.name,
                    key: item?.key,
                    active: defaultActiveModule === item?.key,
                    disabled: false,
                    data: item
                }
            ))
        } else {
            list = list?.map(item => (
                {
                    label: <div className='d-flex align-items-center'>
                        <span className='mr-2 d-flex'>
                            {item?.icon({ height: '14', width: '14' })}
                        </span>
                        <span>
                            {item?.name}
                        </span>
                    </div>,
                    name: item?.name,
                    tooltip: item?.name,
                    key: item?.key,
                    active: defaultActiveModule === item?.key,
                    disabled: false,
                    data: item
                }
            )).filter(item => item.name !== 'Identity & OpenAi')
        }

        setMenuList([...list])
    }, [])

    const items = React.useMemo(() => {
        let list = [...menuList]
        list = list?.filter(d => d?.name?.toLowerCase()?.includes(searchDomain?.toLowerCase()))
        return list
    }, [menuList, searchDomain])

    return (
        <Popover
            trigger={'hover'}
            title={''}
            destroyTooltipOnHide
            content={
                <CustomDropdownContent
                    hideSearch
                    items={items ?? []}
                    searchValue={searchDomain}
                    setSearchValue={setSearchDomain}
                    emptyDescription='No Modules'
                    searchPlaceholder='Search Module...'
                    onItemClick={(itm) => {
                        setMenuList(prev => {
                            prev = prev?.map(m => ({ ...m, active: itm?.key === m?.key }))
                            return prev
                        })
                        setActiveModule(itm?.key)
                    }}
                />
            }
            onOpenChange={(visible) => {
                if (!visible) setSearchDomain("")
            }}
            placement='bottom'
            arrow={false}
            rootClassName='nusg-module-ddl'
        >
            <label className='label'>Select Module</label>
            <div className='d-flex align-items-center nusg-dropdown-selected nusg-module-ddl-selected' style={{ width: '11.25rem' }}>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <span className='fontSizeHeading nusg-dropdown-label text-with-ellipsis'
                        title={menuList?.find(m => m?.active)?.label ?? "Select Module"}
                        style={{ width: '7.25rem' }}>
                        {
                            menuList?.find(m => m?.active)?.label ?? "Select Module"
                        }
                    </span>
                    <span className='bx-rotate-90 ml-2'>
                        <ICON_ARROW_RIGHT height='10' width='10' />
                    </span>
                </div>
            </div>
        </Popover>
    )
}

export default ModuleDropdown