
import React, { useEffect, useRef, useState } from "react";
import Buttons from "../../../../Common/button/Buttons";

import { Literals } from "../../../common/literals";


import { connect, useDispatch, useSelector, useStore } from "react-redux";
import { useParams } from "react-router-dom";


import {

  getParametersList,
  setSQlTransformerQuery,
  updateFlowsElements,
} from "../../../../../store/modules/flows/NewFlowsRedux/flowsActions";

import SqlEditor from "../../../dataCatalogue/preview/components/SqlEditor";

import { cloneDeep } from "lodash";
import { EXITFULLSCREEN, FULLSCREEN } from "../../../../Common/iconSource";
import { SCRIPTDEFAULTVIEW } from "../commonFunctions/ScriptDefaultInfography";
import { checkOutcomeColumnPresent, getInputTransformerDetails } from "../commonFunctions/FlowsHelperFunction";
import { getObjectLength } from "../../../common/helperFunctions";


const SQLIndex = ({
  flowsElements,
  dataSourcePodDetails,
  currentTransformer,
  isExecutePermission,
  updatedFlowRedux,
  setshowCloseWarning,
  setshowOverlay,
  setupdatedFlowRedux
}) => {
  const dispatch = useDispatch();
  const { flowId } = useParams();
  const [Query, setQuery] = useState("");
  const store = useStore();
  const [toggleDefaultView, setToggleDefaultView] = useState(false)
  const [expandTable, setexpandTable] = useState(false);
  const parametersList = useRef([])


  const [readOnly, setreadOnly] = useState(false);
  const sqlQuery = useSelector(
    (state) => state.Lab.sqlTransformerQuery
  );
  const [sqlTables, setsqlTables] = useState([]);


  useEffect(() => {
    let obj = { ...sqlQuery };
    setQuery(obj[currentTransformer.id]);
  }, [sqlQuery]);

  const setQueryvalue = (query) => {
    let finalFlowsElements = cloneDeep(flowsElements)
    let nodes = finalFlowsElements?.nodes.map((item) => {
      if (item.id === currentTransformer.id) {
        item['content']['editorQuery'] = query;
      }
      return item;
    });
    finalFlowsElements['nodes'] = nodes;
    setupdatedFlowRedux(finalFlowsElements);
    setshowCloseWarning(true)
    // store.dispatch(updateFlowsElements(finalFlowsElements));

  };


  const getParams = () => {
    store.dispatch(getParametersList(store?.getState().Lab?.id)).then((resp) => {
      if (resp?.data?.length) {
        let list = resp?.data;
        list?.forEach(element => {
          element['Name'] = element.name;
          element['value'] = element?.name;
          element['isDisableOption'] = false;
        });
        parametersList.current = list;

      }
    })
  }


  useEffect(() => {
    const getColumn = async () => {
      getParams();
      let newcurrentTransformer = {}
      if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

        newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
      } else {
        newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

      }

      if (
        newcurrentTransformer &&
        newcurrentTransformer.content &&
        newcurrentTransformer.content?.editorQuery
      ) {
        setQuery(newcurrentTransformer.content?.editorQuery)

      }

      let InputTransformer = store.dispatch(getInputTransformerDetails())

      if (InputTransformer && InputTransformer?.length) {

        let column = [];
        let inputColumns = [];
        let showOverlayflag = false

        for (let i = 0; i < InputTransformer.length; i++) {
          let ele = InputTransformer[i];
          inputColumns = await dispatch(checkOutcomeColumnPresent(ele));

          if (inputColumns?.length) {
            column = [
              ...column,
              ...CreateSqlTableDetails(
                inputColumns,
                ele.id,
                ele.type,
                ele.transformerName
              ),
            ];
          } else {
            showOverlayflag = true;

          }

        }
        // InputTransformer.forEach((ele) => {
        //   inputColumns = await dispatch(checkOutcomeColumnPresent(InputTransformer[0]));

        //   column = [
        //     ...column,
        //     ...CreateSqlTableDetails(
        //       ele?.content?.outcomeColumns,
        //       ele.id,
        //       ele.type,
        //       ele.transformerName
        //     ),
        //   ];
        // });

        if (showOverlayflag) {
          setshowOverlay(true)
        } else {
          setshowOverlay(false)

        }
        if (column?.length) {
          
          if(parametersList.current?.length){
            parametersList?.current.forEach((params)=>{
              column.push({
                name: `${params?.name}`,
                description: "Parameter",
                type: "Parameter",
                tableName: 'Parameter',
              });
            })
          }
      
          setsqlTables([...column]);
          setToggleDefaultView(false)



        }

      } else if (!InputTransformer?.length) {
        setToggleDefaultView(true);

      }
    }
    getColumn()

  }, []);

  const CreateSqlTableDetails = (
    columns = [],
    transformerId = "",
    transformerType,
    transformerName
  ) => {
    let sqlTable = [...sqlTables];


    let GlobalConnectionCache = localStorage.getItem("GlobalConnectionCache");
    if (!transformerType.includes("datasource")) {
      sqlTable.push({
        name: GlobalConnectionCache
          ? `${GlobalConnectionCache}.flow_${flowId}.${transformerName}`
          : `flow_${flowId}.${transformerName}`,
        description: "",
        type: "Table",
        tableName: transformerName,
      });
    } else {
      sqlTable.push({
        name: transformerName,
        description: "",
        type: "Table",
        tableName: "",
      });
    }

    
    if (columns && columns.length) {
      columns.forEach((element) => {
        if (
          transformerType.includes("datasource")
        ) {
          sqlTable.push({
            name: `${transformerName?.split('.')[1]}.${element.Name}`,
            description: "",
            type: "Column",
            tableName: transformerName,
          });
        } else {
          sqlTable.push({
            name: `${transformerName}.${element.Name}`,
            description: "",
            type: "Column",
            tableName: transformerName,
          });
        }
      });
    }
    if (sqlTable.length) {
      return sqlTable;
    } else {
      return [];
    }
  };

  return (
    <>

      <div className="transformer-details-maindiv">
        <>
          {!toggleDefaultView && (
            <div
              className={`${expandTable ? "full-screen-active" : "h-100"
                } bg-white flow-filter-height flow-sql-prop-edit`}
            >
              <div className="float-right padding-2 custom-border-bottom">

                {expandTable ? (
                  <Buttons
                    props={{
                      buttonText: "",
                      buttonClassName:
                        "custom-btn-outline custom-btn btn-with-icon",
                      buttonEvent: () => {
                        setexpandTable(false);
                      },
                      ImgSrc: () => <EXITFULLSCREEN />,
                      isDisable: false,
                      buttonType: Literals.BTN_TERTIARY,
                    }}
                  />
                ) : (
                  <Buttons
                    props={{
                      buttonText: "",
                      buttonClassName:
                        "custom-btn-outline custom-btn btn-with-icon",
                      buttonEvent: () => {
                        setexpandTable(true);
                      },
                      ImgSrc: () => <FULLSCREEN />,
                      isDisable: false,
                      buttonType: Literals.BTN_TERTIARY,
                    }}
                  />
                )}
              </div>

              <SqlEditor
                height={!expandTable ? 'calc(100vh - 8.25rem)' : 'calc(100vh - 3.25rem)'}
                readOnly={
                  !isExecutePermission ||
                    window.location.href.includes("viewFlows")
                    ? true
                    : false}
                sqlTableDetails={sqlTables}
                fromRoute="sql_transformer"
                getInstantQuery={setQueryvalue}
                disableDebounce={true}
                ExecuteQueryClick={() => { }} //executeQuery
                QueryValue={Query}
              />
            </div>
          )}
          {toggleDefaultView && (
            <div className="source-not-found">
              <SCRIPTDEFAULTVIEW />
            </div>
          )}
        </>
      </div>

    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.Lab.flowsElements,
    currentTransformer: state.Lab.currentTransformer,
    dataSourcePodDetails: state.Lab.dataSourcePodDetails,
  };
};

export default connect(mapStateToProps)(SQLIndex);