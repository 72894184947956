import {
  SET_SAVED_QUERY_SELECTED_INDEX,
  SET_PREVIEW_PAGE_DEFAULT,
  SET_PREVIEW_TABLE,
  SET_IS_API_ERROR,
  SET_ALL_DATA_LOADED,
  SET_ROW_COUNT,
  RESET_NEXT_PAGE_COUNTER,
  SET_NEXT_PAGE_COUNTER,
  RESET_TABLE_RECORDS,
  SET_PREVIEW_TABLE_ROWSET,
  RESET_NEXT_RECORD_TOKEN,
  SET_PREVIEW_TABLE_RECORDS,
  SET_PREVIEW_INITIAL_STATE,
  SET_PREVIEW_NEXT_URI,
  SET_PREVIEW_FILTERS,
  SET_PREVIEW_SORT,
  SET_PREVIEW_QUERY,
  SET_PREVIEW_FLAG,
  SET_PREVIEW_SEARCH_TERM,
  SET_CORELATION_GRAPH,
  SET_QUERY_ERROR,
  SET_PREVIEW_DATA_MAP,
  SET_PREVIEW_SEARCH_TEXT,
  SET_ANALYZE_MODAL_VISIBILITY,
  SET_SEARCH_MODE,
  SET_SAVE_SQL_TRIGGER,
  SET_CHATGPT

} from "./previewActiontypes";

const initialState = {
    previewPageData: {},
    tableRecords: [],
    next_uri: null,
    query: '',
    filters: [],
    sort: [],
    previewFlag: '',
    sqlEditorQuery: '',
    searchKeyword: '',
    coGraphData: [],
    queryErrorDetail: '',
    pageCounter: 0,
    isAllDataLoaded:false,
    rowCount:0,
    isApiError:false,
    savedQuerySelectedIndex: "",
    previewDataMap: {},
    searchMode: false,
    isChatgpt:false,
    previewSearchText: "",
    isAnalyzeModalVisible: false,
    saveSQlTrigger: false,

}

const PreviewPageReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        
        case SET_SAVE_SQL_TRIGGER:
            return { ...state, saveSQlTrigger: {...payload} }

        case SET_IS_API_ERROR:
            return { ...state,isApiError:payload }
           
        case SET_ROW_COUNT:

            return { ...state,rowCount:payload }
           
        case SET_NEXT_PAGE_COUNTER:

            return { ...state, pageCounter:state.pageCounter + 1 }
          
        case SET_CHATGPT:
            return {...state, isChatgpt:payload}

        case RESET_NEXT_PAGE_COUNTER:
            return {...state,pageCounter:0}
           
        case SET_ALL_DATA_LOADED:
            return {...state,isAllDataLoaded:payload}

        case RESET_NEXT_RECORD_TOKEN:
            let temp = { ...state.previewPageData };
            temp['nexturi'] = ''
            return { ...state, previewPageData: { ...temp } }

        case SET_PREVIEW_TABLE:
            return {
                ...state,
                previewPageData: { ...payload },
            };
        case SET_PREVIEW_TABLE_ROWSET:
            return {
                ...state,
                previewPageData: { ...payload },
            };
        case SET_PREVIEW_TABLE_RECORDS:
            return {
                ...state,
                tableRecords: [...state.tableRecords, ...payload],
            };

        case SET_PREVIEW_DATA_MAP: 
            return {
                ...state,
                previewDataMap: action.payload
            }
           
        case SET_PREVIEW_INITIAL_STATE:

            return initialState

           
        case SET_PREVIEW_NEXT_URI:
            return {
                ...state,
                next_uri: payload.next_uri,
                query: payload.query
            };
           
        case SET_PREVIEW_FILTERS:
            return {
                ...state,
                filters: payload && payload.length
                    ? [...state.filters, ...payload] : []
            };
           
        case SET_PREVIEW_SORT:
            return {
                ...state,
                sort: payload && payload.length
                    ? [...payload] : []
            };
           
        case SET_PREVIEW_QUERY:
            return {
                ...state,
                sqlEditorQuery: payload
            };
           
        case SET_PREVIEW_SEARCH_TERM:
            return {
                ...state,
                searchKeyword: payload
            };
           
        case SET_PREVIEW_FLAG:
            return {
                ...state,
                previewFlag: payload
            };
           
        case SET_CORELATION_GRAPH:
            return {
                ...state,
                coGraphData: [...payload]
            };
           
        case SET_QUERY_ERROR:
            return {
                ...state,
                queryErrorDetail: payload
            };
           
        case SET_SAVED_QUERY_SELECTED_INDEX:
            return {
                ...state,
                savedQuerySelectedIndex: payload
            };
        case RESET_TABLE_RECORDS:
            return {
                ...state,
                previewPageData: {},
                tableRecords: []
            };
           
        case SET_PREVIEW_PAGE_DEFAULT:
            return initialState;
          
        case SET_PREVIEW_SEARCH_TEXT: 
            return {
                ...state,
                previewSearchText: action.payload
            }
        case SET_ANALYZE_MODAL_VISIBILITY:
            return {...state, isAnalyzeModalVisible: action.payload}
            case SET_SEARCH_MODE:
              return { ...state, searchMode: payload };
        default:
            return state;
    }
}

export default PreviewPageReducer;
