import React from "react";
import { setLoadingStatus } from "../common/loadingActions";
import { fetchData } from "../../../services/apiService";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../../helpers/toast_helper";
const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const getLineageData = (pk, model, flag, connection) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(
          "GET",
          `${BASE_URL}lineage/?model=${model}&pk=${pk}${
            model == "dashboard" ? "" : `&flag=${flag}`
          }${model == "source" ? `&connection_name=${connection}` : ""}`,
          {}
        )
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status !== "success") {
            emitToastNotification("error", resp.message);
            reject(resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

          emitToastNotification("error", err?.message);
          reject(err);
        });
    });
  };
};
