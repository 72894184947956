import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'
import { setFilteringColumns, setHidingColumns, setSortingColumns } from '../../../../../store/modules/common/DataGridTable/dataGridTableRedux';
import { getSavedQueryList, setPreviewTableQuery } from '../../../../../store/modules/dataCatalogue/preview/previewActions';
import DataGridTable from '../../../../Common/dataGridTable/DataGridTable';
import EditorContainer from './EditorContainer';
import SavedQueryTable from './SavedQueryTable';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';
import { ErrorComponent, QueryKillError, getReloadErrorTemplate, getTableSkeleton } from '../../../common/helperFunctions';
import { v4 as uuidv4 } from "uuid";

function SplitPaneView({
    resp,
    resultResp,
    isEnableAnalyze = false,
    isDisableHideBtn,
    isPreviewPage,
    resetAppliedFilters,
    isSplitPane,
    setshowLoader,
    CurrentPODName,
    CurrentPODID,
    CurrentDomainName,
    expandTable,
    scroll,
    resetQuery,
    showLoader,
    sqlTableDetails,
    ExecuteQueryClick,
    setSplitview,
    // uniqUUID,
    // setUniqUUID = () => { },
    isPreviewDataAlreadyLoading,
    setPreviewAlreadyLoading,
    isLoadingMore = () => { },
    isDeletePermission,
    isEditPermission,
    isViewPermission,
    isReadyDataGrid,
    queryModalDetail, // to reset modal info,
    activeTab = '',
    isUnstructured = false,
    connectionName,
    // setStoredData = () => {},
    // getLatestData = () => {},
    // storedFilter = {},
    // parentPageNo,
    // downloadQuery,

    resultRecPageNo,
    resultRecStoredFilter,
    resultRecStoredData,
    resultRecUUID,
    setResultAllRecUUID,
    resultRecDownloadQuery,
    resultRecGetData,
    isAllRecGridReady,
    isResultGridReady,
    originalChildren,
    parentSplitSize,
    resetResultTab = () => {}
}) {
    const [, setCurrentMenu] = useState('');
    const [Query, setQuery] = useState('');
    const [savedQueryResponse, setsavedQueryResponse] = useState({})
    const podDetails = useSelector((state) => state.DataCatalogue.Dictionary.dictionaryPodData);
    const dispatch = useDispatch();
    const [tableId, settableId] = useState();
    const errorQ = useSelector(state => state.DataCatalogue.PreviewPage.queryErrorDetail);
    const storedQuery = useSelector(state => state.DataCatalogue.PreviewPage.sqlEditorQuery);
    const [, seterrorDetails] = useState('');
    const [editorHeight, setEditorHeight] = useState();

    const queryEditorRef = useRef();
    const queryHeaderRef = useRef();
    const [isEditingQuery, setIsEditingQuery] = useState(false)
    const [selectedQuery, setSelectedQuery] = useState({})
    const [isEditorVisible, setIsEditorVisible] = useState(false)
    const [isAllRecordsReady, setIsAllRecordsReady] = useState(false)
    const [isResultReady, setIsResultReady] = useState(false)
    const [queryObj, setQueryObj] = useState(null)

    const [, setTableHeight] = useState(400)
    let isQueryKill = useSelector((state => state.CommonComponent?.DataGridTable?.isQueryKill))


    useEffect(() => {
        dispatch(setSortingColumns([]))
        dispatch(setFilteringColumns([]))
        dispatch(setHidingColumns([]))
        setIsAllRecordsReady(true)
        setIsResultReady(true)
    }, [])

    useEffect(() => {
        if(parentSplitSize)
        setSizes(parentSplitSize)
    }, [parentSplitSize])

    useEffect(() => {
        seterrorDetails(errorQ);
        if (errorQ === '') {
            setCurrentMenu('editor');
        } else {
            setCurrentMenu('errors')
        }
    }, [errorQ])

    useEffect(() => {
        if (resetQuery) {
            setQuery('')
        }
    }, [resetQuery])

    useEffect(() => {
        if (podDetails?.length > 0) {
            settableId((podDetails.table_id).toString())
        }

        if(podDetails?.table_id) {
            getSavedQueries()
        }
    }, [podDetails])

    React.useEffect(() => {
        setSelectedQuery(queryModalDetail)
    }, [queryModalDetail])

    const getSavedQueries = () => {
        try {
            dispatch(getSavedQueryList(podDetails?.table_id)).then((response) => {

                if (response.status === 'success') {
                    if (response?.data?.length === 0) {
                        setsavedQueryResponse(null)
                        resetAppliedFilters("addReport")
                    }
                    else {
                        setsavedQueryResponse([...response.data])
                    }
                }
            }).catch(e => console.error(e))
        } catch (error) {
            setsavedQueryResponse('error')
        }
    }

    const setQueryvalue = (query) => {

        // if (isSavedQuery && Query === '') {
        //     setQuery(query);
        //     dispatch(setPreviewTableQuery(query))
        // }
        if (query) {
            setQuery(query);
            dispatch(setPreviewTableQuery(query))

        }
    }

    useEffect(() => {
        if (storedQuery !== '') {
            setQuery(storedQuery)
        }
        else {
            setQuery("")
        }
    }, [storedQuery])

    const openEditorHandler = (iseditQueryClick=false) => {
        let element = document.getElementsByClassName('react-split__pane');
        if (element.length > 1) {
            setEditorHeight(element[1]?.clientHeight)
        }
        if (!iseditQueryClick) {

            resetAppliedFilters('addReport')
        }
        setIsEditorVisible(true);
    }

    const closeEditorHandler = () => {
        resetAppliedFilters('addReport')
        setIsEditorVisible(false);
    }


    const onEditQueryHandler = (queryData) => {
        setIsEditingQuery(true);
        setSelectedQuery(queryData);

        dispatch(setPreviewTableQuery(queryData.queryString));
        openEditorHandler(true);
    }

    const resultTabQuery = () => {
        if(queryObj) {
            return queryObj?.selected ?? ''
        }
        return Query
    }

    const resizeFunction = (size) => {
        setEditorHeight(queryEditorRef.offsetHeight - queryHeaderRef.offsetHeight);
        setTableHeight(size - 80);
    }
    const [sizes, setSizes] = useState(
        (() => {
            if(parentSplitSize)
                return parentSplitSize
            return ["55%", '45%']
        })()
    );

    const layoutCSS = {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };
    
    return (
        <div style={{ height: "100%" }}>
        {/* <div style={{ height: "80vh" }}> */}
            <SplitPane
                split='horizontal'
                sizes={sizes}
                onChange={(size) => { setSizes(size); setEditorHeight(size[1] - 50) }}
            >
                {/* <Pane minSize={50} maxSize='50%'> */}
                <div style={{ ...layoutCSS }}>
                    <div style={{ height: '100%', display: activeTab === '0' ? 'block' : 'none' }} className="custom-virtual-data-table w-100">
                        {originalChildren}
                    </div>
                    <div style={{ height: '100%', display: activeTab === '1' ? 'block' : 'none' }} className="custom-virtual-data-table w-100">
                        {
                        // isQueryKill ? <QueryKillError /> :
                            isResultReady && isResultGridReady ?
                            resultResp?.isError
                                    ? getReloadErrorTemplate({
                                        errorMessage: resultResp?.error, hideReloadBtn: true, onReload: () => ExecuteQueryClick(resultResp?.query)
                                    })
                                    :
                                    <DataGridTable
                                        key={
                                            `preview-grid-1`
                                            // activeTab === '0'
                                            // ? activeTab + '-' + uniqUUID
                                            // : undefined
                                        }
                                        resp={resultResp}
                                        CurrentPODName={CurrentPODName}
                                        requestedTableId={CurrentPODID}
                                        CurrentDomainName={CurrentDomainName}
                                        expandTable={expandTable}
                                        isSplitPane={true}
                                        setshowLoader={setshowLoader}
                                        isEnableAnalyze={isEnableAnalyze}
                                        isDisableHideBtn={isDisableHideBtn}
                                        isPreviewPage={isPreviewPage}
                                        uniqUUID={resultRecUUID}
                                        setUniqUUID={(uid) => setResultAllRecUUID(uid)}
                                        isPreviewDataAlreadyLoading={true}
                                        // isPreviewDataAlreadyLoading={isPreviewDataAlreadyLoading}
                                        setPreviewAlreadyLoading={setPreviewAlreadyLoading}
                                        isLoadingMore={isLoadingMore}
                                        isCustomSql={true}
                                        parentQuery={resultTabQuery()}
                                        connectionName={connectionName}
                                        isUnstructured={isUnstructured}
                                        // getLatestData={resultRecGetData}
                                        storedFilter={resultRecStoredFilter}
                                        parentPageNo={resultRecPageNo}
                                        downloadQuery={resultRecDownloadQuery}
                                    /> :
                                // getTableSkeleton(15) 
                                <ClaristaLoader isCancel={true} />
                        }
                    </div>
                </div>
                {/* </Pane> */}
                <Pane
                    maxSize={'70%'}
                    minSize={'30%'}
                >
                    <div style={{ ...layoutCSS }}>
                        <div className="spits-pane-inside-wrapper w-100">
                            <div className="sql-white-header "></div>
                            <div className="query-records-editor-wrapper" ref={queryEditorRef}>
                                {isEditorVisible ?
                                    <EditorContainer
                                        ref={queryHeaderRef}
                                        showLoader={showLoader}
                                        savedQueryResponse={savedQueryResponse}
                                        tableId={podDetails?.table_id}
                                        isEditingQuery={isEditingQuery}
                                        setIsEditingQuery={setIsEditingQuery}
                                        sqlTableDetails={sqlTableDetails}
                                        selectedQuery={selectedQuery}
                                        setQueryvalue={setQueryvalue}
                                        setQueryObj={setQueryObj}
                                        Query={Query}
                                        height={editorHeight}
                                        ExecuteQueryClick={ExecuteQueryClick}
                                        closeEditor={closeEditorHandler}
                                        getSavedQueries={getSavedQueries}
                                        setIsEditorVisible={setIsEditorVisible}
                                    />
                                    :
                                    <SavedQueryTable
                                        ref={queryHeaderRef}
                                        openEditor={openEditorHandler}
                                        setSplitview={setSplitview}
                                        table_id={tableId}
                                        setQueryvalue={setQueryvalue}
                                        savedQueryResponse={savedQueryResponse}
                                        getSavedQueries={getSavedQueries}
                                        onEdit={onEditQueryHandler}
                                        ExecuteQueryClick={ExecuteQueryClick}
                                        resetResultTab={resetResultTab}
                                        isDeletePermission={isDeletePermission}
                                        isEditPermission={isEditPermission}
                                        isViewPermission={isViewPermission}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </Pane>
            </SplitPane>
        </div>
    );
}

export default SplitPaneView





