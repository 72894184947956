import React, { useRef, useState } from 'react'
import DataDictionaryDetailedPage from './DataDictionaryDetailedPage'
import LandingpageFirstHeader from "../../common/LandingpageFirstHeader";
import { CATALOGUE, ANGLERIGHT, CLOSE, EDIT, CHECK, CHANGELOG } from '../../../Common/iconSource';
import { getColumnDataType, getConfirmationModal, permissions } from '../../common/helperFunctions';
import Buttons from '../../../Common/button/Buttons';
import { Literals } from '../../common/literals';
import { connect } from 'react-redux';
import LoadingComponent from '../../common/loadingComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICON_BAG, ICON_CATALOG, ICON_CATALOG_DOMAIN, ICON_SAVE } from '../../../Common/newIconSource';

const ViewDataDictionaryIndex = (props) => {

    const history = useNavigate()
    const dictionaryRef = useRef()
    const [isEdit, setIsEdit] = useState(false)
    const [showLogs, setShowLogs] = useState(false)
    const [dictionaryDetails, setDictionaryDetails] = useState({})
    const [openResetModal, setResetModal] = useState(false)
    const searchParams = new URLSearchParams(document.location.search)
    const history_id = searchParams.get("history_id");

    const location = useLocation()
    const activeDomain = location?.state?.domain ?? null
    
    const isEditor = React.useMemo(() => {
        return !!dictionaryDetails?.permission?.find(p => p === permissions.dataDictionary.editor)
    }, [dictionaryDetails])
    
    return (
        <>
            {props.loadingStatus ? <LoadingComponent /> : null}
            <LandingpageFirstHeader
                title={"Data Dictionary"}
                flag={false}
                backRoute={Literals.links.DATA_DICTIONARY}
            />
            <div className='d-flex justify-content-between padding-2 py-1 dict-detail-header shadow-sm' style={{ borderBottom: 'solid 1px #ebebeb' }}>
                <div className='d-flex align-items-center'>
                    <span>
                        <span className=''>
                            <ICON_CATALOG_DOMAIN color='#0A3053' />
                            {/* <ICON_BAG color='#0A3053' /> */}
                        </span>
                        <span className='fontInterSemiBold dict-color-dark'>Dictionary</span>
                        <span className='fontInterSemiBold dict-color-dark mx-2'>/</span>
                    </span>
                    <span>
                        {/* <span className='mr-1'>{getColumnDataType('integer')}</span> */}
                        <span className=''>{dictionaryDetails?.business_term?.toUpperCase() ?? '...'}</span>
                    </span>
                </div>
                {history_id ? "" : <div className='d-flex'>
                    <Buttons
                        props={{
                            buttonText: isEdit ? "Save" : "Edit",
                            buttonClassName:
                                "custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: () => {
                                if (isEdit) {
                                    dictionaryRef.current.handleSave()
                                    // setIsEdit(false)
                                }
                                else setIsEdit(true)
                            },
                            tooltip: isEdit ? "Save" : "Edit",
                            ImgSrc: () => isEdit ? <ICON_SAVE /> : <EDIT />,
                            isDisable: !isEditor,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                    {/* <Buttons
                        props={{
                            tooltip: `${showLogs ? 'Hide Change Logs' : 'Show Change Logs'}`,
                            buttonText: "",
                            buttonClassName: `${showLogs ? 'rdg-filter-active' : ''} custom-btn-outline custom-btn btn-with-icon ml-2`,
                            buttonEvent: () => {
                                setShowLogs(!showLogs)
                            },
                            ImgSrc: () => <CHANGELOG />,
                            isDisable: false,
                            buttonType: Literals.BTN_TERTIARY,
                        }}
                    /> */}
                    <Buttons
                        props={{
                            buttonText: "",
                            buttonClassName:
                                "custom-btn-outline mr-2  ml-2 custom-btn btn-with-icon",
                            buttonEvent: () => {
                                if (isEdit) {
                                    setResetModal(true)
                                }
                                else {
                                    history(Literals.links.CLARISTA_NAVIGATOR + '#business', { state: { domain: activeDomain, fromDict: true } })
                                    // history(Literals.links.DATA_DICTIONARY)
                                }
                            },
                            tooltip: isEdit ? "Close Edit Mode" : "Close",
                            ImgSrc: () => <CLOSE />,
                            isDisable: false,
                            buttonType: Literals.BTN_TERTIARY,
                        }}
                    />
                    {/* <Buttons
                        props={{
                            buttonText: "Edit",
                            buttonClassName:
                                "custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: (e) => {
                                
                            },
                            tooltip: "Edit",
                            ImgSrc: () =>  <EDIT />,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    /> */}
                </div>}
            </div>
            <DataDictionaryDetailedPage
                ref={dictionaryRef}
                isEdit={!isEdit}
                setIsEdit={setIsEdit}
                setDictionaryDetails={setDictionaryDetails}
                showChangeLogs={showLogs}
                setShowChangeLogs={setShowLogs}
                activeDomain={activeDomain}
            />
            {
                getConfirmationModal({
                    body: 'Unsaved changes will be lost. Are you sure?',
                    open: openResetModal,
                    setOpen: setResetModal,
                    onConfirm: () => {
                        dictionaryRef?.current?.reset()
                        setIsEdit(false)
                    }
                })
            }
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loadingStatus: state.LoadingReducer.loadingStatus,
    };
};
export default connect(mapStateToProps)(ViewDataDictionaryIndex);