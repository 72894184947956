
import { DatePicker, Drawer, List, Popover, Tooltip } from "antd";
import React, { useEffect, useState } from 'react';
import { useStore } from "react-redux";
import { ALERTBELLLARGE, CHECK, CLOCK, CLOSE, CRAWLER, DATASOURCE, EDITOR2, FILTER, FILTERFLOW, FLOWS, FLOWTABICON, MERGEICON, PREVIEW, PYTHONSCRIPTICON, REDIRECTJOBS, REFRESH, RESETFILTER, SCHEDULER, SCRIPTOUTPUT, SQLTRANSFORMERNEWBLUE, TARGETICON, USER } from "../../../Common/iconSource";
import { LandingSimpleTable } from "../../../Common/landingSimpleTable/LandingSimpleTable";
import { convertSecondToHHMMSS, ErrorComponent, getISODateElapseTime, getMonthName, getTime, NoDataComponent, queryStatus, showSimpleLoading } from "../../common/helperFunctions";
import { Literals } from "../../common/literals";

import { cloneDeep } from 'lodash';
import moment from "moment";
import { useParams } from 'react-router-dom';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { getSummaryTimelineV2, killQuery } from '../../../../store/actions';
import { getSchedulerJobsList } from '../../../../store/modules/jobsUsage/jobsAction';
import { getSchedulerLandingPage, GetSchedulerLastRun, setSelectedScheduler } from '../../../../store/modules/scheduler/action';
import Buttons from '../../../Common/button/Buttons';
import SingleSelection from '../../../Common/dropDown/SingleSelection';
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton';
import '../../usageAndJobs/components/usageLogs.scss';
import { ICON_EVENT } from "../../../Common/newIconSource";

export const LastRun = ({ isFromJobs = false, showRefresh, showFilters = false, refreshButtonClick = false, executionId = '', setRefreshButtonClick = () => { }, isRefreshing = false, setRefreshing = () => { } }) => {




    const store = useStore();
    const { RangePicker } = DatePicker;
    const { schedulerId } = useParams();
    const [timelineData, setTimelineData] = useState([])
    const [noDataFound, setNoDataFound] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [stopLazyload, setStopLazyload] = useState(false)
    const [logDetails, setLogDetails] = useState({})
    const [pageNo, setPageNo] = useState(1)
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const [datePickerValue, setDatePickerValue] = useState([]);
    const [value, setValue] = useState(null);

    const defaultSelectValues = { scheduler: 'Select Scheduler', status: 'Select Status' }
    const [schedulerSelectValue, setSchedulerSelectValue] = useState(defaultSelectValues.scheduler);
    const [statusSelectValue, setStatusSelectValue] = useState(defaultSelectValues.status);
    const [schedulersList, setSchedulersList] = useState([]);
    const [dataCount, setDataCount] = useState();
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');

    const statusList = Object.keys(queryStatus)?.slice(0, 3).map(status => ({ Name: queryStatus[status], Value: queryStatus[status] }));
    const filterObj = { scheduler_id: '', status: '', start_date_time: '', end_date_time: '' }
    const [filtersToSend, setFiltersToSend] = useState({ ...filterObj })

    const [showFilterPop, setFilterPop] = useState(false);
    const [openErrorModal, setopenErrorModal] = useState(false)
    const [transformerErrorMsg, settransformerErrorMsg] = useState('')
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        if (dataCount === 0) {
            setNoDataFound(true)
        }
        else {
            setNoDataFound(false)
        }
    }, [dataCount])

    useEffect(() => {

        if (refreshButtonClick) {
            refreshSummaryTimelineData()
            setRefreshButtonClick(false)
        }
    }, [refreshButtonClick])

    // useEffect(() => {
    //     // setTimeout(() => {
    //     //   el = el.scrollHeight;

    //     setTimeout(() => {
    //         let el = document.querySelector('.custom-simple-landing-dgt')
    //         if (el) el.scrollTop = 0;
    //     }, 100);
    //     // }, 100)
    // }, [timelineData])

    const getSummaryTimelineData = async () => {
        let payload = {
            limit: 50,
            page: pageNo,

        };

        let response;
        try {
            if (isFromJobs) {
                payload['executionId'] = ''
                let filterString = `job/schedule/?page=${payload.page}&limit=${payload.limit}&execution_id=${payload.executionId}`;
                Object.keys(filtersToSend).forEach(key => {
                    if (filtersToSend[key])
                        filterString += `&${key}=${filtersToSend[key]}`
                })
                response = await store.dispatch(getSchedulerJobsList(filterString));
            }
            else {
                response = await store.dispatch(GetSchedulerLastRun(schedulerId, payload.page, payload.limit, executionId));
            }
            if (response.status === "success" && !response?.data?.executions?.length && pageNo === 1) {

                setDataCount(0)

            }
            if (response.status === "success" && response.message !== "Invalid Page Requested") {
                setErrMsg('')

                createLogsData(response?.data?.executions, false)
                setLoadMore(false)
                setPageNo(prev => prev + 1)
                setDataCount(response?.data?.count)
                setRefreshing(false)
            }
            if (response.status === "success" && response.message === "Invalid Page Requested") {
                emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)
                setErrMsg('')

                setStopLazyload(true)
                setLoadMore(false)
            }
        } catch (error) {
            setErrMsg(error.message)

            setRefreshing(false);
            setLoadMore(false);
        }
    };

    const refreshSummaryTimelineData = async () => {
        setDataCount()

        resetAllFilter(true)
        setNoDataFound(false)
        setFiltersToSend({ ...filterObj })
        setSchedulerSelectValue(defaultSelectValues.scheduler)
        setStatusSelectValue(defaultSelectValues.status)
        setFilterStartDate('')
        setFilterEndDate('')
        setTimelineData([])
        setRefreshing(true)
        setPageNo(1)
        let payload = {

            page: 1,
            limit: 50,

        };

        let response;
        try {
            if (isFromJobs) {
                payload['executionId'] = ''
                let filterString = `job/schedule/?page=${payload.page}&limit=${payload.limit}&execution_id=${payload.executionId}`;
                response = await store.dispatch(getSchedulerJobsList(filterString));
            }
            else {
                response = await store.dispatch(GetSchedulerLastRun(schedulerId, payload.page, payload.limit, executionId));
            }
            if (response.status === "success") {
                setErrMsg('')
                createLogsData(response?.data?.executions, true)
                setLoadMore(false)
                setDataCount(response?.data?.count)
                setPageNo(prev => prev + 1)
                setStopLazyload(false)
                setRefreshing(false)
            }
            if (response.status === "success" && response.message === "Invalid Page Requested") {
                emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)
                setErrMsg('')

                setLoadMore(false)
                setDataCount(response?.data?.count)
                setRefreshing(false)
            }
        } catch (error) {
            setErrMsg(error.message)

            setRefreshing(false);
            setLoadMore(false);
            emitToastNotification('error', Literals.UNABLE_TO_REFRESH_USAGE)

        }
    };

    const applyFilter = async () => {
        setDataCount()
        setNoDataFound(false)
        setTimelineData([])
        setRefreshing(true)
        setPageNo(1)
        let response;
        try {
            if (isFromJobs) {
                let payload = {
                    limit: 50,
                    executionId: executionId,
                    page: 1
                }
                let filterString = `job/schedule/?page=${payload.page}&limit=${payload.limit}&execution_id=${payload.executionId}`;
                Object.keys(filtersToSend).forEach(key => {
                    if (filtersToSend[key])
                        filterString += `&${key}=${filtersToSend[key]}`
                })
                response = await store.dispatch(getSchedulerJobsList(filterString));
            }
            else {
                let payload = {
                    action: "Run Query",
                    table_id: schedulerId,
                    page: 1,
                    start_date: filtersToSend.start_date_time,
                    end_date: filtersToSend.end_date_time,
                };
                response = await store.dispatch(getSummaryTimelineV2({ payload }));
            }
            if (response.status === "success" && response.message !== "Invalid Page Requested" && response.message !== "No results found") {
                createLogsData(
                    isFromJobs
                        ?
                        response?.data?.executions
                        :
                        response?.data?.timeline,
                    true
                )
                setLoadMore(false)
                setPageNo(prev => prev + 1)
                setDataCount(response?.data?.count)
                setStopLazyload(false)
                setRefreshing(false)
            }
            else if (response.status === "success" && response.message === "Invalid Page Requested") {
                emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)

                setDataCount(response?.data?.count)
                setLoadMore(false)
                setRefreshing(false)
            }
            else if (response.status === "success" && response.message === "No results found") {
                setDataCount(0)
                setLoadMore(false)
                setRefreshing(false)
            }
        } catch (error) {

            setRefreshing(false);
            setLoadMore(false);
            emitToastNotification('error', Literals.UNABLE_TO_REFRESH_USAGE)

        }
    };

    const killProcessingQuery = async (query_id) => {
        let payload = { query_id: query_id }
        let resp = await store.dispatch(killQuery({ payload }))

        if (resp.status === "success") {
            refreshSummaryTimelineData()
        }
        else {
            emitToastNotification('error', "Can't kill this query, please refresh logs.")
        }
    }


    const createLogsData = (data, isRefreshing) => {
        let dataList = [...data]
        let originalData = cloneDeep(data)

        dataList !== undefined && dataList?.length > 0 && dataList.forEach((item, index) => {
            dataList[index]['originalState'] = item?.status
            dataList[index]['id'] = item?.id


            dataList[index]['state'] = <label className={`mb-0 text-capitalize 
            ${item?.status === queryStatus.finished || item?.status === queryStatus.success || item?.status === queryStatus.done ? 'text-success'
                    : item?.status === queryStatus.running || item?.status?.toLocaleLowerCase() === 'starting' ? 'text-primary'
                        : item?.status === queryStatus.queued ? 'text-warning'
                            : item?.status === queryStatus.failed || item?.status === queryStatus.error ? 'text-danger'
                                : 'text-black-50'} `}>

                <i className={`mr-1 bx bx-${item?.status === queryStatus.finished || item?.status === queryStatus.success || item?.status === queryStatus.done ? 'check-circle'
                    : item?.status === queryStatus.running || item?.status?.toLocaleLowerCase() === 'starting' ? 'run'
                        : item?.status === queryStatus.queued ? 'dots-horizontal-rounded'
                            : item?.status === queryStatus.failed || item?.status === queryStatus.error ? 'x-circle'
                                : 'text-black-50'
                    }
                `}></i>

                {item?.status && item?.status.length > 0
                    ? item?.status.toLowerCase()
                    : 'No Status'}
            </label>

            dataList[index]['start_date_time'] = <div className="align-items-center d-flex h-100">
                <div>
                    <label className="d-block label text-dark" style={{ lineHeight: 1, marginBottom: 3 }}>
                        <i className="mr-1 bx bxs-calendar-alt"></i>
                        {item?.start_date_time === null ? '' : new Date(item?.start_date_time).getUTCDate()}
                        {item?.start_date_time === null ? '' : ' ' + getMonthName.short[new Date(item?.start_date_time).getUTCMonth()]}
                        {item?.start_date_time === null ? '' : ' ' + new Date(item?.start_date_time).getUTCFullYear()}
                    </label>
                    <p className="small text-black-50 mb-0" style={{ lineHeight: 1 }}>
                        <i className="mr-1 bx bx-time"></i>{item?.start_date_time === null ? '00:00:00' : getTime(item?.start_date_time)}
                    </p>
                </div>
            </div>

            dataList[index]['scheduler_name'] = <div className="align-items-center d-flex h-100">
                <Tooltip title={item?.scheduler_detail?.name}>
                    <span className='primary-svg-color'>{
                        item?.scheduler_detail?.type?.toLocaleLowerCase() === 'time'
                            ? <CLOCK height='18' width='18' />
                            : item?.scheduler_detail?.type?.toLocaleLowerCase() === 'partition'
                                ? <CRAWLER height='18' width='18' />
                                : <ICON_EVENT height='18' width='18' />
                    }
                    </span>   {item?.scheduler_detail?.name?.toUpperCase()}</Tooltip>
            </div>

            dataList[index]['executed_by'] = <div className="align-items-center d-flex h-100">
                <Tooltip title={item?.executed_by_user?.email}>
                    <span ><USER height="10" width="10" ></USER>
                    </span>   {item?.executed_by_user?.email}</Tooltip>
            </div>

            dataList[index]['elapsed_time_sec'] = <p key={convertSecondToHHMMSS(item.elapsed_time_sec)} className="small text-black mb-0" >
                <Tooltip title={item?.elapsed_time_sec === null ? '00:00:00' : `${convertSecondToHHMMSS(item.elapsed_time_sec)}`} >

                    <i className="mr-1 bx bx-time"></i>{item?.elapsed_time_sec === null ? '00:00:00' : `${convertSecondToHHMMSS(item.elapsed_time_sec)}`

                    }</Tooltip>
            </p>
            // <p className="small text-black mb-0" >
            //     <Tooltip title={item?.end_date_time === null ? getISODateElapseTime(new Date(originalData[index]['start_date_time']).getTime(), new Date().getTime()) : getISODateElapseTime(new Date(originalData[index]['start_date_time']).getTime(), new Date(item?.end_date_time).getTime())} >  <i className="mr-1 bx bx-time"></i>{item?.end_date_time === null ? getISODateElapseTime(new Date(originalData[index]['start_date_time']).getTime(), new Date().getTime()) : getISODateElapseTime(new Date(originalData[index]['start_date_time']).getTime(), new Date(item?.end_date_time).getTime())}</Tooltip>
            // </p>

            dataList[index]['action_btn'] =
                <div className="d-flex align-items-center justify-content-center h-100">
                    <Tooltip placement="bottom" title="View Scheduler">
                        <button className="custom-btn-outline  bg-transparent  custom-btn btn-with-icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.open(`scheduler/view/${item?.scheduler_detail?.id}?schedulerType=${item?.scheduler_detail?.type?.toLocaleLowerCase() === 'partition' ? 'alertflows' : 'crawler'} `)
                            }
                            }
                        >
                            <REDIRECTJOBS />
                        </button>
                    </Tooltip>

                </div>



        })
        if (!isRefreshing) {
            setTimelineData([...timelineData, ...dataList])
        }
        else {
            setTimelineData([...dataList])
        }
    }


    useEffect(() => {
        getSummaryTimelineData()
        getSchedulersList()
    }, [])

    const getSchedulersList = async () => {
        let temp = []
        let resp = await store.dispatch(getSchedulerLandingPage('', 'mode=minimal'))
        if (resp.status === 'success') {
            let list = resp?.data
            list.forEach((ele) => {
                temp.push({ Name: ele?.name, value: ele?.id })
            })
            setSchedulersList(temp)
        }
    }

    let columns = [
        {
            name: (
                <div>
                    Id

                </div>
            ),
            key: "id",
            width: "15%",

            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div>
                    Scheduler Name
                    {filtersToSend.name !== defaultSelectValues.scheduler && filtersToSend.name !== ''
                        ? <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "scheduler_name",
            width: "30%",

            sortable: false,
            resizable: true,

        },
        {
            name: (
                <div>
                    Start Time
                    {(filtersToSend.start_date_time !== '' || filtersToSend.start_date_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "start_date_time",
            sortable: false,
            resizable: false,
            width: "20%",


        },


        {
            name: (
                <div>
                    State
                    {(filtersToSend.status !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "state",
            sortable: false,
            resizable: false,
            width: "10%",


        },
        {
            name: (
                <div>
                    Elapsed Time
                    {(filtersToSend.start_date_time !== '' || filtersToSend.start_date_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "elapsed_time_sec",
            sortable: false,
            resizable: false,
            width: "10%",


        },
        {
            name: (
                <div className='text-center'>
                    Details
                </div>
            ),
            key: "action_btn",
            sortable: false,
            resizable: false,
            width: "20%",

        }

    ];

    let leastColumns = [
        {
            name: (
                <div>
                    Scheduler Name
                    {filtersToSend.name !== defaultSelectValues.scheduler && filtersToSend.name !== ''
                        ? <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "scheduler_name",
            sortable: false,
            resizable: false,


        },
        {
            name: (
                <div>
                    Start Time
                    {(filtersToSend.start_date_time !== '' || filtersToSend.start_date_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "start_date_time",
            sortable: false,
            resizable: false,

        },


        {
            name: (
                <div>
                    State
                    {(filtersToSend.status !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "state",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div>
                    Elapsed Time
                    {(filtersToSend.start_date_time !== '' || filtersToSend.start_date_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "elapsed_time_sec",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div>
                    Executed By
                    {(filtersToSend.start_date_time !== '' || filtersToSend.start_date_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "executed_by",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div className='text-center'>
                    Details
                </div>
            ),
            key: "action_btn",
            sortable: false,
            resizable: false,
            width: "20%",

        }

    ];

    const RowSelection = (row) => {

        setLogDetails(row)

        setOpenModal(true)


    }

    if (window.location.href.includes('/scheduler/view') || window.location.href.includes('/scheduler/edit')) {
        columns = columns.filter((item) => item.key !== "action_btn");
        leastColumns = leastColumns.filter((item) => item.key !== "action_btn");
    }


    useEffect(() => {
        if (!openModal) {
            setLogDetails({})
        }
    }, [openModal])

    const onScrollToBottom = (isAtBottom) => {
        if (!stopLazyload) {
            if (isAtBottom && !loadMore) {
                setLoadMore(true)
                getSummaryTimelineData()
            }
        }
    }

    const onCalendarChange = (dates, dateString, info) => {
        const currentDate = new Date();
        if (dateString[0] && dateString[1]) {
            let startDate = dateString[0];
            let endDate = dateString[1];

            if ((new Date(startDate) > currentDate || new Date(endDate) > currentDate)) {
                emitToastNotification('warning', "you cannot select date greater than current date")

                return;
            }
            let temp = [moment(startDate, "YYYY-MM-DD"), moment(endDate, "YYYY-MM-DD")];
            setFilterStartDate(startDate)
            setFilterEndDate(endDate)
            setDatePickerValue(temp);
        }
        else {
            setFilterStartDate('')
            setFilterEndDate('')
        }
    }

    const disabledDate = (current) => {

        return current && (current > moment().endOf('day') || current < currentDate);
    }

    const handleStatusSelect = (value) => {
        setStatusSelectValue(value)
    }

    const handleSchedulerSelect = (value) => {
        setSchedulerSelectValue(value)
    }

    const resetFilter = (field) => {
        if (field === 'scheduler') {
            setSchedulerSelectValue(defaultSelectValues.scheduler)
        }
        else if (field === 'status') {
            setStatusSelectValue(defaultSelectValues.status)
        }
        else if (field === 'date') {
            setFilterStartDate('')
            setFilterEndDate('')
            setDatePickerValue([])
        }
        else {
            setSchedulerSelectValue(defaultSelectValues.scheduler)
            setStatusSelectValue(defaultSelectValues.status)
            setFilterStartDate('')
            setFilterEndDate('')
        }
    }

    const getTransformerIcon = (nodeType) => {
        switch (nodeType?.toLowerCase()) {
            case 'datasource':

                return <DATASOURCE />
            case 'target':

                return <TARGETICON />
            case 'merge':
                return <MERGEICON />
            case 'filter':
                return <FILTERFLOW />
            case 'sql':
                return <SQLTRANSFORMERNEWBLUE />
            case 'scriptoutput':
                return <SCRIPTOUTPUT />
            case 'script':
                return <PYTHONSCRIPTICON />
            case 'alert':
                return <ALERTBELLLARGE />
            default:
                return <SCRIPTOUTPUT />

        }
    }
    const resetAllFilter = (onlyReset = false) => {

        setSelectedScheduler(defaultSelectValues.scheduler)

        setStatusSelectValue(defaultSelectValues.user)

        setFilterStartDate('')
        setFilterEndDate('')
        setDatePickerValue([])

        if (!onlyReset) {

            refreshSummaryTimelineData('all')
        }
        // }, 500);

    }
    const onOpenChange = (open) => {
        if (open) {
            setDatePickerValue([null, null]);
        } else {
            setDatePickerValue(null);
        }
    };
    const filterPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                tooltip: "Reset Filters",
                                buttonText: "",
                                buttonClassName: "custom-btn-outline error-btn custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                    resetAllFilter()
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setFilterPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className='row mx-n1 pb-3' style={{ width: '324px' }}>
                    <div className='col-12 my-3'>
                        <div
                        // className={`usage-filter-grid ${schedulerSelectValue !== defaultSelectValues.scheduler
                        //     && schedulerSelectValue.length ? 'active' : ''
                        //     }`}
                        >
                            {
                                schedulersList.length ?
                                    <>
                                        <SingleSelection
                                            props={{
                                                label: "",
                                                placeholder: "Select Scheduler Name",
                                                dropDownValue: schedulerSelectValue,
                                                onClickEvent: (value) => handleSchedulerSelect(value),
                                                optionList: schedulersList,
                                                isDisable: schedulersList.length ? false : true,
                                            }}
                                        />
                                        {/* {
                                            schedulerSelectValue !== defaultSelectValues.scheduler
                                                && schedulerSelectValue.length ?
                                                <Buttons
                                                    props={{
                                                        tooltip: "Reset Scheduler Filter",
                                                        buttonText: "",
                                                        buttonClassName: "custom-btn-outline danger-btn custom-btn btn-with-icon",
                                                        buttonEvent: () => {
                                                            resetFilter('scheduler');
                                                        },
                                                        ImgSrc: () => <RESETFILTER />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_TERTIARY,
                                                    }}
                                                /> : ''
                                        } */}
                                    </> : <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>

                    </div>
                    <div className='col-12 mb-3'>
                        <div
                        // className={`usage-filter-grid ${statusSelectValue !== defaultSelectValues.status
                        //     && statusSelectValue.length ? 'active' : ''
                        //     }`}
                        >
                            {
                                statusList.length ?
                                    <>
                                        <SingleSelection
                                            props={{
                                                label: "",
                                                placeholder: "Select Status",
                                                dropDownValue: statusSelectValue,
                                                onClickEvent: (value) => handleStatusSelect(value),
                                                optionList: statusList,
                                                isDisable: statusList.length ? false : true,
                                            }}
                                        />
                                        {/* {
                                            statusSelectValue !== defaultSelectValues.status
                                                && statusSelectValue.length ?
                                                <Buttons
                                                    props={{
                                                        tooltip: "Reset Status",
                                                        buttonText: "",
                                                        buttonClassName: "custom-btn-outline danger-btn custom-btn btn-with-icon",
                                                        buttonEvent: () => {
                                                            resetFilter('status');
                                                        },
                                                        ImgSrc: () => <RESETFILTER />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_TERTIARY,
                                                    }}
                                                /> : ''
                                        } */}
                                    </> : <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>
                    </div>
                    <div className='col-12 d-flex justify-content-between'>
                        <div id='usage-filter-range-picker' style={{ width: '200px' }}>
                            {
                                timelineData ?
                                    <>
                                        <RangePicker className='w-100 usage-filter-range-picker' value={datePickerValue || value}
                                            onCalendarChange={onCalendarChange}
                                            disabledDate={disabledDate}
                                            // onCalendarChange={(val) => {console.log(val);setDates(val)}}
                                            onChange={(val) => { setValue(val) }}
                                            onOpenChange={onOpenChange} />
                                        {/* <RangePicker className='w-100 usage-filter-range-picker' value={datePickerValue} onCalendarChange={onCalendarChange} disabledDate={disabledDate} /> */}
                                    </>
                                    : <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>
                        {
                            timelineData &&
                                statusList.length &&
                                schedulersList.length
                                ?
                                <Buttons
                                    props={{
                                        buttonText: "Apply",
                                        buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                        buttonEvent: () => {
                                            filtersToSend.scheduler_id = schedulerSelectValue !== defaultSelectValues.scheduler ? schedulerSelectValue : ''
                                            filtersToSend.status = statusSelectValue !== defaultSelectValues.status ? statusSelectValue : ''
                                            filtersToSend.start_date_time = filterStartDate
                                            filtersToSend.end_date_time = filterEndDate
                                            applyFilter()
                                            setFilterPop(false)
                                        },
                                        toggleBtnValue: "",
                                        ImgSrc: () => <CHECK />,
                                        isDisable: false,






                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        toggleBtnOption: null,
                                    }}
                                />
                                : <CustomSkeleton height='28px' width='75px' />
                        }
                    </div>
                </div>
            </>
        }
    }

    const handleFilterVisibleChange = (visible) => {
        setFilterPop(visible)
    }

    return <>
        {errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> : <div className="usage-listing">
            <div id="usage-refresh-panel-jobs" className='py-2 px-3 d-flex align-items-center justify-content-end usage-refresh-panel-jobs'>
                {
                    isRefreshing && showRefresh ? <div className='py-2 label mr-2 mb-0' style={{ lineHeight: 1 }}><i className='bx bx-loader-alt bx-spin mr-1'></i>Refreshing...</div> : ''
                }
                {
                    showFilters ?
                        <Popover placement="bottomLeft"
                            title={filterPopover.header}
                            content={filterPopover.body}
                            trigger="focused"
                            autoAdjustOverflow={true}
                            open={showFilterPop && true}
                            onOpenChange={handleFilterVisibleChange}
                        >
                            <Tooltip placement='bottom' title='Filter'>
                                <button className={`${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"
                                    } custom-btn btn-with-icon mr-2 ${(
                                        !Object.keys(filtersToSend).every(key => filtersToSend[key] === '')



                                    ) ? 'rdg-filter-active' : ''
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFilterPop(true)
                                    }
                                    }
                                >
                                    <FILTER />
                                </button>
                            </Tooltip>
                        </Popover> : ''
                }

                {showRefresh ? <Buttons
                    props={{
                        tooltip: "Refresh Logs",

                        buttonText: "",
                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                        buttonEvent: () => {
                            refreshSummaryTimelineData()
                        },
                        ImgSrc: () => <span className={`${isRefreshing ? 'bx-spin' : ''} d-flex`}><REFRESH /></span>,
                        isDisable: isRefreshing,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        tooltipPlacement: 'left'

                    }}
                /> : null}
            </div>
            <div>
                {
                    noDataFound ?
                        <NoDataComponent logo={<SCHEDULER width="60" height="60" color="black" />}
                            message="No Logs Found" />
                        // <LandingSimpleTable
                        //     cols={showFilters ? columns : leastColumns}
                        //     rowsData={timelineData}
                        //     onRowClick={RowSelection}
                        //     onScrollToBottom={onScrollToBottom}
                        //     autoScroll={pageNo > 1}
                        //     loadMore={loadMore}
                        //     tableHeight={450}
                        // />
                        : timelineData && timelineData.length > 0 ?
                            <LandingSimpleTable
                                cols={showFilters ? columns : leastColumns}
                                rowsData={timelineData}
                                // autoWidth={true}
                                onRowClick={RowSelection}
                                onScrollToBottom={onScrollToBottom}
                                autoScroll={pageNo > 1}
                                loadMore={loadMore}
                                tableHeight={'calc(100vh - 112px)'}
                            /> :

                            showSimpleLoading()
                }
            </div>

            <Drawer
                title={<h1 className="section-heading"> <span className='primary-svg-color mr-1'>{
                    logDetails?.scheduler_detail?.type?.toLocaleLowerCase() === 'time'
                        ? <CLOCK height='18' width='18' />
                        : logDetails?.scheduler_detail?.type?.toLocaleLowerCase() === 'partition'
                            ? <CRAWLER height='18' width='18' />
                            : <ICON_EVENT height='18' width='18' />
                }
                </span>{logDetails?.scheduler_detail?.name}</h1>}
                placement={'right'}
                width={600}
                onClose={() => setOpenModal(false)}
                visible={openModal}
                closable={false}
                extra={
                    <div className="d-flex align-items-center justify-content-between w-100">

                        <div className='mb-1 mr-3 '>
                            <div className='d-flex small mb-0 align-items-center  text-right justify-content-center' style={{ lineHeight: 0 }}>Status:
                                {/* <label className='mb-0  text-black-50 d-block text-right'>{logDetails.description}</label> */}

                                <label className={`mb-0 ml-1  text-capitalize 
                                        ${logDetails?.status === queryStatus.finished || logDetails?.status === queryStatus.done || logDetails?.status === queryStatus.success ? 'text-success'
                                        : logDetails?.status === queryStatus.running || logDetails?.status?.toLocaleLowerCase() === 'starting' ? 'text-primary'
                                            : logDetails?.status === queryStatus.queued ? 'text-warning'
                                                : logDetails?.status?.toLocaleLowerCase() === 'initial' ? 'text-warning'
                                                    : logDetails?.status === queryStatus.failed || logDetails?.status === queryStatus.error ? 'text-danger'
                                                        : 'text-warning'} `}>
                                    <i className={`mr-1  bx bx-${logDetails.status === queryStatus.finished || logDetails.status === queryStatus.success || logDetails.status === queryStatus.done ? 'check-circle'
                                        : logDetails.status === queryStatus.running || logDetails.status?.toLocaleLowerCase() === 'starting' ? 'run'
                                            : logDetails.status === queryStatus.queued ? 'dots-horizontal-rounded'
                                                : logDetails.status?.toLocaleLowerCase() === 'initial' ? 'error-circle'
                                                    : logDetails.status === queryStatus.failed || logDetails?.status === queryStatus.error ? 'x-circle'
                                                        : 'text-warning'
                                        }
                                            `}></i>

                                    {logDetails?.status && logDetails?.status.length > 0
                                        ? logDetails?.status?.toLowerCase()
                                        : 'Initial'}

                                </label>
                            </div>
                        </div>
                        <Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

                    </div>
                }
            >
                {
                    logDetails?.step_status?.length ? <div className='custom-card shadow-none border' style={{ height: 'calc(100vh - 245px' }}>
                        <p className='mb-2 h6 text-black-100 d-block text-left' >Steps Details</p>

                        {
                            logDetails?.step_status?.length ?
                                <List
                                    style={{
                                        height: logDetails.error_msg === null ? `calc(100vh - 289px)` : `calc(100vh - 300px)`,
                                        overflow: 'auto'
                                    }}

                                    itemLayout="horizontal"

                                    dataSource={logDetails?.step_status}
                                    renderItem={item => (
                                        <List.Item
                                            style={{ display: 'grid', gridTemplateColumns: '1fr  1fr' }}
                                            actions={[<div className='d-flex align-items-center justify-content-center'> Status: <label className={`mb-0 ml-1 text-capitalize 
                                        ${item?.status === queryStatus.finished || item?.status === queryStatus.done || item?.status === queryStatus.success ? 'text-success'
                                                    : item?.status === queryStatus.running || item?.status?.toLocaleLowerCase() === 'starting' ? 'text-primary'
                                                        : item?.status === queryStatus.queued ? 'text-warning'
                                                            : item?.status?.toLocaleLowerCase() === 'initial' ? 'text-warning'
                                                                : item?.status === queryStatus.failed || item?.status === queryStatus.error ? 'text-danger'
                                                                    : 'text-warning'} `}>

                                                <i className={`mr-1 bx bx-${item?.status === queryStatus.finished || item?.status === queryStatus.success || item?.status === queryStatus.done ? 'check-circle'
                                                    : item?.status === queryStatus.running || item?.status?.toLocaleLowerCase() === 'starting' ? 'run'
                                                        : item?.status === queryStatus.queued ? 'dots-horizontal-rounded'
                                                            : item?.status?.toLocaleLowerCase() === 'initial' ? 'error-circle'
                                                                : item?.status === queryStatus.failed || item?.status === queryStatus.error ? 'x-circle'
                                                                    : 'text-warning'
                                                    }
                                            `}></i>

                                                {item?.status && item?.status.length > 0
                                                    ? item?.status?.toLowerCase()
                                                    : 'Initial'}
                                            </label>



                                                <div className="d-flex align-items-center justify-content-center h-100">
                                                    {item.error_msg !== null ? <Tooltip placement="bottom" title="Preview Error Log">
                                                        <button className="custom-btn-outline ml-1 color-primary bg-transparent border-0 custom-btn btn-with-icon"
                                                            onClick={() => {
                                                                settransformerErrorMsg(item?.error_msg)
                                                                setopenErrorModal(true)
                                                            }
                                                            }
                                                        >
                                                            <PREVIEW />
                                                        </button>

                                                    </Tooltip> : null}
                                                    <Tooltip placement="bottom" title={item?.content_type?.toLocaleLowerCase() === 'flow' ? "View Flow" : "View Alert"}>
                                                        <button style={{ border: 'none' }} className=" bg-transparent   custom-btn btn-with-icon ml-1"
                                                            onClick={(e) => {
                                                                item?.content_type?.toLocaleLowerCase() === 'flow' ?
                                                                    window.open(`/flows/${item?.object_id}`) : window.open(`/viewAlert/${item?.object_id}`)
                                                            }
                                                            }
                                                        >
                                                            <REDIRECTJOBS />
                                                        </button>
                                                    </Tooltip>

                                                </div>
                                            </div>]}
                                        >

                                            <List.Item.Meta
                                                avatar={<div className='transformerIcon mr-0 pr-1'><Tooltip title={item?.content_type}>{item?.content_type?.toLocaleLowerCase() === 'flow' ? <FLOWS /> : <ALERTBELLLARGE />}</Tooltip></div>}
                                                title={<Tooltip title={item?.object_name.toLowerCase()}>{item?.object_name?.length > 25 ? `${item?.object_name?.slice(0, 25).toLowerCase()} ...` : item?.object_name?.toLowerCase()}</Tooltip>}
                                                description={<p className="small text-black mt-0 mb-0" style={{ lineHeight: 1 }} >
                                                    If Previous Fail : {item?.if_previous_fail}
                                                </p>}
                                            />



                                        </List.Item>
                                    )}
                                /> : null

                        }
                    </div> : null}

                {







                }
            </Drawer>


            <Modal size="lg" isOpen={openErrorModal} className="custom-modal" centered={true} onClosed={() => setopenErrorModal(false)}>
                <ModalHeader close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setopenErrorModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className='d-flex'>
                            <EDITOR2 />
                            <h2 className="section-heading">{logDetails?.scheduler_detail?.name}</h2>
                        </div>
                        <div className='mb-n1 mr-3 small'>
                            <label className='d-block mb-0 small text-right' style={{ lineHeight: 0 }}>Status: {logDetails?.status}</label>
                            <label className='mb-0 small text-black-50 d-block text-right'>{logDetails?.description}</label>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='usage-drawer'>
                        <div>
                            <div className='py-2'>
                                <div className='row mx-n2'>


                                    <div className='col-12 h-100 error'>

                                        <div>
                                            <p>
                                                {transformerErrorMsg}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>

        </div>}
    </>;
};
