import React, { useState, useEffect, Fragment } from "react";
import { useStore } from "react-redux";
import { Literals } from "../../../common/literals";
import { Card, CardBody } from "reactstrap";

import { Tooltip } from "antd";
import NoSearchResultFound from '../../../common/NoSearchResultFound';
import { pxToRem, remToPx, RolePrivilegeModal } from "../../../common/helperFunctions";
import { useNavigate } from "react-router-dom";
import UserManagementCard from "../../common/UserManagementCard";
import { SMALLDATADOMAIN, DATADOMAIN, TABVIEW, LISTVIEW, USERSGROUP } from "../../../../Common/iconSource";
import DeleteDomainModal from "./DeleteDomainModal";
import LandingPageTable from "../../../../Common/landingPageTable/LandingPageTable";
import { getRelativeTime } from "../../../common/helperFunctions";
import SearchInput from "../../../../Common/search/Search";
import NoDataInfo from "../../../dataCatalogue/accessMangament/components/NoDataInfo";
import { getDataDomainDetail } from "../../../../../store/modules/UserManagement/dataDomains/dataDomainActions";
import Highlighter from "react-highlight-words";
import Buttons from "../../../../Common/button/Buttons";
import { LandingSimpleTable, LandingSimpleTableInner } from "../../../../Common/landingSimpleTable/LandingSimpleTable";
import {
  InfoCircleFilled
} from '@ant-design/icons';
const DomainDetailsLowerSection = ({
  icon,
  heading,
  List,
  isUserGroup,
  id,
}) => {
  const store = useStore();
  const history = useNavigate();

  const [filteredList, setFilteredList] = useState([...List]);
  const [searchData, setSearchData] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [userGroupList, setUserGroupList] = useState([]);
  const [layout, setlayout] = useState('list')
  const [openModal, setOpenModal] = useState(false);
  const [listData, setlistData] = useState([])

  const domainData = [];

  useEffect(() => {
    if (!isUserGroup) {
      getUserGroupListData();
    }
  }, []);

  useEffect(() => {
    setFilteredList([...List]);
  }, [List]);
  useEffect(() => {

    if (filteredList.length) {
      let records = [...filteredList]
      records = filteredList.filter((data) => {
        return (
          data?.dataDomain
            ?.toLocaleLowerCase()
            .includes(searchData?.toLocaleLowerCase()) ||
          data?.description
            ?.toLocaleLowerCase()
            .includes(searchData?.toLocaleLowerCase()) ||
          data?.updated_on
            ?.toLocaleLowerCase()
            .includes(searchData?.toLocaleLowerCase()) ||
          data?.created_by
            ?.toLocaleLowerCase()
            .includes(searchData?.toLocaleLowerCase())

        );
      });
      let temp = []
      if (!records.length) {
        setlistData([])
        return;
      } else {

        for (let i = 0; i < records.length; i++) {
          let entitlement_name = records[i].entitlement_detail ? records[i].entitlement_detail[0]?.entitlement_name : "";
          temp.push(
            {
              key: records[i].key,
              // sources: <div className="landingCard-circle">
              //   <div className="icon-box">

              //   </div>
              // </div>,
              dataDomain: <div className="d-flex align-items-center h-100 w-100 usr-grp-domain-ic"><DATADOMAIN height={15} width={15} color="#2c83c4" /> <span className='flexible-search-wrap ml-1'><Tooltip placement="topLeft" title={records[i].dataDomain}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].dataDomain ? records[i].dataDomain : ''} /></Tooltip></span></div>,
              description: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].description}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].description ? records[i].description : ''} /></Tooltip></span>,
              // database_name: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i][3]?.database}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i][3]?.database ? records[i][3]?.database : ''} /></Tooltip></span>,
              entitlement: <span className="flexible-search-wrap"><Tooltip placement="topLeft" title={entitlement_name}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={entitlement_name ? entitlement_name : ''} /></Tooltip></span>,
              permission: records[i].permission,
              updatedOn: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].updatedOn}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].updatedOn ? records[i].updatedOn : ''} /></Tooltip></span>,
              createdBy: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].createdBy}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].createdBy ? records[i].createdBy : ''} /></Tooltip></span>,
              data: records[i]
            }
          )
        }
        setlistData([...temp])
      }
    } else {
      setlistData([])
    }
  }, [filteredList, searchData])

  const columns1 = [
    // {
    //   name: '',
    //   key: "sources",
    //   width:10,
    //   resizable: false,
    //   sortable: false,

    // },
    {
      name: 'Domain Name',
      key: "dataDomain",
      resizable: false,

    },

    {
      name: Literals.DESCRIPTION,
      key: "description",
      width: 350,
      resizable: false,
      sortable: false,


    },

    {
      name: Literals.UPDATED_ON,
      key: "updatedOn",
      resizable: false,



    },
    {
      name: Literals.CREATED_BY,
      key: "createdBy",
      resizable: false,
      sortable: false



    },
    {
      title: Literals.ROLES,
      key: "entitlement",
      resizable: false,
      sortable: false

    }

  ];


  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'dataDomain':
      case 'updatedOn':

        return (a, b) => a?.data?.[sortColumn]?.localeCompare(b?.data?.[sortColumn]) ?? null
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }
  const toggleBtnOption = [
    { label: <TABVIEW />, value: 'grid' },
    { label: <LISTVIEW />, value: 'list' },
  ];
  const setView = (view) => {
    setlayout(view);
  }


  const getUserGroupListData = async () => {
    let response;
    try {
      response = await store.dispatch(getDataDomainDetail(id));
      if (response.status === "success") {

        if (response?.data?.group?.group_detail?.length) {
          let groupList = response?.data?.group?.group_detail
          let sortedlist = groupList.sort((a, b) => {
            if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
              return -1;
            }
            if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          setUserGroupList(sortedlist);
        }
      }
    } catch (error) { }
  };

  function clickEdit(dataDomain) {

    history(Literals.links.USER_MANAGEMENT_EDIT_DATA_DOMAIN + dataDomain.key, { state: { tabName: Literals.DATA_DOMAIN, activeTab: Literals.DATA_DOMAIN } });
  }

  const redirectTo = async (key) => {
    history(Literals.links.USER_MANAGEMENT_DATA_DOMAIN + key, { state: { tabName: Literals.DATA_DOMAIN, activeTab: Literals.DATA_DOMAIN } });
  };
  const getCardView = () => {
    let data = filterData;
    if (data && data.length > 0) {
      return (
        data.map((dataObj, i) =>
          <div key={i} className="data-connectiongridview-responsive cursor-pointer" onClick={() => {

          }}>
            <Card >
              <CardBody>
                <div className="connectionsCard-circle">
                  <div className="icon-box">{<DATADOMAIN />}</div>
                </div>
                <div className="connectionsCard-description">
                  <p className="m-0 custom-ellipsis two-line-ellipsis connection-heading landingCard-header text-dark text-uppercase">
                    {dataObj.name}
                  </p>
                  <p className="paragraph grey-text m-0 custom-ellipsis two-line-ellipsis">
                    {dataObj.description}
                  </p>
                </div>
              </CardBody>
            </Card>
          </div>
        )
      )
    }
  }

  const clickDelete = async (value) => {
    setDeleteValue(value);
    setOpenDeleteModal(true);
  };

  const onRowClick = (record) => {
   
    window.open(Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS + record.key);
    // history(Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS + record.key, { state: { tabName: Literals.USERGROUP, activeTab: Literals.USERGROUP } });
  };
  function getDataDomainListData() {
    // after delete
  }

  function renderAssocitedDomain() {

    if (isUserGroup) {
      return (
        <Fragment>
          {filteredList?.length === 0 ? (
            <NoDataInfo category="viewUserGroup" />
          ) : (
            filteredList.map((list, i) => (
              <UserManagementCard
                key={i}
                isUserGroup={isUserGroup}
                searchData={searchData}
                domainDetails={list}
                selectedDomainList={list}
                clickEdit={clickEdit}
                clickDelete={clickDelete}
                icon={<DATADOMAIN />}
                tabName="DataDomain"
                redirectTo={redirectTo}
              />
            ))
          )}
        </Fragment>
      );
    }
  }
  const columns = [

    {
      name: Literals.USERGROUPNAME,
      dataIndex: "name",
      key: "name",
      sortable: true

    },
    {
      name: Literals.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      className: "description",
      sortable: false,
      
    },
    {
      name: Literals.updated_on,
      dataIndex: "updatedOn",
      key: "updatedOn",
    },
    {
      name: Literals.CREATED_BY,
      dataIndex: "createdBy",
      key: "createdBy",
      className: "single-line-ellipsis",
      sortable: false
    },
    {
      name: <div style={{ display: "flex", alignItems: "center" }}>{Literals.TYPE} <InfoCircleFilled style={{ color: "#2C83C4" }}
        onClick={() => {
          setOpenModal(true);
        }}
        className="ml-2" /></div>,
      dataIndex: "type",
      key: "type",
      sortable: false
    },
    {
      name: Literals.ROLES,
      dataIndex: "entitlement",
      key: "entitlement",
      sortable: false
    }
  ];

  const filterData =
    userGroupList &&
    userGroupList.filter((data) => {
      return (
        data?.name
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.description
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        getRelativeTime(data?.updated_on)
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.created_by_user?.username
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase())
        ||
        data?.permission?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase())
      );
    });

  filterData &&
    filterData.map((value) => {
      let entitlement_name = value.entitlement_detail ? value.entitlement_detail[0]?.entitlement_name : "";

      domainData.push({
        key: value?.id,//value?.data_domain_id,

        name: (
          <div className="d-flex align-items-center">
            <span className="icon-box-desc">
              <USERSGROUP color='#2c83c4' height="20" width="20" />
            </span>
            <Tooltip placement="topLeft" title={value?.name}>
            <div className='text-with-ellipsis'> {value?.name.toUpperCase()}</div>
            </Tooltip>
          </div>
        ),
        description: (
          <Tooltip placement="topLeft" title={value?.description}>
            <div className='text-with-ellipsis'>{value?.description}</div>
          </Tooltip>
        ),
        updatedOn: (
          <Tooltip placement="topLeft" title={getRelativeTime(value?.updated_on)}>
            {getRelativeTime(value?.updated_on)}
          </Tooltip>
        ),
        createdBy: (
          <Tooltip placement="topLeft" title={value?.created_by_user?.username}>
            {value?.created_by_user?.username}
          </Tooltip>
        ),
        updatedOn: (
          <Tooltip placement="topLeft" title={getRelativeTime(value?.updated_on)}>
            {getRelativeTime(value?.updated_on)}
          </Tooltip>
        ),
        updatedBy: (
          <Tooltip placement="topLeft" title={value?.updated_by_user?.username}>
            {value?.updated_by_user?.username}
          </Tooltip>
        ),
        // domain: (
        //   <Tooltip placement="topLeft" title={value?.data_domain}>
        //     {value?.data_domain}
        //   </Tooltip>
        // ),
        type: (
          <Tooltip placement="topLeft" title={value?.owner ? 'owner' : 'member'}>
            {value?.owner ? 'Owner' : 'Member'}
          </Tooltip>
        ),
        permission: value?.editor?'Editor' : 'Viewer',

        entitlement: (
          <Tooltip title={value?.editor ? 'Editor' : 'Viewer'} placement="topLeft">
            {value?.editor ? 'Editor' : 'Viewer'}
          </Tooltip>
        ),
        data: value
      })
    }
    );

  const getUserGroupComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'name':
        return (a, b) => a?.data?.name?.localeCompare(b?.data?.name) ?? null
      case 'updatedOn':
        return (a, b) => a?.data?.updated_on?.localeCompare(b?.data?.updated_on) ?? null

      default:
        break;
    }
  }

  function renderUserGroupList() {


    if (!isUserGroup) {
      return (
        <Fragment>
          {domainData.length === 0 ? (
            <NoDataInfo category="viewUserGroup" />
          ) : (
            <LandingSimpleTable
              cols={columns}
              autoWidth={false}
              rowsData={domainData}
              getComparator={getUserGroupComparator}
              onRowClick={onRowClick}
              tableHeight={domainData?.length * 41 + 41}
            />
            // <LandingPageTable
            //   props={{
            //     columns: columns,
            //     dataSource: domainData,
            //     tableHeight: "",
            //     onRowSelection: onRowClick,
            //   }}
            // />
          )}
        </Fragment>
      );
    }
  }

  return (
    <div className="dataDomain-lower-part usr-manage-usr-grp">
      <div className="lower-subheader">
        <div className="sub-header-left">
          <div className="heading-content">
            <span className="domain-icon">{icon}</span>
            <p>{heading}</p>
          </div>
        </div>
        <div className="sub-header-right d-flex">
          <SearchInput searchData={searchData} setSearchData={setSearchData} />
          {/* <div className='ml-2'>
            <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: layout, buttonClassName: "toggle-btn custom-btn btn-with-icon pb-1 ", buttonEvent: setView, ImgSrc: () => <></>, isDisable: false, buttonType: Literals.BTN_TOGGLE }} />
          </div> */}
        </div>
        {/* <div className="sub-header-right">
          <ul className="landingPage_navbar-nav-right">
            <li className="landingPage_nav-item mr-2">
              <SearchInput setSearchData={setSearchData} />
            </li>
          </ul>
        </div> */}
      </div>
      {
        isUserGroup ?

          !listData?.length && searchData !== '' ? <div className='mt-20'><NoSearchResultFound showSubMsg={false} width={'100'} height={'100'} /></div> : layout === 'grid' ? <div className="data-domain-gridview usr-manage-usr-grp" style={filteredList?.length === 0 ? { gridTemplateColumns: '1fr' } : {}}>{renderAssocitedDomain()}

          </div>
            : <LandingSimpleTable
              cols={columns1}
              rowsData={listData}
              getComparator={getComparator}
              onRowClick={(e) => { redirectTo(e.key) }}
              tableHeight={(listData?.length * remToPx(pxToRem(41))) + remToPx(pxToRem(41))}
            /> : filterData?.length === 0 && searchData !== '' ? <div className='mt-20'><NoSearchResultFound showSubMsg={false} width={'100'} height={'100'} /></div> : layout === 'grid' ? <div className="data-domain-gridview"> {getCardView()}</div> :
              <div className="user-management-table">{renderUserGroupList()}</div>
      }
      <RolePrivilegeModal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <DeleteDomainModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteValue={deleteValue}
        getDataDomainListData={getDataDomainListData}
      />
    </div>
  );
};

export default DomainDetailsLowerSection;
