const modulesPrimaryLinks = {
  dataCatalog: "dataCatalog",
};

export const Literals = {
  TAG_RENAME: "Rename",
  PERSONA: "Persona",

  TAG_DELETE: "Delete",
  CREATE_RULE_HEADING: "Data Rules - Create New Rule",
  //icons
  DATA_SCIENTIST: "Data Engineer/Scientist",
  DATA_CONSUMER: "Management/Business",
  DATA_ANALYST: "Data Analyst",
  DELETE_POD_MSG:
    "Once you delete the pod, Query editor/Applied Filter will reset",
  DELETE_FLOW_MSG: "Once you delete the flow,everything will reset",
  ICON_SEARCH: "Search",
  FLOWS_MENU_ONCLICK_MSG: "Please drag the transformer on canvas",
  ICON_FILTER: "Filter",
  ICON_LiST_VIEW: "List View",
  ICON_GRID_VIEW: "Grid View",
  ICON_GRAPH_VIEW: "Graph",
  ICON_REFRESH: "Refresh",
  ICON_DOWNLOAD_TABLE: "Download Table",
  ICON_EXPAND: "Full Screen",
  ICON_TAGS: "Tags",
  ICON_SAVE_QUERY: "Save Query",
  ICON_DOWNLOAD_QUERY: "Download Query",
  ICON_EXECUTE_QUERY: "Execute Query",
  ICON_DATA_SOURCE: "Datasource",
  ICON_TARGET: "Target",
  ICON_CLOSE: "Close",
  ICON_PROFILE: "Profile",
  ICON_SEARCH_CLOSE: "Close Search",
  ICON_COLLAPSE: "Collapse Screen",
  PLACE_HOLDER: "Search here ...",
  ICON_RECALCULATE_STATS: "Recalculate Stats",

  //button
  BUTTON_CREATE_NEW_POD: "POD",
  BUTTON_ADD_TAG: "Add tag",
  BUTTON_EDIT: "Edit",
  BUTTON_RESET_FILTERS: "Reset Filters",
  BUTTON_QUERY_1: "query_1",
  BUTTON_DELETE: "DELETE",
  BUTTON_CANCEL: "CANCEL",
  BUTTON_OK: "Ok",
  BUTTON_SAVE: "Save",

  BUTTON_UPDATE: "Update",
  BUTTON_DAUPLICATE: "DUPLICATE",
  BUTTON_Cancel: "Cancel",
  BUTTON_DISCARD: "Discard",
  BUTTON_BROWSE: "Browse",
  BUTTON_FINISH: "Finish",
  BUTTON_UPLOAD: "Upload",
  BUTTON_QUERY_RESULT: "Query Result",
  FILTER: "Filter",
  BUTTON_CREATE: "Create",
  BUTTON_CLOSE: "Close",

  //popup
  OPEN_IN_NEW_TAB: "Open in New Tab",
  COPY_POD_LINK: "Copy in New Tab",
  RENAME: "Rename",
  DUPLICATE: "Duplicate",
  DELETE: "Delete",
  POP_UP_MESSAGE_DELETE: "This action will delete the user permanently.",

  //page name
  DICTIONARY: "Dictionary",
  PREVIEW: "Preview",
  LINEAGE: "Lineage",
  ACCESS: "Access",
  ENTITLEMENT: "Entitlements",
  ROLES: "Roles",
  AUDIT: "Audit",
  ALERT: "Alerts",
  MYALERT: "My Alerts",
  POLICY: "Policy",

  LANDING_PAGE_HEADING: "Data Catalog",
  POD_NAME: "POD Name",
  DESCRIPTION: "Description",
  SOURCES: "Sources",
  CREATED_ON: "Created On",
  CREATED_BY: "Created By",
  UPDATED_BY: "Updated By",
  UPDATED_ON: "Updated On",

  ALL: "All",
  STRUCTURED: "Structured",
  UNSTRUCTURED: "Unstructured",
  STARRED: "Starred",
  TAGS: "Tags",
  AVAILABLE_TAGS: "Available Tags",
  ADD_A_NEW_TAG: "Add a new tag    +",
  SELECT_COLOR: "Select Color",
  NAME: "Name",
  CLEAR_ALL: "ClearAll",
  CATEGORY: "Category",

  INNER_JOIN: "Inner",
  OUTER_JOIN: "Outer",
  LEFT_JOIN: "Left",
  RIGHT_JOIN: "Right",


  DICTIONARY_HEADING: "Dictionary",
  STATISTICS_HEADING: "Statistics",
  KEY_STATS: "Key Stats",
  COLUMNS: "Columns",

  BUSINESS_TERM: "Display Name",
  TECHNICAL_TERM: "Technical Name",
  TYPE: "Type",
  PII: "Pii",

  ROWS: "Rows",
  SQL_EDITOR: "Report Editor",
  QUERY_EDITOR: "Query Editor",
  ERRORS: "Errors",
  SAVED_QUERIES: "Saved Report",

  FUNNEL_NAME: "Funnel name",
  MODIFIED_BY: "Modified by me",
  FUNNEL_ID: "Funnel Id",
  CRON_EXPRESSION: "Cron Expression",
  VERSION: "Version",
  FUNNEL_DETAILS: "Funnel Details",
  FUNNEL_VIEW: "Funnel View",
  SOURCES_LOCATIONS: "Source Locations",
  TARGET_LOCATIONS: "Target Locations",
  PERFORMANCE: "Performance(Execution time in seconds)",
  RECENT_RUNS: "Recent runs",
  STATUS: "Status",
  START_DATE: "Start Date",
  STOP_DATE: "Stop Date",
  PROCESSOR: "Processor(DPUs)",

  AZURE: "Azure",
  ORACLE: "Oracle",
  POST: "Post",
  REDSHIFT: "Redshift",
  SNOWFLAKE: "Snowflake",
  MYSQL: "MySQL",
  FILTERS: "FILTERS",
  SORT_BY_ASC: "Sort by ascending",
  DuplicatePOD_header: "Data Catalog-Duplicate POD",
  BOOKMARK_THIS_POD: "Bookmark this POD",
  BUSINESS_NAME: "Business Name",
  DUPLICATEPOD_WARNING: "Business name can't be edited after this step!",
  DUPLICATEPOD_DATA_DOMAIN: "Select Data domain",
  APPLY_TO_ALL: "Apply to all",
  DATA_DOMAIN: "Data Domain",
  ANALYSIS_NAME: "Analysis Name",
  CHART_TYPE: "Chart Type",
  SOURCE: "Source",
  RECORDS: "Records",
  AUTHORIZED_USERS: "Authorized Users",
  README: "Readme",
  TIMELINE: "Timeline",
  UPLOAD_YOUR_FILES: "Upload your files",
  DRAG_AND_DROP_YOUR_FILES: "Drag and drop your files here",
  UPLOAD_DOC_INFO: "(max. size: 6MB & file format: .pdf, .txt, .html, .doc)",
  UPLOAD_BUSINESS_TERM_INFO: "file format: .csv, .xlsx",
  UPLOAD_DOC_FAIL: "Fail",
  UPLOAD_DOCUMENTS: "Uploaded Documents",
  TABLE_DATA_NOT_AVAILABLE: "No Documents Found",
  TOTAL_QUERY_RUN: "Total Query Run",
  TOTAL_SAVED_QUERY: "Total Saved Queries",
  TOTAL_LINEAGE: "Total Lineage",
  MISSING_VALUES: "Missing Values",
  BUSINESS_TERM_INFO:
    "Technical Names will be used internally to create column names corresponding to this POD. These column names will appear in BI tools not Display Names.",
  PII_INFO: "Personally Identifiable Information/ Sensitive Data",
  SUMMARY: "Summary",

  ANALYZE: "Analyze",
  TOTAL_COUNT: "Total Count",
  DATA_TYPE: "Data Type",
  UNIQUE: "Unique",
  MISSING: "Missing",
  ZEROES: "Zeroes",
  VALUE: "Value",
  SUM_VALUE: "Sum",
  FREQUENCY_ANALYSIS: "Frequency Analysis",
  LENGTH_STATISTICS: "Length Statistics",
  CORELATION: "Correlation",
  TECHNICAL_NAME: "Technical Name",
  CRITERIA: "Operator ",
  NONE: "None",
  ADD_CRITERIA: "Add Criteria",
  ADD_COLUMN: "Add Column",

  RESET: "Reset",
  APPLY: "Apply",

  TIMESTAMPS: "Timestamps",
  MESSAGES: "Messages",
  SQL_EDITORS: "SQL Editor",
  MIN: "Min",
  MEDIAN: "Median",
  AVG: "Avg",
  MAX: "Max",
  PREVIEW_SORT_BY_ASC: "Sort by Asc",
  PREVIEW_SORT_BY_DESC: "Sort by Desc",
  ERRORS_1: "Errors(1)",
  NO_RECORDS_AVAILABLE: "no Records available",
  TOTAL_USERS: "Total users",
  TOTAL_GROUPS: "Total groups",
  CONNECTION_NAME: "Connection Name",
  POLICY_NAME: "Policy_Name",
  DATABASE_NAME: "Database name",
  DATA_DOMAINS: "Data Domains",
  USERS: "Users",
  USERGROUP: "Usergroup",
  CONNECTIONS: "Connections",

  DASHBOARD: "Dashboard",
  ANALYSIS: "Analysis",
  ALERTS: "Alerts",

  USERGROUPNAME: "User Group Name",
  LEADERS: "Leaders",
  LISTOFUSER: "List of Users",

  FIRSTNAME: "First Name",
  LASTNAME: "Last Name",
  EMAIL: "Email Address",

  USER_GROUPS: "User Groups",
  DESIGNATION: "Designation",
  EMAIL_ID: "Email-id",
  LIST_OF_USERGROUPS: "List Of User Groups",
  ALL_PODS: "All PODs",
  ROW_LEVEL_MASKING: "Row Level Filtering",

  DATA_DOMAIN_NAME: "Data Domain Name",
  ADD_USERGROUPS: "Add User Groups",
  ADD_USER_GROUP: "Add User Group",
  SELECT_A_USERGROUP: "Select A User Group",
  SELECT_GROUP_TYPE: "Select Group Type",
  ACTIONS: "Actions",
  SAVE_CHANGES: "Save changes",
  CANCEL: "Cancel",
  SAVE_DATA_DOMAIN: "Save Data Domain",
  ACCESS_MANAGEMENT_HEADING: "Access Management",
  FLOWS: "Flows",
  ACCESS_MANAGEMENT: "Access management",
  DATA_CATEGORY: "Data Category",
  DATA_CLASSIFICATION: "Data Classification",
  POLICY_COL_NAME: "Policy Name",
  NUMBER_OF_USERGROUPS: "Usergroups Count",
  USER_MANAGEMENT_HEADING: "User Management",
  USER_MANAGEMENT: "User Management",
  SELECTED_USERGROUPS: "Selected Usergroups",
  MASK: "Mask",
  COLUMN_LEVEL_MASKING: "Column Level Masking",
  ADD: "Add",
  REMOVE: "Remove",
  CREATE_POLICY_HEADER1: "Create policy - Select User group",
  CREATE_POLICY_HEADER2: "Create policy - Assign Permissions",
  EDIT_POLICY_HEADER1: "Edit policy - Select User group",
  EDIT_POLICY_HEADER2: "Edit policy - Assign Permissions",
  PROCEED: "Proceed",
  CREATE_POLICY_BUTTON: "Create policy",
  EDIT_POLICY_BUTTON: "Save policy",
  PREVIOUS: "Previous",
  ALL_USER_GROUP_IN_DOMAIN_NAME: "All Usergroup in Domainname",
  ACCESS_PERMISSION: "Access Permission",
  USER_GROUP_NAME: "User Group Name",
  VIEW: "View",
  EDIT: "Edit",
  ADD_FILTER_BUTTON: "Add Filter",
  DOMAINS: "Domains",
  CREATE_USER_GROUP: "Create User Group",
  USERS_IN_THIS_GROUP: "Users In This Group",
  ADD_USERS_BUTTON: "Add Users",
  ADD_USERS: "Add Users",
  CREATE_DATA_DOMAIN_BUTTON: "Create Data Domain",
  SELECT_BUTTON: "Select",
  SELECT_USER_GROUP: "Select User Group",
  SELECT_ENTITLEMENT: "Select Entitlement",
  SELECT_ROLES: "Select Roles",
  EDIT_DATA_DOMAIN_BUTTON: "Edit Data Domain",
  EDIT_USER_GROUP: "Edit User Group",

  HOURS: "Hours",
  MINUTE: "Minute",
  MONTH: "Month",
  DAY_IN_MONTH: "Day in Month",
  DAY: "Day",
  CUSTOM: "Custom",
  FREQUENCY: "Frequency",

  RULE_NAME: "Rule Name",
  RULE_CATEGORY: "Rule Category",
  RULE_TYPE: "Rule Type",
  RULE_PURPOSE: "Rule Purpose",
  NOTIFY: "Notify",
  SUPER_USER: "Super User",
  YES: "Yes",
  NO: "No",
  APPLY_TO_ALL_INFO: "Data Doamin successfully applied to all.",

  RULES_SCHEDULER_NOTE:
    "If user not select any option from the below's dropdown then default value will be the '*' it's called continues CRON value",
  RECENT_USERS: "Recent Users",
  USAGE_HISTORY: "Usage History",
  DASHBOARD_NAME: "Dashboard Name",

  ADD_BOOKMARK: "Add to Bookmark",
  ADD_FAVOURITES: "Add To Favourites",
  REMOVE_FAVOURITES: "Remove From Favourites",
  REMOVE_BOOKMARK: "Remove from Bookmark",
  TIRGGER_RULES: "Trigger Rule",
  NO_RESULTS_FOUND: "No results found",
  PLEASE_TRY_SEARCHING_WITH_ANOTHER_TERM:
    "Please try searching with another term",
  LOGOUT: "Logout",
  PROFILE: "Profile",

  CREATED_BY_ME: "Created by me",
  PINNED: "Pinned",
  POP_UP_MESSAGE_DELETE_DASHBOARD:
    "This action will delete the Dashboard permanently",
  POP_UP_MESSAGE_DELETE_ANALYSIS:
    "This action will delete the Analysis permanently",
  POP_UP_MESSAGE_DELETE_FLOW: "This action will delete the Flow permanently",
  SELECT_FILTER_TYPE: "Select Filter Type",

  BTN_PRIMARY_WITH_TEXT: "primary_with_text",
  BTN_PRIMARY_WITHOUT_TEXT: "primary_without_text",
  BTN_SECONDARY: "secondary",
  BTN_TERTIARY: "tertiary",
  BTN_TOGGLE: "toggle",
  BUSINESS_TERM_LENGTH_MSG: "Maximum 250 characters are allowed",
  OVERVIEW: "Overview",
  USAGE: "USAGE",
  // DUPLICATE_HEADING: "Duplicate POD Name",
  DUPLICATE_HEADING: "Create a Copy",

  CONFIRMATION_MSG_FOR_UNSAVED:
    "Any unsaved changes will be lost. Are you sure?",
  links: {
    PAGE_404: "/pages-404",
    PAGE_500: "/pages-500",
    PAGE_COMING_SOON: "/pages-comingsoon",
    LOGOUT: "/logout",
    PAGE_MAINTENANCE: "/pages-maintenance",

    HOME: "/home",
    SIGNUP: "/signup",
    LOGIN: "/login",
    ADMINLOGIN: "/admin/login",
    VERIFY_OTP: "/verify-otp",
    FORGOT_PASSWORD: "/forgot-password",
    PROFILE: "/profile",
    ANALYTICS: "/analytics",

    DATA_CATALOG: `/${modulesPrimaryLinks.dataCatalog}`,
    DATA_CATALOG_POD_OF_POD: `/${modulesPrimaryLinks.dataCatalog}/Pod-of-Pod`,
    DATA_CATALOG_POD_DETAILS: `/${modulesPrimaryLinks.dataCatalog}/podDetails/`,
    VIEW_DATA_CATALOG: `/${modulesPrimaryLinks.dataCatalog}/podDetails/:podId`,
    DATA_CATALOG_FILE_UPLOAD: `/${modulesPrimaryLinks.dataCatalog}/file-upload`,

    ACCESS_MANAGEMENT_VIEW: "/accessManagement/",
    ACCESS_MANAGEMENT_POD_DETAILS: "/accessManagement/podDetails/",
    ACCESS_MANAGEMENT_EDIT_POLICY: "/accessManagement/editPolicy/",
    CREATE_ACCESS_POLICY: "/accessManagement/createPolicy/:podId",
    ACCESS_MANAGEMENT_VIEW_POLICY: "/accessManagement/viewPolicy/",
    VIEW_ACCESS_POLICY: "/accessManagement/viewPolicy/:podId",
    EDIT_ACCESS_POLICY: "/accessManagement/editPolicy/:podId",
    EDIT_ACCESS_POLICY_POLICY_ID: "/accessManagement/editPolicy/:policyId",

    VISUALIZATION: "/visualization",
    DASHBOARD: "/dashboard",
    CHART: "/chart",
    EDIT_DASHBOARD: "/dashboard/edit",
    DASHBOARD_ID: "/dashboard/:dashboardID",
    VIEW_CHART: "/chart/:chartId",

    CREATE_DASHBOARD: "/dashboard/create",
    VIEW_DASHBOARD: "/dashboard/view",

    FLOWS: "/flows",
    FLOWS_CREATE: "/createFlows/",
    FLOWS_VIEW: "/viewFlows/",
    FLOWS_EDIT: "/editFlows/",
    CREATE_FLOWS: "/createFlows/:flowId",
    VIEW_FLOWS: "/viewFlows/:flowId",
    EDIT_FLOWS: "/editFlows/:flowId",

    FLOWS_NEW: "/flows/:flowId",


    ALERTS: "/alerts",
    ALERTS_VIEW: "/viewAlert/",
    ALERTS_EDIT: "/editAlert/",
    CREATE_ALERT: "/createAlert",
    VIEW_ALERT: "/viewAlert/:alertId",
    EDIT_ALERT: "/editAlert/:alertId",

    USAGE: "/usage",
    CLUSTERS: "/clusters",

    LINEAGE: "/lineage",
    VIEW_LINEAGE: "/viewLineage/:model/:pk",

    CONNECTIONS: "/connections",

    USER_MANAGEMENT: "/userManagement",
    USER_MANAGEMENT_USER: "/userManagement/user/",
    USER_MANAGEMENT_USER_GROUP_DETAILS: "/userManagement/userGroupDetails/",
    USER_MANAGEMENT_EDIT_USER_GROUP: "/userManagement/editUserGroup/",
    USER_MANAGEMENT_DATA_DOMAIN: "/userManagement/dataDomain/",
    USER_MANAGEMENT_EDIT_DATA_DOMAIN: "/userManagement/editDataDomain/",
    CREATE_USER_MANAGEMENT: "/userManagement/createUserGroup",
    VIEW_USER_MANAGEMENT: "/userManagement/user/:id",
    EDIT_USER_MANAGEMENT: "/userManagement/editUserGroup/:id",
    VIEW_DOMAIN_USER_MANAGEMENT: "/userManagement/dataDomain/:id",
    CREATE_DOMAIN_USER_MANAGEMENT: "/userManagement/createDataDomain",
    EDIT_DOMAIN_USER_MANAGEMENT: "/userManagement/editDataDomain/:id",
    VIEW_USER_GROUP_USER_MANAGEMENT: "/userManagement/userGroupDetails/:id",

    POD_CREATION: "/pod-creation",
    UNSTRUCTURED_POD_CREATION: `/unstructured-pod-creation`,

    SCHEDULER: "/scheduler",
    CREATESCHEDULER: "/scheduler/create",
    SCHEDULER_VIEW: "/scheduler/view/",
    SCHEDULER_EDIT: "/scheduler/edit/",
    VIEWSCHEDULER: "/scheduler/view/:schedulerId",
    EDITSCHEDULER: "/scheduler/edit/:schedulerId",
    CREATESCHEDULER_CRAWLER: "/scheduler/create?schedulerType=crawler",
    CREATESCHEDULER_ALERTFLOWS: "/scheduler/create?schedulerType=alertflows",

    DATA_DICTIONARY: "/data-dictionary",
    DATA_DICTIONARY_VIEW: "/data-dictionary/",
    DATA_DICTIONARY_MANUAL_CREATION: "/data-dictionary/manual-creation",
    VIEW_DATA_DICTIONARY: "/data-dictionary/:dataDictionaryId",
    DATA_DICTIONARY_IMPORT_DB: "/data-dictionary/import-from-db",
    // EDIT_DATA_DICTIONARY: "/data-dictionary/edit/:dataDictionaryId",

    SEARCH_LANDING_PAGE: "/home",
    CLARISTA_NAVIGATOR: "/clarista-navigator",
    CLARISTA_LAB: "/clarista-lab",
    CLARISTA_PULSE: "/clarista-pulse",

    // PULSE_LANDING: "/dev/pulse-landing",
    PULSE_LANDING: "/clarista-pulse",
    DASHBOARD_PULSE: "/clarista-pulse#dashboards",
    PULSE_REPORT_DETAIL: "/clarista-pulse/report/:reportId",
    PULSE_REPORT_DETAIL_VIEW: "/clarista-pulse/report/",
    PULSE_TALK: "/clarista-pulse/talk",
    PULSE_TRACKER_DETAIL: "/clarista-pulse/tracker/:trackerId",
    PULSE_TRACKER_DETAIL_VIEW: "/clarista-pulse/tracker/",
    PULSE_TALK_TRACE: "/clarista-pulse/trace",
    PULSE_UNSTRUCTURED_TALK: "/clarista-pulse/unstructured-talk",


    CLARISTA_HOME: "/home",
  },
  POD_OF_POD: "pod-of-pod",
  POD_OF_POD_API: "POD of PODs",
  //Error Message
  NO_ROW_SELECTED: "No Row Selected",

  SOMETHING_WENT_WRONG: "Something went wrong",
  REGISTER_TECHNICAL_TERM_FAILED:
    "Failed to register technical term with display name",
  EMPTY_FIELD_FOUND_IN_BUSINESS_SCHEMA:
    'Empty field found in "Display Name" column of Schema',
  EMPTY_CATEGORY_FOUND: "Empty Category Found",
  EMPTY_CLASSIFICATION_FOUND: "Empty Classification Found",
  DELETE_SUCCESSFUL: "Successfully Deleted",
  DELETE_FAILED: "Failed to delete",
  ADD_SUCCESSFUL: "Successfully Added",
  ADD_FAILED: "Failed to add",
  UPDATE_SUCCESSFUL: "Successfully Updated",
  UPDATE_FAILED: "Failed to update",
  BUSINESS_TERM_REQUIRED: "Display Name is required",
  FILL_REQUIRED_FIELD: "Please Fill required fields",
  BUSINESS_TERM_DELETE: "Business Term Sucessfully Deleted",
  BUSINESS_TERM_CREATED: "Business Term Sucessfully Created",
  NO_VALID_RECORD_FOUND: "No Valid Records Found To Save",
  BUSINESS_TERM_SAVE_FAILED: "Failed to save display names",
  FLOW_ALREADY_RUNNING_WILL_OPEN_IN_VIEW_MODE:
    "This flow is already in running state, now this flow will open in view mode",
  FLOW_UPDATE_SUCCESSFULL: "Flows Updated Successfully",
  MAX_TWO_SOURCES_ALLOWED_TO_CONNECT_FOR_FILTER_COMPONENT:
    "Maximum two input source allowed to connect for filter component",
  MAX_ONE_SOURCE_ALLOWED_TO_CONNECT_FOR_FILTER_COMPONENET:
    "Maximum one input source allowed to connect for filter component",
  MAX_ONE_SOURCE_ALLOWED_TO_CONNECT_FOR_SCHEMA_COMPONENET:
    "Maximum one input source allowed to connect for schema component",
  EMPTY_FIELD_FOUND_IN_AGGREGATE_TRANSFORMER:
    "Empty field found in aggregate transformer.",
  PLEASE_SELECT_ONE_GROUP_KEY: "Please select atleast one group key",
  PLEASE_SELECT_ONE_DATA_DOMAIN: "Please select atleast one Data Domain",

  DATA_SOURCE_ALREADY_EXIST_PLEASE_SELECT_OTHER_DATASOURCE:
    "This Datasource already exists in the canvas, please select any other Datasource",

  PLEASE_CLOSE_ALREADY_OPENED_CONNECTION_PANEL:
    "Please close the already opened connection panel and then try",
  PLEASE_PROVIDE_EMAIL: "Please Provide Email",
  NO_MORE_USAGE_LOGS_TO_LOAD: "No More Usage Logs to Load",
  UNABLE_TO_REFRESH_USAGE: "Unable to Refresh Usage",
  CANT_KILL_THIS_QUERY_PLEASE_REFRESH_LOGS:
    "Can't kill this query, please refresh logs.",
  CANNOT_SELECT_DATE_GREATER_THAN_CURRENT_DATE:
    "you cannot select date greater than current date",
  PLEASE_ADD_ATLEAST_ONE_STEP_IN_SCHEDULER:
    "Please add atleast one step in scheduler",
  PLEASE_SELECT_ONE_POD: "Please select one pod",
  PLEASE_SELECT_ONE_SCHEDULER: "Please select one scheduler",
  PLEASE_SELECT_ATLEAST_ONE_SCHEDULER: "Please select atleast one pod",
  PLEASE_EXECUTE_ALERT_TRANSFORMER_FOR_RESULT:
    "Please execute alert transformer for result",
  PLEASE_SELECT_ALERT_TRANSFORMER: "Please Select Alert transformer",
  PLEASE_WAIT_FLOWS_CURRENTLY_RUNNING: "Please Wait Flows currently running",
  SELECTED_ALERT_DOES_NOT_EXIST_NOW:
    "Selected alert transformer does not exist now, Please select another alert or flows",
  SELECTED_FLOW_DOES_NOT_EXIST_NOW:
    "Selected flow does not exist now, Please select another alert or flows",
  COPYING_WAS_UNSUCCESSFULL: "Copying was unsuccessful",
  TIMELINE_UPDATED_SUCCESSFULLY: "Timeline updated successfully",
  NO_MORE_TIMELINE_TO_LOAD: "No more timeline to load",
  PLEASE_CLOSE_ALREADY_OPEN_CONNECTION_AND_THEN_TRY:
    "Please close the already opened connection panel and then try",
  AZURE_ADLS_PATH_FIELD_CANNOT_BE_EMPTY:
    "AZURE ADLS PATH field cannot be empty or /.",
  S3_URI_PATH_FIELD_CANNOT_BE_EMPTY: "S3 URI field cannot be empty or /.",
  UNABLE_TO_DELETE_CREDENTIAL: "Unable to delete credential",

  POLICY_NAME_IS_REQUIRED: "Policy Name is required",
  USERGROUPS_ARE_REQUIRED: "User groups are required",
  YOU_ARE_IN_VIEW_MODE: "You are in view mode.",
  POD_CREATION_FAILED: "Pod creation failed",
  POD_UPDATION_FAILED: "Pod updation failed",
  FILE_UPLOAD_CONNECTION_NAME_FAIL: "Failed to get file upload connection name",
  FOLDER_DELETE_SUCCESSFULL: "Folder deleted successfully!",
  FOLDER_DELETE_FAILED:
    "Folder deleted successfully. Please create a new folder to proceed with file uploads.",
  FILL_POD_NAME_FIRST: "Please fill the Pod Name first",
  FILE_ALREADY_EXIST: "file already exist in the list",
  PLEASE_FILL_POD_NAME_FIRST: "Please fill the Pod Name first",
  FILE_FORMAT_NOT_SUPPORTED: "file format not supported",
  PLEASE_CLICK_ON_SAVE_POD_NAME_TO_CREATE_FOLDER:
    "Please click on save pod name to create folder",
  CLICK_ON_SAVE_ICON_TO_CREATE_FOLDER: "Click on save icon to create folder.",
};
