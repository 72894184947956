import React, { useEffect, useMemo } from 'react';
import { useRef, useState } from 'react';
import DataGrid from "react-data-grid";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';
import './LandingSimpleTable.scss'
import { pxToRem, remToPx } from '../../modules/common/helperFunctions';


function isAtBottom({ currentTarget }) {

  return (
    currentTarget.scrollTop + 10 >=
    currentTarget.scrollHeight - currentTarget.clientHeight
  );
}





export const LandingSimpleTable = ({ cols = [], autoWidth, ...rest }) => {


  const [columns, setColumns] = useState([...cols]);
  const [colWidth, setColWidth] = useState();

  useEffect(() => {
    function handleResize() {
      let tempColWidth = parseInt(window.innerWidth / cols.length);

      let tempColumns = [];

      for (let i = 0; i < cols.length; i++) {

        tempColumns.push({ ...cols[i], width: autoWidth ? (tempColWidth - 10) : cols[i].width ?? (tempColWidth - 10) })
      }
      // tempColumns=tempColumns.map(col=>{
      //   return {...col , width:tempColWidth-10}
      // })
      if (cols.length > 0) {
        setColWidth(tempColWidth)
        setColumns(tempColumns)
      }

    }

    window.addEventListener("resize", handleResize)
    handleResize();
    return () => window.removeEventListener("resize", handleResize)
  }, [cols])
  return (
    <>
      {colWidth && columns.length ? <LandingSimpleTableInner cols={columns} {...rest} /> : ""}
    </>
  )

}

export const LandingSimpleTableInner = ({
  cols = [],
  rowsData = [],
  rowHeight = remToPx(pxToRem(41)),
  isTableIDPreset = () => { },
  getComparator = () => { },
  onRowClick = () => { },
  tableHeight = undefined,
  showCursor = true,
  loadMore = false,
  removeBorders = false,
  selectedRowIndex = undefined,
  onScrollToBottom = () => { },
  autoScroll = false,
  scrollToRow = undefined,
  virtualization = true,
  resizable = true,
  ...props
}) => {

  const gridRef = useRef();
  if (gridRef.current) {
    isTableIDPreset(true)
  }
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([...rowsData])
  const [currentRowsCount, setCurrentRowsCount] = useState(rows?.length)
  const [, setScrollValue] = useState(currentRowsCount) // in use for auto scroll after load more data.
  // const [rowSelectedIndex, setRowSelectedIndex] = useState()
  const [sortColumns, setSortColumns] = useState([]);
  const counter = useRef(0)









  useEffect(() => {

    setRows([...rowsData])
  }, [rowsData])



  useEffect(() => {
    setColumns([...cols])
  }, [cols])

  useEffect(() => {
    // This row count is for auto scroll after loading more data
    setCurrentRowsCount(rows?.length)
  }, [rows])

  useEffect(() => {
    if (scrollToRow) {
      gridRef?.current?.scrollToCell({ rowIdx: scrollToRow })
    }
  }, [scrollToRow])

  useEffect(() => {
    if (counter.current === 0) return
    setScrollValue(prev => {
      if (autoScroll) {
        gridRef?.current?.scrollToCell({ rowIdx: Number(prev - 1) })
      }
      return currentRowsCount
    })
  }, [currentRowsCount, autoScroll])

  /* const draggableColumns = useMemo(() => {

      const HeaderRenderer = (props) => {
  
        return (
          <DraggableHeaderRenderer
            {...props}
            onColumnsReorder={handleColumnsReorder}
          />
        );
      };
  
  
      // column re-ordering
      const handleColumnsReorder = (sourceKey, targetKey) => {
        const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
        const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
  
        const reorderedColumns = [...columns];
  
        reorderedColumns.splice(
          targetColumnIndex,
          0,
          reorderedColumns.splice(sourceColumnIndex, 1)[0]
        );
  
        setColumns(reorderedColumns);
      };
  
      return columns.map((c) => ({ ...c, headerRenderer: HeaderRenderer }));
    }, [columns]); */

  const handleScroll = (event) => {
    onScrollToBottom(isAtBottom(event))
    counter.current += 1
    if (!isAtBottom(event)) return;
    // setLoadMore(true)
  };

  const sortedRows = useMemo(() => {
    if (sortColumns?.length === 0) return rows;
    const sortedRows = [...rows];
    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sortedRows;
  }, [rows, sortColumns]);


  return <>

    <div id="LandingSimpleTableID" className={`lan-sim-tbl ${showCursor ? 'show-hand-cursor' : ''}`} style={{ height: tableHeight !== undefined ? sortedRows.length === 0 ? '82px' : tableHeight : tableHeight, position: 'relative' }}>
      <DndProvider backend={HTML5Backend}>
        {
          //  columns[0] && columns[0].width ?
          <DataGrid
            ref={gridRef}
            className={`custom-simple-landing-dgt ${removeBorders?'remove-table-borders':''} ${tableHeight !== undefined ? 'h-100' : ''}`}
            columns={columns}
            rows={sortedRows}
            defaultColumnOptions={{
              sortable: true,
              resizable: resizable,
            }}
            onSortColumnsChange={setSortColumns}
            onRowsChange={setRows}
            sortColumns={sortColumns}
            enableVirtualization={virtualization}
            onScroll={handleScroll}
            rowClass={(record, index) => (selectedRowIndex && record.key === selectedRowIndex) || (selectedRowIndex === 0 && record?.key && index === 0) ? 'table-row-selected' : ''}
            renderers={
              {
                noRowsFallback: <div style={{ gridColumn: '1/-1' }} className='p-2 text-black-50 text-center fontSizeHeading'>No Records Found</div>
              }
            }

            onCellClick={({ row, ...rest }, index, e) => { onRowClick(row, index, e) }}

            onRowClick={(record, index, e) => { onRowClick(record, index, e) }}
            rowHeight={rowHeight}
            {...props}
          />
          // : ''
        }
        {
          loadMore ?
            <div className='load-more-dgt-wrapper'>
              <div className="load-more-dgt">
                <i className="bx bx-loader-alt bx-spin mr-2"></i>
                <label className="small mb-0">
                  Loading More...
                </label>
              </div>
            </div>
            : ''
        }
      </DndProvider>
    </div>
  </>;
};
