import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Literals } from './literals'
import { getUserSelectedDomain } from '../../../store/actions'

const DomainTopStrip = ({
    isLight = false
}) => {

    const dispatch = useDispatch()

    const { userSelectedDomain } = useSelector(state => state.UserManagement.DataDomain)
    const { activeRoute } = useSelector(state => state.Layout)

    React.useEffect(() => {
        if(userSelectedDomain === null) {
            dispatch(getUserSelectedDomain())
        }
    }, [])

    const checkLinks = () => {
        const stLinks = Literals.links // storedLinks
        const links = [
            stLinks.CLUSTERS,
            stLinks.USER_MANAGEMENT,
            stLinks.USAGE,
            stLinks.CONNECTIONS
        ]
        const res = links?.includes(activeRoute)
        // const res = links?.includes('/'+window.location?.pathname)
        return !res
    }

    return <>
        {
            !!userSelectedDomain && userSelectedDomain?.name && checkLinks()
            ?   <div className={`domain-indicator-strip ${isLight ? 'dark' : ''}`}>
                    {userSelectedDomain?.name?.replaceAll("_", " ")}
                </div>
            :   ''
        }
    </>
}

export default DomainTopStrip