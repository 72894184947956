import { Avatar, Checkbox, Collapse, Radio, Tooltip } from 'antd'
import React, { useEffect, useState ,useRef} from 'react'
import { Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Buttons from '../../../Common/button/Buttons'
import { ACCORDIONDOWNARROW, CLOSE, DELETEICON, EDIT, PLUS, USERTAG } from '../../../Common/iconSource'
import { LandingSimpleTable, LandingSimpleTableInner } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import SearchInput from '../../../Common/search/Search'
import {useDispatch} from 'react-redux';
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { getRelativeTime,getDeleteMessage, getReloadErrorTemplate, NoDataComponent, NonPII, pxToRem, remToPx } from '../../common/helperFunctions';
import { getFieldTypeIcon, showSimpleLoading } from '../../common/helperFunctions'
import {getClassificationData,deleteClassificationData} from "../../../../store/modules/dataDictionary/action";
import { Literals } from '../../common/literals'
import SettingsClassificationModal from './SettingsClassificationModal'
import ActionPopUp from '../../common/actionPopUp'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { ICON_USER_TAG } from '../../../Common/newIconSource'
import _ from 'lodash'
import Draggable from 'react-draggable'
import ClaristaCopilot from '../../copilot'
import ContainsPII from './ContainsPII'

const dataList = [
    {
        id: 1,
        data_category: 'ESG Ratings',
        data_type: 'varchar',
        business_term: 'Issuer Name',
        technical_terms: ['carbon_risk.issuer_name', 'carbon_risk.issuer_name', 'carbon_risk.issuer_name', 'carbon_risk.issuer_name'],
        description: 'Weekly datasets of covid postitive peopls, a demo data description.',
        consider_training: true
    }
]


export const ClassificationTable=({
    selected,
    setSelected,
    classificationsPresent, 
    openCreateModal = false,
    selectedSingle,
    setSelectedSingle = () => {}
})=>{
    const dispatch=useDispatch();
    const rawData=useRef([]);

    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(undefined);
    const [selectedIndex, setSelectedIndex] = useState(null);
    console.log({selectedSingle})
    React.useEffect(() => {
        if(selectedSingle) {
            setSelectedIndex(selectedSingle?.id)
        }
    }, [selectedSingle])
    
    const onSelect=(record)=>{
        const isChecked=selected.filter(item=>item.id==record.id).length>0;
        console.log({selected, record, isChecked})
        if(isChecked){
          setSelected(selected.filter(item=>item.id!==record.id));
        }
        else{
          setSelected([...selected,record])
        }
        
    }
    
    const generateRow = (data) => {
        let temp=[...data];
        // if(classificationsPresent){
        //     let classificationsPresentTemp=Object.keys(classificationsPresent).join(" ");
        //     temp = data.filter(item=>classificationsPresentTemp.includes(item.name))
        // }
        

       temp=temp.map((item, index) => {
            // const isChecked=selected.filter(row=>item.id==row.id)[0];
            
            return {
                id:item.id,
                key:item.id,
                data: item,
                active: selectedSingle?.id === selectedIndex,
                classification_name: <>
                    <div class="text-uppercase dict-color-dark text-with-ellipsis fontSizeLabel">{item.name}</div>
                </>,
                description: listTemplate.description(item.description),
                created_on: listTemplate.createdOn(item.updated_on),
                pattern: listTemplate.pattern(item.pattern ?? ''),
                index
            }
            
            // return {
            //     id:item.id,
            //     classification_name: listTemplate.classificationName(item.name,true,item,isChecked,onSelect, true),
            //     description: listTemplate.description(item.description),
            //     created_on: listTemplate.createdOn(item.updated_on),
            //     pattern: listTemplate.pattern(item.pattern ?? '')
               
            // }
        })

        return temp
    }
    const [data, setData] = useState([])
    const getList = () => {
        setLoading(true)
        dispatch(getClassificationData(false, false))
        .then(res=>{
            let temp = generateRow(res?.data)
            // temp = _.sortBy(temp, [o => o?.id !== selectedIndex])
            // console.log({temp, selectedIndex})
            setData([...temp])
            rawData.current=[...res?.data];
            setLoading(false)
        })
        .catch(err=>{
            setErrMsg(err?.message)
            setData([])
            setLoading(false)
        })
    }
    useEffect(()=>{
        let temp=  generateRow(rawData.current)
        setData([...temp])
    },[selected])

    useEffect(()=>{
        if(!openCreateModal) {
            getList();
        }
    },[openCreateModal])

    const onRowClick = (record) => {
        console.log({record})
        setSelectedSingle(record?.data)
        setSelected([record?.data]);
        if(record?.active) {
            setSelectedIndex(null)
        }
        else {
            setSelectedIndex(record.id);
        }
    }

    return (
        <>
            {
                errMsg ? getReloadErrorTemplate({errorMessage: errMsg, onReload: getList})
                :
                loading ? <ClaristaLoader/>
                :
                <LandingSimpleTable
                    rowsData={
                        (() => {
                            let dat = [...data]
                            dat = _.sortBy(dat, [o => o?.id !== selectedIndex])
                            return dat ?? []
                        })()
                    }
                    cols={modalColumns}
                    tableHeight={'100%'}
                    onRowClick={onRowClick}
                    selectedRowIndex={selectedIndex}
                />
            }
        </>
    )
}

export const SettingsClassifications = ({
    searchText = '',
    setClassifiersModal = () => {},
    openClassifiersModal = false,
    hideActions = false,
    defaultOpenId
}) => {
    const dispatch=useDispatch();
    const [data, setData] = useState(null)
    const [rawData, setRawData] = useState([])
    const [searchData, setSearchData] = useState('')
    const [openModal, setOpenModal] = useState(openClassifiersModal ?? false)
    const [viewOnly,setViewOnly]=useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [selectedData, setSelectedData] = useState({name:'', description: ''})
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [empty, setEmpty] = useState(false);
    const [activeDrags, setActiveDrags] = React.useState(0)

    const getList = () => {
        setError(undefined)
        setEmpty(false)
        setLoading(true)
        dispatch(getClassificationData(false, false))
        .then(res => {
            let temp = generateRow(res?.data)
            setData([...temp])
            setRawData([...res.data])
            setEmpty(res?.data?.length === 0)

            if(!!res?.data && defaultOpenId) {
                const item = res?.data?.find(d => d?.id?.toString() === defaultOpenId?.toString())
                if(!!item) {
                    setTimeout(() => {
                        setIsEdit(true)
                        setViewOnly(true);
                        setSelectedData({...item})
                        setOpenModal(true) 
                    }, 1000) 
                }
            }

            setLoading(false)
        })
        .catch(err => {
            setData([])
            setEmpty(false)
            setError(err?.message)
            setLoading(false)
        })
    }

    useEffect(()=>{
       getList();
    },[])

    useEffect(() => {
        setSearchData(searchText)
    }, [searchText])

    useEffect(() => {
        setClassifiersModal(openModal)
    }, [openModal])

    useEffect(() => {
        setOpenModal(openClassifiersModal)
    }, [openClassifiersModal])

    useEffect(()=>{
        if(data!==null) {
            const FILTER_COLUMNS = ['name', 'description', 'created_on']
            let clone = [...rawData]
            let newData = clone.filter(item=>FILTER_COLUMNS.some(key=>{
                if(key==='created_on') {
                    item[key] = getRelativeTime(item[key])
                }
                return item[key].toLowerCase().includes(searchData.toLowerCase())
            }))
            let temp = generateRow(newData)
            setData([...temp])
        }
    },[searchData])

    const openDeleteDialog = (data) => {
        setDeletePopUpContent({
            message: data.name,
            action: (force = false) => {
                setData(null)
                dispatch(deleteClassificationData(data.id, force))
                .then(()=>{
                    getList()
                    emitToastNotification('success', Literals.DELETE_SUCCESSFUL)
                    setOpenDeletePopup(false);
                })
                .catch(()=>{
                    getList()
                    emitToastNotification('error', Literals.DELETE_FAILED)
                })
            },
        });
        setOpenDeletePopup(true);
      };

    const action = {
        onEdit: (data) => {
            setIsEdit(true)
            setSelectedData({...data})
            setOpenModal(true)
        },
        onDelete: (data) => openDeleteDialog(data)
    }    
    const generateRow = (data) => {
        let temp = data.map((item) => {
            return {
                id:item.id,
                classification_name: listTemplate.classificationName(item.name),
                description: listTemplate.description(item.description),
                data_owner: '-',
                data_steward: '-',
                pii: listTemplate.pii(item?.pii ?? false),
                // pattern: listTemplate.pattern(item?.pattern ?? ''),
                created_on: listTemplate.createdOn(item.updated_on),
                action: listTemplate.action(action, item)
            }
        })
        return temp
    }


    const onRowClick = (data) => {
        setIsEdit(true)
        setViewOnly(true);
        let pureData=rawData.find(item=>item.id==data.id);
        if(pureData) {
            setSelectedData({...pureData})
        }
        setOpenModal(true)    
    }

    const accordionBtns = () => {
        return <>
            <span className='d-flex' style={{gap:8}}>
                <Buttons
                    props={{
                        buttonText: "",
                        tooltip: "Delete",
                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red red-border`,
                        buttonEvent: () => {
                            
                        },
                        ImgSrc: () => <DELETEICON />,
                        isDisable: false,
                        buttonType: Literals.BTN_SECONDARY,
                    }}
                />
                <Buttons
                    props={{
                        buttonText: "",
                        tooltip: "Edit",
                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon`,
                        buttonEvent: (e) => {
                            e.stopPropagation();
                        },
                        ImgSrc: () => <EDIT />,
                        isDisable: false,
                        buttonType: Literals.BTN_SECONDARY,
                    }}
                />
                <span className='class-acc-ic'>
                    <ACCORDIONDOWNARROW />
                </span>
            </span>
        </>
    }

    const onStart = () => {
        setActiveDrags(prev => ++prev)
    }

    const onStop = () => {
        setActiveDrags(prev => --prev)
    }

    return (
        <div className='h-100'>
            <Card className='card custom-card p-0 h-100 border-0 shadow-none'>
                {/* <CardHeader className='padding-2 bg-white'>
                    <div style={{height: 27}} className='d-flex align-items-center w-100 justify-content-between'>
                        <label className='mb-0 d-flex align-items-center font-weight-bold'>
                            {
                                searchData?.trim() !== ''
                                ?   <div className='dict-color-dark fontSizeLabel fontInterSemiBold'>
                                        Showing {data?.length} result(s) for "{searchData}"
                                    </div>
                                :   <div className='dict-color-dark fontSizeLabel fontInterSemiBold'>
                                        {data?.length} Record(s)
                                    </div>
                            }
                        </label>
                        {
                            hideActions ? ''
                            :
                            <div className='d-flex'>
                                <SearchInput searchData={searchData} setSearchData={setSearchData}/>
                                <Buttons
                                    props={{
                                        buttonText: 'Classification',
                                        tooltip: '',
                                        buttonClassName: "custom-btn-primary custom-btn btn-with-text ml-2",
                                        buttonEvent: () => setOpenModal(true),
                                        ImgSrc: () => <PLUS/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />
                            </div>
                        }
                    </div>
                </CardHeader> */}
                <CardBody className='p-0 dict-classifiers-items'>
                  
                        {
                            empty ? <NoDataComponent logo={<ICON_USER_TAG/>} message={'Click on "+" button to Add Classification'} />
                            : error ? getReloadErrorTemplate({errorMessage: error, onReload: () => getList()})
                              : loading ? <ClaristaLoader/>
                                :
                                    data===null
                                    ?
                                    showSimpleLoading()
                                    :
                                    data.length
                                    ?
                                    <LandingSimpleTableInner
                                        key={'settingsClassifierTable'}
                                        rowsData={data}
                                        cols={columns}
                                        onRowClick={onRowClick}
                                        tableHeight={'100%'}
                                    />
                                
                                    : <div className='p-3 text-center text-black-50'>No Classification Found</div>
                        }
                        {/* </Collapse.Panel> */}
                    {/* </Collapse> */}
                </CardBody>
            </Card>

            {/* <Draggable 
                onStart={onStart}
                onStop={onStop}
                handle='.c-cpilot-sec-wrap, .cpilot-pop-head'
                axis='x'
                bounds={{top: 0, left: -(document.querySelector('.dict-setting-pg-wrap')?.clientWidth ? document.querySelector('.dict-setting-pg-wrap').clientWidth - 400 : 700), right: 0, bottom: 0}}
            >
                <div style={{zIndex: 12, position: 'relative'}}>
                    <ClaristaCopilot
                        type="classifier"
                        extraProps={{
                            getList
                        }}
                    />
                </div>
            </Draggable> */}

            <SettingsClassificationModal
                openModal={openModal}
                viewOnly={viewOnly}
                setViewOnly={setViewOnly}
                setOpenModal={setOpenModal}
                getList={getList}
                selectedData={selectedData}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
            />

            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={getDeleteMessage({
                    title: 'Classification'
                })}
                onConfirm={() => deletePopUpContent.action()}
            />
        </div>
    )
}
const listTemplate = {
    classificationName: (text = '',check,row,isChecked,onSelect, isRadio) => {
       return <>
          <div className='d-flex align-items-center h-100'>

            {
            isRadio
            ?
            <Radio
                key={row.id}
                onChange={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    onSelect(row)
                }}
                checked={isChecked}
            />
            :
            check
            ? <Checkbox
              key={row.id}

              onChange={(e)=>{
               e.stopPropagation();
               e.preventDefault();
                onSelect(row)
              }}
               checked={isChecked}
              // onChange={(e) => {
              //   e.stopPropagation();
              //   e.preventDefault();
              //   addToSelectedPOD(row[0], e, row.table_name, row);
              // }}
              className="pr-2"

            ></Checkbox>:""}
            <Tooltip placement='topLeft' title={text?.toUpperCase()}>
                <div className="text-uppercase dict-color-dark text-with-ellipsis fontSizeLabel">
                {text}
                </div>
              {/* <label style={{lineHeight: '1.4'}} className='mb-0 classificationTag primary text-uppercase'>
                {   
                    text.length>9
                    ? text?.slice(0, 8) + '...'
                    : text
                }  
              </label> */}
            </Tooltip>
          </div>
      </>
    },
    description: (text = '') => {
        return <>
            {
                text === ''
                ?   <div className='text-muted'>No Description</div>
                :
                <Tooltip placement='topLeft' title={text}>
                    <div className='text-with-ellipsis'>
                        {text}
                    </div>
                </Tooltip>
            }
        </>
    },
    pattern: (text = '') => {
        return <>
            {
                text === ''
                ?   <div className='text-black-50'>N/A</div>
                :
                <Tooltip placement='topLeft' title={text}>
                    <div className='text-with-ellipsis'>
                        {text}
                    </div>
                </Tooltip>
            }
        </>
    },
    createdOn: (text = '') => {
        return <>
            <Tooltip placement='topLeft' title={getRelativeTime(text)}>
                <div className='text-with-ellipsis'>
                    {getRelativeTime(text)}
                </div>
            </Tooltip>
        </>
    },
    pii: (pii = false) => {
        if(pii) return <>
            <div className='d-flex h-100 align-items-center'>
                <ContainsPII/>
            </div>
        </>
        return <>
            <div className='d-flex h-100 align-items-center'>
                <NonPII/>
            </div>
        </>
    },
    dataOwner: (owners = [
        {first_name: 'Clarista', last_name: 'Admin'},
        {first_name: 'Clarista', last_name: 'BAdmin'},
        {first_name: 'Clarista', last_name: 'CAdmin'},
        {first_name: 'Clarista', last_name: 'DAdmin'},
        {first_name: 'Clarista', last_name: 'EAdmin'}
    ]) => {
        owners = owners.map((v) => `${v.first_name} ${v.last_name}`);
        return (
          <>
            <div className="d-flex category-table dict-custom-avt">
              <Avatar.Group maxCount={3}>
                {owners.map((name, i) => {
                  let nameArr = name?.split(' ')?.map(v => v?.[0] ?? '')
                  return (
                    <Tooltip placement="topLeft" title={name}>
                      <Avatar
                        style={{ backgroundColor: "#2c83c4", color: "white" }}
                      >
                        {
                          nameArr?.length === 2
                          ?
                          nameArr?.[0] + nameArr?.[1] ?? ''
                          :
                          nameArr?.[0] ?? ''
                        }
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </Avatar.Group>
            </div>
          </>
        );
      },
      dataStewards: (stewards = []) => {
        stewards = stewards.map((v) => `${v.first_name} ${v.last_name}`);
        return (
          <>
            <div className="d-flex category-table dict-custom-avt">
              <Avatar.Group maxCount={3}>
                {stewards.map((name, i) => {
                  let nameArr = name?.split(' ')?.map(v => v?.[0] ?? '')
                  return (
                    <Tooltip placement="topLeft" title={name}>
                      <Avatar
                        style={{ backgroundColor: "#2c83c4", color: "white" }}
                      >
                        {
                          nameArr?.length === 2
                          ?
                          nameArr?.[0] + nameArr?.[1] ?? ''
                          :
                          nameArr?.[0] ?? ''
                        }
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </Avatar.Group>
            </div>
          </>
        );
      },
    action: (action = {}, data = {}) => {
        return <>
            <div className='d-flex justify-content-end align-items-center h-100'>
                <Buttons
                    props={{
                        buttonText: "",
                        tooltip: "Edit",
                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent`,
                        buttonEvent: (e) => {
                            e.stopPropagation();
                            action.onEdit(data)
                        },
                        ImgSrc: () => <EDIT />,
                        isDisable: false,
                        buttonType: Literals.BTN_SECONDARY,
                    }}
                />
                <Buttons
                    props={{
                        buttonText: "",
                        tooltip: "Delete",
                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red bg-transparent border-0 ml-2`,
                        buttonEvent: (e) => {
                            e.stopPropagation()
                            action.onDelete(data)
                        },
                        ImgSrc: () => <DELETEICON />,
                        isDisable: false,
                        buttonType: Literals.BTN_SECONDARY,
                    }}
                />
            </div>
        </>
    }
}

const dynamic = (px) => remToPx(pxToRem(px))

const modalColumns=[
    {
        name: 'Classifiers',
        key: "classification_name",
        sortable: false,
        resizable: false,
        width:"33%",
    },
    {
        name: 'Description',
        key: "description",
        sortable: false,
        resizable: false,
        width: "33%"
    },
    {
        name: 'Pattern',
        key: "pattern",
        sortable: false,
        resizable: false,
        width: 120,
    },
    {
        name: 'Created On',
        key: "created_on",
        sortable: false,
        resizable: false,
        // width:"33%",
    }
]
const columns = [
    {
        name: 'Classifiers',
        key: "classification_name",
        sortable: false,
        resizable: false,
        width: dynamic(250),
    },
    {
        name: 'Description',
        key: "description",
        sortable: false,
        resizable: false,
        width: dynamic(360)
    },
    {
        name: 'PII',
        key: "pii",
        sortable: false,
        resizable: false,
        width: dynamic(150)
    },
    { 
        name: 'Data Owner',
        key: "data_owner",
        sortable: false,
        resizable: false,
        // width: dynamic(200)
    },
    { 
        name: 'Data Steward',
        key: "data_steward",
        sortable: false,
        resizable: false,
        // width: dynamic(200)
    },
    // {
    //     name: 'Created On',
    //     key: "created_on",
    //     sortable: false,
    //     resizable: false,
    //     width:"20%",
    // },
    {
        name: <div className='text-right'>Actions</div>,
        key: "action",
        sortable: false,
        resizable: false,
        width: undefined
        // width: dynamic(100)
    }
]
