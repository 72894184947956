import { Switch, Tooltip } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import SingleSelection from '../../../../Common/dropDown/SingleSelection'
import { ICON_INFO_FILLED, ICON_WARNING } from '../../../../Common/newIconSource'
import { getGlobalConnectionCache } from '../../../../../store/modules/UserManagement/connection/connectionActions'

const EXPIRY_OPTIONS = [
    // { Name: '1 hour', value: 60 },
    // { Name: '3 hours', value: 180 },
    // { Name: '6 hours', value: 360 },
    // { Name: '12 hours', value: 720 },
    { Name: '1 day', value: 1440 },
    // { Name: '3 days', value: 4320 },
    { Name: '7 days', value: 10080 },
    { Name: '15 days', value: 21600 },
    { Name: '30 days', value: 43200 },
]

const StoreCacheOption = ({
    isEditMode = false,
    isEditing = false,
    disabled = false
}) => {

    const dispatch = useDispatch()

    const podData = useSelector(state => state?.DataCatalogue.Dictionary.dictionaryPodData)
    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)

    const [show, setShow] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [showAccessErr, setShowAccessErr] = React.useState(false)

    const [selectedExpiry, setSelectedExpiry] = React.useState((() => {
        if(reduxData?.cacheExpiry) return reduxData?.cacheExpiry
        return EXPIRY_OPTIONS[3]?.value ?? EXPIRY_OPTIONS[0]?.value
    })())

    const [checked, setChecked] = React.useState((() => {
        if(podData?.has_policy) {
            return false
        }
        return !!reduxData?.cacheEnabled
    })())

    React.useEffect(() => {
        setLoading(true)
        dispatch(getGlobalConnectionCache())
        .then(res => {
            setShow(!!res?.data)
            setLoading(false)
        })
        .catch(err => {
            console.error(err?.message)
            setShow(false)
            setLoading(false)
        })
    }, [])

    React.useEffect(() => {
        setShowAccessErr(!!podData?.has_policy)
    }, [podData])

    const handleCache = (bool) => {

        if(showAccessErr) return

        setChecked(bool)
        reduxData['cacheEnabled'] = bool
        if(bool) {
            reduxData['cacheExpiry'] = selectedExpiry
        }
        dispatch(setPodCreationData(reduxData))
    }

    const handleCacheExpiry = (expiry) => {
        setSelectedExpiry(expiry)
        reduxData['cacheExpiry'] = expiry
        dispatch(setPodCreationData(reduxData))
    }
    
    const isDisabled = React.useMemo(() => {
        if(!show || showAccessErr) return true
        if(isEditMode) return !isEditing
        return false
    }, [isEditMode, isEditing, show, showAccessErr])

    const NoCacheTemplate = () => {
        return <Tooltip title="Create a cache connection to use Cache functionality" placement='bottom'>
            <div className='d-flex align-items-center text-black-50 fontSizeLabel fontInterSemiBold'>
                <span className='d-flex mr-1'>
                    <ICON_WARNING color='currentColor' height='.8rem' width='.8rem' />
                </span>
                Cache Not Configured
            </div>
        </Tooltip>
    }

    return (
        loading ? ''
        :
        <React.Fragment>
            <div className='d-flex align-items-center'>
                {
                    show && checked 
                    ?   <>
                            <label className='mb-0 mr-2 fontSizeLabel fontInterSemiBold text-dark'>Cache Expiry</label>
                            <div style={isEditMode && !isEditing ? {maxWidth: '6rem'} : {width: '6rem'}} className='mr-2'>
                                <SingleSelection
                                    props={{
                                        placeholder: "Select",
                                        dropDownValue: selectedExpiry ?? '',
                                        onClickEvent: (value) => {
                                            handleCacheExpiry(value)
                                        },
                                        optionList: EXPIRY_OPTIONS,
                                        isDisable: isDisabled || disabled,
                                        selectClassName: '',
                                        optionClassName: '',
                                        isSortOptionDisable: true,
                                    }}
                                />
                            </div>
                        </>
                    :   ''
                }
                <label className='mb-0 mr-2 fontSizeLabel fontInterSemiBold text-dark d-flex align-items-center'>
                    Store Cache 
                    <Tooltip 
                        placement='bottom' 
                        title={`Enabling "Store Cache" in the POD allows data from query executed on this POD to be cached for improved performance. The data will also be cached for charts and dashboards created from this POD.`}
                    >
                        <span className='d-flex align-items-center ml-1'>
                            <ICON_INFO_FILLED color='currentColor' height='.8rem' width='.8rem' />
                        </span>    
                    </Tooltip>
                </label>
                <Tooltip
                    placement='top' 
                    title={
                        !show ? 'Create a cache connection to use this functionality'
                        :
                        showAccessErr
                        ? "This POD has an active access policy. To enable caching, please remove the existing access policy first."
                        : ''
                    }
                >
                    <Switch
                        key={'cacheEn-' + checked ? '1' : '0'}
                        className='custom-switch-btn'
                        checkedChildren="Yes" unCheckedChildren="No"
                        size='small'
                        defaultChecked={podData?.has_policy || !show ? undefined : checked}
                        checked={podData?.has_policy || !show ? false : undefined}
                        // checked={checked}
                        onChange={(chk) => handleCache(chk)}
                        disabled={isDisabled || disabled}
                    />
                </Tooltip>
            </div>
        </React.Fragment>
    )
}

export default StoreCacheOption