
import React from 'react'
import { useDispatch } from 'react-redux'
import { createTenantOkta, getSAMLTenant, changeSAMLProviderMFAStatus, getTenantMicrosoft } from '../../../../../store/modules/UserManagement/users/usersActions'
import { ICON_ALERT, ICON_CHECK, ICON_LOADING_CIRCLE } from '../../../../Common/newIconSource'
import SingleSelection from '../../../../Common/dropDown/SingleSelection'
import { Switch } from 'antd'
import { getObjectLength } from '../../../common/helperFunctions'

const OktaClientForm = React.forwardRef(({
    fieldValues = {},
    setFieldValues = () => { },
    loading = false,
    setLoading = () => { },
    isActiveToggle,
    setisActiveToggle,
    saving = false,
    setSaving = () => { }
}, ref) => {

    const dispatch = useDispatch()

    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showError, setShowError] = React.useState(false)

    React.useImperativeHandle(ref, () => {
        return {
            save: () => {
                handleSave()
            }
        }
    }, [fieldValues])

    React.useEffect(() => {
        setFieldValues(prev => (
            {
                ...prev,
                saml: {
                    metadata_url: '',

                    provider: 'SAML',

                    mfa_enabled: false,
                    is_active: false,
                    type: 'SAML'
                }
            }
        ))
        getDetails();
    }, [])
    // console.log({fieldValues})
    const getDetails = () => {
        setLoading(true)
        dispatch(getTenantMicrosoft()).then(res => {
            let data = res?.data ?? []
            let el = {};
            if (data?.length) {
                el = data?.find(item => item.type?.toLowerCase() === 'saml');
            }
            // .then(res => {
            //     // let data = res?.data ?? []
            //     // let el = data;
            if (getObjectLength(el)) {
                setFieldValues(prev => (
                    {
                        ...prev,
                        saml: {
                            metadata_url: el?.metadata_url,
                            provider: el?.provider,
                            mfa_enabled: el?.mfa_enabled ?? false,
                            is_active: false,
                            type: el?.type ?? 'SAML'
                        }
                    }
                ))
                setisActiveToggle(el?.is_active)
            }

            setLoading(false)
        })
            .catch(err => {
                setLoading(false)
            })
    }

    const handleSave = () => {
        setShowError(false)
        setShowSuccess(false)
        setSaving(true)
        let values = fieldValues?.saml
        let payload = {
            ...values,
            entity_id: values?.metadata_url,
        }
        setTimeout(() => {
            dispatch(createTenantOkta(payload))
                .then(res => {
                    setSaving(false)
                    setShowSuccess(true)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 5000)
                })
                .catch(err => {
                    setSaving(false)
                    setShowError(true)
                    setTimeout(() => {
                        setShowError(false)
                    }, 5000)
                })
        }, 5000)
    }

    const onInputHandler = (key, value) => {

        setFieldValues(prev => {
            return {
                ...prev,
                saml: {
                    ...prev?.saml,
                    [key]: value
                }
            }
        })
    }

    return (
        <div>
            <div className='row'>
                <div className="col-12 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Meta Data URL
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="text"
                        value={fieldValues?.saml?.metadata_url ?? ''}
                        onChange={(e) => {
                            onInputHandler('metadata_url', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                {/* <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Type
                        </label>
                    </div>
                    <SingleSelection
                        props={{
                            label: "",
                            placeholder: "Select Type",
                            dropDownValue: fieldValues?.saml?.type,
                            onClickEvent: (value) => onInputHandler('type', value),
                            optionList: [{ Name: 'SAML', Value: 'SAML' }, { Name: 'OAuth2', Value: 'OAuth2' }],
                            isDisable: false,
                        }}
                    />
                   
                </div> */}

                {/* <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            SAML Provider
                        </label>
                    </div>
                    <SingleSelection
                        props={{
                            label: "",
                            placeholder: "Select Type",
                            dropDownValue: fieldValues?.saml?.provider,
                            onClickEvent: (value) => onInputHandler('provider', value),
                            optionList: [{ Name: 'SAML', value: 'SAML' }, { Name: 'Microsoft Azure AD', value: 'microsoft' }],
                            isDisable: false,
                        }}
                    />

                </div> */}
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Two-Factor Authentication<span style={{ fontSize: "9px", fontStyle: "italic", }}>{"On (TOTP Authenticator)"}</span>
                        </label>
                    </div>
                    <Switch
                        size='small'
                        checked={fieldValues?.saml?.mfa_enabled}
                        disabled={!fieldValues?.saml?.metadata_url}
                        onChange={(checked) => {
                            onInputHandler('mfa_enabled', checked)
                            dispatch(changeSAMLProviderMFAStatus(true, 'SAML', checked)).then((res) => {

                            })

                        }}
                    />
                </div>

                {
                    showSuccess
                        ?
                        <div className='col-12 mt-2'>
                            <div className='bg-light border border-success fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-success'>
                                <span className='mr-2'><ICON_CHECK width='16' height='16' color='currentColor' /></span>
                                <span>
                                    Succesfully created SAML Client
                                </span>
                            </div>
                        </div>
                        : ''
                }
                {
                    saving
                        ?
                        <div className='col-12 mt-2'>
                            <div className='bg-light border border-primary fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-primary'>
                                <span className='mr-2'><ICON_LOADING_CIRCLE width='16' height='16' color='currentColor' /></span>
                                <span>
                                    Creating SAML Client
                                </span>
                            </div>
                        </div>
                        : ''
                }
                {
                    showError
                        ?
                        <div className='col-12 mt-2'>
                            <div className='bg-light border border-danger fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-danger'>
                                <span className='mr-2'><ICON_ALERT width='16' height='16' color='currentColor' /></span>
                                <span>
                                    Failed to create SAML Client
                                </span>
                            </div>
                        </div>
                        : ''
                }
            </div>
        </div>
    )
})

export default OktaClientForm