import React from 'react'
import './podCreation.scss'
import PodCreationHeader from './components/PodCreationHeader'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import SelectConnection from './components/SelectConnection'
import { ConnectionProvider } from '../../connections/landingPage/components/ConnectionContext'
import PodCreationSource from './components/PodCreationSource'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { resetPodCreationData, setPodCreationData } from '../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import PodCreationSchema from './components/PodCreationSchema'
import PodCreationSaveDetails from './components/PodCreationSaveDetails'
import { getConnectionNameProperties, setConnectionSelectedConn } from '../../../../store/modules/UserManagement/connection/connectionActions'
import { NoDataComponent, getObjectLength, getSimpleTutorialMessageTemplate } from '../../common/helperFunctions'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { useNavigate, useParams } from 'react-router-dom'
import { getDataDomainList, getUserSelectedDomain, setDataDomainList } from '../../../../store/actions'
import { Literals } from '../../common/literals'
import _, { isArray } from 'lodash'
import Walkthrough from '../../../Common/walkthrough/Walkthrough'
import useHideSidebar from '../../../../customHooks/useHideSidebar'
import { ICON_CATALOG_DOMAIN, ICON_CLOSE, ICON_CONNECTION, ICON_UPLOAD } from '../../../Common/newIconSource'
import Buttons from '../../../Common/button/Buttons'
import SourceTypeImage from '../../common/sourceTypeImage'

const dictionarySteps = [
    { key: 0, title: 'Connection', active: true },
    { key: 1, title: 'Explorer', active: false },
    { key: 2, title: 'Schema', active: false }
]
const podStepsForTable = [
    { key: 0, title: 'Connection', active: true },
    { key: 1, title: 'Explorer', active: false },
    { key: 2, title: 'Schema', active: false },
    { key: 3, title: 'Save Details', active: false },
]

const podStepsForUpload = [
    { key: 0, title: 'Explorer', active: true },
    { key: 1, title: 'Schema', active: false },
    { key: 2, title: 'Save Details', active: false },
]

export const podCreationTutorialComponentIds = {
    selectConnectionId: 'conn-gridv-0',
    nextButtonId: 'pod-crt-next-btn',
    schemaTableTreeId: 'schema-table-container',
    sqlEditorComponentId: 'pod-crt-sql-cont',
    sqlRunButtonId: 'sql-cont-runbtn',
    previewTabsId: 'pod-crt-preview-table',
    resultTabId: 'pcrt-restab-tab-result',
    explorerTreeId: 'pod-crt-exp-tree',
    fileOptionsId: 'pod-crt-fileopts',
    previewButtonId: 'pod-crt-fileopt-prev',
    fileUploadContainerId: 'pod-crt-fileup-cont',
    podNameId: 'pod-crt-name-inp',
    podDescriptionId: 'pod-crt-desc-box',
    podDomainId: 'pod-crt-sel-domain',
    podSaveButtonId: 'pod-crt-save-btn',
}

const PodCreation = ({
    isUnstructuredConn = false,
    isDataDictionary = false,
    isPodOfPodConn = false,
    isFileUploadConn = false,
    isEditMode = false,
    fetchDictionaryTableData = () => {},
    switchPageContainerTabTo = () => {},
    isDeletePermission = false,
    isEditPermission = false,
    isPodCreationReady = true,
    setPodCreationReady = () => {},
    setUploadInProgress = () => {},
    isUploadInProgress = false
}) => {

    const [steps, setSteps] = React.useState(
        isDataDictionary 
        ? [...dictionarySteps]
        // : isFileUpload || isPodOfPod 
        //     ? [...podStepsForUpload] 
            : [...podStepsForTable]
    )

    const [schemaSearchValue, setSchemaSearchValue] = React.useState('')
    const [previewUUID, setPreviewUUID] = React.useState('')
    const [fileUploadUUID, setFileUploadUUID] = React.useState('')
    const [newBusinessTerms, setNewBusinessTerms] = React.useState([])
    const [isEditing, setIsEditing] = React.useState(false)
    const [podRetrievedConnection, setPodRetrievedConnection] = React.useState('')
    const [isDetailsFetched, setDetailsFetched] = React.useState(false)
    const [isPodSaveRunning, setPodSaveRunning] = React.useState(false)
    const [isPodPreviewRunning, setPodPreviewRunning] = React.useState(false)

    const [stringPattern, setStringPattern] = React.useState('')    // for explorer
    const [storedStringPattern, setStoredStringPattern] = React.useState(undefined) // for next button to schema

    const [isCustomQuery, setIsCustomQuery] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState('result')
    const [editorQuery, setEditorQuery] = React.useState('')
    const [currentActiveTabKey, setCurrentActiveTabKey] = React.useState('') // Storing Globally in Parent Component when coming back to previous screen

    const [autoClassifyObj, setAutoClassifyObj] = React.useState({}) // Auto Classify is Removed, remove all states in future when you get time
    const [sqlReady, setSqlReady] = React.useState(isEditMode)
    const [noDomains, setNoDomains] = React.useState(false)
    const [sqlChat, setSqlChat] = React.useState([])
    const [defaultDomain, setDefaultDomain] = React.useState(null)

    const isApiCompletedRef = React.useRef(false)

    const { selectConnectionId, 
        nextButtonId, 
        schemaTableTreeId, 
        sqlEditorComponentId, 
        sqlRunButtonId,
        previewTabsId,
        resultTabId,
        explorerTreeId,
        fileOptionsId,
        previewButtonId,
        fileUploadContainerId,
        podNameId, podDescriptionId, podDomainId, podSaveButtonId
    } = podCreationTutorialComponentIds

    const selectedConn = useSelector(state => state.UserManagement.ConnectionReducer.selectedConn)

    const [isFileUpload, setIsFileUpload] = React.useState(isFileUploadConn)
    const [isPodOfPod, setIsPodOfPod] = React.useState(isPodOfPodConn)
    // const [isUnstructured, setIsUnstructured] = React.useState(isUnstructuredConn)
    const [domainFetched, setDomainFetched] = React.useState(false)
    const [noConnections, setNoConnections] = React.useState(false)

    const [tutorialStepsIdMap, setTutorialStepsIdMap] = React.useState({
        step1: `#${selectConnectionId}`,
        step2: `#${nextButtonId}`,
        step3: `#${isFileUpload ? fileUploadContainerId : selectedConn?.file_system ? explorerTreeId : schemaTableTreeId}`,
        step4: `#${isFileUpload || selectedConn?.file_system ? fileOptionsId : previewTabsId}`,
        step5: `#${isFileUpload || selectedConn?.file_system ? previewButtonId : resultTabId}`,
        step6: `#${isFileUpload || selectedConn?.file_system ? previewTabsId : sqlEditorComponentId}`,
        step7: `#${sqlRunButtonId}`,
        step8: `#${podNameId}`,
        step9: `#${podDomainId}`,
        step10: `#${podDescriptionId}`,
        step11: `#${podSaveButtonId}`
    })
    
    const [publishedTable, setPublishedTable] = React.useState({}) // data dictionary use

    const store = useStore()
    const { podId } = useParams()
    const updateSchemaRef = React.useRef()

    const dispatch = useDispatch()
    const history = useNavigate()

    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)

    const { format } = reduxData?.fileOptions
    const { selectedSheets = [], schemaQueryMap, parameters, clarista_engine } = reduxData

    const isTutorialActive = useSelector(state => state?.CommonComponent?.TutorialRedux?.navigator?.create_pod)
    const isFileSystemTutorial = useSelector(state => state?.CommonComponent?.TutorialRedux?.navigator?.file_system)

    const schemaWalkthroughSteps = [
        {
            content: getSimpleTutorialMessageTemplate('Welcome, To The Schema', "Here you can do your all modifications on Display Names"),
            locale: { skip: <strong>SKIP</strong> },
            placement: "center",
            target: "body"
        },
        {
            content: <>
                {getSimpleTutorialMessageTemplate('Save Pod', 'Once you have done with your changes in Schema, let us move on to the final step towards Saving the Pod')}
            </>,
            placement: "bottom",
            target: tutorialStepsIdMap.step2,
            title: "Almost There",
            locale: { last: "Got it" }
        },
    ]

    const [walkthrough, setWalkThrough] = React.useState({
        run: !isEditMode,
        steps: [...schemaWalkthroughSteps]
    })

    const [connectionsLoaded, setConnectionsLoaded] = React.useState(false)
    const [searchConn, setSearchConn] = React.useState('')
    
    useHideSidebar(isEditMode)

    // React.useEffect(() => {
    //     setIsFileUpload(isFileUploadConn)
    // }, [isFileUploadConn])

    // React.useEffect(() => {
    //     setIsPodOfPod(isPodOfPodConn)
    // }, [isPodOfPodConn])

    React.useEffect(() => {
        if(connectionsLoaded) {
            const el = document.querySelectorAll('.av-conn-card')
            if(el?.length) {
                let list = []
                el?.forEach(e => {
                    if(isFileSystemTutorial) {
                        if(e.role === 'file_system') list.push(e)
                    }
                    else {
                        if(e.role === 'non_file_system') list.push(e)
                    }
                })
                setTutorialStepsIdMap(
                    prev => ({...prev, step1: `#${list?.[0]?.id}`})
                )
            }
        }
    }, [isFileSystemTutorial, isTutorialActive, connectionsLoaded])

    React.useEffect(() => {
        setTutorialStepsIdMap({
            step1: `#${selectConnectionId}`,
            step2: `#${nextButtonId}`,
            step3: `#${isFileUpload ? fileUploadContainerId : selectedConn?.file_system ? explorerTreeId : schemaTableTreeId}`,
            step4: `#${isFileUpload || selectedConn?.file_system ? fileOptionsId : previewTabsId}`,
            step5: `#${isFileUpload || selectedConn?.file_system ? previewButtonId : resultTabId}`,
            step6: `#${isFileUpload || selectedConn?.file_system ? previewTabsId : sqlEditorComponentId}`,
            step7: `#${sqlRunButtonId}`,
            step8: `#${podNameId}`,
            step9: `#${podDomainId}`,
            step10: `#${podDescriptionId}`,
            step11: `#${podSaveButtonId}`
        })
    }, [selectedConn])

    React.useEffect(() => {
        fetchUserSelectedDomain()
        return () => {
            dispatch(resetPodCreationData())
        }
    }, [])

    React.useEffect(() => {

        return () => {
            let files = JSON.parse(localStorage.getItem('cancelledFiles')) ?? {}
            if(Object?.keys(files)?.includes(fileUploadUUID)) {
                delete files[fileUploadUUID]
            }
            localStorage.setItem('cancelledFiles', JSON.stringify({...files}))
        }

    }, [fileUploadUUID])

    React.useEffect(() => {
        setPublishedTable({})

        let reduxPodData = store.getState().DataCatalogue?.PodCreationData

        const reset = () => {
            let podDetails = reduxPodData?.podDetails

            
            setIsFileUpload(false)
            setIsPodOfPod(false)
            setStringPattern('')
            setStoredStringPattern(undefined)
            let { payload } = dispatch(resetPodCreationData())
            payload.podDetails = podDetails
            dispatch(setPodCreationData(payload))
        }

        if(!isEditMode && !isArray(selectedConn) && selectedConn?.connection_name) {
            reset()
        }
        else if(isEditMode) {
            const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData

            if(podData?.source_type === 'upload') {
                if(isPodOfPod) {
                    let podDetails = reduxPodData?.podDetails
                    setIsFileUpload(false)
                    setIsPodOfPod(true)
                    setStringPattern('')
                    setStoredStringPattern(undefined)
                    let { payload } = dispatch(resetPodCreationData())
                    payload.podDetails = podDetails
                    dispatch(setPodCreationData(payload))
                }
                else if(!isArray(selectedConn) && selectedConn?.connection_name) {
                    reset()
                }
            }
            else if(podData?.source_type === Literals?.POD_OF_POD_API) {
                if(isFileUpload) {
                    let podDetails = reduxPodData?.podDetails
                    setIsPodOfPod(false)
                    setIsFileUpload(true)
                    setStringPattern('')
                    setStoredStringPattern(undefined)
                    let { payload } = dispatch(resetPodCreationData())
                    payload.podDetails = podDetails
                    dispatch(setPodCreationData(payload))
                }
                else if(!isArray(selectedConn) && selectedConn?.connection_name) {
                    reset()
                }
            }
            else if(podData?.source_type !== 'upload' && podData?.source_type !== Literals?.POD_OF_POD_API) {
                if(!selectedConn?.connection_name && isPodOfPod) {
                    let podDetails = reduxPodData?.podDetails
                    setIsFileUpload(false)
                    setIsPodOfPod(true)
                    setStringPattern('')
                    setStoredStringPattern(undefined)
                    let { payload } = dispatch(resetPodCreationData())
                    payload.podDetails = podDetails
                    dispatch(setPodCreationData(payload))
                }
                else if(!selectedConn?.connection_name && isFileUpload) {
                    let podDetails = reduxPodData?.podDetails
                    setIsPodOfPod(false)
                    setIsFileUpload(true)
                    setStringPattern('')
                    setStoredStringPattern(undefined)
                    let { payload } = dispatch(resetPodCreationData())
                    payload.podDetails = podDetails
                    dispatch(setPodCreationData(payload))
                }
                else if(!isArray(selectedConn) && podData?.connection !== selectedConn?.connection_name) {
                    reset()
                }
            }
        }
    }, [selectedConn])

    React.useEffect(() => {
        // if(isFileUpload || isPodOfPod) {
            if(!domainFetched) {
                dispatch(getDataDomainList(true, false)).then(
                    res => {
                        setNoDomains(res?.data?.length === 0)
                        dispatch(setDataDomainList(res.data))
                        if(res?.data?.length > 0) setDomainFetched(true)
                    }
                )
                .catch(() => {})
            }
        // }
    }, [isFileUpload, isPodOfPod])

    React.useEffect(() => {
        if(isEditMode) {
            getDetails()
        }
    }, [isEditMode])

    React.useEffect(() => {
        if(!isUnstructuredConn) {
            let stringPattern = ''
            if(!isFileUpload && (isPodOfPod || !selectedConn?.file_system)) {
                // On SQL Query Editor
                stringPattern = getSchemaLevelStringPattern(parameters, schemaQueryMap?.result, clarista_engine)
            }
            else {
                // On File Options
                const optsObj = reduxData?.fileOptions
                stringPattern = Object.keys(optsObj)?.map(v => `${optsObj?.[v]??''}`)?.join('|')
                if(selectedSheets && selectedSheets?.length) {
                    stringPattern = stringPattern?.concat(selectedSheets?.join(','))
                }
            }
            setStringPattern(stringPattern)
        }
    }, [reduxData, isFileUpload, isPodOfPod])
    // }, [reduxData])

    React.useEffect(() => {
        if(!isFileUpload && (isPodOfPod || !selectedConn?.file_system)) return
        if(!isUnstructuredConn) {
            setStoredStringPattern(stringPattern)
        }
    }, [previewUUID, isFileUpload, isPodOfPod])
    // }, [previewUUID])

    React.useEffect(() => {
        startWalkthrough(isTutorialActive)
    }, [isTutorialActive])
    
    const startWalkthrough = (bool) => {
        setWalkThrough(prev => ({...prev, run: bool }))
    }

    const activeStep = React.useMemo(() => {
        return steps.find(s => s.active)?.key
    }, [steps])

    const fetchUserSelectedDomain = () => {
        dispatch(getUserSelectedDomain())
        .then(res => {
            const domain = res?.data?.domain ?? undefined
            if (domain && domain?.id) {
                setDefaultDomain(domain)
            }
            else if (domain) {
                setDefaultDomain(domain ?? null)
            }
            else setDefaultDomain(null) 
        })
        .catch(err => {
            setDefaultDomain(null)
        })
    }

    const resetPodType = () => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        if(podData?.source_type === Literals?.POD_OF_POD_API) {
            setStringPattern('')
            setStoredStringPattern(undefined)
            dispatch(setConnectionSelectedConn([]));
            setIsPodOfPod(true)
            setIsFileUpload(false)
        }
        else if(podData?.source_type === 'upload') {
            setStringPattern('')
            setStoredStringPattern(undefined)
            dispatch(setConnectionSelectedConn([]));
            setIsFileUpload(true)
            setIsPodOfPod(false)
        }
        else if(podData?.is_unstructured) {
            setStringPattern('')
            setStoredStringPattern(undefined)
            dispatch(setConnectionSelectedConn([]));
            // setIsUnstructured(true)
            setIsFileUpload(false)
            setIsPodOfPod(false)
        }
        else {
            setIsFileUpload(false)
            setIsPodOfPod(false)
        }
    }

    const getDetails = async () => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        const fileInfo = podData?.pod_file_info

        setIsCustomQuery(podData?.pod_custom_query_flag)

        const isPoP = podData?.source_type === Literals?.POD_OF_POD_API
        const isUpl = podData?.source_type === 'upload'

        if(isPoP || isUpl) {
            dispatch(setConnectionSelectedConn([]));
        }
        setIsPodOfPod(isPoP)
        setIsFileUpload(isUpl)

        if(isPoP) {
            setCurrentActiveTabKey(podData?.pod_custom_query_flag ? 'result' : podData?.source?.[0])
        }
        else {
            let atk = podData?.source?.[0]?.split('.')?.filter((v, i) => i !== 0 )?.join('.')
            setCurrentActiveTabKey(podData?.pod_custom_query_flag ? 'result' : atk)
        }
        
        let reduxData = store.getState()?.DataCatalogue?.PodCreationData
        reduxData.podDetails = {
            name: podData?.table_name,
            domain: podData?.data_domain_detail?.data_domain_name,
            description: podData?.pod_description
        }
        reduxData.fileOptions =  {
            delimeter: fileInfo?.delimeter,
            format: fileInfo?.format?.toLowerCase(),
            encoding: fileInfo?.encoding,
            headerCount: fileInfo?.header_count,
            escapeChar: fileInfo?.escapechar,
            quoteChar: fileInfo?.quotechar,
            skipFooter: fileInfo?.skipfooter,
            skipRows: fileInfo?.skiprows,
            nullFormat: fileInfo?.null_format
        }
        let connection_name = ''

        reduxData.cacheEnabled = !!podData?.cache_enabled
        reduxData.cacheExpiry = podData?.cache_expiry ?? null

        if(isUpl) {
            setFileUploadUUID(podData?.source_detail[0])
            let sheetInfo = podData?.sheet_info
            let selectedSheets = sheetInfo?.map(s => `${s?.file_name}:::${s?.sheet_name}`)
            reduxData['selectedSheets'] = selectedSheets ? [...selectedSheets] : undefined
            reduxData['saveParqData'] = sheetInfo ? [...sheetInfo] : undefined
        }
        else {
            const isPodOfPodSource = podData?.source_type?.toLowerCase() === Literals.POD_OF_POD_API?.toLowerCase()
            const isFileSystem = podData?.source_type === 's3' || podData?.source_type === 'azure_adls'

            if (getObjectLength(podData) > 0) {
                let src = podData?.source
                let isTableStructure = podData?.source_type !== 's3' && podData?.source_type !== 'azure_adls'
                if(!isPodOfPodSource && podData?.source_type !== 'upload') {
                    connection_name = src[0].split(isTableStructure ? '.' : '@')[0]
                    getConnectionDetails(connection_name)
                    setPodRetrievedConnection(connection_name)
                }
            }

            if(!isFileSystem) {
                const params = podData?.default_param
                if(params && getObjectLength(params) > 0) {
                    let paramsArr = []
                    const idMap = Object.keys(params).reduce((out, curr, curr_idx) => ({...out, [curr]: curr_idx}), {})
                    for (const key in params) {
                        paramsArr.push({ id: idMap[key], name: key, value: params[key] })
                    }
                    reduxData.parameters =[...paramsArr]
                }
            }
            


            if(!isFileSystem) {
                let keysToSelect = podData?.source ? _.uniq(podData?.source.map(v => v.split('.')[podData?.source_type.toLowerCase()==='pod of pods' ? 0 : 1])) : []
                reduxData.keysToSelect = keysToSelect
            }

            reduxData.clarista_engine = podData?.clarista_engine
            reduxData.currentSelectedConnection = connection_name
        }
        
        dispatch(setPodCreationData(reduxData))

        setDetailsFetched(true)
    }

    const getConnectionDetails = (name) => {
        dispatch(getConnectionNameProperties(name, false)).then((res) => {
            if (getObjectLength(res?.data) > 0) {
                dispatch(setConnectionSelectedConn(res?.data));
            }
        })
        .catch((err) => {
            emitToastNotification('error', err?.message)
        })
    }

    const getActiveTabKey = (activeTab) => {
        setActiveTab(activeTab)
    }

    const handlePodType = (type) => {
        if(isEditMode && !isEditing) return

        if(type === 'navigator') {
            setIsFileUpload(false)
            setIsPodOfPod(true)
            // setIsUnstructured(false)
        }
        else if(type === 'upload'){
            setIsPodOfPod(false)
            setIsFileUpload(true)
            // setIsUnstructured(false)
        }
        setStringPattern('')
        setStoredStringPattern(undefined)
        dispatch(setConnectionSelectedConn([]));
        if(!isEditMode) {
            dispatch(resetPodCreationData())
        }
    }

    const getStepContent = (step) => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        switch (step) {
            case 0:
            // case isFileUpload || isPodOfPod ? -1 : 0:
                return <>
                <div className='h-100'>
                    {
                        // isUnstructuredConn && !isEditMode ? ''
                        // :
                        <>
                            <div className='pod-crt-types'>
                                {
                                    isUnstructuredConn ? ''
                                    :
                                    <div className={`d-flex align-items-center px-2 py-2 rounded pod-crt-type-card ${isPodOfPod ? 'active' : ''} ${((isEditMode && isEditing) || !isEditMode) && !noConnections ? '' : 'disabled'} `}
                                        onClick={() => handlePodType('navigator')}
                                    >
                                        <div className='d-flex mr-2 pod-crt-type-ic'>
                                            <SourceTypeImage dataName={'POD of POD'} />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <label className='mb-0 fontSizeLabel label-gray fontInterSemiBold'>POD of PODs</label>
                                            <div className='fontSizeHeading fontInterSemiBold'>
                                                Navigator
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={`d-flex align-items-center px-2 py-2 rounded pod-crt-type-card ${isFileUpload ? 'active' : ''} ${((isEditMode && isEditing) || !isEditMode) && !noConnections ? '' : 'disabled'} `}
                                    onClick={() => handlePodType('upload')}
                                >
                                    <div className='d-flex mr-2 pod-crt-type-ic'>
                                        <span className='ic-wrap'>
                                            <ICON_UPLOAD/>
                                        </span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <label className='mb-0 fontSizeLabel label-gray fontInterSemiBold'>File</label>
                                        <div className='fontSizeHeading fontInterSemiBold'>
                                            Upload
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className='mt-0 mb-1'/>
                            {
                                noConnections ? ''
                                :
                                <div className='mb-2 px-4 ml-n1'>
                                    <label className='label label-gray mb-0'>CONNECTIONS</label>
                                </div>
                            }
                        </>
                    }
                    <div style={{height: isUnstructuredConn ? '100%' : 'calc(100% - 6.75rem)'}}>
                        <SelectConnection
                            isEditing={isEditing}
                            isEditMode={isEditMode}
                            tutorialStepsIdMap={tutorialStepsIdMap}
                            isTutorialActive={isTutorialActive}
                            setConnectionsLoaded={setConnectionsLoaded}
                            searchConn={searchConn}
                            setNoConnections={setNoConnections}
                            isUnstructured={isUnstructuredConn}
                        />
                    </div>
                </div>
                    
                </>
            case 1:
            // case isFileUpload || isPodOfPod ? 0 : 1:
                return <PodCreationSource 
                            previewUUID={previewUUID}
                            setPreviewUUID={setPreviewUUID}
                            isEditMode={isEditMode}
                            isEditing={isEditing}
                            podRetrievedConnection={podRetrievedConnection}
                            isFileUpload={isFileUpload}
                            setFileUploadUUID={setFileUploadUUID}
                            fileUploadUUID={fileUploadUUID}
                            isDetailsFetched={isDetailsFetched}
                            getDetails={getDetails}
                            isPodOfPod={isPodOfPod}
                            setPodPreviewRunning={setPodPreviewRunning}
                            setStoredStringPattern={setStoredStringPattern}
                            tutorialStepsIdMap={tutorialStepsIdMap}
                            podCreationTutorialComponentIds={podCreationTutorialComponentIds}
                            isTutorialActive={isTutorialActive}
                            isDataDictionary={isDataDictionary}
                            isCustomQuery={isCustomQuery}
                            setIsCustomQuery={setIsCustomQuery}
                            getActiveTabKey={getActiveTabKey}
                            editorQuery={editorQuery}
                            setEditorQuery={setEditorQuery}
                            getEditorQuery={(qry) => {
                                setEditorQuery(qry)
                            }}
                            currentActiveTabKey={currentActiveTabKey}
                            setCurrentActiveTabKey={setCurrentActiveTabKey}
                            isApiCompletedRef={isApiCompletedRef}
                            autoClassifyObj={autoClassifyObj}
                            setAutoClassifyObj={setAutoClassifyObj}
                            sqlReady={sqlReady}
                            setSqlReady={setSqlReady}
                            stringPattern={stringPattern}
                            setStringPattern={setStringPattern}
                            storedStringPattern={storedStringPattern}
                            setUploadInProgress={setUploadInProgress}
                            isUploadInProgress={isUploadInProgress}
                            setSqlChat={setSqlChat}
                            sqlChat={sqlChat}
                            isUnstructured={isUnstructuredConn}
                        />
            case 2:
            // case isFileUpload || isPodOfPod ? 1 : 2:
                return <>
                        <PodCreationSchema
                            podId={podId}
                            searchValue={schemaSearchValue}
                            setSearchValue={setSchemaSearchValue}
                            schemaUUID={previewUUID}
                            setNewBusinessTerms={setNewBusinessTerms}
                            isUpdateView={isEditMode}
                            isReadOnly={isEditMode && !isEditing}
                            disableRowReorder={ isFileUpload || selectedConn?.file_system ? format && ['csv', 'txt'].includes(format.toLowerCase()) : false}
                            disableCheckbox={ isFileUpload || selectedConn?.file_system ? format && ['csv', 'txt'].includes(format.toLowerCase()) : false}
                            isIFDB={isDataDictionary}
                            ref={updateSchemaRef}
                            currentActiveTabKey={currentActiveTabKey}
                            isPodOfPod={isPodOfPod}
                            isFileUpload={isFileUpload}
                            isCustomQuery={isCustomQuery}
                            setPodPreviewRunning={setPodPreviewRunning}
                            isUnstructured={isUnstructuredConn}
                        />
                        <Walkthrough
                            walkthrough={walkthrough}
                            showSkipButton={false}
                            spotlightClicks={true}
                        />
                </>
            case 3:
            // case isFileUpload || isPodOfPod ? 2 : 3:
                return <PodCreationSaveDetails
                            newBusinessTerms={newBusinessTerms}
                            isEditMode={isEditMode}
                            switchPageContainerTabTo={switchPageContainerTabTo}
                            fetchDictionaryTableData={fetchDictionaryTableData}
                            isEditing={isEditing}
                            isFileUpload={isFileUpload}
                            fileUploadUUID={fileUploadUUID}
                            isPodOfPod={isPodOfPod}
                            setPodSaveRunning={setPodSaveRunning}
                            podCreationTutorialComponentIds={podCreationTutorialComponentIds}
                            tutorialStepsIdMap={tutorialStepsIdMap}
                            isTutorialActive={isTutorialActive}
                            isCustomQuery={isCustomQuery}
                            currentActiveTabKey={currentActiveTabKey}
                            defaultDomain={defaultDomain}
                            isUnstructured={isUnstructuredConn}
                        />
            default:
                break;
        }
    }

    const onStepChange = (stepKey) => {
        setSteps(prev => prev?.map(s => ({...s, active: stepKey === s.key})))
    }

    return (
        <React.Fragment>
            <ConnectionProvider>
                {
                    isEditMode ? ''
                    :   <LandingpageFirstHeader title={'Pod Creation'} />
                }
                {
                    noDomains
                    ?
                    <>
                        <div className='d-flex align-items-center justify-content-between padding-2'>
                            <div></div>
                            <div>
                                <Buttons
                                    props={{
                                        tooltip: "",
                                        tooltipPlacement: 'top',
                                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon`,
                                        buttonEvent: () => {
                                            history(Literals?.links?.CLARISTA_NAVIGATOR + '#pod')
                                        },
                                        ImgSrc: () => <ICON_CLOSE/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </div>
                        </div>
                        <div className='my-5 mx-auto w-50'>
                            <div
                                className="text-center p-5"
                                style={{ background: "transparent" }}
                            >
                            <span>{
                                    isFileUpload
                                    ?
                                    <ICON_CATALOG_DOMAIN width="50" height='50' />
                                    :
                                    <ICON_CONNECTION height='50' width='50' />
                                }</span>
                            <h2 className="h5 mt-2">{
                                isFileUpload
                                ?
                                'No Domains Available'
                                :
                                'No Connections Available'
                            }</h2>
                            {/* <p>May be you don't have permisson</p> */}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <PodCreationHeader 
                            steps={steps}
                            onStepChange={onStepChange}
                            newBusinessTerms={newBusinessTerms}
                            isEditMode={isEditMode}
                            isEditPermission={isEditPermission}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            fetchPodDetails={getDetails}
                            isFileUpload={isFileUpload}
                            setPodCreationReady={setPodCreationReady}
                            isPodOfPod={isPodOfPod}
                            isPodSaveRunning={isPodSaveRunning}
                            isPodPreviewRunning={isPodPreviewRunning}
                            stringPattern={stringPattern}
                            storedStringPattern={storedStringPattern}
                            podCreationTutorialComponentIds={podCreationTutorialComponentIds}
                            isDataDictionary={isDataDictionary}
                            updateSchemaRef={updateSchemaRef}
                            setPublishedTable={setPublishedTable}
                            publishedTable={publishedTable}
                            isCustomQuery={isCustomQuery}
                            setIsCustomQuery={setIsCustomQuery}
                            activeTab={activeTab}
                            setSchemaUUID={setPreviewUUID}
                            editorQuery={editorQuery}
                            isApiCompleted={isApiCompletedRef?.current}
                            searchConn={searchConn}
                            setSearchConn={setSearchConn}
                            resetPodType={resetPodType}
                            isUnstructured={isUnstructuredConn}
                        />
                        <div className='pod-crt-parent' style={{height: `calc(100vh - ${isEditMode ? '7.5rem' : '4.8125rem'})`}}>
                            {getStepContent(activeStep)}
                        </div>
                    </>
                }
            </ConnectionProvider>
        </React.Fragment>
    )
}

export default PodCreation

export const getSchemaLevelStringPattern = (params, query, engine) => {
    let stringPattern = query?.concat('|' + engine?.toString() + '|')
    if(params && params?.length) {
        stringPattern = stringPattern?.concat(params?.map(p => `${p?.name}|${p?.value}`)?.join(','))
    }
    return stringPattern
}