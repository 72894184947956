import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useHideSidebar from "../../../../../customHooks/useHideSidebar";
import CreatePolicyHeader from "../../../common/LandingpageFirstHeader";
import LoadingComponent from "../../../common/loadingComponent";
import CreatePolicySecondaryHeader from "./CreatePolicySecondaryHeader";
import "./CreatePolicy.scss";
import PolicyLeftSide from "./PolicyLeftSide";
import PolicyRightSide from "./PolicyRightSide";
import { fetchPodDetails, fetchPolicyDetails } from "../../../../../store/modules/accessManagement/actions";
import {
  createPolicy,
  readPolicy,
  setInitialCatalogueTab,
  updatePolicy,
} from "../../../../../store/actions";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { COLLAPSESIDEBAR } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import LeftSidePanelToggler from "../../../../Common/leftSidePanelToggler/LeftSidePanelToggler";
import { permissions } from "../../../common/helperFunctions";
import { isArray } from "lodash";

const CreatePolicy = () => {
  const { loadingStatus } = useSelector((state) => state.LoadingReducer);
  const { podId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);

  let policyId = queryParams.get('policyId');


  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation()
  const { podDetails, columnDetails, selectedPolicy } = useSelector(
    (state) => state.AccessManagement
  );
  const {
    table_name: tableName,
    data_domain_name: domainName,
    data_domain_id: domainId,
  } = podDetails;
  let childAddFilterRef = useRef();

  const [policyName, setPolicyName] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [groupPermissions, setGroupPermissions] = useState([]);
  const [maskedColumns, setMaskedColumns] = useState([]);
  const [filterDropDownOptions, setFilterDropDownOptions] = useState([]);
  const [filters, setFilters] = useState([]);
  const [showFilterPop, setFilterPop] = useState(false);

  const [viewMode, setViewMode] = useState("create");
  const [showLeftPanel, setshowLeftPanel] = React.useState(true);
  const [policyNameError, setPolicyNameError] = useState("");
  const [filterArray, setfilterArray] = useState([]);
  const [isEditor, setEditor] = useState(false);

  const NEW_FILTER_ARRAY = useRef([]);
  const [initialColumns, setInitialColumns] = useState([]);
  const [policyData, setPolicyData] = useState({});

  const store = useStore()

  useEffect(() => {
    document.querySelector('.right-bar').scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const isEditor = !!podDetails?.permission?.find(v => v === permissions.policy.editor)
    const isOwner = !!podDetails?.permission?.find(v => v === permissions.policy.owner)
  
    setEditor(isEditor && isOwner)
  }, [podDetails])

  useEffect(() => {
    if (selectedPolicy) {
      console.log({ selectedPolicy })
      setEditor(
        !!selectedPolicy?.permission?.find(v => v === permissions.policy.editor) &&
        !!selectedPolicy?.permission?.find(v => v === permissions.policy.owner)
      )
    }
  }, [selectedPolicy])

  const onInputChangeHandler = (field, value) => {
    switch (field) {
      case "policyName":
        setPolicyName(value);
        break;
      case "policyDescription":
        setPolicyDescription(value);
        break;
      default:
        break;
    }
  };

  const fetchPodDetailsHandler = () => {
    dispatch(fetchPodDetails(podId));
  };

  const addNewFilter = () => {
    setFilters((filters) => [
      ...filters,
      {
        options: [...filterDropDownOptions],
        criteria: "",
        value: "",
        datatype: "",
        column: "",
        lowerLimit: "",
        upperLimit: "",
      },
    ]);
  };


  const onChangePermissionHandler = (index, field, value, group_id) => {

    if (groupPermissions?.length && groupPermissions[index]) {
      setGroupPermissions((permissions) => {
        const newPermissions = [...permissions];
        newPermissions[index][field] = value;
        return newPermissions;
      });
    } else {
      let obj = {
        id: group_id,
        edit_pod: false,
        view_pod: true,
        delete_pod: false,
      };
      obj[field] = value
      groupPermissions.splice(index, 0, obj)
    }
  };

  const checkValidation = async () => {
    if (!policyName) {
      setPolicyNameError(Literals.POLICY_NAME_IS_REQUIRED);
      setshowLeftPanel(true)
      emitToastNotification("error", Literals.POLICY_NAME_IS_REQUIRED);
      return true;
    } else if (!selectedUserGroups.length) {
      emitToastNotification("error", Literals.USERGROUPS_ARE_REQUIRED);
      return true;
    }
    else if (selectedUserGroups.length) {
      let returnValue = []
      for (let i = 0; i < selectedUserGroups.length; i++) {
        let response = await dispatch(readPolicy(`policy/?table_id=${podId}&group_id=${selectedUserGroups[i].id}`))

        if (response?.status === "success" && response.data?.length > 0) {
          // let selectedPolicyNames = response.data.map((item) => item.name.toLowerCase())
          let selectedPolicyId = response.data.map((item) => item.id);

          setshowLeftPanel(false)
          if (selectedPolicyId?.length === 0) {
            returnValue = []
          } else if (selectedPolicyId?.length === 1 && policyId && !selectedPolicyId.includes(Number(policyId)) && window.location.href.includes('editPolicy')) {
            console.log(selectedPolicyId, policyId)
            returnValue.push(selectedUserGroups[i].name)
          } else {
            returnValue = []
          }
          //   if (!selectedPolicyNames.includes(policyName.toLowerCase()) && window.location.href.includes('createPolicy')) {

          //     returnValue.push(selectedUserGroups[i].name)
          //   }
          //   else if (policyId && !selectedPolicyId.includes(policyId) && !selectedPolicyNames.includes(policyName.toLowerCase()) && window.location.href.includes('editPolicy')) {
          //     returnValue.push(selectedUserGroups[i].name)
          //   }
          // } else {


          // }

        }
        if (returnValue.length > 0) {
          emitToastNotification("info", `Policy for this POD and this " ${returnValue.toString()} " Usergroup  already exists. Please make sure not to create conflicting policies.`);
          return true
        } else {
          return false
        }

      }
    }
    else {
      return false
    }

  };

  const policyActionHandler = async () => {

    if (childAddFilterRef.current && childAddFilterRef.current.executeFilter) {
      childAddFilterRef.current.executeFilter();
    }
    let isValidate = await checkValidation()

    if (isValidate) {
      return;
    }


    try {
      let finalselectedgroupids = selectedUserGroups.map((item) => item.id)
      let newPermissions = []
      if (finalselectedgroupids?.length) {
        newPermissions = groupPermissions.filter((group) => finalselectedgroupids.includes(group.id))
      }
      let temp = NEW_FILTER_ARRAY.current.filter(item => item.column != "" && item.datatype != "")
      const payload = {
        name: policyName,
        description: policyDescription,
        pod: podId,
        filter_row: temp.map((filter) => {
          const data = { ...filter };

          // if (data.criteria === "BETWEEN") {
          //   data.value = `${data.lowerLimit}-${data.upperLimit}`;
          // }

          //Removing Extra fields from payload
          delete data.options;
          delete data.lowerLimit;
          delete data.upperLimit;
          delete data.uid;
          return data;
        }),
        mask_column: maskedColumns?.length ? maskedColumns.map((column) => column.name) : [],
        group_perms: newPermissions,
      };

      let response;

      if (viewMode === "create") {
        response = await dispatch(createPolicy(payload));
      } else {
        response = await dispatch(updatePolicy(selectedPolicy.id, payload));
      }

      const status = response.status;
      const message = response.message;

      if (status === "success") {
        setshowLeftPanel(false)
        history(Literals.links.DATA_CATALOG_POD_DETAILS + podId, { state: { fromRoute: 'accessmanagement' } });
      } else {
        emitToastNotification("error", message);
      }
    } catch (error) {
      emitToastNotification("error", error.message);
    }
  };

  const setInitialValuesHandler = () => {
    if (selectedPolicy) {
      const { name, description, group_perms, groups, filter_row, mask_column } =
        selectedPolicy;
      onInputChangeHandler("policyName", name);
      onInputChangeHandler("policyDescription", description);
      setSelectedUserGroups(groups.group_detail);
      setGroupPermissions(group_perms);
      setfilterArray([...filter_row])
      setFilters(
        filter_row.map((filterData) => {
          const field = { options: filterDropDownOptions, ...filterData };
          console.log(filterData)
          if (filterData.criteria.toLowerCase() === "BETWEEN") {
            let lowerLimit;
            let upperLimit;

            // const [lowerLimit, upperLimit] = filterData.value.split("-");
            if (isArray(filterData?.value)) {
              lowerLimit = filterData?.value[0];
              upperLimit = filterData?.value[1];
              field["lowerLimit"] = lowerLimit;
              field["upperLimit"] = upperLimit;
            }

          }

          return field;
        })
      );

      setMaskedColumns([...mask_column]);

    }



  };

  useEffect(() => {
    fetchPodDetailsHandler();
    if (policyId && policyId !== 'null' && policyId !== 'undefined') {
      dispatch(fetchPolicyDetails(policyId))
    }
  }, []);

  useEffect(() => {
    if (viewMode === "create") {
      let groupPermissions;
      if (selectedUserGroups.length) {
        groupPermissions = selectedUserGroups.map((group) => {
          return {
            id: group.id,
            edit_pod: false,
            view_pod: true,
            delete_pod: false,
          };
        });
      }
      else {
        groupPermissions = [];
      }

      setGroupPermissions(groupPermissions);
    } else if (viewMode !== "create") {
      let groupPermissionids = groupPermissions.map((ele) => ele.id);
      let groupPermission = [...groupPermissions];
      if (selectedUserGroups.length) {
        selectedUserGroups.forEach((group) => {
          if (!groupPermissionids.includes(group.id)) {
            groupPermission.push({
              id: group.id,
              edit_pod: false,
              view_pod: true,
              delete_pod: false,
            });
          }
        });
      }
      else {
        groupPermission = [];
      }

      setGroupPermissions(groupPermission);
    }
  }, [selectedUserGroups, viewMode]);

  useEffect(() => {
    if (columnDetails.length) {
      const dropdownOptions = columnDetails.map((column, index) => {
        return {
          key: column.column_id,
          Name: column.column_name,
          Type: column.column_datatype,
          value: `${column.column_name}-${column.column_datatype}`,
        };
      });
      let temp = createTableColumns(columnDetails);
      setInitialColumns(temp);
      setFilterDropDownOptions(dropdownOptions);
    }
  }, [columnDetails]);

  const createTableColumns = (columnDetails) => {
    let tableColumns = [];
    columnDetails.forEach((ele, i) => {
      tableColumns.push({
        Name: ele.column_name,
        Type: ele.column_datatype,
        Label: ele.column_description,
        columnValue: `'${ele.column_name}'`,
      });
    });
    return tableColumns;
  };

  useHideSidebar();

  useEffect(() => {
    if (location?.pathname.includes("edit")) {
      setViewMode("edit");
    } else if (location?.pathname.includes("view")) {
      setViewMode("view");
    } else {
      setViewMode("create");
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (
      (viewMode.includes("view") || viewMode.includes("edit")) &&
      filterDropDownOptions.length
    ) {
      setInitialValuesHandler();
    }
  }, [viewMode, filterDropDownOptions]);

  useEffect(() => {
    dispatch(setInitialCatalogueTab("AccessManagement"));
  }, []);
  const setAppliedFilter = (filterCriteriaGroup) => {

    NEW_FILTER_ARRAY.current = [...filterCriteriaGroup];
    setfilterArray([...filterCriteriaGroup]);

    //  return filterCriteriaGroup
  };

  return (
    <Fragment>
      <CreatePolicyHeader title="Data Catalog" />
      <CreatePolicySecondaryHeader
        podId={podId}
        policyName={policyName}
        domainName={domainName}
        tableName={tableName}
        policyId={policyId}
        policyAction={policyActionHandler}
        viewMode={viewMode}
        isEditorPermission={isEditor}
      />
      {loadingStatus && <LoadingComponent />}
      <div className="policy-container">
        <div style={!showLeftPanel ? { width: "0px" } : { width: "17.5rem" }} className={`left-bar button--disapear ${!showLeftPanel ? 'out' : 'active'} `}>
          <PolicyLeftSide
            policyName={policyName}
            policyNameError={policyNameError}
            policyDescription={policyDescription}
            onChange={onInputChangeHandler}
            viewMode={viewMode}
            isEditorPermission={isEditor}
          />
          <LeftSidePanelToggler open={showLeftPanel} setOpen={setshowLeftPanel} />
        </div>
        <div style={!showLeftPanel ? { width: "100%" } : { width: "calc(100% - 17.5rem)" }} className="right-bar px-1">
          <PolicyRightSide
            ref={childAddFilterRef}
            props={{
              domainName: domainName,
              domainId: domainId,
              selectedUserGroups: selectedUserGroups,
              setSelectedUserGroups: setSelectedUserGroups,
              columnDetails: columnDetails,
              onChangePermission: onChangePermissionHandler,
              groupPermissions: groupPermissions,
              filters: filters,
              initialColumns: initialColumns,
              setFilterPop: setFilterPop,
              setAppliedFilter: setAppliedFilter,
              appliedFilter: filterArray,
              setFilters: setFilters,
              addNewFilter: addNewFilter,
              maskedColumns: maskedColumns,
              setMaskedColumns: setMaskedColumns,
              viewMode: viewMode,
              isEditorPermission: isEditor,
              policyData: selectedPolicy
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CreatePolicy;
