import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Literals } from "../../../../components/modules/common/literals";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";

import {
  SET_DATADOMAIN_LIST,
  SET_DATADOMAIN_ID,
  SET_DATADOMAIN_NAME,
  SET_DATADOMAIN_DESCRIPTION,
  SET_DATA_DOMAIN_PAGE_DEFAULT,
  SET_DISABLE_UM_BTN,
  SET_USER_SELECTED_DOMAIN
}
  from './dataDomainActionTypes'

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;
const LANDING_PAGE_API_URL = window._env_.REACT_APP_API_BASE_URL;

export const setDataDomainList = (data) => {
  return {
    type: SET_DATADOMAIN_LIST,
    payload: data,
  };
};

export const disabledUMHeaderButtons = (data) => {
  return {
    type: SET_DISABLE_UM_BTN,
    payload: data,
  };
};

export const setDataDomainPageDefault = () => {
  return {
    type: SET_DATA_DOMAIN_PAGE_DEFAULT,
  };
};

export const setUserActiveDomain = (data) => {
  return {
    type: SET_USER_SELECTED_DOMAIN,
    payload: data
  };
};



export const getDataDomainList = (editor = false, showLoader = true, showToast = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}data_domain/${editor ? '?editor=true' : ''}`))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === "success") {
            if (showLoader) {
              dispatch(setLoadingStatus(false));
            }
            dispatch(setDataDomainList(resp.data));
            if (resp.data.length <= 0) {
              dispatch(disabledUMHeaderButtons({ isDisable: true, dataTab: Literals.DATA_DOMAIN }))
            } else {
              dispatch(disabledUMHeaderButtons({ isDisable: false, dataTab: Literals.DATA_DOMAIN }))

            }
            resolve(resp)

          } else {
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
            reject(resp?.message);
          }
        }).catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    })
  }
}
export const getDataDomainDetail = (id) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}data_domain/${id}/`))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}
export const fetchDataDomainDetails = ({ method, endPoint, payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(method, `${BASE_URL}${endPoint}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}



export const getDataDomainConnectionList = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${LANDING_PAGE_API_URL}${payload.data_domain_id ? 'connection/?data_domain_id=' + payload.data_domain_id : 'connection/'} `, {}))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    });
  };
}

export const getDataDomainPODList = (payload, showLoader = true) => {
  return (dispatch) => {

    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}catalog_home/v2/?data_domain_id=${payload.data_domain_id}`, {})
      )
        .then((resp) => {

          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {

            resolve(resp);
          }
        })
        .catch((err) => {

          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          //reject(err);
        });
    });
  };
};


export const setDataDomainId = (id) => ({
  type: SET_DATADOMAIN_ID,
  payload: id,
})
export const setDataDomainName = (value) => ({
  type: SET_DATADOMAIN_NAME,
  payload: value,
})
export const setDataDomainDescription = (value) => ({
  type: SET_DATADOMAIN_DESCRIPTION,
  payload: value,
})