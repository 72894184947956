import { DatePicker, Drawer, List, Popover, Tooltip } from "antd";
import React, { useEffect, useState } from 'react';
import Highlighter from "react-highlight-words";
import { useStore } from "react-redux";
import { useParams } from 'react-router-dom';
import Buttons from '../../../Common/button/Buttons';
import { ALERTBELLLARGE, CHECK, CLOSE, DATABRICKSICON, DATASOURCE, EDITOR2, FILTER, FILTERFLOW, FLOWS, MERGEICON, PREVIEW, PYTHONSCRIPTICON, REDIRECTJOBS, REFRESH, RESETFILTER, SCRIPTOUTPUT, SMALLFLOWS, SQLTRANSFORMERNEWBLUE, STOPOCTAGON, TARGETICON } from "../../../Common/iconSource";
import { LandingSimpleTable } from "../../../Common/landingSimpleTable/LandingSimpleTable";
import { convertSecondToHHMMSS, ErrorComponent, getMonthName, getTime, NoDataComponent, queryStatus, showSimpleLoading } from "../../common/helperFunctions";
import { Literals } from "../../common/literals";


import _, { cloneDeep } from 'lodash';
import moment from "moment";

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { fetchFlowsLandingPageList } from '../../../../store/modules/flows/landingPage/landingPageAction';
import { FlowsJobsAbort, GetFlowsJobsList } from '../../../../store/modules/jobsUsage/jobsAction';
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions';
import SingleSelection from '../../../Common/dropDown/SingleSelection';
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton';
import './usageLogs.scss';
import { ICON_FILTER, ICON_FLOW_MERGE } from "../../../Common/newIconSource";
import { showFlowsLogsLowerSection } from "../../../../store/modules/flows/NewFlowsRedux/flowsActions";


export const Jobs = ({ showFilters = false, flowsexecutionId, flowId, flowName = '', fromFlowLogs = false, activeKey, getLogs }) => {

    const store = useStore();
    const { RangePicker } = DatePicker;
    const [timelineData, setTimelineData] = useState([])
    const [filterText,] = useState("");
    const [isRefreshing, setRefreshing] = useState(false)
    const [noDataFound, setNoDataFound] = useState(false)
    const [, setFilteredData] = useState([]);
    const [loadMore, setLoadMore] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [stopLazyload, setStopLazyload] = useState(false)
    const [logDetails, setLogDetails] = useState({})
    const [pageNo, setPageNo] = useState(1)
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const [datePickerValue, setDatePickerValue] = useState(null);
    const [value, setValue] = useState(null);
    const defaultSelectValues = { flow: null, pod: null, user: null, status: null }
    const [flowSelectValue, setFlowSelectValue] = useState(defaultSelectValues.flow);
    const [userSelectValue, setUserSelectValue] = useState(defaultSelectValues.user);
    const [flowstatus, setflowstatus] = useState(defaultSelectValues.status);
    const [flowsList, setFlowsList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [dataCount, setDataCount] = useState();
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filtersToSend, setFiltersToSend] = useState({ flow: '', user: '', pod: '', start_time: '', end_time: '', status: "" })
    const [showFilterPop, setFilterPop] = useState(false);
    const [openErrorModal, setopenErrorModal] = useState(false)
    const [transformerErrorMsg, settransformerErrorMsg] = useState('')
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        // setTimeout(() => {
        //     let el = document.querySelector('.custom-simple-landing-dgt')
        //     if (el)
        //         el.scrollTop = 0;
        // }, 5000);
        if (activeKey !== "flows") {
            refreshSummaryTimelineData();
        }
    }, [activeKey])

    // useEffect(() => {
    //     // setTimeout(() => {
    //     //   el = el.scrollHeight;

    //     setTimeout(() => {
    //         let el = document.querySelector('.custom-simple-landing-dgt')
    //         if (el)
    //             el.scrollTop = 0;
    //     }, 100);
    //     // }, 100)
    // }, [timelineData])

    useEffect(() => {
        if (dataCount === 0) {
            setNoDataFound(true)
        }
        else {
            setNoDataFound(false)
        }
    }, [dataCount])

    const getSummaryTimelineData = async (fromApplyFilter) => {

        let payload = {
            limit: 50,
            page: fromApplyFilter ? 1 : pageNo,
            executionId: flowsexecutionId
        };

        let filterString = `job/flow/?page=${payload.page}&limit=${payload.limit}&execution_id=${payload.executionId}`;
        if (flowId && fromFlowLogs) {
            filterString += `&flow_id=${filtersToSend.flow !== '' ? filtersToSend.flow : flowId}`
        }
        // if (filtersToSend.flow || flowName !== '')
        //     filterString += `&flow_name=${filtersToSend.flow !== '' ? filtersToSend.flow : flowName}`
        if (filtersToSend.start_time)
            filterString += `&start_date_time=${filtersToSend.start_time}`
        if (filtersToSend.end_time)
            filterString += `&end_date_time=${filtersToSend.end_time}`
        if (filtersToSend.user)
            filterString += `&executed_by=${filtersToSend.user}`
        if (filtersToSend.status)
            filterString += `&state=${filtersToSend.status}`

        try {
            let response = await store.dispatch(GetFlowsJobsList(filterString));
            if (response.status === "success" && response?.data?.executions) {
                setErrMsg('')

                createLogsData(response?.data?.executions, fromApplyFilter ? true : false)
                setLoadMore(false)
                setPageNo(prev => prev + 1)
                setDataCount(response?.data?.count)
                setRefreshing(false)
            }
            if (response.status === "success" && response.message === "Invalid Page Requested") {
                emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)

                setStopLazyload(true)
                setLoadMore(false)
            }
        } catch (error) {
            setErrMsg(error.message)
            setRefreshing(false);
            setLoadMore(false);
        }
    };

    const refreshSummaryTimelineData = async (resetFilterType = null) => {
        setDataCount()
        setNoDataFound(false);
        // resetAllFilter(true);

        // setFlowSelectValue(defaultSelectValues.flow)
        // setUserSelectValue(defaultSelectValues.user)
        // setFilterStartDate('')
        // setFilterEndDate('')
        setTimelineData([])
        setRefreshing(true)
        setPageNo(1)
        let payload = {

            page: 1,
            limit: 50,
            executionId: flowsexecutionId
        };

        let filterString = `job/flow/?page=${payload.page}&limit=${payload.limit}&execution_id=${payload.executionId}`;
        if (flowId && fromFlowLogs) {
            filterString += `&flow_id=${filtersToSend.flow !== '' ? filtersToSend.flow : flowId}`
        }
        if (resetFilterType !== 'all') {


            if (filtersToSend.start_time)
                filterString += `&start_date_time=${filtersToSend.start_time}`
            if (filtersToSend.end_time)
                filterString += `&end_date_time=${filtersToSend.end_time}`
            if (filtersToSend.user)
                filterString += `&executed_by=${filtersToSend.user}`
            if (filtersToSend.status)
                filterString += `&state=${filtersToSend.status}`
        }




        try {
            let response = await store.dispatch(GetFlowsJobsList(filterString));
            if (response.status === "success") {
                setErrMsg('')

                createLogsData(response?.data?.executions, true)
                setLoadMore(false)
                setDataCount(response?.data?.count)
                setPageNo(prev => prev + 1)
                setStopLazyload(false)
                setRefreshing(false)
            }
            if (response.status === "success" && response.message === "Invalid Page Requested") {
                emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)

                setLoadMore(false)
                setDataCount(response?.data?.count)
                setRefreshing(false)
            }
        } catch (error) {
            setErrMsg(error.message)

            setRefreshing(false);
            setLoadMore(false);
            emitToastNotification('error', Literals.UNABLE_TO_REFRESH_USAGE)

        }
    };

    const applyFilter = async () => {
        setDataCount()
        setNoDataFound(false)
        setTimelineData([])
        setRefreshing(true)




        // // let filterString = ""
        // // if (filtersToSend.flow)
        // //     filterString += `&flow_id=${filtersToSend.flow}`
        // // if (filtersToSend.start_time)
        // //     filterString += `&start_date_time=${filtersToSend.start_time}`
        // // if (filtersToSend.end_time)
        // //     filterString += `&end_date_time=${filtersToSend.end_time}`
        // // if (filtersToSend.user)
        // //     filterString += `&executed_by=${filtersToSend.user}`
        // // try {
        // //     let response = await store.dispatch(GetFlowsJobsList(`job/flow/?page=${1}&limit=${50}` + filterString));
        // //     if (response.status === "success") {
        // //         createLogsData(response?.data?.executions, true)
        // //         setLoadMore(false)
        // //         setPageNo(prev => prev + 1)
        // //         setDataCount(response?.data?.count)
        // //         setRefreshing(false)
        // //     }
        // //     if (response.status === "success" && response.message === "Invalid Page Requested") {
        // //         emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)

        // //         setStopLazyload(true)
        // //         setLoadMore(false)
        // //     }
        // // } catch (error) {

        //     setRefreshing(false);
        //     setLoadMore(false);
        //     emitToastNotification('error', Literals.UNABLE_TO_REFRESH_USAGE)

        // }


        getSummaryTimelineData(true)

    };


    const killProcessingQuery = async (query_id) => {

        let resp = await store.dispatch(FlowsJobsAbort(query_id))

        if (resp.status === "success") {
            refreshSummaryTimelineData()
        }
        else {
            emitToastNotification('error', Literals.CANT_KILL_THIS_QUERY_PLEASE_REFRESH_LOGS)
        }
    }


    const createLogsData = (data, isRefreshing) => {
        let dataList = [...data]

        dataList !== undefined && dataList?.length > 0 && dataList.forEach((item, index) => {
            dataList[index]['id'] = item?.id;
            dataList[index]['originalState'] = item?.state

            dataList[index]['action_btn'] =
                <div className="d-flex align-items-center justify-content-center h-100">
                    <Tooltip placement="bottom" title="View Flow">
                        <button className="custom-btn-outline  bg-transparent  custom-btn btn-with-icon"
                            onClick={(e) => {

                                window.open(`/flows/${item?.flow_detail?.id}`)
                            }
                            }
                        >
                            <REDIRECTJOBS />
                        </button>
                    </Tooltip>

                </div>

            dataList[index]['state'] = <div className={`${item?.state === queryStatus.failed || item?.state === queryStatus.error ? 'cursor-pointer' : ''} d-inline-flex align-items-center flows-logs-timeline`}><label key={item?.state} className={`mb-0 text-capitalize 
            ${item?.state === queryStatus.finished || item?.state === queryStatus.success || item?.state === queryStatus.done ? 'text-success'
                    : item?.state === queryStatus.running || item?.state?.toLocaleLowerCase() === 'starting' ? 'text-primary'
                        : item?.state === queryStatus.queued ? 'text-warning'
                            : item?.state?.toLocaleLowerCase() === 'initial' || item?.state === null ? 'text-warning'
                                : item?.state === queryStatus.failed || item?.state === queryStatus.error ? 'text-danger'
                                    : 'text-warning'} `}
            >

                <i className={`mr-1 bx bx-${item?.state === queryStatus.finished || item?.state === queryStatus.success || item?.state === queryStatus.done ? 'check-circle'
                    : item?.state === queryStatus.running || item?.state?.toLocaleLowerCase() === 'starting' ? 'run'
                        : item?.state === queryStatus.queued ? 'dots-horizontal-rounded'
                            : item?.state?.toLocaleLowerCase() === 'initial' || item?.state === null ? 'error-circle'
                                : item?.state === queryStatus.failed || item?.state === queryStatus.error ? 'x-circle'
                                    : 'text-warning'
                    }
                `}></i>

                {item?.state && item?.state.length > 0
                    ? item?.state.toLowerCase()
                    : 'Initial'}
            </label>
                {
                    (item?.state === queryStatus.running || item?.state === queryStatus.queued) ?
                        <Tooltip placement="bottom" title="Abort Execution">

                            <button className="custom-btn-outline danger-btn bg-transparent border-0 custom-btn btn-with-icon ml-2"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (window.confirm('Are you sure want to abort this flow execution?')) {
                                        killProcessingQuery(item?.id)
                                    }
                                }
                                }
                            >
                                {/* <i className='bx bx-stop-circle h5 mb-0 text-danger'></i> */}
                                <STOPOCTAGON />
                            </button>
                        </Tooltip> : ''
                }</div>

            dataList[index]['start_date_time'] = <div key={item?.start_date_time} className="align-items-center d-flex h-100">
                <div>
                    <label className="d-block label text-dark" style={{ lineHeight: 1, marginBottom: 3 }}>
                        <i className="mr-1 bx bxs-calendar-alt"></i>
                        {item?.start_date_time === null ? '' : new Date(item?.start_date_time).getDate()}
                        {item?.start_date_time === null ? '' : ' ' + getMonthName.short[new Date(item?.start_date_time).getMonth()]}
                        {item?.start_date_time === null ? '' : ' ' + new Date(item?.start_date_time).getFullYear()}
                    </label>
                    <p className="small text-black-50 mb-0" style={{ lineHeight: 1 }}>
                        <i className="mr-1 bx bx-time"></i>{item?.start_date_time === null ? '00:00:00' : getTime(item?.start_date_time)}
                    </p>
                </div>
            </div>

            dataList[index]['flow_name'] = <div key={item?.flow_detail?.name} className="align-items-center d-flex h-100">
                <Tooltip title={item?.flow_detail?.name}>

                    <span className='primary-svg-color'><FLOWS width={"30"} height={"20"} /></span>
                    <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item?.flow_detail?.name?.toUpperCase()}>

                        {item?.flow_detail?.name?.toUpperCase()}
                    </Highlighter>
                </Tooltip>
            </div>
            dataList[index]['user'] = <p key={item?.executed_by_user?.email} className=" text-black mb-0" >
                <Tooltip placement="topLeft" className="d-block text-with-ellipsis" title={item?.executed_by_user?.email}>
                    <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item?.executed_by_user?.email}>

                        <i className="mr-1 bx bx-user"></i>{item?.executed_by_user?.email}
                    </Highlighter>
                </Tooltip>
            </p>
            dataList[index]['elapsed_time_sec'] = <p key={convertSecondToHHMMSS(item.elapsed_time_sec)} className="small text-black mb-0" >
                <Tooltip title={item?.elapsed_time_sec === null ? '00:00:00' : `${convertSecondToHHMMSS(item.elapsed_time_sec)}`} >

                    <i className="mr-1 bx bx-time"></i>{item?.elapsed_time_sec === null ? '00:00:00' : `${convertSecondToHHMMSS(item.elapsed_time_sec)}`

                    }</Tooltip>
            </p>


        })
        if (!isRefreshing) {
            setTimelineData([...timelineData, ...dataList])
        }
        else {
            setTimelineData([...dataList])
        }
    }


    useEffect(() => {

        // getSummaryTimelineData()

        // getFlowsList();
        getUsersList();

    }, [flowsexecutionId])

    useEffect(() => {
        if (getLogs) {
            setPageNo(1)
            getSummaryTimelineData()
        }

    }, [getLogs])

    const getUsersList = async () => {
        let temp = []
        let response;
        try {
            response = await store.dispatch(
                getUserGroupList({ method: "GET", endPoint: "profile/" })
            );
            if (response.status === "success") {
                let list = response?.data
                list.forEach((ele) => {
                    temp.push({ Name: ele?.username, Value: ele?.username })
                })
                setUsersList(temp);
            }
        } catch (error) { }
    };




    const getFlowsList = async () => {
        let temp = []


        await store.dispatch(fetchFlowsLandingPageList('', '', 'mode=minimal')).then((res) => {

            let list = res.data;

            list.forEach((ele) => {
                temp.push({ Name: ele?.name, flowId: ele?.id, value: ele?.id })

            })
        })
        setFlowsList(temp);


    }

    let columns = [
        {
            name: (
                <div>
                    Id
                </div>
            ),
            key: "id",
            width: "5%",

            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div>
                    Flows Name
                    {filtersToSend.user !== defaultSelectValues.user && filtersToSend.user !== ''
                        ? <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "flow_name",
            sortable: false,
            resizable: true,

        },
        {
            name: (
                <div>
                    Start Time
                    {(filtersToSend.start_time !== '' || filtersToSend.start_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "start_date_time",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div>
                    Elapsed Time
                    {(filtersToSend.start_time !== '' || filtersToSend.start_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "elapsed_time_sec",
            sortable: false,
            resizable: false,

        },

        {
            name: (
                <div>
                    State
                </div>
            ),
            key: "state",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div>
                    User
                </div>
            ),
            key: "user",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div className='text-center'>
                    Details
                </div>
            ),
            key: "action_btn",
            sortable: false,
            resizable: false,

        }
    ];

    let leastColumns = [
        {
            name: (
                <div>
                    Flow Name
                    {filtersToSend.user !== defaultSelectValues.user && filtersToSend.user !== ''
                        ? <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "flow_name",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div>
                    Start Time
                    {(filtersToSend.start_time !== '' || filtersToSend.start_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "start_date_time",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div>
                    Elapsed Time
                    {(filtersToSend.start_time !== '' || filtersToSend.start_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "elapsed_time_sec",
            sortable: false,
            resizable: false,

        },

        {
            name: (
                <div>
                    State
                </div>
            ),
            key: "state",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div>
                    User
                </div>
            ),
            key: "user",
            sortable: false,
            resizable: false,

        },
        {
            name: (
                <div className='text-center'>
                    Details
                </div>
            ),
            key: "action_btn",
            sortable: false,
            resizable: false,

        }
    ];

    if (fromFlowLogs) {
        columns = columns.slice(0, columns.length - 1)
        leastColumns = leastColumns.slice(0, leastColumns.length - 1)
    }
    const RowSelection = (row) => {

        setLogDetails(row)

        setOpenModal(true)


    }

    useEffect(() => {
        if (!openModal) {
            // setLogDetails({})
        }
    }, [openModal])


    useEffect(() => {

        let temp = timelineData.filter((item) => {

            let formattedData = _.pick(item, ["flow_name", "elapsed_time_sec", "start_date_time", "state", "user"])
            Object.keys(formattedData).forEach(item => {
                formattedData[item] = formattedData[item].key
            })

            return JSON.stringify(formattedData).toLowerCase().includes(filterText.toLowerCase());
        })
        setFilteredData(temp)

    }, [timelineData, filterText])


    const onScrollToBottom = (isAtBottom) => {
        if (!stopLazyload) {
            if (isAtBottom && !loadMore) {
                setLoadMore(true)
                getSummaryTimelineData()
            }
        }
    }

    const onCalendarChange = (dates, dateString, info) => {
        const currentDate = new Date();
        if (dateString[0] && dateString[1]) {
            let startDate = dateString[0];
            let endDate = dateString[1];

            if ((new Date(startDate) > currentDate || new Date(endDate) > currentDate)) {
                emitToastNotification('warning', Literals.CANNOT_SELECT_DATE_GREATER_THAN_CURRENT_DATE)

                return;
            }
            let temp = [moment(startDate, "YYYY-MM-DD"), moment(endDate, "YYYY-MM-DD")];
            setFilterStartDate(startDate)
            setFilterEndDate(endDate)
            setDatePickerValue(temp);
        }
        else {
            setFilterStartDate('')
            setFilterEndDate('')
        }
    }

    const disabledDate = (current) => {

        return current && (current > moment().endOf('day') || current < currentDate);
    }

    const handleUserSelect = (value) => {
        setUserSelectValue(value)
    }




    const getTransformerIcon = (nodeType) => {
        switch (nodeType?.toLowerCase()) {
            case 'datasource':

                return <DATASOURCE />
            case 'target':

                return <TARGETICON />
            case 'merge':
            case 'join':
                return <ICON_FLOW_MERGE />
            case 'filter':
                return <ICON_FILTER />
            case 'sql':
                return <SQLTRANSFORMERNEWBLUE />
            case 'scriptoutput':
                return <SCRIPTOUTPUT />
            case 'nb_python3':
                return <PYTHONSCRIPTICON />
            case 'alert':
                return <ALERTBELLLARGE />
            case 'databricks':
                return <DATABRICKSICON />
            default:
                return <SCRIPTOUTPUT />

        }
    }

    const onOpenChange = (open) => {
        if (open) {
            setDatePickerValue([null, null]);
        } else {
            setDatePickerValue(null)
        }
    };

    const resetAllFilter = (onlyReset = false) => {

        setFlowSelectValue(defaultSelectValues.flow)

        setUserSelectValue(defaultSelectValues.user)
        setflowstatus(defaultSelectValues.status)

        setFilterStartDate('')
        setFilterEndDate('')
        setDatePickerValue([])
        setFiltersToSend({ flow: '', user: '', pod: '', start_time: '', end_time: '', status: "" })

        if (!onlyReset) {

            refreshSummaryTimelineData('all')
        }


    }
    const filterPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                tooltip: "Reset Filters",
                                buttonText: "",
                                buttonClassName: "custom-btn-outline error-btn custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                    resetAllFilter()
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setFilterPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                                tooltipPlacement: 'left'
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className='row mx-n1 pb-3' style={{ width: '324px' }}>
                    {/* <div className='col-12 my-3'>
                        <div
                        // className={`usage-filter-grid ${flowSelectValue !== defaultSelectValues.flow && flowSelectValue.length ? 'active' : ''
                        //     }`}
                        >
                            {
                                flowsList.length ?
                                    <>
                                        <SingleSelection
                                            props={{
                                                label: "",
                                                placeholder: "Select Flow Name",
                                                dropDownValue: flowSelectValue,
                                                onClickEvent: (value) => handleFlowSelect(value),
                                                optionList: flowsList,
                                                isDisable: flowsList.length ? false : true,
                                            }}
                                        />
                                        {/* {
                                            flowSelectValue !== defaultSelectValues.flow
                                                && flowSelectValue.length ?
                                                <Buttons
                                                    props={{
                                                        tooltip: "Reset Domain Filter",
                                                        buttonText: "",
                                                        buttonClassName: "custom-btn-outline danger-btn custom-btn btn-with-icon",
                                                        buttonEvent: () => {
                                                            resetFilter('flow');
                                                        },
                                                        ImgSrc: () => <RESETFILTER />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_TERTIARY,
                                                    }}
                                                /> : ''
                                        } 
                                    </> : <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>

                    </div> */}

                    <div className='col-12 mb-3'>
                        <div

                        >
                            {
                                usersList.length ?
                                    <>
                                        <SingleSelection
                                            props={{
                                                label: "",
                                                placeholder: "Select User Name",
                                                dropDownValue: userSelectValue,
                                                onClickEvent: (value) => handleUserSelect(value),
                                                optionList: usersList,
                                                isDisable: usersList.length ? false : true,
                                            }}
                                        />

                                    </> : <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>
                    </div>
                    <div className='col-12 mb-3'>
                        <div

                        >

                            <SingleSelection
                                props={{
                                    label: "",
                                    placeholder: "Select Status",
                                    dropDownValue: flowstatus,
                                    onClickEvent: (value) => setflowstatus(value),
                                    optionList: [{ Name: "Success", value: "success" }, { Name: "Failed", value: "failed" },
                                    { Name: "Running", value: "running" },
                                    { Name: "Aborted", value: "aborted" }
                                    ],
                                    isDisable: false,
                                }}
                            />


                        </div>
                    </div>
                    <div className='col-12 d-flex justify-content-between'>
                        <div id='usage-filter-range-picker' style={{ width: '200px' }}>
                            {
                                timelineData ?
                                    <>
                                        <RangePicker className='w-100 usage-filter-range-picker' value={datePickerValue || value}
                                            onCalendarChange={onCalendarChange}
                                            disabledDate={disabledDate}
                                            // onCalendarChange={(val) => {console.log(val);setDates(val)}}
                                            onChange={(val) => { setValue(val) }}
                                            onOpenChange={onOpenChange} />
                                    </>
                                    : <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>
                        {
                            timelineData &&
                                // flowsList.length &&
                                usersList.length ?
                                <Buttons
                                    props={{
                                        buttonText: "Apply",
                                        buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                        buttonEvent: () => {
                                            filtersToSend.flow = flowSelectValue !== defaultSelectValues.flow ? flowSelectValue : ''
                                            filtersToSend.user = userSelectValue !== defaultSelectValues.user ? userSelectValue : ''
                                            filtersToSend.start_time = filterStartDate
                                            filtersToSend.end_time = filterEndDate;
                                            filtersToSend.status = flowstatus
                                            setPageNo(1)
                                            // setTimeout(() => {

                                            applyFilter();
                                            // }, 500);

                                            setFilterPop(false)
                                        },
                                        toggleBtnValue: "",
                                        ImgSrc: () => <CHECK />,
                                        isDisable: userSelectValue === defaultSelectValues.user && !flowstatus && !filterStartDate && !filterEndDate ? true : false,






                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        toggleBtnOption: null,
                                    }}
                                />
                                : <CustomSkeleton height='28px' width='75px' />
                        }
                    </div>
                </div>
            </>
        }
    }

    const handleFilterVisibleChange = (visible) => {
        setFilterPop(visible)
    }

    const refreshTransformerStatus = async (logDetails) => {

        let payload = {

            page: 1,
            limit: 50,
            executionId: flowsexecutionId
        };



        try {
            let response = await store.dispatch(GetFlowsJobsList(`job/flow/?page=${payload.page}&limit=${payload.limit}&execution_id=${logDetails?.id}&flow_id=${flowId ? flowId : ''}`));
            if (response.status === "success" && response['data'].executions?.length) {
                let newlogsDetails = cloneDeep(logDetails);
                newlogsDetails['progress'] = response['data'].executions[0]?.progress;
                RowSelection(newlogsDetails);
                emitToastNotification('success', 'Data refresh succesfully')
            }

        } catch (error) {
            emitToastNotification('success', 'Data not refresh')

        }
    }

    return <>
        {errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> : <div className="usage-listing">
            <div id="usage-refresh-panel-jobs" className={`py-2 px-3 d-flex align-items-center usage-refresh-panel-jobs ${fromFlowLogs ? 'justify-content-between' : 'justify-content-end'}`}>
                {fromFlowLogs ? <div className='font-weight-bold text-uppercase '>USAGE OF: {flowName} </div> : null}



                {/* <Search className="mr-2" setSearchData={setFilterText} /> */}
                {/* <Buttons
                    props={{
                        tooltip: "Reset Domain Filter",
                        buttonText: "",
                        buttonClassName: "custom-btn-outline danger-btn custom-btn btn-with-icon",
                        buttonEvent: () => {
                            resetAllFilter();
                        },
                        ImgSrc: () => <RESETFILTER />,
                        isDisable: false,
                        buttonType: Literals.BTN_TERTIARY,
                    }}
                /> */}

                <div className="d-flex align-items-center justify-content-center">
                    <Popover placement="bottomLeft"
                        title={filterPopover.header}
                        content={filterPopover.body}
                        trigger="focused"
                        autoAdjustOverflow={true}
                        open={showFilterPop && true}
                        onOpenChange={handleFilterVisibleChange}
                    >
                        <Tooltip placement='bottom' title='Filter'>
                            <button className={`${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"
                                } custom-btn btn-with-icon  ${(filtersToSend.flow !== '' ||
                                    filtersToSend.user !== '' ||
                                    filtersToSend.start_time !== '' || filtersToSend.end_time !== '') ? 'rdg-filter-active' : ''
                                }`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFilterPop(true)
                                }
                                }
                            >
                                <FILTER />
                            </button>
                        </Tooltip>
                    </Popover>

                    <Buttons
                        props={{
                            tooltip: "Refresh Logs",
                            buttonText: "",
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                            buttonEvent: () => {
                                refreshSummaryTimelineData()
                            },
                            ImgSrc: () => <span className={`${isRefreshing ? 'bx-spin' : ''} d-flex`}><REFRESH /></span>,
                            isDisable: isRefreshing,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            tooltipPlacement: 'left'

                        }}
                    />
                </div>
            </div>
            <div className='job-table-containing-box'>
                {
                    noDataFound ?
                        <NoDataComponent logo={<SMALLFLOWS width="60" height="60" color="black" />}
                            message="No Logs Found" />

                        : timelineData && timelineData.length > 0 ?
                            <LandingSimpleTable

                                cols={showFilters ? columns : leastColumns}
                                autoWidth={true}
                                rowsData={timelineData}
                                onRowClick={RowSelection}
                                onScrollToBottom={onScrollToBottom}
                                autoScroll={pageNo > 1}
                                loadMore={loadMore}


                            /> : showSimpleLoading()
                }
            </div>

            <Drawer
                rootClassName="jobs-drawer"
                title={<h2 className="section-heading">{logDetails.flow_name}</h2>}
                placement={fromFlowLogs ? 'bottom' : 'right'}
                width={600}
                onClose={() => setOpenModal(false)}
                open={openModal}
                closable={false}
                extra={
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className='mb-n1 mr-3 text-small'>
                            <label className='d-block mb-1 text-right small-text' style={{ lineHeight: 0 }}><Tooltip title={logDetails?.error_msg ? logDetails?.error_msg : null}><span className={logDetails.error_msg ? "cursor-pointer" : ""}>Status: {logDetails.state}</span> </Tooltip> </label>
                            <label className='mb-0 text-black-50 d-block text-right small-text'>{logDetails.user}</label>
                        </div>
                        <Buttons props={{ buttonText: 'Refresh', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { refreshTransformerStatus(logDetails) }, ImgSrc: () => <REFRESH />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                        <Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

                    </div>
                }

            >
                {
                    logDetails?.progress?.length ? <div className='custom-card shadow-none border' style={{ height: 'calc(100vh - 245px' }}>
                        <p className='mb-2 h6 text-black-100 d-block text-left' >Transformer Details</p>

                        {
                            logDetails?.progress?.length ?
                                <List
                                    style={{
                                        height: logDetails.error_msg === null ? `calc(100vh - 306px)` : `calc(100vh - 300px)`,
                                        overflow: 'auto'
                                    }}

                                    itemLayout="horizontal"

                                    dataSource={logDetails?.progress}
                                    renderItem={item => (
                                        <List.Item
                                            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 0.5fr' }}
                                            actions={[<div className='d-flex align-items-center justify-content-center'> Status:  <i style={{ fontSize: "1rem" }} className={`mr-1 ml-1 bx bx-${item?.state === queryStatus.finished || item?.state === queryStatus.success || item?.state === queryStatus.done ? 'check-circle'
                                                : item?.state === queryStatus.running || item?.state?.toLocaleLowerCase() === 'starting' ? 'run'
                                                    : item?.state === queryStatus.queued ? 'dots-horizontal-rounded'
                                                        : item?.state?.toLocaleLowerCase() === 'initial' || item?.state === null ? 'error-circle'
                                                            : item?.state === queryStatus.failed || item?.state === queryStatus.error ? 'x-circle'
                                                                : 'text-warning'
                                                }

                                                   ${item?.state === queryStatus.finished || item?.state === queryStatus.done || item?.state === queryStatus.success ? 'text-success'
                                                    : item?.state === queryStatus.running || item?.state?.toLocaleLowerCase() === 'starting' ? 'text-primary'
                                                        : item?.state === queryStatus.queued ? 'text-warning'
                                                            : item?.state?.toLocaleLowerCase() === 'initial' || item?.state === null ? 'text-warning'
                                                                : item?.state === queryStatus.failed || item?.state === queryStatus.error ? 'text-danger'
                                                                    : 'text-warning'}
                                        `}></i>
                                                <label className={`mb-0 text-capitalize 
                                        ${item?.state === queryStatus.finished || item?.state === queryStatus.done || item?.state === queryStatus.success ? 'text-success'
                                                        : item?.state === queryStatus.running || item?.state?.toLocaleLowerCase() === 'starting' ? 'text-primary'
                                                            : item?.state === queryStatus.queued ? 'text-warning'
                                                                : item?.state?.toLocaleLowerCase() === 'initial' || item?.state === null ? 'text-warning'
                                                                    : item?.state === queryStatus.failed || item?.state === queryStatus.error ? 'text-danger'
                                                                        : 'text-warning'} `}>



                                                    {item?.state && item?.state.length > 0
                                                        ? item?.state?.toLowerCase()
                                                        : 'Initial'}
                                                </label>

                                                {item.error_msg !== null ? <Tooltip placement="bottom" title="Preview Error Log">
                                                    <button className="custom-btn-outline ml-1 color-primary bg-transparent border-0 custom-btn btn-with-icon"
                                                        onClick={() => {
                                                            settransformerErrorMsg(item?.error_msg)
                                                            setopenErrorModal(true)
                                                        }
                                                        }
                                                    >
                                                        <PREVIEW />
                                                    </button>
                                                </Tooltip> : null}
                                            </div>]}
                                        >

                                            <List.Item.Meta
                                                avatar={<div className='transformerIcon mr-0 pr-1'><Tooltip title={item?.NodeType}>{getTransformerIcon(item?.NodeType)}</Tooltip></div>}
                                                title={<Tooltip title={item?.transformerID}>{`${item?.transformerID > 40 ? item?.transformerID?.slice(0, 40) + `...` : item?.transformerID} `}</Tooltip>}
                                                description=''
                                            />
                                            <div >
                                                <div className="align-items-center d-flex h-100">

                                                    <i className="mr-1 bx bx-time"></i>
                                                    <p className=" mt-0 mb-0 text-dark" style={{ lineHeight: 1, fontSize: "0.75rem", fontWeight: 400 }} >
                                                        <b>Elapsed Time:</b> {item?.time_elapsed_sec === null ? '00:00:00' : `${convertSecondToHHMMSS(item.time_elapsed_sec)}`}
                                                    </p>


                                                </div>

                                            </div>

                                            <div >
                                                <div className="align-items-center d-flex h-100">
                                                    <div>
                                                        <label className="d-block label text-dark" style={{ lineHeight: 1, marginBottom: 3 }}>
                                                            <i className="mr-1 bx bxs-calendar-alt"></i>
                                                            {item?.start_date_time === null ? '' : new Date(item?.start_date_time).getDate()}
                                                            {item?.start_date_time === null ? '' : ' ' + getMonthName.short[new Date(item?.start_date_time).getMonth()]}
                                                            {item?.start_date_time === null ? '' : ' ' + new Date(item?.start_date_time).getFullYear()}
                                                        </label>
                                                        <p className="small text-black-50 pt-1 mb-0" style={{ lineHeight: 1 }}>
                                                            <i className="mr-1 bx bx-time"></i>{item?.start_date_time === null ? '00:00:00' : getTime(item?.start_date_time)}
                                                        </p>
                                                    </div>

                                                </div>

                                            </div>

                                        </List.Item>
                                    )}
                                /> : null

                        }
                    </div> : null}

                {/* {

                    logDetails?.error_msg !== null ? <div className='mt-1  custom-card shadow-none border' style={{ height: 'calc(100vh - 415px', overflow: 'auto' }}>
                        <p className='mb-2 pt-2 h6 text-black-100 d-block text-left' >Error Logs Details</p>
                        <p className='text-error'>
                            {logDetails?.error_msg}
                        </p>
                    </div> : null
                } */}
            </Drawer>


            <Modal size="lg" isOpen={openErrorModal} className="custom-modal error-details-modal" centered={true} onClosed={() => setopenErrorModal(false)}>
                <ModalHeader close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setopenErrorModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className='d-flex'>

                            <h2 className="section-heading">{logDetails.flow_name}</h2>
                        </div>
                        <div className='mb-n1 mr-3 small'>
                            <label className='label d-block mb-0 small text-right' style={{ lineHeight: 0 }}>Status: {logDetails.state}</label>
                            <label className='label mb-0 small text-black-50 d-block text-right'>{logDetails.user}</label>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='usage-drawer'>
                        <div>
                            <div className='py-2'>
                                <div className='row mx-n2'>


                                    <div className='col-12 h-100'>

                                        <div>
                                            <p className="paragraph">
                                                {transformerErrorMsg}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>

        </div>}
    </>;
};
