import React from 'react'
import { ICON_ARROW_LEFT, ICON_ARROW_RIGHT, ICON_CATALOG_DOMAIN, ICON_CHECK, ICON_CLOSE, ICON_DELETE, ICON_LOADING_CIRCLE, ICON_OPTION, ICON_PLAY, ICON_PLUS, ICON_RESET, ICON_VERIFIED } from '../../../Common/newIconSource'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import SqlEditor from '../../dataCatalogue/preview/components/SqlEditor'
import { useNavigate } from 'react-router-dom'
import CommonModal from '../../../Common/CommonModal/CommonModal'
import { Dropdown, Input, Popover, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { deleteFeedback, verifyConversation } from '../../../../store/modules/common/GenAi/GenAiReducer'
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'
import { setUserSelectedDomain } from '../../../../store/actions'
import _ from 'lodash'
import VerifyModal from './VerifyModal'

const TalkDataTraceSql = React.forwardRef(({
    setVariationModalOpen = () => {},
    data = {},
    loadingFeeds = false,
    query = '',
    setQuery = () => {},
    runCallback = () => {},
    executeQuery = () => {},
    isQueryExecuting = false,
    chartData = {},
    reload = () => {},
    staticQuery = '',
    setStaticQuery = () => {},
    chartType = '',
    graphData = {},
    questVariations = [],
    domainList = [],
    loadingDomains = false,
    activeDomain = '',
    fetchDomains = () => {},
    empty = false,
    sqlTableDetails = [],
    selectedRef = {},
    onDeleteReload = () => {},
    afterUnverify = () => {},
    setParentDomain = () => {}
}, ref) => {

    const history = useNavigate()
    const dispatch = useDispatch()

    const [open, setOpen] = React.useState(false)
    const [comment, setComment] = React.useState('')
    const [verifying, setVerifying] = React.useState(false)
    const [domainSearch, setDomainSearch] = React.useState('')
    const [openDelete, setOpenDelete] = React.useState(false)
    const [deleting, setDeleting] = React.useState(false)
    const [optOpen, setOptOpen] = React.useState(false)
    const [unverifying, setUnverifying] = React.useState(false)

    React.useEffect(() => {
        if(data) {
            setComment(data?.verify_comment ?? '')
        }
    }, [data])

    const onVerify = () => {
        setVerifying(true)
        let quests = questVariations?.filter(q => q?.question !== '')?.map(q => q?.question)
        let payload = {
            question_variation: [
                // data?.conversation_detail?.question,
                ...quests
            ],
            is_verified: true,
            verify_comment: comment,
            query,
            chart_type: {
                ...graphData?.chart_type,
                type: chartType
            },

        }

        dispatch(verifyConversation(data?.conversation_detail?.id, payload))
        .then(() => {
            selectedRef.current = {
                ...selectedRef.current,
                is_verified: true,
                conversation_detail: {
                    ...selectedRef.current.conversation_detail,
                    query: payload?.query,
                    chart_type: payload?.chart_type
                },
                selected_chart: payload?.chart_type
            }

            setComment('')
            setVerifying(false)
            setOpen(false)
            setTimeout(() => {
                reload(true)
            }, 1000)
            fetchDomains()
        })
        .catch(() => {
            setVerifying(false)
        })
    }

    const onUnverify = () => {
        setUnverifying(true)
        let payload = {
            is_verified: false
        }

        dispatch(verifyConversation(data?.conversation_detail?.id, payload))
        .then(() => {
            selectedRef.current = {
                ...selectedRef.current,
                is_verified: false,
            }
            setOpen(false)
            setUnverifying(false)
            setTimeout(() => {
                afterUnverify()
            }, 1000)
            fetchDomains()
        })
        .catch(() => {
            setUnverifying(false)
        })
    }

    const onRun = () => {
        setQuery(staticQuery)
        executeQuery(staticQuery)
        runCallback()
    }

    const onDelete = () => {
        setDeleting(true)
        dispatch(deleteFeedback(data?.conversation_detail?.id))
        .then(() => {
            setDeleting(false)
            setOpenDelete(false)
            selectedRef.current = {}
            onDeleteReload(data?.conversation_detail?.id)
        })
        .catch(() => {
            setDeleting(false)
        })
    }

    const dataDomainList = React.useMemo(() => {
        let list = [...domainList]

        list = list?.filter(d => d?.data_domain_detail?.data_domain_name?.replaceAll("_"," ")?.toLowerCase()?.includes(domainSearch?.toLowerCase()))

        return _.sortBy(list, [l => l?.data_domain_detail?.data_domain_name]) ?? []
    }, [domainList, domainSearch])

    const userSelectedOomain = (d_id) => {
        dispatch(setUserSelectedDomain({ data_domain_id: d_id }, false))
        .catch(err => console.error(err?.message))
    }

    const unmatched = query !== staticQuery

    return (
        <>
            <div className='chat-feed-sql-wrap w-100 h-100'>
                <div className='chat-feed-verify-head'>
                    <div className='chat-feed-title'>
                        <div className='d-flex align-items-center color-primary sel-domain'>
                            {
                                loadingFeeds
                                ?
                                <CustomSkeleton height='1.5rem' width='6.25rem' />
                                :
                                <>
                                    <Popover
                                        trigger={'hover'}
                                        placement='bottomLeft'
                                        arrow={false}
                                        rootClassName='feed-dm-li'
                                        title={
                                            <>
                                                <div className='d-flex fontInterSemiBold fontSizeHeading 
                                                align-items-center justify-content-between p-2'>
                                                    <input
                                                        className='custom-input-field'
                                                        placeholder='Search...'
                                                        value={domainSearch}
                                                        onChange={e => {
                                                            setDomainSearch(e?.target?.value)
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }
                                        content={
                                            <>
                                                {
                                                    loadingDomains ? <ClaristaLoader height='9.0625rem' />   
                                                    :
                                                    dataDomainList?.length === 0 ?
                                                    <div className='p-3 text-center text-black-50 fontSizeHeading'>
                                                        No Domains Found
                                                    </div>
                                                    :
                                                    <div className='p-2' style={{maxHeight: '11.25rem', overflowY: 'auto'}}>
                                                    {
                                                        dataDomainList?.map((d, i) => (
                                                            <div 
                                                                key={`${d?.data_domain_detail?.data_domain_id}-${d?.data_domain_detail?.data_domain_name}=${i}`} 
                                                                className={`trace-domain-sel-itm d-flex align-items-center my-1 px-2 py-1 feed-dm-li-itm
                                                                    ${activeDomain?.toString() === d?.data_domain_detail?.data_domain_id?.toString() ? 'active' : ''}
                                                                `}
                                                                onClick={() => {
                                                                    if(activeDomain?.toString() !== d?.data_domain_detail?.data_domain_id?.toString()) {
                                                                        let d_id = d?.data_domain_detail?.data_domain_id?.toString()
                                                                        setParentDomain(d_id)
                                                                        userSelectedOomain(d_id)
                                                                    }

                                                                }}
                                                            >
                                                                <ICON_CATALOG_DOMAIN color='currentColor' height='14' width='14' />
                                                                <label 
                                                                    title={d?.data_domain_detail?.data_domain_name?.replaceAll("_"," ")}
                                                                    className='mb-0 fontSizeLabel ml-2 text-uppercase text-with-ellipsis cursor-pointer'
                                                                    style={{width: '6.25rem'}}
                                                                    >
                                                                    {d?.data_domain_detail?.data_domain_name?.replaceAll("_"," ")}
                                                                </label>
                                                                <span title={`${d?.pending_count} Pending`} className='feed-dm-li-tg ml-2'>
                                                                    {d?.pending_count ?? 0}
                                                                </span>
                                                            </div>
                                                        ))
                                                    }
                                                    </div>
                                                }
                                            </>
                                        }
                                    >
                                        <ICON_CATALOG_DOMAIN height='20' width='20'/>
                                        <span style={{fontSize: '0.875rem'}} className='ml-2 fontInterSemiBold feedback-custom-domain'>
                                            {
                                                empty ? 'Select Domain'
                                                :
                                                data?.conversation_detail?.data_domain_name?.toUpperCase()}
                                        </span>
                                        <span className='d-inline-flex bx-rotate-90 color-primary ml-2'>
                                            <ICON_ARROW_RIGHT color='currentColor' height='12' width='12' />
                                        </span>
                                    </Popover>
                                </>
                            }
                        </div>
                        {
                            empty ? ''
                            :
                            <>
                                <div className='vertical-separator' style={{height: '1.5rem', width: '0.125rem'}}></div>
                                <div className='d-flex align-items-start mr-2 feed-ques-title-wrap' style={{maxWidth: '34.75rem'}}>
                                    {
                                        loadingFeeds
                                        ?
                                        <CustomSkeleton height='1.5rem' width='18.75rem' />
                                        :
                                        <h4 title={data?.conversation_detail?.question}
                                            className='fontSizeLargeHeading color-primary fontInterSemiBold feed-ques-title'>
                                            {data?.conversation_detail?.question}
                                        </h4>
                                    }
                                </div>
                            </>
                        }
                        {
                            loadingFeeds || empty
                            ?
                            ''
                            :
                            <div className='d-flex veri-ic'>
                                <ICON_VERIFIED height='24' width='24' color={data?.is_verified ? '#03A932' : '#d0d0d0'} />
                                {/* <Buttons
                                    props={{
                                        buttonText: "Add Variations",
                                        buttonClassName: `ml-2 custom-btn-outline custom-btn btn-with-text border`,
                                        buttonEvent: () => {
                                            setVariationModalOpen(true)
                                        },
                                        ImgSrc: () => <ICON_PLUS/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                /> */}
                            </div>
                        }
                    </div>
                    <div className='chat-feed-actions d-flex'>
                        {
                            empty ? ''
                            :
                            <>
                                <Tooltip 
                                    placement='left'
                                    title={unmatched ? "Please Run Sql To Proceed" : ""}
                                    destroyTooltipOnHide
                                >
                                    <button
                                        className={`custom-btn-primary custom-btn btn-with-text mr-2 feed-verify-btn
                                        ${unmatched ? 'warning' : ''}
                                        `}
                                        disabled={isQueryExecuting || loadingFeeds|| staticQuery?.trim() === "" || unverifying}
                                        onClick={() => {
                                            if(unmatched) {
                                                return
                                            }
                                            setOpen(true)
                                        }}
                                    >
                                        {unverifying ? <ICON_LOADING_CIRCLE/> : <ICON_CHECK/>} {
                                            unverifying ? "Unverifying" :
                                            data?.is_verified ? "Reverify" : "Verify"
                                        }
                                    </button>
                                </Tooltip>
                                <Dropdown 
                                    open={optOpen}
                                    onOpenChange={(open) => setOptOpen(open)}
                                    trigger={'click'}
                                    menu={{ 
                                            items: [
                                                {
                                                    key: '1',
                                                    label: 'Unverify',
                                                    disabled: !data?.is_verified,
                                                },
                                                {
                                                    key: '2',
                                                    danger: true,
                                                    label: 'Delete',
                                                }],
                                            onClick: ({key}) => {
                                                if(key === "1")
                                                onUnverify()
                                                else if(key === "2")
                                                setOpenDelete(true)
                                                else return
                                            }
                                        }}
                                    >
                                    <Buttons
                                        props={{
                                            buttonText: "",
                                            buttonClassName: `${optOpen ? 'active' : ''} custom-btn-outline custom-btn btn-with-icon`,
                                            buttonEvent: () => {
                                                setOptOpen(true)
                                            },
                                            ImgSrc: () => <ICON_OPTION/>,
                                            isDisable: isQueryExecuting || loadingFeeds,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                </Dropdown>
                                {/* <Buttons
                                    props={{
                                        buttonText: "",
                                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2 error-red`,
                                        buttonEvent: () => {
                                            setOpenDelete(true)
                                        },
                                        ImgSrc: () => <ICON_DELETE/>,
                                        isDisable: isQueryExecuting || loadingFeeds,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                /> */}
                            </>
                        }
                        {/* <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon ml-2`,
                                buttonEvent: () => {
                                    history(Literals.links.CLARISTA_PULSE + '#chats')
                                },
                                ImgSrc: () => <ICON_CLOSE/>,
                                isDisable: false,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        /> */}
                    </div>

                </div>
                    {
                        empty
                        ?
                        <div className='d-flex align-items-center justify-content-center h-100 w-100'>
                            <div className='d-flex flex-column align-items-center'>
                                It Seems There Are No Feedbacks Yet
                                <Buttons
                                    props={{
                                        buttonText: "Back to Pulse",
                                        buttonClassName: `custom-btn-outline custom-btn btn-with-text border mt-2`,
                                        buttonEvent: () => {
                                            history(Literals.links.CLARISTA_PULSE + '#chats')
                                        },
                                        ImgSrc: () => <ICON_ARROW_LEFT/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                                {/* <span className='my-2'>
                                OR
                                </span>
                                <span>Switch To Different Domain</span> */}
                            </div>
                        </div>
                        :
                        <div className='chat-feed-sql'>
                            <div className='d-flex align-items-center w-100 justify-content-between p-2'>
                                <div className='fontSizeHeading fontInterSemiBold'>
                                    Sql Editor
                                </div>
                                <div className='d-flex'>
                                    <Buttons
                                        props={{
                                            buttonText: "Reset",
                                            buttonClassName: `custom-btn-outline custom-btn btn-with-text border mr-2`,
                                            buttonEvent: () => {
                                                setStaticQuery(data?.conversation_detail?.sql_query?.query)
                                            },
                                            ImgSrc: () => <ICON_RESET/>,
                                            isDisable: isQueryExecuting || loadingFeeds,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                    <Buttons
                                        props={{
                                            buttonText: isQueryExecuting ? "Executing" : "Run",
                                            buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                            buttonEvent: () => {
                                                onRun()
                                            },
                                            ImgSrc: () => isQueryExecuting ? <ICON_LOADING_CIRCLE/> : <ICON_PLAY/>,
                                            isDisable: isQueryExecuting || loadingFeeds || staticQuery?.trim() === "",
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                </div>
                            </div>
                            <SqlEditor
                                setQueryvalue={setQuery}
                                QueryValue={staticQuery}
                                onChangeSqlEditor={(q) => {
                                    setStaticQuery(q)
                                }}
                                height={'100%'}
                                disableDebounce
                                // debounceTime={500}
                                handleEmptyQuery={true}
                                wrapClassName="feed-sql-edit"
                                readOnly={isQueryExecuting}
                                isQueryViewMode={isQueryExecuting}
                                sqlTableDetails={sqlTableDetails}
                            />
                        </div>
                    }

                <VerifyModal
                    open={open}
                    setOpen={setOpen}
                    comment={comment}
                    setComment={setComment}
                    onVerify={onVerify}
                    verifying={verifying}
                />

                <DeleteModal
                    open={openDelete}
                    setOpen={setOpenDelete}
                    heading='Delete Feedback'
                    loading={deleting}
                    deleteBtnDisabled={deleting}
                    onConfirm={onDelete}
                />
            </div>
        </>
    )
})

export default TalkDataTraceSql