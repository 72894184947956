import { emitToastNotification } from "../../../helpers/toast_helper";
import { fetchData } from "../../../services/apiService";
import { setLoadingStatus } from "../common/loadingActions";

const SET_SORTING_COLUMNS = "SET_SORTING_COLUMNS";
const RESET_ALL_DATAGRIDTABLE_FILTERS = "RESET_ALL_DATAGRIDTABLE_FILTERS";
var BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const resetAllDataGridTableFilters = () => {
  return {
    type: RESET_ALL_DATAGRIDTABLE_FILTERS,
    payload: initialState,
  };
};

// /flow/abort/?execution_id=4873

export const FlowsJobsAbort = (executionId) => {
  return (dispatch) => {
    // dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${BASE_URL}flow/abort/?execution_id=${executionId}`)
      )
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification("error", resp?.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification("error", err?.message);

          reject(err);
        });
    });
  };
};
export const GetFlowsJobsList = (url) => {
  return (dispatch) => {
    // dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}${url}`))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification("error", resp?.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification("error", err?.message);

          reject(err);
        });
    });
  };
};

export const getUsageActivity = (url) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}${url}`))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            if (resp?.data?.results?.length) {
              resp.data.results = resp?.data?.results?.map((element) => {
                if (element && element.user_detail) {
                  element["user_detail"]["first_name"] = element?.user_detail
                    ? element?.user_detail?.first_name
                        ?.split(" ")
                        .slice(0, 2)
                        .join(" ")
                    : "";
                  element["user_detail"]["last_name"] = element?.user_detail
                    ? element?.user_detail?.last_name
                        ?.split(" ")
                        .slice(0, 2)
                        .join(" ")
                    : "";
                }

                return element;
              });
              resolve(resp);
            } else {
              resolve(resp);
            }
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification("error", resp?.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification("error", err?.message);

          reject(err);
        });
    });
  };
};

export const getUsageStats = (domainId) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(
          "GET",
          `${BASE_URL}${domainId ? "usage/" + domainId + "/" : "usage/"}`
        )
      )
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification("error", resp?.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification("error", err?.message);

          reject(err);
        });
    });
  };
};

export const getSchedulerJobsList = (url) => {
  return (dispatch) => {
    // dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}${url}`))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification("error", resp?.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification("error", err?.message);

          reject(err);
        });
    });
  };
};

const initialState = {};

export const JobsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SORTING_COLUMNS:
      return {
        ...state,
        sortArray: [...payload],
      };

    case RESET_ALL_DATAGRIDTABLE_FILTERS:
      return initialState;

    default:
      return state;
  }
};
