import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions'
import { generateHtmlTitle, getQueryParams } from '../../common/helperFunctions'
import { DataCategory } from './DataCategory'
import { SettingsAdditionalDetails } from './SettingsAdditionalDetails'
import { SettingsClassifications } from './SettingsClassifications'
import { Tabs } from 'antd'
import { ICON_DATA_CATEGORY, ICON_INFO, ICON_USER_TAG } from '../../../Common/newIconSource'
import Buttons from '../../../Common/button/Buttons'
import { CLOSE, PLUS } from '../../../Common/iconSource'
import { Literals } from '../../common/literals'
import SearchInput from '../../../Common/search/Search'
import { useLocation, useNavigate } from 'react-router-dom'

const BUTTON_TITLE_MAP = {
    1: 'Data Category',
    2: 'Classification',
    3: 'Entry'
}

export const Settings = ({usersList, userMapping}) => {

    const history = useNavigate()
    const location = useLocation()
    const queryParam = !(!!location?.search) ? null : getQueryParams(location?.search ?? '')

    const [activeTabKey, setActiveTabKey] = useState(
        (() => {
            if(queryParam && !!queryParam?.tab) {
                return queryParam?.tab
            }
            return 'classification'
        })()
    )

    const [searchData, setSearchData] = useState('')
    const [openCategoryModal, setOpenCategoryModal] = useState(false)
    const [openClassifiersModal, setOpenClassifiersModal] = useState(false)
    const [openAddFieldsModal, setOpenAddFieldsModal] = useState(false)

    useEffect(() => {
        let title = generateHtmlTitle('Settings', 'Data Dictionary')
        document.title = title
    },[])

    const tabItems = () => {
        return [
            // {
            //     key: '1',
            //     label: <span>
            //         <ICON_DATA_CATEGORY /> Data Category
            //     </span>,
            //     children: <>
            //         <div className='dict-cat-li-wrap'>
            //             <DataCategory 
            //                 usersList={usersList}
            //                 searchText={searchData}
            //                 setCategoryModal={setOpenCategoryModal}
            //                 openCategoryModal={openCategoryModal}
            //                 hideActions={true}
            //             />
            //         </div>
            //     </>,
            // },
            {
                key: 'classification',
                label: <span>
                    <ICON_USER_TAG /> Data Classifiers
                </span>,
                children: <>
                    <div className='dict-cls-li-wrap'>
                        <SettingsClassifications
                            searchText={searchData}
                            setClassifiersModal={setOpenClassifiersModal}
                            openClassifiersModal={openClassifiersModal}
                            hideActions={true}
                            defaultOpenId={queryParam?.id ?? null}
                        />
                    </div>
                </>,
            },
            {
                key: 'additional_field',
                label: <span>
                    <ICON_INFO /> Additional Details
                </span>,
                children: <>
                    <div className='dict-addfields-li-wrap'>
                        <SettingsAdditionalDetails
                            searchText={searchData}
                            setAddFieldsModal={setOpenAddFieldsModal}
                            openAddFieldsModal={openAddFieldsModal}
                            hideActions={true}
                            defaultOpenId={queryParam?.id ?? null}
                        />
                    </div>
                </>,
            },
        ]
    }

    const onTabChange = (key) => {
        setActiveTabKey(key)
        setSearchData("")
    }

    return (
        <div className='padding-2 dict-setting-pg-wrap'>
            <Tabs 
                className='dict-detail-tab dict-settings'
                defaultActiveKey="1" 
                activeKey={activeTabKey}
                items={tabItems()} 
                onChange={onTabChange} 
                tabBarExtraContent={{
                    right: <>
                        <div className='position-relative d-flex' style={{top: -5}}>
                            <SearchInput searchData={searchData} setSearchData={setSearchData}/>
                            <Buttons
                                props={{
                                    buttonText: BUTTON_TITLE_MAP[activeTabKey] ?? 'Add',
                                    tooltip: '',
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text ml-2",
                                    buttonEvent: () => {
                                        if(activeTabKey === '1') setOpenCategoryModal(true)
                                        else if(activeTabKey === '2') setOpenClassifiersModal(true)
                                        else if(activeTabKey === '3') setOpenAddFieldsModal(true)
                                    },
                                    ImgSrc: () => <PLUS/>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                            <Buttons 
                                props={{ 
                                    buttonText: '', 
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2", 
                                    buttonEvent: () => { 
                                        history(Literals.links.CLARISTA_NAVIGATOR + '#business')
                                    }, 
                                    ImgSrc: () => <CLOSE />, 
                                    isDisable: false, 
                                    buttonType: Literals.BTN_TERTIARY 
                            }} />
                        </div>
                    </>
                }}
            />
            {/* <div className='row row-margin-correction'>
                <div className='col-6 pr-1 data-catgy-settings-ls'>
                    <div className='setting-category-table'>
                        <DataCategory 
                            usersList={usersList}
                        />
                    </div>
                    <div className='mt-2 setting-additionaldetail-table'>
                        <SettingsAdditionalDetails/>
                    </div>
                </div>
                <div className='col-6 pl-1 setting-classification-table'>
                    <SettingsClassifications/>
                </div>
            </div> */}
        </div>
    )
}
