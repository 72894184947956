import React from 'react'
import './newDataGridTable.scss'
import { ICON_CLOSE, ICON_PLUS } from '../../newIconSource'
import { DatePicker, Input, Radio, Select, TimePicker } from 'antd'
import dayjs from 'dayjs'
import { isArray } from 'lodash'
import DataGridCriteriaDropdown from './DataGridCriteriaDropdown'
import { ChevronDown } from 'react-bootstrap-icons'
import { pxToRem, remToPx } from '../../../modules/common/helperFunctions'

let onlyDateRegex = /\b(?:date)\b/gi;
let onlytimeRegex = /\b(?:time)\b/gi;
let onlyDatetimeRegex = /\b(?:timestamp|datetime)\b/gi;

const dateIsOptions = [
    {label: 'Exact Date', value: ''},
    {label: 'Today', value: '@today'},
    {label: 'Tomorrow', value: '@tomorrow'},
    {label: 'Yesterday', value: '@yesterday'},
    {label: 'Last Week', value: '@past_week'},
    {label: 'Last Month', value: '@past_month'},
    {label: 'Last Year', value: '@past_year'},
]

const DataGridFilterByCondition = React.forwardRef(({
    column = {},
    colFilterRef = {},
    setParentFilters = () => {}
}, ref) => {

    const [operation, setOperation] = React.useState('and')
    const [conList, setConList] = React.useState([{
        criteria: 'none',
        value: '',
        date_factor: ''
    }])

    React.useImperativeHandle(ref, () => {
        // Future Use
        return {
            setConditionList: (list) => {
                setConList(list)
            },
            setConOperation: (op) => {
                setOperation(op)
            }
        }
    }, [])

    const criteriaList = React.useMemo(() => {
        let data = [...conList]
        data = data?.map((d, idx) => ({...d, id: idx}))

        colFilterRef.current[column?.name] = {
            ...colFilterRef.current[column?.name],
            filters: [...data]
        }

        setParentFilters(data)

        return data
    }, [conList])

    const onInputChangeHandler = (index, value, date_factor) => {
        setConList(prev => {
            prev = prev?.map((c, i) => i === index ? ({...c, value, date_factor: date_factor ?? c?.date_factor ?? ''}) : ({...c}))
            return [...prev]
        })
    }

    const dynamic = px => remToPx(pxToRem(px))

    const getInputField = ({criteria = 'none', index = 0, value = '', date_factor}) => {
        switch (criteria) {
            case 'none':
                return <Input
                            size='middle'
                            style={{height: '1.875rem'}}
                            value={'No Criteria Selected'}
                            className='input-readyonly'
                        />
            case 'like':
            case 'not like':
            case 's%':
            case '%s':
            case '==':
            case 'regexp_like':
                return  <Input
                            size='middle'
                            placeholder='Value'
                            style={{height: '1.875rem'}}
                            value={value}
                            type='text'
                            onKeyDown={(e) => {
                                e.stopPropagation()
                            }}
                            onChange={(e) => {
                                let val = e?.target?.value
                                onInputChangeHandler(index, val)
                            }}
                        />
            case 'in':
            case 'not in':
                return  <div>
                            <Input
                                size='middle'
                                placeholder='Value'
                                style={{height: '1.875rem'}}
                                value={value}
                                type='text'
                                onKeyDown={(e) => {
                                    e.stopPropagation()
                                }}
                                onChange={(e) => {
                                    let val = e?.target?.value
                                    onInputChangeHandler(index, val)
                                }}
                            />
                            <div className='fontSizeExtraSmall text-muted pt-1 fontInterSemiBold'>
                                Enter Comma <code className='bg-white border px-1 rounded'>","</code> Separated Value, for ex: <code className='bg-white border px-1 rounded'>apple,orange,grapes</code>
                            </div>
                        </div>
            case 'date=':
            case 'date<':
            case 'date>':
                return <>
                    {
                        criteria === 'date=' && ['date', 'datetime', 'timestamp']?.includes(column?.dtype?.split('(')?.[0])
                        ?
                        <>
                            <Select
                                size='middle'
                                value={date_factor ?? ''}
                                // showSearch
                                placeholder="Options"
                                optionFilterProp="children"
                                onChange={(val) => {
                                    onInputChangeHandler(index, val, val)
                                }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={dateIsOptions}
                                suffixIcon={<><ChevronDown height={dynamic(14)} width={dynamic(14)} color='#d9d9d9'/></>}
                            />
                            {
                                date_factor === ''
                                ?
                                    column?.dtype?.match(onlyDatetimeRegex)?.length
                                    ?
                                    <DatePicker
                                        picker="date"
                                        showTime
                                        placeholder="Date Time"
                                        className={'mt-2'}
                                        value={value ? dayjs(value, "YYYY-MM-DD HH:mm:ss") : null}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        onChange={(e, val) => {
                                            onInputChangeHandler(index, val)
                                        }}

                                    />
                                    :
                                    <DatePicker
                                        picker="date"
                                        placeholder="Date"
                                        className={'mt-2'}
                                        value={value ? dayjs(value, "YYYY-MM-DD") : null}
                                        format="YYYY-MM-DD"
                                        onChange={(e, val) => {
                                            onInputChangeHandler(index, val)
                                        }}
                                    />
                                :   ''
                            }
                        </>
                        :
                        getDatatypeInput(column, index, value)
                    }
                </>
            case '<':
            case '<=':
            case '>':
            case '>=':
            case '<>':
            case '=':
                return  getDatatypeInput(column, index, value)
            case 'between':
            case 'not between':
                const [betVal1, betVal2] = isArray(value) ? value : ['', '']
                return <>
                    <div 
                        className='align-items-center' 
                        style={{display: 'grid', gridTemplateColumns: 'auto 0.625rem auto', columnGap: 0}}
                    >
                        {
                            column?.dtype?.match(onlytimeRegex)?.length
                            ?
                            <>
                                <TimePicker
                                    picker="time"
                                    placeholder="Time 1"
                                    className={''}
                                    value={betVal1 ? dayjs(betVal1, "HH:mm:ss") : null}
                                    onChange={(e, val) => {
                                        setConList(prev => {
                                            prev = prev?.map((c, i) => i === index ? ({...c, value: [val, betVal2]}) : ({...c}))
                                            return [...prev]
                                        })
                                    }}
                                />
                                <div></div>
                                <TimePicker
                                    picker="time"
                                    placeholder="Time 2"
                                    className={''}
                                    value={betVal2 ? dayjs(betVal2, "HH:mm:ss") : null}
                                    onChange={(e, val) => {
                                        setConList(prev => {
                                            prev = prev?.map((c, i) => i === index ? ({...c, value: [betVal1, val]}) : ({...c}))
                                            return [...prev]
                                        })
                                    }}
                                />
                            </>
                            :
                            column?.dtype?.match(onlyDatetimeRegex)?.length
                            ?
                            <>
                                <DatePicker
                                    picker="date"
                                    showTime
                                    placeholder="Date Time 1"
                                    className={''}
                                    value={betVal1 ? dayjs(betVal1, "YYYY-MM-DD HH:mm:ss") : null}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    onChange={(e, val) => {
                                        setConList(prev => {
                                            prev = prev?.map((c, i) => i === index ? ({...c, value: [val, betVal2]}) : ({...c}))
                                            return [...prev]
                                        })
                                    }}

                                />
                                <div></div>
                                <DatePicker
                                    picker="date"
                                    showTime
                                    placeholder="Date Time 2"
                                    className={''}
                                    value={betVal2 ? dayjs(betVal2, "YYYY-MM-DD HH:mm:ss") : null}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    onChange={(e, val) => { 
                                        setConList(prev => {
                                            prev = prev?.map((c, i) => i === index ? ({...c, value: [betVal1, val]}) : ({...c}))
                                            return [...prev]
                                        })
                                    }}

                                />
                            </>
                            :
                            column?.dtype === 'date'
                            ?
                            <>
                                <DatePicker
                                    picker="date"
                                    placeholder="Date 1"
                                    className={''}
                                    value={betVal1 ? dayjs(betVal1, "YYYY-MM-DD") : null}
                                    format="YYYY-MM-DD"
                                    onChange={(e, val) => {
                                        setConList(prev => {
                                            prev = prev?.map((c, i) => i === index ? ({...c, value: [val, betVal2]}) : ({...c}))
                                            return [...prev]
                                        })
                                    }}
                                />
                                <div></div>
                                <DatePicker
                                    picker="date"
                                    placeholder="Date 2"
                                    className={''}
                                    value={betVal2 ? dayjs(betVal2, "YYYY-MM-DD") : null}
                                    format="YYYY-MM-DD"
                                    onChange={(e, val) => {
                                        setConList(prev => {
                                            prev = prev?.map((c, i) => i === index ? ({...c, value: [betVal1, val]}) : ({...c}))
                                            return [...prev]
                                        })
                                    }}
                                />
                            </>
                            :
                            <>
                                <Input
                                    size='middle'
                                    placeholder='Value 1'
                                    style={{height: '1.875rem'}}
                                    value={betVal1}
                                    type='text'
                                    onKeyDown={(e) => {
                                        e.stopPropagation()
                                    }}
                                    onChange={(e) => {
                                        let val = e?.target?.value
                                        setConList(prev => {
                                            prev = prev?.map((c, i) => i === index ? ({...c, value: [val, betVal2]}) : ({...c}))
                                            return [...prev]
                                        })
                                    }}
                                />
                                <div></div>
                                <Input
                                    size='middle'
                                    placeholder='Value 2'
                                    style={{height: '1.875rem'}}
                                    value={betVal2}
                                    type='text'
                                    onKeyDown={(e) => {
                                        e.stopPropagation()
                                    }}
                                    onChange={(e) => {
                                        let val = e?.target?.value
                                        setConList(prev => {
                                            prev = prev?.map((c, i) => i === index ? ({...c, value: [betVal1, val]}) : ({...c}))
                                            return [...prev]
                                        })
                                    }}
                                />
                            </>
                        }
                    </div>
                </>
            case 'is':
            case 'is not':
                return <>
                    <Input
                        size='middle'
                        style={{height: '1.875rem'}}
                        value={'BLANK/NULL'}
                        className='input-readyonly'
                    />
                </>
            default:
                return <></>
        }
    }

    const getDatatypeInput = (column, index, value) => {
        if(column?.dtype?.match(onlytimeRegex)?.length)
            return  <TimePicker
                        picker="time"
                        placeholder="Time"
                        className={''}
                        value={value ? dayjs(value, "HH:mm:ss") : null}
                        onChange={(e, val) => {
                            onInputChangeHandler(index, val)
                        }}
                    />
        else if(column?.dtype?.match(onlyDatetimeRegex)?.length)
        return  <DatePicker
                    picker="date"
                    showTime
                    placeholder="Date Time"
                    className={''}
                    value={value ? dayjs(value, "YYYY-MM-DD HH:mm:ss") : null}
                    format="YYYY-MM-DD HH:mm:ss"
                    onChange={(e, val) => {
                        onInputChangeHandler(index, val)
                    }}

                />
        else if(column?.dtype?.match(onlyDateRegex)?.length)
        return  <DatePicker
                    picker="date"
                    placeholder="Date"
                    className={''}
                    value={value ? dayjs(value, "YYYY-MM-DD") : null}
                    format="YYYY-MM-DD"
                    onChange={(e, val) => {
                        onInputChangeHandler(index, val)
                    }}
                />
        else if(column?.dtype === 'boolean')
        return  <Select
                    allowClear
                    options={[
                        {
                            value: 'true',
                            label: 'True',
                        },
                        {
                            value: 'false',
                            label: 'False',
                        }
                    ]}
                    value={value}
                    onChange={(val) => {
                        onInputChangeHandler(index, val)
                    }}
                />
        return <Input
                    size='middle'
                    placeholder='Value'
                    style={{height: '1.875rem'}}
                    type='number'
                    value={value}
                    onKeyDown={(e) => {
                        e.stopPropagation()
                    }}
                    onChange={(e) => {
                        let val = e?.target?.value
                        onInputChangeHandler(index, val)
                    }}
                />
    }

    return (
        <div className='new-dgt-flt-con-comp'>
            <div className='d-flex align-items-center justify-content-between mb-2'>
                <div></div>
                <div className='action-part d-flex'>
                    <Radio.Group
                        options={[
                            {
                                label: 'AND',
                                value: 'and',
                            },
                            {
                                label: 'OR',
                                value: 'or',
                            },
                        ]}
                        onChange={(e) => {
                            let op = e?.target?.value
                            setOperation(op)
                            colFilterRef.current[column?.name] = {
                                ...colFilterRef.current[column?.name],
                                operation: op
                            }
                        }}
                        value={operation}
                        optionType="button"
                        buttonStyle="solid"
                        className='prv-and-or-tg'
                    />
                    <span className='underline-button only-text fontInterSemiBold ml-3'
                        onClick={() => {
                            setConList(prev => [...prev, {criteria: 'none', value: '', date_factor: ''}])
                        }}>
                        <ICON_PLUS />
                        <span className='fontSizeLabel'>Add Criteria</span>
                    </span>
                </div>
            </div>
            <div className='flt-criteria-itm-li'>
                {
                    criteriaList?.map((d, idx) => (
                        <div 
                            key={`criteria-${d?.id}`}
                            className={`p-3 flt-criteria-wrap position-relative`} 
                            style={{background: '#F9F9F9', borderRadius: '0.375rem'}}>
                            <div className='mb-2'>
                                <DataGridCriteriaDropdown
                                    column={column}
                                    setConList={(criteria) => {
                                        setConList(prev => {
                                            prev = prev?.map((c, i) => i === idx 
                                                ? ({...c, criteria, value: criteria?.includes('is') ? 'BLANK/NULL' : criteria?.includes('between') ? ['', ''] : '' }) 
                                                : ({...c}) )
                                            return prev
                                        })
                                    }}
                                    activeCriteria={d?.criteria}
                                />
                            </div>
                            <div>
                                {
                                    getInputField({
                                        criteria: d?.criteria,
                                        index: idx,
                                        value: d?.value,
                                        date_factor: d?.date_factor ?? undefined
                                    })
                                }
                            </div>
                            <div className={`criteria-close-btn ${criteriaList?.length === 1 ? 'disabled' : ''}`}
                                onClick={() => {
                                    if(criteriaList?.length === 1) return
                                    setConList(prev => prev?.filter((_, i) => i !== idx))
                                }}
                            >
                                <ICON_CLOSE/>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
})

export default DataGridFilterByCondition