import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from "react";
import { Tooltip, Dropdown } from "antd";
import { getJourneyDate, getRelativeTime } from "../../../common/helperFunctions";
import {
  setConnectionSelectedConn,
  getConnectionProperties,
  setConnectionDynamicFields,
  setConnectionOptionalDynamicFields,
} from "../../../../../store/modules/UserManagement/connection/connectionActions";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  DELETEICON,
  EDIT,
  CONNECTIONSUCCESS,
  UPDATED_BY,
  DOMAIN_SMALL,
  EYEOPEN,
  USER,
} from "../../../../Common/iconSource";
import { Card, CardBody } from "reactstrap";
import "./connectionsGridView.scss";

import { openModel, testConnection } from "../../../../../store/actions";
import ConnectionContext from "./ConnectionContext";
import Status from "../../../../Common/status/Status";
import { setConnectionDetails } from "../../../../../store/modules/flows/NewFlowsRedux/targetTransformer/targetTransformer";
import SourceTypeImage from "../../../common/sourceTypeImage";
import Highlighter from "react-highlight-words";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { Literals } from "../../../common/literals";
import { ICON_CATALOG_DOMAIN } from "../../../../Common/newIconSource";

let handleMultipleCalls = false;

export const GridCard = ({
  index,
  data,
  handleSelection,
  openDeleteDialog,
  handleTestConnection,
  fromConnectionRoute,
  connectionStatus,
  handleConnectionTest,
  setOpenModal = () => { },
  selectedConn = [],
  searchValue,
  optionsVisible,
  fromModal,
  fromModalRoute,
  disableUnselected = false,
  setWalkThroughStep = () => { }
}) => {
  const [optionOpen, setOptionOpen] = useState(false)
  const isSuperUser = useSelector(state => state.UserReducer.user.userDetails.is_superuser)

  const showStatus =
    fromConnectionRoute &&
    connectionStatus &&
    connectionStatus.unqiueId === data.uniqueId;

  let style = null;

  const preSelectConnection = () => {
    if (window.location.pathname === Literals.links.CONNECTIONS) {
      return false;
    }
    else if (fromModal) {
      return selectedConn?.connection_name === data?.connection_name
    }
    else {
      return (
        selectedConn[0] === data?.connection_name
      );
    }
  };

  if (showStatus && preSelectConnection()) {
    style = {
      border: `1px solid ${connectionStatus.color}`,
    };
  }

  const optionList = [
    {
      label: <span className="dbox-opt-itm"
        onClick={(e) => {
          e.stopPropagation();
          onClickHandler()
        }}>
        <EYEOPEN />
        View
      </span>,
      key: '0'
    },
    {
      label: <span className={`dbox-opt-itm danger ${!isSuperUser ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!isSuperUser) return
          openDeleteDialog(data?.data);
        }}>
        <DELETEICON />
        Delete
      </span>,
      key: '1',
      disabled: !isSuperUser
    },
  ]

  const onClickHandler = () => {
    if (fromModal && disableUnselected) return

    setOpenModal(true);
    fromConnectionRoute
      ? handleConnectionTest(data)
      : handleSelection(data);

    /**For Walkthrough**/
    if (setWalkThroughStep) setWalkThroughStep(2)
  }

  return (
    <Card
      id={`conn-gridv-${index}`}
      className={`av-conn-card ${preSelectConnection() ? "selected-connection-cm" : disableUnselected ? "disabled-opt-conn" : ""}`}
      style={style}
      onClick={onClickHandler}
      role={data?.data?.file_system ? 'file_system' : 'non_file_system'}
    >
      <CardBody>
        <div className='data-view-dbox' onClick={() => { }}>
          <div className='_src_img'>
            {data.sources}
          </div>
          <div className='desc-box'>
            <div className="d-flex align-items-start">
              <Tooltip placement='topLeft' title={data?.connection_name?.toUpperCase()}>
                <label style={{ width: 'calc(100% - 52px)' }} className='paragraph mt-1 title-txt font-w-600 text-uppercase text-with-ellipsis'>
                  <Highlighter searchWords={[searchValue?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={data?.connection_name ? data?.connection_name : ''}> {data?.connection_name}</Highlighter>
                </label>
              </Tooltip>

              {
                optionsVisible
                  ? <Dropdown
                    menu={{
                      items: optionList,
                      onClick: (e) => e.domEvent.stopPropagation()
                    }}
                    trigger={["hover"]}
                    placement="bottomRight"
                    onOpenChange={(bool) => setOptionOpen(bool)}
                    overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                  >
                    <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                  </Dropdown>
                  : ''
              }
            </div>
            <div>
              {data.connection_description?.length > 0
                ?
                <Tooltip placement='topLeft' title={data.connection_description}>
                  <p className='mb-1 paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                    <Highlighter searchWords={[searchValue?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={data.connection_description ? data.connection_description : ''}>{data.connection_description}</Highlighter>
                  </p>
                </Tooltip>
                :
                <p className='mb-1 paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                  No description has been given yet.
                </p>
              }
            </div>
            <ul className="landingCard-profile-list">
              <li className="landingCard-profile-listitem d-flex mt-1 pt-1">
                <USER height="14" width="14" />
                <span className="label label-gray ml-1">{Literals.UPDATED_BY} </span>
                <Tooltip placement="top" title={data?.data?.updated_by_user?.email}>
                  <span className="paragraph ml-2 connection-desc-para">
                    <Highlighter searchWords={searchValue && searchValue.length ? [searchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.data?.updated_by_user?.email} />
                  </span>
                </Tooltip>
              </li>
              <li className="landingCard-profile-listitem d-flex mt-1 pt-1">
                <UPDATED_BY width="14" height="14" />
                <span className="label label-gray ml-1">{Literals.UPDATED_ON} </span>
                <Tooltip placement="top" title={getRelativeTime(data?.data?.updated_on) + ' (' + getJourneyDate(data?.data?.updated_on) + ')'}>
                  <span className="paragraph ml-2 connection-desc-para">
                    {/* <Highlighter searchWords={searchValue && searchValue.length ? [searchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.data?.updated_by_user?.email} /> */}
                    {getRelativeTime(data?.data?.updated_on)}
                  </span>
                </Tooltip>
              </li>
            </ul>
            <div className="round-tags-container mt-2">
              <Tooltip title={data?.data?.isAssignedConnection ? 'Assigned Connection' : 'Domains'} placement={'topLeft'}>
                <span className="conn-dmn-ic">
                  {
                    data?.data?.isAssignedConnection
                      ? <i className="bx bx-check-circle"></i>
                      : <ICON_CATALOG_DOMAIN height="14" width="14" />
                    // : <DOMAIN_SMALL/>
                  }
                </span>
              </Tooltip>
              {
                data?.data?.isAssignedConnection
                  ?
                  <>
                    {
                      data?.data?.assignedAs.filter(item => item.val === true).map((as, i) => (
                        <div
                          key={`_ass-conn-domains-ls-${i}`}
                          className={`custom-badge ${as?.text?.toLowerCase() === 'upload' ? 'primary' : 'warning'} mr-2 text-uppercase mb-1`}
                        >
                          {as.text}
                        </div>
                      ))
                    }
                  </>
                  :
                  data?.data?.data_domain?.length === 0
                    ?
                    <label className="fontSizeLabel text-black-50 mb-0">No Domains</label>
                    :
                    data?.domains
              }
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const ConnectionGridView = ({
  searchValue,
  unqiueId,
  listData,
  openModal,
  openCreateConnection,
  setOpenCreateConnection,
  openEditConnection,
  setOpenEditConnection,
  openDeleteDialog,
  setType,
  setOpenModal = () => { },
  setChangedConn = () => { },
  isChangeModal = false,
  optionsVisible = true,
  fromModal = false,
  disableUnselected = false,
  setWalkThroughStep = () => { },
  fromModalRoute = ""
}) => {

  const selectedConn = useSelector(
    (state) => state.UserManagement.ConnectionReducer.selectedConn
  );
  const connectionList = useSelector((state) => {
    return state.UserManagement.ConnectionReducer.dataList;
  });
  const dispatch = useDispatch();
  const {
    fromConnectionRoute,
    setFromConnectionRoute,
    connectionStatus,
    setConnectionStatus,
  } = useContext(ConnectionContext);

  const [data, setData] = useState([]);
  const { search } = useLocation();

  useEffect(() => {
    let temp = new URLSearchParams(search);

    let connection_name = temp.get("connection_name");
    let open = temp.get("open");
    if (connection_name) {
      let data = connectionList.filter(
        (item) => item?.connection_name?.toLowerCase() === connection_name?.toLowerCase()
      );
      // dispatch(setConnectionSelectedConn(data));
      if (!handleMultipleCalls) {
        handleMultipleCalls = true;

        handleSelection({ data: data[0] });
      }
    }
  }, []);
  const closeEditConnection = () => {
    setType("create");
    setOpenEditConnection(false);
  };

  const handleSelection = (record) => {
    if (openCreateConnection || openEditConnection) {
      emitToastNotification(
        "info",
        "Please close the already opened connection panel and then try"
      );
      // emitToastNotification('info',
      //   "Please close the already opened connection panel and then try"
      // );
    } else {
      localStorage.setItem("selectedConn", JSON.stringify(record.data));
      try {
        let requestBody = {
          action: "show_connector_properties",
          connector_name: record.data.source_type,
        };
        dispatch(getConnectionProperties(record.data.source_type)).then(
          (res) => {
            if (res?.data?.connection_properties !== undefined) {
              dispatch(
                setConnectionDynamicFields(res?.data?.connection_properties)
              );
              dispatch(
                setConnectionOptionalDynamicFields(res?.data?.optional_properties)
              );
              let resp = res?.data ?? {}
              dispatch(setConnectionSelectedConn({...record?.data, api:{...resp}}));
              closeEditConnection();
              setType("view");
              setOpenEditConnection(true);
              setOpenModal(true);
            } else {
              dispatch(setConnectionDynamicFields([]));
              dispatch(setConnectionOptionalDynamicFields([]));
            }
          }
        );
      } catch (e) { }
    }
  };

  const handlePreviousTestConnection = (data) => {
    setConnectionStatus(null);

    // let requestBody2 = {
    //   action: "test_connection",
    //   connection_name: data.data[0][0],
    // };
    // setConnectionStatus({
    //   color: "#2C83C4",
    //   message: "Establishing Connection",
    //   background: "rgba(44, 131, 196, 0.2)",
    //   Icon: <LOADING />,
    //   unqiueId: data.uniqueId,
    // });

    // try {
    // dispatch(testConnection(requestBody2))
    //   .then((res) => {
    //     if (res.status === "success") {
    setConnectionStatus({
      color: "#00CC92",
      message: "Selected",
      background: "rgba(0, 204, 146, 0.2)",
      Icon: <CONNECTIONSUCCESS />,
      unqiueId: data.uniqueId,
    });
    setChangedConn(data?.data)
    if (isChangeModal) return
    if (fromModal) {

    }
    // if(fromModalRoute !== 'target_transformer'){

    dispatch(setConnectionSelectedConn(data?.data));
    sessionStorage.setItem("selectedConn", JSON.stringify(data?.data));
    // }
    dispatch(setConnectionDetails(data?.data));

    // })
    // .catch(() => {
    //   setConnectionStatus({
    //     color: "#FF565E",
    //     message: "Failed",
    //     background: "rgba(255, 86, 94, 0.2)",
    //     Icon: <CONNECTIONBROKEN />,
    //     unqiueId: data.uniqueId,
    //   });
    // setTimeout(() => {
    //   setConnectionStatus(null);
    // }, 3000);
    //     });
    // } catch (e) {
    //   setConnectionStatus({
    //     color: "#FF565E",
    //     message: "Failed",
    //     background: "rgba(255, 86, 94, 0.2)",
    //     Icon: <CONNECTIONBROKEN />,
    //     unqiueId: data.uniqueId,
    //   });
    // setTimeout(() => {
    //   setConnectionStatus(null);
    // }, 3000);

    // }
  };

  const handleTestConnection = (data) => {
    let requestBody2 = {
      action: "test_connection",
      connection_name: data?.data?.connection_name,
    };
    try {
      dispatch(testConnection(requestBody2))
        .then(() => {
          // setCreateConnFlag(true)
        })
        .catch((e) => { });
    } catch (e) { }
  };

  useMemo(() => {
    if (listData !== undefined && listData !== null) {

      let temp = _.uniqBy(listData, 'connection_name').map((v, i) => {
        let unique_domain_list = v?.data_domain?.map(
          (el) => el?.data_domain_name
        );

        if (fromModalRoute === 'target_transformer' && !v.assigned_as_cache && !v.assigned_as_upload && v.data_domain?.length) {
          return ({
            key: i,
            connection_name: (
              v.connection_name ?? ''
            ),
            connection_description: (
              _.isEmpty(v?.connection_description)
                ?
                <div className="conn-desc-min-hght"></div>
                :
                v?.connection_description ?? ''
            ),
            sources: <SourceTypeImage dataName={v?.source_type} />,
            createdOn: (
              <Tooltip placement="top" title={getRelativeTime(v?.created_on)}>
                {getRelativeTime(v?.created_on)}
              </Tooltip>
            ),
            createdBy: (
              <Tooltip placement="top" title={v?.created_by_user?.email}>
                {v?.created_by_user?.email}
              </Tooltip>
            ),
            data: v,
            uniqueId: `${unqiueId}-${i}`,
            domains: (
              <>
                {unique_domain_list.map((domain, i) => {
                  return (
                    <React.Fragment key={`conn-domains-ls-${i}`}>
                      {i < 2 ? (
                        <div
                          className="custom-badge success mr-2 mb-1"
                        >
                          {domain}
                        </div>
                      ) : (
                        ""
                      )}
                      {i === 2 ? (
                        <Tooltip
                          placement="topLeft"
                          title={unique_domain_list.slice(2).join(", ")}
                        >
                          <div
                            className="custom-badge success mb-1"
                          >
                            +{unique_domain_list.length - 2}
                          </div>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            ),
          });

        } else if (fromModalRoute !== 'target_transformer') {

          return ({
            key: i,
            connection_name: (
              v.connection_name ?? ''
            ),
            connection_description: (
              _.isEmpty(v?.connection_description)
                ?
                <div className="conn-desc-min-hght"></div>
                :
                v?.connection_description ?? ''
            ),
            sources: <SourceTypeImage dataName={v?.source_type} />,
            createdOn: (
              <Tooltip placement="top" title={getRelativeTime(v?.created_on)}>
                {getRelativeTime(v?.created_on)}
              </Tooltip>
            ),
            createdBy: (
              <Tooltip placement="top" title={v?.created_by_user?.email}>
                {v?.created_by_user?.email}
              </Tooltip>
            ),
            data: v,
            uniqueId: `${unqiueId}-${i}`,
            domains: (
              <>
                {unique_domain_list.map((domain, i) => {
                  return (
                    <React.Fragment key={`conn-domains-ls-${i}`}>
                      {i < 2 ? (
                        <div
                          className="custom-badge success mr-2 mb-1"
                        >
                          {domain}
                        </div>
                      ) : (
                        ""
                      )}
                      {i === 2 ? (
                        <Tooltip
                          placement="topLeft"
                          title={unique_domain_list.slice(2).join(", ")}
                        >
                          <div
                            className="custom-badge success mb-1"
                          >
                            +{unique_domain_list.length - 2}
                          </div>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            ),
          });

        }


      }).filter(item => item);

      setData([...temp]);
    }
  }, [listData]);

  return (
    <>
      <div className="connectiongridview-responsive new-box-style">
        {data &&
          _.sortBy(data, [
            (o) => o?.data?.isAssignedConnection,
            (o) => o?.connection_name?.toLocaleLowerCase()
          ])
            .map((val, i) => {
              return (
                <div key={`grid-crd-conn-${i}`}>
                  <GridCard
                    index={i}
                    fromConnectionRoute={fromConnectionRoute}
                    data={val}
                    handleSelection={handleSelection}
                    openDeleteDialog={openDeleteDialog}
                    handleTestConnection={handleTestConnection}
                    connectionStatus={connectionStatus}
                    handleConnectionTest={handlePreviousTestConnection}
                    // setOpenModal={setOpenModal}
                    searchValue={searchValue}
                    selectedConn={selectedConn}
                    optionsVisible={optionsVisible}
                    fromModal={fromModal}
                    fromModalRoute={fromModalRoute}
                    disableUnselected={disableUnselected}
                    setWalkThroughStep={setWalkThroughStep}
                  />
                </div>
              );
            })}
      </div>
    </>
  );
};

export default ConnectionGridView;